import { createSlice } from '@reduxjs/toolkit'

export const webChatReducer = createSlice({
  name: 'webChat',
  initialState: {
    webChatAuth: {
      loading: false,
      isAuthenticated: false,
    },
    webChatUsername: '',
    webChatFormKey: '',
  },
  reducers: {
    setWebChatIsAuthenticated: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.webChatAuth.isAuthenticated = true
    },
    setWebChatIsNotAuthenticated: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.webChatAuth.isAuthenticated = false
    },
    setWebChatUsername: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.webChatUsername = action.payload
    },
    setWebChatAuthLoading: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.webChatAuth.loading = true
    },
    setWebChatAuthLoaded: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.webChatAuth.loading = false
    },
  },
})

export const { setWebChatIsAuthenticated, setWebChatIsNotAuthenticated, setWebChatUsername, setWebChatAuthLoading, setWebChatAuthLoaded } =
  webChatReducer.actions

export const getWebChatUsername = (state) => {
  return state.webChat.webChatUsername
}

export const getWebChatIsAuthenticated = (state) => {
  return state.webChat.webChatAuth.isAuthenticated
}

export const getWebChatAuthLoading = (state) => {
  return state.webChat.webChatAuth.loading
}

export default webChatReducer.reducer
