import { createSlice } from '@reduxjs/toolkit'

export const userReducer = createSlice({
  name: 'user',
  initialState: {
    currentCompany: null,
    currentCompanyFeatures: [],
    currentCompanyDomain: null,
    currentUser: null,
    currentCompanyRoleUser: null,
    currentUserApiKey: null,
    currentUserPermissions: [],
  },
  reducers: {
    userIsChatting: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.currentUser.isChatting = true
    },
    userIsNotChatting: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.currentUser.isChatting = false
    },
    setCurrentCompany: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentCompany = action.payload
    },
    setCurrentCompanyFeatures: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentCompanyFeatures = action.payload
    },
    setCurrentCompanyDomain: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentCompanyDomain = action.payload
    },
    setCurrentUser: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentUser = action.payload
    },
    setCurrentCompanyRoleUser: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentCompanyRoleUser = action.payload
    },
    setCurrentUserApiKey: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentUserApiKey = action.payload
    },
    setCurrentUserPermissions: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentUserPermissions = action.payload
    },
  },
})

export const {
  setCurrentCompany,
  setCurrentCompanyFeatures,
  setCurrentCompanyDomain,
  setCurrentUser,
  setCurrentCompanyRoleUser,
  setCurrentUserApiKey,
  setCurrentUserPermissions,
  userIsChatting,
  userIsNotChatting,
} = userReducer.actions

export const getCurrentUser = (state) => {
  return state.user.currentUser || null
}
export const getCurrentCompanyRoleUser = (state) => {
  return state.user.currentCompanyRoleUser || null
}
export const getCurrentUserApiKey = (state) => {
  return state.user.currentUserApiKey || null
}
export const getCurrentCompany = (state) => {
  return state.user.currentCompany
}
export const getCurrentCompanyFeatures = (state) => {
  return state.user.currentCompanyFeatures
}
export const getCurrentCompanyDomain = (state) => {
  return state.user.currentCompanyDomain
}
export const getPermissions = (state) => {
  return state.user.currentUserPermissions
}

export default userReducer.reducer
