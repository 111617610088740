export default {
  colors: {
    black: '#333',
    arsenic: 'rgba(57, 70, 72, 1)',
    green: 'rgba(105, 153, 93, 1)',
    greenDark: '#53784a',
    greenLight: '#84ae7a',
    greenSuperLight: 'rgb(105, 177, 81)',
    laurelGreen: '#A6C19C',
    kellyGreen: '#4CBB17',
    beige: 'rgba(203, 172, 136, 1)',
    desert: 'rgba(237, 182, 163, 1)',
    linen: 'rgba(248, 233, 233, 1)',
    white: '#fff',
    grayLight: '#f5f5f5',
    grayDark: '#dbdbdb',
    pastelGrey: `#C6D8C0`,
    blue: '#0984e3',
    blueLight: '#e5f2ff',
    moonstoneBlue: `#79BCB8`,
    middleBlue: '#7FC8E2',
    topaz: `#FFC267`,
    terraCotta: `#ED6A5A`,
    bubbles: `#E6F2FF`,
    pastelPink: '#EDA59C',
    red: '#D2273B',
  },
  space: {
    none: '0px',
    xSmall: '2px',
    small: '5px',
    medium: '10px',
    large: '20px',
    mLarge: '25px',
    xLarge: '40px',
  },
  fonts: {
    sourceSansPro: 'Source Sans Pro, Arial',
  },
  fontSizes: ['10px', '12px', '14px', '16px', '20px', '24px', '32px'],
  radii: {
    none: '0px',
    small: '5px',
  },
  breakpoints: {
    tablet: '768px',
    desktop: '1024px',
  },
  mediaQueries: {
    tablet: `@media screen and (min-width: 768px)`,
    large: `@media screen and (min-width: 1024px)`,
  },
}
