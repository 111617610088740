import { gql } from '@apollo/client'

export const companySafeFields = gql`
  fragment CompanySafeFields on company {
    id
    name
    username
    domain: domain
    cfDomainIdentifier: cf_domain_identifier
    cfDomainConfirmed: cf_domain_confirmed
    apiDomain: api_domain
    cfApiDomainIdentifier: cf_api_domain_identifier
    cfApiDomainConfirmed: cf_api_domain_confirmed
    trackingDomain: tracking_domain
    cfTrackingDomainIdentifier: cf_tracking_domain_identifier
    cfTrackingDomainConfirmed: cf_tracking_domain_confirmed
    parentCompanyId: parent_company_id
    companyTypeId: company_type_id
    companyType: company_type {
      id
      name
      shortcode
      plans {
        plan {
          id
        }
      }
    }
    companyOwnerId: company_owner_id
    companyOwner: company_owner {
      id
      user {
        id
        firstName: first_name
        email
      }
    }
    logoUrl: logo_cloudinary
    faviconUrl: favicon_cloudinary
    colorLeftSidebar: color_left_sidebar
    colorBackground: color_background
    colorButtonMain: color_button_main
    colorButtonSecondary: color_button_secondary
    colorHeader: color_header
    colorLeftSidebarHover: color_left_sidebar_hover
    colorLeftSidebarSelected: color_left_sidebar_selected
    allowUserSignup: allow_user_signup
    allowCompanySignup: allow_company_signup
    useChatCompany: use_chat_company
    useGlobalIntents: use_global_intents
    isApproved: is_approved
    isEnabled: is_enabled
    stripeCustomerId: stripe_customer_id
    stripeSubscriptionId: stripe_subscription_id
    stripePlanPriceId: stripe_plan_price_id
    planId: plan_id
    ## Plan Features

    plan {
      id
      planFeatures: plan_features {
        id
        isUpgradeable: is_upgradeable
        isUnlimited: is_unlimited
        limit
        feature {
          id
          featureKey: feature_key
        }
      }
    }
  }
`

export const companyFields = gql`
  fragment CompanyFields on company {
    id
    name
    metabaseDashboardId: metabase_dashboard_id
    username
    domain
    apiDomain: api_domain
    trackingDomain: tracking_domain
    parentCompanyId: parent_company_id
    parentCompany: parent_company {
      id
      name
    }
    childCompanies: child_companies {
      id
      name
    }
    companyTypeId: company_type_id
    companyType: company_type {
      id
      name
      shortcode
    }
    companyFeatures: features {
      feature {
        id
        featureKey: feature_key
      }
    }
    companyOwnerId: company_owner_id
    companyOwner: company_owner {
      id
      user {
        id
        firstName: first_name
        email
      }
    }
    stripeCustomerId: stripe_customer_id
    stripeSubscriptionId: stripe_subscription_id
    stripePlanPriceId: stripe_plan_price_id
    externalId: company_external_affiliate_id
    logoUrl: logo_cloudinary
    faviconUrl: favicon_cloudinary
    colorLeftSidebar: color_left_sidebar
    colorBackground: color_background
    colorButtonMain: color_button_main
    colorButtonSecondary: color_button_secondary
    colorHeader: color_header
    colorLeftSidebarHover: color_left_sidebar_hover
    colorLeftSidebarSelected: color_left_sidebar_selected
    defaultOpsGoogleId: default_ops_google_id
    defaultOpsKikId: default_ops_kik_id
    defaultOpsSkypeId: default_ops_skype_id
    transcribeRecordings: transcribe_recordings
    isApproved: is_approved
    isEnabled: is_enabled
    allowUserSignup: allow_user_signup
    allowCompanySignup: allow_company_signup
    companyCode: company_code
    companyApiKey: company_api_key
    smtpFromEmail: SMTP_from_email
    smtpHost: SMTP_host
    smtpPort: SMTP_port
    smtpSecure: SMTP_secure
    smtpAuthMethod: SMTP_auth_method
    smtpAuthUsername: SMTP_auth_username
    smtpAuthPassword: SMTP_auth_password
    useChatCompany: use_chat_company
    useGlobalIntents: use_global_intents
    personasAutoRemoveEnabled: personas_auto_remove_enabled
    personasAutoRemoveHours: personas_auto_remove_hours
    planId: plan_id
    ## Plan Features

    plan {
      id
      name
      friendlyName: friendly_name
      price
      planFeatures: plan_features {
        id
        isUpgradeable: is_upgradeable
        limit
        feature {
          id
          featureKey: feature_key
        }
      }
    }
  }
`
export const allCompaniesQuery = gql`
  query AllCompanies {
    companies: company(order_by: { name: asc }) {
      ...CompanyFields
    }
  }
  ${companyFields}
`

export const getCompanyQuery = gql`
  query Company($id: Int!) {
    company: company(where: { id: { _eq: $id } }) {
      ...CompanyFields
    }
  }
  ${companyFields}
`

export const getCompaniesByFilterQuery = gql`
  query CompanyByFilter($filter: company_bool_exp) {
    companies: company(where: $filter) {
      ...CompanyFields
    }
  }
  ${companyFields}
`

export const getCompanyDomainsQuery = gql`
  query CompanyDomains($id: Int!) {
    company: company(where: { id: { _eq: $id } }) {
      domain: domain
      cfDomainIdentifier: cf_domain_identifier
      cfDomainConfirmed: cf_domain_confirmed
      apiDomain: api_domain
      cfApiDomainIdentifier: cf_api_domain_identifier
      cfApiDomainConfirmed: cf_api_domain_confirmed
      trackingDomain: tracking_domain
      cfTrackingDomainIdentifier: cf_tracking_domain_identifier
      cfTrackingDomainConfirmed: cf_tracking_domain_confirmed
    }
  }
`

export const getAllCompanyDomainsQuery = gql`
  query GetAllCompanyDomains {
    companies: company(order_by: { name: asc }, where: { domain: { _is_null: false } }) {
      domain
      cf_domain_identifier
      tracking_domain
      cf_api_domain_identifier
      api_domain
      cf_tracking_domain_identifier
    }
  }
`

export const allAdvertiserCompaniesQuery = gql`
  query AllAdvertiserCompanies {
    companies: company(order_by: { name: asc }, where: { company_type: { shortcode: { _eq: "advertiser" } } }) {
      ...CompanyFields
    }
  }
  ${companyFields}
`

export const allChatCompaniesQuery = gql`
  query AllChatCompanies {
    companies: company(order_by: { name: asc }, where: { company_type: { shortcode: { _eq: "chat_company" } } }) {
      ...CompanyFields
    }
  }
  ${companyFields}
`

export const allWhiteLabelCompaniesQuery = gql`
  query AllWhiteLabelCompanies {
    companies: company(order_by: { name: asc }, where: { company_type: { shortcode: { _eq: "whitelabel" } } }) {
      ...CompanyFields
    }
  }
  ${companyFields}
`

export const getCompanyByDomainQuery = gql`
  query CompanyByDomainQuery($domain: String!) {
    company(where: { domain: { _eq: $domain } }) {
      ...CompanySafeFields
    }
    defaultCompany: company(where: { id: { _eq: 1 } }) {
      ...CompanySafeFields
    }
  }
  ${companySafeFields}
`

export const updateCompanyMutation = gql`
  mutation UpdateCompany($id: Int!, $changes: company_set_input) {
    UpdateCompany: update_company(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
    }
  }
`

export const insertCompanyMutation = gql`
  mutation InsertCompany($object: company_insert_input!) {
    insert_company(objects: [$object]) {
      returning {
        id
      }
    }
  }
`

export const deleteCompanyMutation = gql`
  mutation DeleteCompany($id: Int!) {
    delete_company(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

export const getCompanyByUserName = gql`
  query getCompanyByUserName($username: String!) {
    company: company(limit: 1, where: { username: { _eq: $username } }) {
      username
    }
  }
`

export const getCompanyByAnyDomain = gql`
  query getCompanyByAnyDomains($domain: String!) {
    company: company(
      limit: 1
      where: { _or: [{ domain: { _eq: $domain } }, { api_domain: { _eq: $domain } }, { tracking_domain: { _eq: $domain } }] }
    ) {
      id
      domain
      api_domain
      tracking_domain
    }
  }
`

export const getCompanyByCompanyDomain = gql`
  query getCompanyByCompanyDomain($domain: String!) {
    company: company(limit: 1, where: { domain: { _eq: $domain } }) {
      id
      domain
    }
  }
`

export const getCompanyByCompanyApiDomain = gql`
  query getCompanyByCompanyApiDomain($apiDomain: String!) {
    company: company(limit: 1, where: { api_domain: { _eq: $apiDomain } }) {
      id
      api_domain
    }
  }
`

export const getCompanyByCompanyTrackingDomain = gql`
  query getCompanyByCompanyTrackingDomain($trackingDomain: String!) {
    company: company(limit: 1, where: { tracking_domain: { _eq: $trackingDomain } }) {
      id
      tracking_domain
    }
  }
`
