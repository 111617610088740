export const setInLocalStorage = (key: string, object: object) => {
  try {
    const stringifiedObject = JSON.stringify(object)
    localStorage.setItem(key, stringifiedObject)
    return true
  } catch (error) {
    return false
  }
}

export const getFromLocalStorage = (key: string) => {
  try {
    const stringifiedObject = localStorage.getItem(key)

    return stringifiedObject ? JSON.parse(stringifiedObject) : null
  } catch (error) {
    return null
  }
}

export const clearLocalStorage = () => {
  try {
    localStorage.clear()
    return true
  } catch (error) {
    return false
  }
}
