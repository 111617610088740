import React from 'react'
import { ApolloProvider, ApolloClient, HttpLink, split } from '@apollo/client'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'

import fetch from 'isomorphic-unfetch'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getHasuraApiUrl, getHasuraApiWebSocketUrl, getHasuraAdminSecret } from './settings'

let singletonApolloClient: ApolloClient<NormalizedCacheObject> | undefined

const hasuraAdminSecret = getHasuraAdminSecret()

/**
 * Creates and configures the ApolloClient
 */
export function createApolloClient(initialState = {}) {
  const wsLink = new WebSocketLink({
    uri: getHasuraApiWebSocketUrl(), // Server URL (must be absolute)
    options: {
      reconnect: true,
      connectionParams: async () => {
        return {
          headers: {
            'x-hasura-admin-secret': hasuraAdminSecret,
          },
        }
      },
    },
  })

  // FIXME(KMK): Move this to backend API call as to hide api key
  const graphqlLink = new HttpLink({
    uri: getHasuraApiUrl(), // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    fetchOptions: {
      mode: 'cors',
    },
    fetch,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'x-hasura-admin-secret': hasuraAdminSecret,
    },
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    graphqlLink
  )

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: link,
    cache: new InMemoryCache().restore(initialState),
  })
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
export function initApolloClient(initialState = {}) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)

  if (typeof window === 'undefined') {
    return createApolloClient(initialState)
  }

  // Reuse client on the client-side
  if (!singletonApolloClient) {
    singletonApolloClient = createApolloClient(initialState)
  }

  return singletonApolloClient
}

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 */
export function withApollo(PageComponent, { ssr = true } = {}) {
  // eslint-disable-next-line react/prop-types
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client = apolloClient || initApolloClient(apolloState)
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    )
  }

  // Set the correct displayName in development
  if (process.env.REACT_APP_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component'

    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.')
    }

    WithApollo.displayName = `withApollo(${displayName})`
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx) => {
      const { AppTree } = ctx

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      // eslint-disable-next-line no-param-reassign
      ctx.apolloClient = initApolloClient()
      const apolloClient = ctx.apolloClient

      // Run wrapped getInitialProps methods
      let pageProps = {}
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx)
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/client/react/ssr')
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient,
                }}
              />
            )
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error)
          }

          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          // Head.rewind()
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract()

      return {
        ...pageProps,
        apolloState,
      }
    }
  }

  return WithApollo
}

// export const AuthGraphql = new ApolloClient({
//   ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
//   link: new HttpLink({
//     uri: AuthPath(),
//     credentials: 'same-origin',
//     fetch,
//   }),
//   cache: new InMemoryCache().restore({}),
// })
