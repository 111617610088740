import * as Types from './operation-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const ConversationNoteFieldsFragmentDoc = gql`
    fragment ConversationNoteFields on conversation_note {
  id
  created_at
  noteMessage: note_message
  reviewRating: review_rating
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  conversationId: conversation_id
}
    `;
export const FlowFieldsFragmentDoc = gql`
    fragment FlowFields on flow {
  id
  name
  noReplyGoLive: no_reply_go_live
  noReplyGoLiveStartAt: no_reply_go_live_start_at
  noReplyGoLiveEndAt: no_reply_go_live_end_at
  firstMessageDelayStartAt: first_message_delay_start_at
  firstMessageDelayEndAt: first_message_delay_end_at
  normalMessageDelayStartAt: normal_message_delay_start_at
  normalMessageDelayEndAt: normal_message_delay_end_at
  flowFollowupMessageDelayStartAt: flow_follow_up_message_delay_start_at
  flowFollowupMessageDelayEndAt: flow_follow_up_message_delay_end_at
  noReplyMessageDelayStartAt: no_reply_message_delay_start_at
  noReplyMessageDelayEndAt: no_reply_message_delay_end_at
  incomingCallMessageDelayStartAt: incoming_call_message_delay_start_at
  incomingCallMessageDelayEndAt: incoming_call_message_delay_end_at
  rotateOperators: rotate_operators
  rebumpDelayEnabled: rebump_delay_enabled
  rebumpDelayStartAt: rebump_delay_start_at
  rebumpDelayEndAt: rebump_delay_end_at
  rebumpDelayStartSendAt: rebump_delay_start_send_at
  rebumpDelayEndSendAt: rebump_delay_end_send_at
  transferToSMS: transfer_to_sms
  defaultOfferId: default_offer_id
  overrideOfferId: override_offer_id
  resendOfferLinkMessage: resend_offer_link_message
  resendOfferLinkAttempts: resend_offer_link_attempts
  campaign {
    id
    name
    isEnabled: is_enabled
  }
  messages: chatbot_messages(
    order_by: [{rank: asc}, {id: asc}]
    where: {message_type: {_eq: "flow"}}
  ) {
    id
    text
    rank
    messageType: message_type
  }
  noReplies: chatbot_messages(
    order_by: [{rank: asc}, {id: asc}]
    where: {message_type: {_eq: "no-reply"}}
  ) {
    id
    text
    rank
    messageType: message_type
  }
  incomingCallReplies: chatbot_messages(
    order_by: [{id: asc}]
    where: {message_type: {_eq: "incoming-call-reply"}}
  ) {
    id
    text
    rank
    messageType: message_type
  }
  rebumpMessages: chatbot_messages(
    order_by: [{id: asc}]
    where: {message_type: {_eq: "rebump"}}
  ) {
    id
    text
    rank
    messageType: message_type
  }
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on company {
  id
  name
  metabaseDashboardId: metabase_dashboard_id
  username
  domain
  apiDomain: api_domain
  trackingDomain: tracking_domain
  parentCompanyId: parent_company_id
  parentCompany: parent_company {
    id
    name
  }
  childCompanies: child_companies {
    id
    name
  }
  companyTypeId: company_type_id
  companyType: company_type {
    id
    name
    shortcode
  }
  companyFeatures: features {
    feature {
      id
      featureKey: feature_key
    }
  }
  companyOwnerId: company_owner_id
  companyOwner: company_owner {
    id
    user {
      id
      firstName: first_name
      email
    }
  }
  stripeCustomerId: stripe_customer_id
  stripeSubscriptionId: stripe_subscription_id
  stripePlanPriceId: stripe_plan_price_id
  externalId: company_external_affiliate_id
  logoUrl: logo_cloudinary
  faviconUrl: favicon_cloudinary
  colorLeftSidebar: color_left_sidebar
  colorBackground: color_background
  colorButtonMain: color_button_main
  colorButtonSecondary: color_button_secondary
  colorHeader: color_header
  colorLeftSidebarHover: color_left_sidebar_hover
  colorLeftSidebarSelected: color_left_sidebar_selected
  defaultOpsGoogleId: default_ops_google_id
  defaultOpsKikId: default_ops_kik_id
  defaultOpsSkypeId: default_ops_skype_id
  transcribeRecordings: transcribe_recordings
  isApproved: is_approved
  isEnabled: is_enabled
  allowUserSignup: allow_user_signup
  allowCompanySignup: allow_company_signup
  companyCode: company_code
  companyApiKey: company_api_key
  smtpFromEmail: SMTP_from_email
  smtpHost: SMTP_host
  smtpPort: SMTP_port
  smtpSecure: SMTP_secure
  smtpAuthMethod: SMTP_auth_method
  smtpAuthUsername: SMTP_auth_username
  smtpAuthPassword: SMTP_auth_password
  useChatCompany: use_chat_company
  useGlobalIntents: use_global_intents
  personasAutoRemoveEnabled: personas_auto_remove_enabled
  personasAutoRemoveHours: personas_auto_remove_hours
  planId: plan_id
  plan {
    id
    name
    friendlyName: friendly_name
    price
    planFeatures: plan_features {
      id
      isUpgradeable: is_upgradeable
      limit
      feature {
        id
        featureKey: feature_key
      }
    }
  }
}
    `;
export const CampaignFieldsFragmentDoc = gql`
    fragment CampaignFields on campaign {
  id
  name
  belongs_to_company_id
  isEnabled: is_enabled
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    user {
      id
      username: username
    }
  }
  category {
    id
    categoryName: category_name
  }
  flows {
    ...FlowFields
    conversations_aggregate {
      aggregate {
        count
      }
    }
    chatbot_messages_aggregate(where: {message_type: {_eq: "flow"}}) {
      aggregate {
        count
      }
    }
  }
  phone_numbers_aggregate {
    aggregate {
      count
    }
  }
  company {
    ...CompanyFields
  }
}
    ${FlowFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;
export const CampaignResponderFieldsFragmentDoc = gql`
    fragment CampaignResponderFields on campaign_responder {
  id
  createdAt: created_at
  updatedAt: updated_at
  belongsToCompanyId: belongs_to_company_id
  company {
    id
    name
  }
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    user {
      id
      username: username
    }
  }
  name
  isEnabled: is_enabled
  campaignId: campaign_id
  campaign {
    id
    name
  }
  offerId: offer_id
  offer {
    id
    name
  }
  fallbackPersonaId: fallback_persona_id
  fallbackTargetSiteId: fallback_target_site_id
  startDate: start_date
  endDate: end_date
  monStartTime: mon_start_time
  monEndTime: mon_end_time
  tueStartTime: tue_start_time
  tueEndTime: tue_end_time
  wedStartTime: wed_start_time
  wedEndTime: wed_end_time
  thuStartTime: thu_start_time
  thuEndTime: thu_end_time
  friStartTime: fri_start_time
  friEndTime: fri_end_time
  satStartTime: sat_start_time
  satEndTime: sat_end_time
  sunStartTime: sun_start_time
  sunEndTime: sun_end_time
  campaignResponderContactLists: campaign_responder_contact_lists {
    campaignResponderId: campaign_responder_id
    contactListId: contact_list_id
  }
  campaign_responder_contact_lists_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const ContactListFieldsFragmentDoc = gql`
    fragment ContactListFields on contact_list {
  id
  createdAt: created_at
  updatedAt: updated_at
  name
  belongsToCompanyId: belongs_to_company_id
  company {
    id
  }
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    user {
      id
      username: username
    }
  }
  status
  errorMessage: error_message
  originalFilename: original_filename
  fileLocation: file_location
  totalContactCount: total_contact_count
  importedContactCount: imported_contact_count
  duplicateContactCount: duplicate_contact_count
  malformedContactCount: malformed_contact_count
  suppressedContactCount: suppressed_contact_count
  completedContactCount: completed_contact_count
}
    `;
export const CampaignResponderContactListFieldsFragmentDoc = gql`
    fragment CampaignResponderContactListFields on campaign_responder_contact_list {
  campaignResponderId: campaign_responder_id
  campaign_responder {
    ...CampaignResponderFields
  }
  contactListId: contact_list_id
  contactList: contact_list {
    ...ContactListFields
  }
}
    ${CampaignResponderFieldsFragmentDoc}
${ContactListFieldsFragmentDoc}`;
export const CannedResponseFieldsFragmentDoc = gql`
    fragment CannedResponseFields on canned_response {
  id
  name
  slashCommand: slash_command
  text
}
    `;
export const CarrierFieldsFragmentDoc = gql`
    fragment CarrierFields on carrier {
  id
  canSendMms: can_send_mms
  carrierName: carrier_name
  carrierNameAlias: carrier_name_alias
  carrierType: carrier_type
  countryIso: country_iso
  countryCode: country_code
  countryName: country_name
  countryId: country_id
  country {
    id
    name
  }
  mobileCountryCode: mobile_country_code
  mobileNetworkCode: mobile_network_code
  carrierNotes: carrier_notes
  blockDelivery: block_delivery
  unicodeSwapOn: unicode_swap_on
  contacts_aggregate {
    aggregate {
      contactCount: count
    }
  }
}
    `;
export const CategoriesFragmentDoc = gql`
    fragment Categories on category {
  id
  categoryName: category_name
}
    `;
export const CompanySafeFieldsFragmentDoc = gql`
    fragment CompanySafeFields on company {
  id
  name
  username
  domain: domain
  cfDomainIdentifier: cf_domain_identifier
  cfDomainConfirmed: cf_domain_confirmed
  apiDomain: api_domain
  cfApiDomainIdentifier: cf_api_domain_identifier
  cfApiDomainConfirmed: cf_api_domain_confirmed
  trackingDomain: tracking_domain
  cfTrackingDomainIdentifier: cf_tracking_domain_identifier
  cfTrackingDomainConfirmed: cf_tracking_domain_confirmed
  parentCompanyId: parent_company_id
  companyTypeId: company_type_id
  companyType: company_type {
    id
    name
    shortcode
    plans {
      plan {
        id
      }
    }
  }
  companyOwnerId: company_owner_id
  companyOwner: company_owner {
    id
    user {
      id
      firstName: first_name
      email
    }
  }
  logoUrl: logo_cloudinary
  faviconUrl: favicon_cloudinary
  colorLeftSidebar: color_left_sidebar
  colorBackground: color_background
  colorButtonMain: color_button_main
  colorButtonSecondary: color_button_secondary
  colorHeader: color_header
  colorLeftSidebarHover: color_left_sidebar_hover
  colorLeftSidebarSelected: color_left_sidebar_selected
  allowUserSignup: allow_user_signup
  allowCompanySignup: allow_company_signup
  useChatCompany: use_chat_company
  useGlobalIntents: use_global_intents
  isApproved: is_approved
  isEnabled: is_enabled
  stripeCustomerId: stripe_customer_id
  stripeSubscriptionId: stripe_subscription_id
  stripePlanPriceId: stripe_plan_price_id
  planId: plan_id
  plan {
    id
    planFeatures: plan_features {
      id
      isUpgradeable: is_upgradeable
      isUnlimited: is_unlimited
      limit
      feature {
        id
        featureKey: feature_key
      }
    }
  }
}
    `;
export const CompanyChatCompanyFieldsFragmentDoc = gql`
    fragment CompanyChatCompanyFields on company_chat_company {
  id
  companyId: company_id
  company {
    id
    name
  }
  chatCompanyId: chat_company_id
  chatCompany: chat_company {
    id
    name
  }
  redactCompanyMessages: redact_company_messages
  redactChatCompanyMessages: redact_chat_company_messages
}
    `;
export const CompanyChatCompanyRotationFieldsFragmentDoc = gql`
    fragment CompanyChatCompanyRotationFields on company_chat_company_rotation {
  id
  createdAt: created_at
  updatedAt: updated_at
  belongsToCompanyId: belongs_to_company_id
  belongsToChatCompanyId: belongs_to_chat_company_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleUserId: belongs_to_role_user_id
  createdByRoleUserId: created_by_role_user_id
  platformId: platform_id
  platform {
    id
    name
  }
  chatCompany: chat_company {
    id
    name
  }
  phoneNumberId: phone_number_id
  webChatFormId: web_chat_form_id
  dailyCap: daily_cap
  weight
}
    `;
export const CompanyFeatureFieldsFragmentDoc = gql`
    fragment CompanyFeatureFields on company_feature {
  company {
    id
    name
  }
  feature {
    id
    feature_key
  }
}
    `;
export const CompanyFlowRotationFieldsFragmentDoc = gql`
    fragment CompanyFlowRotationFields on company_flow_rotation {
  id
  createdAt: created_at
  updatedAt: updated_at
  belongsToCompanyId: belongs_to_company_id
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleUserId: belongs_to_role_user_id
  createdByRoleUserId: created_by_role_user_id
  campaignId: campaign_id
  campaign {
    id
    name
  }
  platformId: platform_id
  platform {
    id
    name
  }
  phoneNumberId: phone_number_id
  webChatFormId: web_chat_form_id
  dailyCap: daily_cap
  weight
}
    `;
export const CompanyOfferCampaignUrlFieldsFragmentDoc = gql`
    fragment CompanyOfferCampaignUrlFields on company_offer_campaign_url {
  id
  companyOfferId: company_offer_id
  offerId: offer_id
  campaignOverrideUrl: campaign_override_url
  belongsToCompanyId: belongs_to_company_id
}
    `;
export const DomainFieldsFragmentDoc = gql`
    fragment DomainFields on domain {
  id
  domainName: domain_name
  domainType: domain_type
  belongsToCompanyId: belongs_to_company_id
  company {
    id
    name
  }
  belongsToRoleUserId: belongs_to_role_user_id
  createByRoleUserId: created_by_role_user_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  isEnabled: is_enabled
  isBlacklisted: is_blacklisted
  categoryId: category_id
  hydraRedirect: hydra_redirect
  isDnsResolved: is_dns_resolved
  sslRenewAt: ssl_renew_at
  sslDeleteAt: ssl_delete_at
}
    `;
export const OfferFieldsFragmentDoc = gql`
    fragment OfferFields on offer {
  id
  name
  url
  campaignUrl: campaign_url
  landingPageUrl: landing_page_url
  externalOfferId: external_offer_id
  offerImageCloudinary: offer_image_cloudinary
  offerDetails: offer_details
  shortName: short_name
  isPrivate: is_private
  priority
  offerCategory: offer_category
  useAllRedirectDomains: use_all_redirect_domains
  offerIframes: offer_iframes(order_by: {iframe: {name: asc}}) {
    id
    isDefault: is_default
    iframe {
      id
      name
    }
  }
  offerPayoutTypes: offer_payout_types(order_by: {payout_type: {shortcode: asc}}) {
    id
    isDefault: is_default
    payoutType: payout_type {
      id
      name
      shortcode
      payinAdvertiser: payin_advertiser
    }
  }
  belongsToCompanyId: belongs_to_company_id
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToAdvertiserId: belongs_to_advertiser_id
  advertiser {
    id
    name
    domains(
      where: {is_blacklisted: {_eq: false}, is_enabled: {_eq: true}}
      limit: 1
    ) {
      id
      domain_name
    }
  }
  offerDomains: offer_domains(order_by: {domain: {domain_name: asc}}) {
    isDefault: is_default
    domainId: domain_id
    offerId: offer_id
    domain {
      ...DomainFields
    }
    offer {
      id
      name
    }
  }
  company {
    ...CompanyFields
  }
}
    ${DomainFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;
export const PayoutTypeFieldsFragmentDoc = gql`
    fragment PayoutTypeFields on payout_type {
  id
  createdAt: created_at
  updatedAt: updated_at
  name
  shortcode
  payinAdvertiser: payin_advertiser
  belongsToCompanyId: belongs_to_company_id
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToAdvertiserId: belongs_to_advertiser_id
  advertiser {
    id
    name
  }
}
    `;
export const OfferPayoutTypeFieldsFragmentDoc = gql`
    fragment OfferPayoutTypeFields on offer_payout_type {
  id
  offerId: offer_id
  isDefault: is_default
  payinAdvertiser: payin_advertiser
  payoutCompany: payout_company
  payoutAffiliate: payout_affiliate
  payoutChatCompany: payout_chat_company
  payoutChatOperator: payout_chat_operator
  offer {
    id
    name
  }
  payoutTypeId: payout_type_id
  payoutType: payout_type {
    ...PayoutTypeFields
  }
}
    ${PayoutTypeFieldsFragmentDoc}`;
export const CompanyOfferFieldsFragmentDoc = gql`
    fragment CompanyOfferFields on company_offer {
  id
  createdAt: created_at
  belongsToCompanyId: belongs_to_company_id
  company {
    id
    name
  }
  belongsToChatCompanyId: belongs_to_chat_company_id
  chatCompany: chat_company {
    id
    name
  }
  offerId: offer_id
  offer {
    ...OfferFields
  }
  offerPayoutTypeId: offer_payout_type_id
  offerPayoutType: offer_payout_type {
    ...OfferPayoutTypeFields
  }
  parentCompanyOfferId: parent_company_offer_id
  payoutCompany: payout_company
  payoutAffiliate: payout_affiliate
  payoutChatCompany: payout_chat_company
  payoutChatOperator: payout_chat_operator
  priority
  isEnabled: is_enabled
  overrideCompanyUsername: override_company_username
  postbacks {
    id
    postbackUrl: postback_url
  }
}
    ${OfferFieldsFragmentDoc}
${OfferPayoutTypeFieldsFragmentDoc}`;
export const CompanyOfferPayoutFieldsFragmentDoc = gql`
    fragment CompanyOfferPayoutFields on company_offer_payout {
  id
  overridePayout: override_payout
  companyOfferId: company_offer_id
  companyOffer: company_offer {
    ...CompanyOfferFields
  }
  offerId: offer_id
  belongsToCompanyId: belongs_to_company_id
  belongsToChatCompanyId: belongs_to_chat_company_id
  chatCompany: chat_company {
    id
    name
  }
  createdByRoleUserId: created_by_role_user_id
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    id
    user {
      id
      username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username
    }
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;
export const CompanyOfferPostbackFieldsFragmentDoc = gql`
    fragment CompanyOfferPostbackFields on company_offer_postback {
  id
  companyOfferId: company_offer_id
  offerId: offer_id
  postbackUrl: postback_url
  isGlobal: is_global
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    id
    user {
      id
      username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username
    }
  }
}
    `;
export const CompanyOfferRedirectFieldsFragmentDoc = gql`
    fragment CompanyOfferRedirectFields on company_offer_redirect {
  id
  companyOfferId: company_offer_id
  offerId: offer_id
  redirectUrl: redirect_url
  isPing: is_ping
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    id
    user {
      id
      username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username
    }
  }
}
    `;
export const PermissionFieldsFragmentDoc = gql`
    fragment PermissionFields on permission {
  id
  permissionKey: permission_key
  description
}
    `;
export const CompanyRoleUserPermissionFieldsFragmentDoc = gql`
    fragment CompanyRoleUserPermissionFields on company_role_user_permission {
  id
  permission_id
  permission {
    ...PermissionFields
  }
  company_role_user_id
}
    ${PermissionFieldsFragmentDoc}`;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on user {
  id
  firstName: first_name
  lastName: last_name
  telegramId: telegram_id
  skypeId: skype_id
  username
  externalAffiliateId: external_affiliate_id
  metabaseId: metabase_dashboard_id
  email
  profileImageUrl: profile_image_url
  company: current_company {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;
export const CompanyRoleUserFieldsFragmentDoc = gql`
    fragment CompanyRoleUserFields on company_role_user {
  id
  createdAt: created_at
  inviteAccepted: invite_accepted
  lastLoginAt: last_login_at
  isEnabled: is_enabled
  isApproved: is_approved
  inviteAccepted: invite_accepted
  inviteResetCode: invite_reset_code
  invitedByRoleUserId: invited_by_role_user_id
  createdByRoleUserId: created_by_role_user_id
  postbackUrl: postback_url
  company {
    ...CompanyFields
  }
  role {
    id
  }
  user {
    ...UserFields
  }
}
    ${CompanyFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const CompanyTypeFeatureFieldsFragmentDoc = gql`
    fragment CompanyTypeFeatureFields on company_type_feature {
  company_type {
    id
    name
  }
  feature {
    id
    feature_key
  }
}
    `;
export const CompanyTypePlanFieldsFragmentDoc = gql`
    fragment CompanyTypePlanFields on company_type_plan {
  company_type {
    id
    name
  }
  plan {
    id
    name
    friendlyName: friendly_name
    description
    price
  }
}
    `;
export const CompanyTypeFieldsFragmentDoc = gql`
    fragment CompanyTypeFields on company_type {
  id
  name
  shortcode
  ownerRoleId: owner_role_id
  ownerRole: owner_role {
    id
    name
  }
  allowRegistration: allow_registration
  defaultPlanId: default_plan_id
  defaultPlan: default_plan {
    id
    name
  }
  roles {
    isOwner: is_owner
    role {
      id
      name
    }
  }
  features {
    feature {
      id
      description
      featureKey: feature_key
    }
  }
  plans {
    plan {
      id
      name
      stripePriceId: stripe_price_id
    }
  }
}
    `;
export const CompanyTypeRoleFieldsFragmentDoc = gql`
    fragment CompanyTypeRoleFields on company_type_role {
  id
  companyTypeId: company_type_id
  company_type {
    id
    name
    shortcode
  }
  roleId: role_id
  role {
    allowSignup: allow_signup
    id
    name
    shortcode
  }
  isOwner: is_owner
}
    `;
export const ContactListContactFieldsFragmentDoc = gql`
    fragment ContactListContactFields on contact_list_contact {
  createdAt: created_at
  updatedAt: updated_at
  acquisitionType: acquisition_type
  status
  contactListId: contact_list_id
  contactId: contact_id
  contact {
    id
    phoneNumber: phone_number
  }
  personaId: persona_id
  persona {
    id
    firstName: first_name
    username
  }
  targetSiteId: target_site_id
  targetSite: target_site {
    id
    siteName: site_name
  }
}
    `;
export const ContactFieldsFragmentDoc = gql`
    fragment ContactFields on contact {
  id
  createdAt: created_at
  updatedAt: updated_at
  username
  phoneNumber: phone_number
  firstName: first_name
  age
  bio
  blocked
  blockedReason: blocked_reason
  blockedByRoleUserId: blocked_by_role_user_id
  blockedByRoleUser: blocked_by_role_user {
    user {
      id
      userName: username
    }
  }
  city
  city_id
  contactCity: contact_city {
    id
    name
    state_code
    country_code
    timezone {
      id
      timezone
    }
  }
  state
  state_id
  contactState: contact_state {
    id
    name
  }
  platformId: platform_id
  contactPlatform: contact_platform {
    id
    name
    shortcode
    initials
    icon
    iconColor: icon_color
    backgroundColor: background_color
    canViewOfferLinks: can_view_offer_links
    canSendExtLinks: can_send_ext_links
    canSendOfferLinks: can_send_offer_links
    canSendPhoneNumber: can_send_phone_number
    canSendExtPhoneNumber: can_send_ext_phone_number
    canSendEmailAddress: can_send_email_address
    canSendExtEmailAddress: can_send_ext_email_address
    canAddToUserAccount: can_add_to_user_account
  }
  workflowStatus: workflow_status
  postalCode: postal_code
  longitude
  latitude
  emailAddress: email_address
  skypeId: skype_id
  kikId: kik_id
  numberType: number_type
  carrier {
    id
    carrierName: carrier_name
    carrierNameAlias: carrier_name_alias
  }
  phoneAreaCode: phone_area_code {
    id
    majorCity: major_city {
      id
      cityTimeZone: timezone {
        id
        contactTimezone: timezone
      }
    }
  }
  conversations {
    id
    convPlatform: conv_platform {
      id
      name
      shortcode
      backgroundColor: background_color
      iconColor: icon_color
      icon
    }
  }
  conversationAggregate: conversations_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const ContactSimpleFieldsFragmentDoc = gql`
    fragment ContactSimpleFields on contact {
  id
  createdAt: created_at
  phoneNumber: phone_number
  firstName: first_name
  blocked
  blockedReason: blocked_reason
  contactPlatform: contact_platform {
    id
    name
    backgroundColor: background_color
  }
  workflowStatus: workflow_status
}
    `;
export const PlatformFieldsFragmentDoc = gql`
    fragment PlatformFields on platform {
  id
  createdAt: created_at
  name
  shortcode
  icon
  initials
  iconColor: icon_color
  backgroundColor: background_color
  canViewOfferLinks: can_view_offer_links
  canSendExtLinks: can_send_ext_links
  canSendOfferLinks: can_send_offer_links
  canSendPhoneNumber: can_send_phone_number
  canSendExtPhoneNumber: can_send_ext_phone_number
  canSendEmailAddress: can_send_email_address
  canSendExtEmailAddress: can_send_ext_email_address
  canAddToUserAccount: can_add_to_user_account
  canViewPersonaPhotos: can_view_persona_photos
  canSendPersonaPhotos: can_send_persona_photos
  canChat: can_chat
  canUseApiTokens: can_use_api_tokens
  belongsToCompanyId: belongs_to_company_id
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
}
    `;
export const ConversationMessageFieldsFragmentDoc = gql`
    fragment ConversationMessageFields on conversation_message {
  id
  from
  conversationId: conversation_id
  baseMessageId: base_message_id
  chatbotMessage: chatbot_message {
    id
    intentId: intent_id
  }
  messageText: message_text
  rawMessage: raw_message
  replyingTo: replying_to
  createdAt: created_at
  messageType: message_type
  sentByRoleUser: sent_by_role_user {
    user {
      id
      userName: username
    }
  }
  sid
  cost
  deliveryStatus: delivery_status
  errorCode: error_code
  errorMessage: error_message
  medias: conversation_message_media {
    media {
      id
      rank
      url
      isForSms: is_for_sms
      isForChat: is_for_chat
      mediaType: media_type
    }
  }
}
    `;
export const MediaFieldsFragmentDoc = gql`
    fragment MediaFields on media {
  id
  rank
  url
  clHeight: cl_height
  clWidth: cl_width
  isForSms: is_for_sms
  isForChat: is_for_chat
  mediaType: media_type
  publicId: public_id
}
    `;
export const PersonaFieldsFragmentDoc = gql`
    fragment PersonaFields on persona {
  id
  firstName: first_name
  lastName: last_name
  nickname
  username
  headline
  bio
  landingPageHeadline: landing_page_headline
  landingPageBio: landing_page_bio
  targetSiteHeadline: target_site_headline
  targetSiteBio: target_site_bio
  birthdate
  gender
  job
  bodyType: body_type
  ethnicity
  eyeColor: eye_color
  hairColor: hair_color
  hairLength: hair_length
  interestedIn: interested_in
  lookingFor: looking_for
  orientation
  heightCm: height_cm
  weightKg: weight_kg
  heightFt: height_ft
  heightIn: height_in
  weightLbs: weight_lbs
  custom1: custom_1
  custom2: custom_2
  custom3: custom_3
  custom4: custom_4
  custom5: custom_5
  custom6: custom_6
  isGlobal: global
  country {
    id
    name
  }
  stateProvince: state_province {
    id
    name
  }
  city {
    id
    name
  }
  postalCode: postal_code
  fromUrl: from_url
  internalNote: internal_note
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  createdByRoleUserId: created_by_role_user_id
  company {
    ...CompanyFields
  }
  medias: persona_media(order_by: {media: {rank: asc}}) {
    media {
      ...MediaFields
    }
  }
}
    ${CompanyFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;
export const ConversationFieldsFragmentDoc = gql`
    fragment ConversationFields on conversation {
  id
  convKey: conv_key
  platformId: platform_id
  convPlatform: conv_platform {
    ...PlatformFields
  }
  lastMessageTimestamp: last_message_timestamp
  lastMessageReceivedTimestamp: last_message_received_timestamp
  lastMessageSentTimestamp: last_message_sent_timestamp
  belongsToCompanyId: belongs_to_company_id
  belongsToChatCompanyId: belongs_to_chat_company_id
  assignedToRoleUserId: assigned_to_role_user_id
  assignedToRoleUser: assigned_to_role_user {
    id
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  doNotReassign: do_not_reassign
  workflowStatus: workflow_status
  readStatus: read_status
  liveStatus: live_status
  isArchived: is_archived
  rebumpSent: rebump_sent
  smsLinkSentCount: sms_link_sent_count
  contactListId: contact_list_id
  campaignResponderId: campaign_responder_id
  contact {
    id
    username
    contactFirstName: first_name
    phoneNumber: phone_number
    contactAge: age
    contactCity: contact_city {
      id
      name
    }
    contactState: contact_state {
      id
      name
    }
    contactPlatform: contact_platform {
      id
      name
    }
    blocked
    doNotContact: do_not_contact
    blockedByRoleUserId: blocked_by_role_user_id
    blockedReason: blocked_reason
    blockedByRoleUser: blocked_by_role_user {
      user {
        id
        userName: username
      }
    }
    phoneAreaCode: phone_area_code {
      id
      majorCity: major_city {
        id
        cityTimeZone: timezone {
          id
          contactTimezone: timezone
        }
      }
    }
  }
  conversationMessages: conversation_messages {
    ...ConversationMessageFields
  }
  manualMessages: conversation_messages_aggregate(
    where: {message_type: {_eq: "manual"}}
  ) {
    aggregate {
      messageCount: count
    }
  }
  conversationMessagesAggregate: conversation_messages_aggregate {
    aggregate {
      sum {
        cost
      }
    }
  }
  trackings(limit: 1) {
    id
    offerLinkSent: offer_link_sent
  }
  flow {
    ...FlowFields
  }
  persona {
    ...PersonaFields
  }
}
    ${PlatformFieldsFragmentDoc}
${ConversationMessageFieldsFragmentDoc}
${FlowFieldsFragmentDoc}
${PersonaFieldsFragmentDoc}`;
export const ConversationListFieldsFragmentDoc = gql`
    fragment ConversationListFields on conversation {
  id
  lastMessageReceivedTimestamp: last_message_received_timestamp
  lastMessageSentTimestamp: last_message_sent_timestamp
  workflowStatus: workflow_status
  liveStatus: live_status
  isArchived: is_archived
  platformId: platform_id
  convPlatform: conv_platform {
    ...PlatformFields
  }
  webChatSocketId: web_chat_socket_id
  readStatus: read_status
  rebumpSent: rebump_sent
  smsLinkSentCount: sms_link_sent_count
  contactListId: contact_list_id
  contactList: contact_list {
    id
    name
  }
  campaignResponderId: campaign_responder_id
  campaignResponder: campaign_responder {
    id
    name
  }
  assignedToRoleUser: assigned_to_role_user {
    id
    user {
      id
      chatOperatorUsername: username
    }
  }
  contact {
    id
    firstName: first_name
    emailAddress: email_address
    blocked
    blockedReason: blocked_reason
    blockedByRoleUser: blocked_by_role_user {
      user {
        id
        userName: username
      }
    }
  }
  conversions_aggregate {
    aggregate {
      sum {
        payoutChatOperator: payout_chat_operator
      }
    }
  }
  conversation_messages_aggregate {
    aggregate {
      sum {
        totalCost: cost
      }
    }
  }
  manualMessages: conversation_messages_aggregate(
    where: {message_type: {_eq: "manual"}}
  ) {
    aggregate {
      messageCount: count
    }
  }
  conversation_notes_aggregate {
    aggregate {
      avg {
        review_rating
      }
      count(columns: review_rating)
    }
  }
  trackings(limit: 1) {
    id
    offerLinkSent: offer_link_sent
  }
  trackings_aggregate {
    aggregate {
      sum {
        clickCount: click_count
      }
    }
  }
  unreadMessages: conversation_messages_aggregate(
    where: {read_status: {_eq: false}}
  ) {
    aggregate {
      count
    }
  }
  persona {
    id
    username
  }
}
    ${PlatformFieldsFragmentDoc}`;
export const ConversationTableFieldsFragmentDoc = gql`
    fragment ConversationTableFields on conversation {
  id
  lastMessageTimestamp: last_message_timestamp
  lastMessageReceivedTimestamp: last_message_received_timestamp
  lastMessageSentTimestamp: last_message_sent_timestamp
  liveStatus: live_status
  isArchived: is_archived
  platformId: platform_id
  contactListId: contact_list_id
  contactList: contact_list {
    id
    name
  }
  campaignResponderId: campaign_responder_id
  campaignResponder: campaign_responder {
    id
    name
  }
  convPlatform: conv_platform {
    ...PlatformFields
  }
  assignedToRoleUser: assigned_to_role_user {
    id
    user {
      id
      chatOperatorUsername: username
    }
  }
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username
    }
  }
  trackings_aggregate {
    aggregate {
      sum {
        clickCount: click_count
      }
    }
  }
  conversation_messages_aggregate {
    aggregate {
      count
    }
  }
  conversions_aggregate {
    aggregate {
      count
    }
  }
}
    ${PlatformFieldsFragmentDoc}`;
export const ConversationLiveChatFieldsFragmentDoc = gql`
    fragment ConversationLiveChatFields on conversation {
  id
  lastMessageReceivedTimestamp: last_message_received_timestamp
  lastMessageSentTimestamp: last_message_sent_timestamp
  workflowStatus: workflow_status
  isArchived: is_archived
  platformId: platform_id
  convPlatform: conv_platform {
    ...PlatformFields
  }
  webChatSocketId: web_chat_socket_id
  readStatus: read_status
  assignedToRoleUser: assigned_to_role_user {
    id
    user {
      id
      chatOperatorUsername: username
    }
  }
  contact {
    id
    firstName: first_name
    emailAddress: email_address
  }
  manualMessages: conversation_messages_aggregate(
    where: {message_type: {_eq: "manual"}}
  ) {
    aggregate {
      messageCount: count
    }
  }
}
    ${PlatformFieldsFragmentDoc}`;
export const ConversionsFragmentDoc = gql`
    fragment Conversions on conversion {
  id
  payinAdvertiser: payin_advertiser
  payoutCompany: payout_company
  payoutAffiliate: payout_affiliate
  payoutChatCompany: payout_chat_company
  payoutChatOperator: payout_chat_operator
  conversationId: conversation_id
  createdAt: created_at
  trackingId: tracking_id
  requestId: request_id
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleChatOperatorId: belongs_to_role_chat_operator_id
  externalOfferId: external_offer_id
  receivedAdvertiserPayinDate: received_advertiser_payin_date
  sentCompanyPayoutDate: sent_company_payout_date
  sentAffiliatePayoutDate: sent_affiliate_payout_date
  sentChatOperatorPayoutDate: sent_chat_operator_payout_date
  sentChatCompanyPayoutDate: sent_chat_company_payout_date
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username
    }
  }
  platform: platform {
    id
    name
  }
  company {
    id
    name
  }
  tracking {
    id
    offer {
      id
      name
      advertiser {
        id
        name
      }
    }
    trackingClicks: tracking_clicks {
      id
      clickId: click_id
    }
  }
  trackingClick: tracking_click {
    id
    clickId: click_id
  }
}
    `;
export const CountryFieldsFragmentDoc = gql`
    fragment CountryFields on country {
  id
  name
  iso2
  isSmsReady: is_sms_ready
}
    `;
export const StateFieldsFragmentDoc = gql`
    fragment StateFields on state {
  id
  name
  iso2
  countryId: country_id
}
    `;
export const CityFieldsFragmentDoc = gql`
    fragment CityFields on city {
  id
  name
  countryId: country_id
  stateId: state_id
}
    `;
export const EmailFieldsFragmentDoc = gql`
    fragment EmailFields on email_account {
  id
  emailAddress: email_address
  emailPassword: email_password
  isActive: is_active
}
    `;
export const IntentFieldsFragmentDoc = gql`
    fragment IntentFields on intent {
  id
  name
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleUserId: belongs_to_role_user_id
  createdByRoleUserId: created_by_role_user_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  isGlobal: is_global
  slashCommand: slash_command
  inputs: chatbot_messages(
    where: {message_type: {_eq: "input"}}
    order_by: {text: asc}
  ) {
    id
    intentId: intent_id
    messageType: message_type
    text
    rank
  }
  replies: chatbot_messages(
    where: {message_type: {_eq: "reply"}}
    order_by: {text: asc}
  ) {
    id
    intentId: intent_id
    messageType: message_type
    text
    rank
  }
}
    `;
export const LedgerFieldsFragmentDoc = gql`
    fragment LedgerFields on ledger {
  id
  createdAt: created_at
  updatedAt: updated_at
  name
  description
  transactionAmount: transaction_amount
  transactionDate: transaction_date
  transactionType: transaction_type
  transactionCategory: transaction_category
  billingCycleType: billing_cycle_type
  belongsToCompanyId: belongs_to_company_id
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  company {
    id
    name
  }
}
    `;
export const OfferDomainFieldsFragmentDoc = gql`
    fragment OfferDomainFields on offer_domain {
  isDefault: is_default
  domainId: domain_id
  offerId: offer_id
  domain {
    id
    domainName: domain_name
    domainType: domain_type
    company {
      id
      name
    }
  }
  offer {
    id
    name
  }
}
    `;
export const IframeFieldsFragmentDoc = gql`
    fragment IframeFields on iframe {
  id
  createdAt: created_at
  updatedAt: updated_at
  name
  info
  url: url
  mainColor: main_color
  accentColor: accent_color
  previewImageUrl: preview_image_url
  previewUrl: preview_url
  categoryId: category_id
  belongsToCompanyId: belongs_to_company_id
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToAdvertiserId: belongs_to_advertiser_id
  advertiser {
    id
    name
  }
}
    `;
export const OfferIframeFieldsFragmentDoc = gql`
    fragment OfferIframeFields on offer_iframe {
  id
  offerId: offer_id
  isDefault: is_default
  iframeId: iframe_id
  iframe {
    ...IframeFields
  }
  offer {
    id
    name
  }
}
    ${IframeFieldsFragmentDoc}`;
export const PermissionRoleFieldsFragmentDoc = gql`
    fragment PermissionRoleFields on permission_role {
  permission {
    id
    description
    permissionKey: permission_key
  }
  role {
    id
    name
    adminOnly: admin_only
  }
}
    `;
export const PersonaAccountFieldsFragmentDoc = gql`
    fragment PersonaAccountFields on persona_account {
  id
  accountId: account_id
  accountPassword: account_password
  shortTokenId: short_token_id
  longTokenId: long_token_id
  deviceId: device_id
  pvaProvider: pva_provider
  pvaCountryId: pva_country_id
  pvaCost: pva_cost
  pvaPhoneNumber: pva_phone_number
  proxyProvider: proxy_provider
  proxyUrl: proxy_url
  proxyPort: proxy_port
  proxyUsername: proxy_username
  proxyPassword: proxy_password
  proxyCityId: proxy_city_id
  latitude
  longitude
  extraJSONB: extra_jsonb
  platformId: platform_id
  platform: platform {
    id
    name
    shortcode
    initials
    icon
    iconColor: icon_color
    backgroundColor: background_color
  }
  isActive: is_active
  createdAt: created_at
  updatedAt: updated_at
  personaId: persona_id
  flowId: flow_id
  flow {
    id
    name
    campaign {
      id
      name
    }
  }
  targetSiteId: target_site_id
  targetSite: target_site {
    id
    siteName: site_name
  }
  cityId: city_id
  city {
    id
    name
    state {
      id
      name
    }
  }
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleUserId: belongs_to_role_user_id
  createdByRoleUserId: created_by_role_user_id
  assignedToRoleUserId: assigned_to_role_user_id
  assignedToRoleUser: assigned_to_role_user {
    id
    user {
      id
      username: username
    }
  }
}
    `;
export const PersonaDataSetFieldsFragmentDoc = gql`
    fragment PersonaDataSetFields on persona_dataset {
  id
  memberId: member_id
  firstName: first_name
  username
  email
  gender
  seekGender: seek_gender
  sexualOrientation: sexual_orientation
  birthdate
  ageFrom: age_from
  ageTo: age_to
  headline
  description
  country
  state
  city
  zip
  maritalStatus: marital_status
  lookingFor: looking_for
  occupation
  language
  race
  religion
  height
  bodyType: body_type
  eyeColor: eye_color
  hairColor: hair_color
  wantChildren: want_children
  haveChildren: have_children
  education
  smoke
  drink
  interests
  photoCount: photo_count
  isApproved: is_approved
  hasBeenUsed: has_been_used
  workflowStatus: workflow_status
  updatedAt: updated_at
  assignedToRoleUserId: assigned_to_role_user_id
  assignedToRoleUser: assigned_to_role_user {
    id
    user {
      id
      firstName: first_name
      username: username
    }
  }
}
    `;
export const PhoneAreaCodeFieldsFragmentDoc = gql`
    fragment PhoneAreaCodeFields on phone_area_code {
  id
  areaCode: area_code
  cityId: city_id
  majorCityId: major_city_id
  majorCity: major_city {
    id
    name
    stateId: state_id
    stateCode: state_code
    countryCode: country_code
    latitude
    longitude
    countryId: country_id
    timezoneId: timezone_id
    state {
      id
      name
    }
  }
}
    `;
export const PhoneNumberBlockFieldsFragmentDoc = gql`
    fragment PhoneNumberBlockFields on phone_number_block {
  id
  createdAt: created_at
  updatedAt: updated_at
  phoneNumber: phone_number
  belongsToCompanyId: belongs_to_company_id
  company {
    id
  }
  createByRoleUserId: created_by_role_user_id
}
    `;
export const SmsProviderFieldsFragmentDoc = gql`
    fragment SmsProviderFields on sms_provider {
  id
  name
  provider: sms_provider
  accountId: account_id
  apiKey: api_key
  apiSecret: api_secret
  defaultProvider: default_provider
  accountType: account_type
  status
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  company {
    id
    name
  }
  phoneNumbersAggregate: phone_numbers_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const PhoneNumberFieldsFragmentDoc = gql`
    fragment PhoneNumberFields on phone_number {
  id
  phoneNumberCity: phone_number_city
  phoneNumberState: phone_number_state
  targetSiteId: target_site_id
  phoneNumber: phone_number
  isActive: is_active
  numberType: number_type
  cityId: city_id
  extSubAffiliate: ext_sub_affiliate
  city {
    id
    name
  }
  campaign {
    id
    name
  }
  offer {
    id
    name
  }
  persona {
    id
    firstName: first_name
    username
  }
  company {
    id
    name
  }
  chatCompany: chat_company {
    id
    name
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    id
    user {
      id
      username: username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username: username
    }
  }
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    id
    user {
      id
      username: username
    }
  }
  targetSite: target_site {
    id
    siteName: site_name
  }
  smsProvider: sms_provider {
    ...SmsProviderFields
  }
}
    ${SmsProviderFieldsFragmentDoc}`;
export const PhoneNumberWhitelistFieldsFragmentDoc = gql`
    fragment PhoneNumberWhitelistFields on phone_number_whitelist {
  id
  belongsToCompanyId: belongs_to_company_id
  company {
    id
    name
  }
  belongsToRoleUserId: belongs_to_role_user_id
  createByRoleUserId: created_by_role_user_id
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    id
    user {
      id
      username
    }
  }
  phoneNumber: phone_number
  contactId: contact_id
  contact {
    id
    conversations {
      id
      conversions {
        id
      }
    }
  }
}
    `;
export const FeatureFieldsFragmentDoc = gql`
    fragment FeatureFields on feature {
  id
  featureKey: feature_key
  friendlyName: friendly_name
  description
  faIcon: fa_icon
}
    `;
export const PlanFeatureFieldsFragmentDoc = gql`
    fragment PlanFeatureFields on plan_feature {
  id
  isUpgradeable: is_upgradeable
  isUsageBased: is_usage_based
  isUnlimited: is_unlimited
  isVisibleOnPlanPage: is_visible_on_plan_page
  isVisibleOnUsagePage: is_visible_on_usage_page
  limit
  rank
  stripePriceId: stripe_price_id
  additionalInfo: additional_info
  plan {
    id
    name
  }
  feature {
    ...FeatureFields
  }
}
    ${FeatureFieldsFragmentDoc}`;
export const PlanRoleFieldsFragmentDoc = gql`
    fragment PlanRoleFields on plan_role {
  id
  plan {
    id
    name
  }
  role {
    id
    name
    description
  }
  planRolePermissions: plan_role_permissions(
    order_by: {permission: {permission_key: asc}}
  ) {
    permission {
      id
      description
      permissionKey: permission_key
    }
  }
}
    `;
export const PlanFieldsFragmentDoc = gql`
    fragment PlanFields on plan {
  id
  name
  friendlyName: friendly_name
  description
  price
  stripePriceId: stripe_price_id
  planFeatures: plan_features(order_by: {rank: asc, feature: {feature_key: asc}}) {
    ...PlanFeatureFields
  }
  planRoles: plan_roles(order_by: {role: {name: asc}}) {
    ...PlanRoleFields
  }
}
    ${PlanFeatureFieldsFragmentDoc}
${PlanRoleFieldsFragmentDoc}`;
export const PlanRolePermissionFieldsFragmentDoc = gql`
    fragment PlanRolePermissionFields on plan_role_permission {
  permission {
    id
    description
    permissionKey: permission_key
  }
  planRole: plan_role {
    ...PlanRoleFields
  }
}
    ${PlanRoleFieldsFragmentDoc}`;
export const PostTextsFieldsFragmentDoc = gql`
    fragment PostTextsFields on post_text {
  id
  body
  title
  categoryId: category_id
  category {
    id
    categoryName: category_name
  }
}
    `;
export const RoleFieldsFragmentDoc = gql`
    fragment RoleFields on role {
  id
  name
  description
  adminOnly: admin_only
  isAffiliate: is_affiliate
  shortcode
  allowSignup: allow_signup
  permissions(order_by: {permission: {permission_key: asc}}) {
    permission {
      id
      permissionKey: permission_key
      description
    }
  }
}
    `;
export const SmsBillingUsageFieldsFragmentDoc = gql`
    fragment SmsBillingUsageFields on sms_billing_usage {
  id
  smsProviderId: sms_provider_id
  belongsToCompanyId: belongs_to_company_id
  usageStartDate: usage_start_date
  usageEndDate: usage_end_date
  price
  priceUnit: price_unit
  category
  usage
  usageUnit: usage_unit
  count
  countUnit: count_unit
  usageAsOfTimestamp: usage_as_of_timestamp
  createdAt: created_at
}
    `;
export const TargetSitesFieldsFragmentDoc = gql`
    fragment TargetSitesFields on target_site {
  id
  siteName: site_name
  siteType: site_type
  siteDomain: site_domain
  sitePlatform: site_platform
  newPostUrl: new_post_url
  targetSiteCategories: target_site_categories {
    category {
      id
      categoryName: category_name
    }
  }
  company {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;
export const WebChatFormFieldsFragmentDoc = gql`
    fragment WebChatFormFields on web_chat_form {
  id
  name
  webChatFormKey: web_chat_form_key
  createdAt: created_at
  updatedAt: updated_at
  colorHeader: color_header
  colorWindowBackground: color_window_background
  colorMessageBubbleMe: color_message_bubble_me
  colorMessageBubbleThem: color_message_bubble_them
  colorMessageFontMe: color_message_font_me
  colorMessageFontThem: color_message_font_them
  colorSendMessageBackground: color_send_message_background
  colorSendMessageFont: color_send_message_font
  colorOpenButton: color_open_button
  targetSiteId: target_site_id
  webChatFormKey: web_chat_form_key
  askForEmail: ask_for_email
  askForPhoneNumber: ask_for_phone_number
  askForFirstName: ask_for_first_name
  isFirstNameRequired: is_first_name_required
  isEmailRequired: is_email_required
  isPhoneNumberRequired: is_phone_number_required
  playConnectSound: play_connect_sound
  playDisconnectSound: play_disconnect_sound
  playNewMessageSound: play_new_message_sound
  chatCompanyId: belongs_to_chat_company_id
  cityId: city_id
  city {
    id
    name
  }
  campaign {
    id
    name
    company {
      id
      name
    }
  }
  offer {
    id
    name
    isPrivate: is_private
    company {
      id
      name
    }
  }
  persona {
    id
    firstName: first_name
    lastName: last_name
    username
    company {
      id
      name
    }
  }
  company {
    id
    name
  }
  chatCompany: chat_company {
    id
    name
  }
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      id
      username: username
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    user {
      id
      username: username
    }
  }
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  targetSite: target_site {
    id
    siteName: site_name
    company {
      id
      name
    }
  }
}
    `;
export const WebPageFieldsFragmentDoc = gql`
    fragment WebPageFields on web_page {
  id
  createdAt: created_at
  updatedAt: updated_at
  pageTitle: page_title
  s3Id: s3_id
  offer: offer_id
  belongsToCompanyId: belongs_to_company_id
  belongsToRoleUserId: belongs_to_role_user_id
  belongsToRoleUser: belongs_to_role_user {
    user {
      ...UserFields
    }
  }
  belongsToRoleAffiliateId: belongs_to_role_affiliate_id
  belongsToRoleAffiliate: belongs_to_role_affiliate {
    user {
      ...UserFields
    }
  }
  createdByRoleUserId: created_by_role_user_id
  createdByRoleUser: created_by_role_user {
    user {
      id
      username: username
    }
  }
  isTemplate: is_template
  isApproved: is_approved
}
    ${UserFieldsFragmentDoc}`;
export const ConversationMetaDataByIdDocument = gql`
    query ConversationMetaDataById($conversation_id: Int!) {
  conversation: conversation_by_pk(id: $conversation_id) {
    convKey: conv_key
    id
    liveStatus: live_status
    workflowStatus: workflow_status
    isArchived: is_archived
    platformId: platform_id
    contact {
      id
      blocked
      blockedReason: blocked_reason
    }
    convPlatform: conv_platform {
      createdAt: created_at
      id
      name
      shortcode
      initials
      icon
      iconColor: icon_color
      backgroundColor: background_color
      canViewOfferLinks: can_view_offer_links
      canSendExtLinks: can_send_ext_links
      canSendOfferLinks: can_send_offer_links
      canSendPhoneNumber: can_send_phone_number
      canSendExtPhoneNumber: can_send_ext_phone_number
      canSendEmailAddress: can_send_email_address
      canSendExtEmailAddress: can_send_ext_email_address
      canAddToUserAccount: can_add_to_user_account
    }
    conversions {
      payoutTypeName: payout_type_name
    }
    smsLinkSentCount: sms_link_sent_count
    contactListId: contact_list_id
    contactList: contact_list {
      id
      name
    }
    campaignResponderId: campaign_responder_id
    campaignResponder: campaign_responder {
      id
      name
    }
    conversions_aggregate {
      aggregate {
        sum {
          payinAdvertiser: payin_advertiser
          payoutCompany: payout_company
          payoutAffiliate: payout_affiliate
          payoutChatCompany: payout_chat_company
          payoutChatOperator: payout_chat_operator
        }
      }
    }
    trackings(limit: 1) {
      id
      offerLinkSent: offer_link_sent
    }
    trackings_aggregate {
      aggregate {
        sum {
          clickCount: click_count
        }
      }
    }
    conversation_messages_aggregate {
      aggregate {
        sum {
          totalCost: cost
        }
      }
    }
    assigned_to_role_user {
      user {
        id
        chatOperatorUsername: username
      }
    }
    target_site {
      id
      site_name
    }
    phone_number {
      id
      target_site {
        id
        site_name
      }
      offer {
        id
        name
      }
    }
    company {
      id
      name
    }
    chatCompany: chat_company {
      id
      name
    }
  }
}
    `;

/**
 * __useConversationMetaDataByIdQuery__
 *
 * To run a query within a React component, call `useConversationMetaDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationMetaDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationMetaDataByIdQuery({
 *   variables: {
 *      conversation_id: // value for 'conversation_id'
 *   },
 * });
 */
export function useConversationMetaDataByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationMetaDataByIdQuery, Types.ConversationMetaDataByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationMetaDataByIdQuery, Types.ConversationMetaDataByIdQueryVariables>(ConversationMetaDataByIdDocument, options);
      }
export function useConversationMetaDataByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationMetaDataByIdQuery, Types.ConversationMetaDataByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationMetaDataByIdQuery, Types.ConversationMetaDataByIdQueryVariables>(ConversationMetaDataByIdDocument, options);
        }
export type ConversationMetaDataByIdQueryHookResult = ReturnType<typeof useConversationMetaDataByIdQuery>;
export type ConversationMetaDataByIdLazyQueryHookResult = ReturnType<typeof useConversationMetaDataByIdLazyQuery>;
export type ConversationMetaDataByIdQueryResult = Apollo.QueryResult<Types.ConversationMetaDataByIdQuery, Types.ConversationMetaDataByIdQueryVariables>;
export const ConversationMessageByTypeDocument = gql`
    query ConversationMessageByType($conversation_id: Int!, $message_type: String!) {
  conversation: conversation_by_pk(id: $conversation_id) {
    convKey: conv_key
    conversation_messages_aggregate(where: {message_type: {_eq: $message_type}}) {
      aggregate {
        messageCount: count
      }
    }
  }
}
    `;

/**
 * __useConversationMessageByTypeQuery__
 *
 * To run a query within a React component, call `useConversationMessageByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationMessageByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationMessageByTypeQuery({
 *   variables: {
 *      conversation_id: // value for 'conversation_id'
 *      message_type: // value for 'message_type'
 *   },
 * });
 */
export function useConversationMessageByTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationMessageByTypeQuery, Types.ConversationMessageByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationMessageByTypeQuery, Types.ConversationMessageByTypeQueryVariables>(ConversationMessageByTypeDocument, options);
      }
export function useConversationMessageByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationMessageByTypeQuery, Types.ConversationMessageByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationMessageByTypeQuery, Types.ConversationMessageByTypeQueryVariables>(ConversationMessageByTypeDocument, options);
        }
export type ConversationMessageByTypeQueryHookResult = ReturnType<typeof useConversationMessageByTypeQuery>;
export type ConversationMessageByTypeLazyQueryHookResult = ReturnType<typeof useConversationMessageByTypeLazyQuery>;
export type ConversationMessageByTypeQueryResult = Apollo.QueryResult<Types.ConversationMessageByTypeQuery, Types.ConversationMessageByTypeQueryVariables>;
export const ConversationNotesByIdDocument = gql`
    query ConversationNotesById($conversationId: Int!) {
  conversation_note_aggregate(where: {conversation_id: {_eq: $conversationId}}) {
    aggregate {
      avg {
        review_rating
      }
      count(columns: review_rating)
    }
    nodes {
      ...ConversationNoteFields
    }
  }
}
    ${ConversationNoteFieldsFragmentDoc}`;

/**
 * __useConversationNotesByIdQuery__
 *
 * To run a query within a React component, call `useConversationNotesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationNotesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationNotesByIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useConversationNotesByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationNotesByIdQuery, Types.ConversationNotesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationNotesByIdQuery, Types.ConversationNotesByIdQueryVariables>(ConversationNotesByIdDocument, options);
      }
export function useConversationNotesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationNotesByIdQuery, Types.ConversationNotesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationNotesByIdQuery, Types.ConversationNotesByIdQueryVariables>(ConversationNotesByIdDocument, options);
        }
export type ConversationNotesByIdQueryHookResult = ReturnType<typeof useConversationNotesByIdQuery>;
export type ConversationNotesByIdLazyQueryHookResult = ReturnType<typeof useConversationNotesByIdLazyQuery>;
export type ConversationNotesByIdQueryResult = Apollo.QueryResult<Types.ConversationNotesByIdQuery, Types.ConversationNotesByIdQueryVariables>;
export const InsertConversationNoteDocument = gql`
    mutation InsertConversationNote($object: conversation_note_insert_input!) {
  insert_conversation_note(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertConversationNoteMutationFn = Apollo.MutationFunction<Types.InsertConversationNoteMutation, Types.InsertConversationNoteMutationVariables>;

/**
 * __useInsertConversationNoteMutation__
 *
 * To run a mutation, you first call `useInsertConversationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConversationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConversationNoteMutation, { data, loading, error }] = useInsertConversationNoteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertConversationNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertConversationNoteMutation, Types.InsertConversationNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertConversationNoteMutation, Types.InsertConversationNoteMutationVariables>(InsertConversationNoteDocument, options);
      }
export type InsertConversationNoteMutationHookResult = ReturnType<typeof useInsertConversationNoteMutation>;
export type InsertConversationNoteMutationResult = Apollo.MutationResult<Types.InsertConversationNoteMutation>;
export type InsertConversationNoteMutationOptions = Apollo.BaseMutationOptions<Types.InsertConversationNoteMutation, Types.InsertConversationNoteMutationVariables>;
export const AllConversationsDocument = gql`
    query AllConversations {
  conversations: conversation(order_by: {last_message_timestamp: desc}) {
    id
    lastMessageTimestamp: last_message_timestamp
    lastMessageReceivedTimestamp: last_message_received_timestamp
    lastMessageSentTimestamp: last_message_sent_timestamp
    platformId: platform_id
    convPlatform: conv_platform {
      createdAt: created_at
      id
      name
      shortcode
      initials
      icon
      iconColor: icon_color
      backgroundColor: background_color
      canViewOfferLinks: can_view_offer_links
      canSendExtLinks: can_send_ext_links
      canSendOfferLinks: can_send_offer_links
      canSendPhoneNumber: can_send_phone_number
      canSendExtPhoneNumber: can_send_ext_phone_number
      canSendEmailAddress: can_send_email_address
      canSendExtEmailAddress: can_send_ext_email_address
      canAddToUserAccount: can_add_to_user_account
    }
    convKey: conv_key
    contact {
      username
      phoneNumber: phone_number
      postalCode: postal_code
      city
      state
      firstName: first_name
      emailAddress: email_address
      numberType: number_type
      doNotContact: do_not_contact
      carrier {
        carrierName: carrier_name
      }
    }
    phone_number {
      id
      phone_number
    }
    persona {
      firstName: first_name
      lastName: last_name
    }
    flow {
      campaign {
        id
      }
      name
    }
    readStatus: read_status
    liveStatus: live_status
    conversationMessagesAggregate: conversation_messages_aggregate {
      aggregate {
        sum {
          cost
        }
      }
    }
  }
}
    `;

/**
 * __useAllConversationsQuery__
 *
 * To run a query within a React component, call `useAllConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllConversationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllConversationsQuery, Types.AllConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllConversationsQuery, Types.AllConversationsQueryVariables>(AllConversationsDocument, options);
      }
export function useAllConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllConversationsQuery, Types.AllConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllConversationsQuery, Types.AllConversationsQueryVariables>(AllConversationsDocument, options);
        }
export type AllConversationsQueryHookResult = ReturnType<typeof useAllConversationsQuery>;
export type AllConversationsLazyQueryHookResult = ReturnType<typeof useAllConversationsLazyQuery>;
export type AllConversationsQueryResult = Apollo.QueryResult<Types.AllConversationsQuery, Types.AllConversationsQueryVariables>;
export const AllCampaignsDocument = gql`
    query AllCampaigns($belongsToCompanyId: Int!, $belongsToRoleAffiliateId: Int) {
  campaigns: campaign(
    order_by: {name: asc}
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
  ) {
    ...CampaignFields
  }
}
    ${CampaignFieldsFragmentDoc}`;

/**
 * __useAllCampaignsQuery__
 *
 * To run a query within a React component, call `useAllCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCampaignsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useAllCampaignsQuery(baseOptions: Apollo.QueryHookOptions<Types.AllCampaignsQuery, Types.AllCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCampaignsQuery, Types.AllCampaignsQueryVariables>(AllCampaignsDocument, options);
      }
export function useAllCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCampaignsQuery, Types.AllCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCampaignsQuery, Types.AllCampaignsQueryVariables>(AllCampaignsDocument, options);
        }
export type AllCampaignsQueryHookResult = ReturnType<typeof useAllCampaignsQuery>;
export type AllCampaignsLazyQueryHookResult = ReturnType<typeof useAllCampaignsLazyQuery>;
export type AllCampaignsQueryResult = Apollo.QueryResult<Types.AllCampaignsQuery, Types.AllCampaignsQueryVariables>;
export const CampaignByIdDocument = gql`
    query CampaignById($id: Int!) {
  campaign: campaign_by_pk(id: $id) {
    ...CampaignFields
  }
  offers: offer {
    name
  }
}
    ${CampaignFieldsFragmentDoc}`;

/**
 * __useCampaignByIdQuery__
 *
 * To run a query within a React component, call `useCampaignByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CampaignByIdQuery, Types.CampaignByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CampaignByIdQuery, Types.CampaignByIdQueryVariables>(CampaignByIdDocument, options);
      }
export function useCampaignByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CampaignByIdQuery, Types.CampaignByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CampaignByIdQuery, Types.CampaignByIdQueryVariables>(CampaignByIdDocument, options);
        }
export type CampaignByIdQueryHookResult = ReturnType<typeof useCampaignByIdQuery>;
export type CampaignByIdLazyQueryHookResult = ReturnType<typeof useCampaignByIdLazyQuery>;
export type CampaignByIdQueryResult = Apollo.QueryResult<Types.CampaignByIdQuery, Types.CampaignByIdQueryVariables>;
export const CampaignsByFilterDocument = gql`
    query CampaignsByFilter($filter: campaign_bool_exp) {
  campaigns: campaign(where: $filter) {
    ...CampaignFields
  }
}
    ${CampaignFieldsFragmentDoc}`;

/**
 * __useCampaignsByFilterQuery__
 *
 * To run a query within a React component, call `useCampaignsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CampaignsByFilterQuery, Types.CampaignsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CampaignsByFilterQuery, Types.CampaignsByFilterQueryVariables>(CampaignsByFilterDocument, options);
      }
export function useCampaignsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CampaignsByFilterQuery, Types.CampaignsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CampaignsByFilterQuery, Types.CampaignsByFilterQueryVariables>(CampaignsByFilterDocument, options);
        }
export type CampaignsByFilterQueryHookResult = ReturnType<typeof useCampaignsByFilterQuery>;
export type CampaignsByFilterLazyQueryHookResult = ReturnType<typeof useCampaignsByFilterLazyQuery>;
export type CampaignsByFilterQueryResult = Apollo.QueryResult<Types.CampaignsByFilterQuery, Types.CampaignsByFilterQueryVariables>;
export const InsertCampaignDocument = gql`
    mutation InsertCampaign($campaign: campaign_insert_input!) {
  insert_campaign(objects: [$campaign]) {
    returning {
      id
      flows {
        id
        name
      }
    }
  }
}
    `;
export type InsertCampaignMutationFn = Apollo.MutationFunction<Types.InsertCampaignMutation, Types.InsertCampaignMutationVariables>;

/**
 * __useInsertCampaignMutation__
 *
 * To run a mutation, you first call `useInsertCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCampaignMutation, { data, loading, error }] = useInsertCampaignMutation({
 *   variables: {
 *      campaign: // value for 'campaign'
 *   },
 * });
 */
export function useInsertCampaignMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCampaignMutation, Types.InsertCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCampaignMutation, Types.InsertCampaignMutationVariables>(InsertCampaignDocument, options);
      }
export type InsertCampaignMutationHookResult = ReturnType<typeof useInsertCampaignMutation>;
export type InsertCampaignMutationResult = Apollo.MutationResult<Types.InsertCampaignMutation>;
export type InsertCampaignMutationOptions = Apollo.BaseMutationOptions<Types.InsertCampaignMutation, Types.InsertCampaignMutationVariables>;
export const DeleteCampaignDocument = gql`
    mutation DeleteCampaign($id: Int!) {
  deleteCampaign: delete_campaign(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<Types.DeleteCampaignMutation, Types.DeleteCampaignMutationVariables>;

/**
 * __useDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMutation, { data, loading, error }] = useDeleteCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCampaignMutation, Types.DeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCampaignMutation, Types.DeleteCampaignMutationVariables>(DeleteCampaignDocument, options);
      }
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<Types.DeleteCampaignMutation>;
export type DeleteCampaignMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCampaignMutation, Types.DeleteCampaignMutationVariables>;
export const UpdateCampaignDocument = gql`
    mutation UpdateCampaign($id: Int!, $changes: campaign_set_input) {
  updateCampaign: update_campaign(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<Types.UpdateCampaignMutation, Types.UpdateCampaignMutationVariables>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCampaignMutation, Types.UpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCampaignMutation, Types.UpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<Types.UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCampaignMutation, Types.UpdateCampaignMutationVariables>;
export const AllCampaignResponderContactListsDocument = gql`
    query AllCampaignResponderContactLists {
  campaignResponderContactLists: campaign_responder_contact_list {
    ...CampaignResponderContactListFields
  }
}
    ${CampaignResponderContactListFieldsFragmentDoc}`;

/**
 * __useAllCampaignResponderContactListsQuery__
 *
 * To run a query within a React component, call `useAllCampaignResponderContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCampaignResponderContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCampaignResponderContactListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCampaignResponderContactListsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCampaignResponderContactListsQuery, Types.AllCampaignResponderContactListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCampaignResponderContactListsQuery, Types.AllCampaignResponderContactListsQueryVariables>(AllCampaignResponderContactListsDocument, options);
      }
export function useAllCampaignResponderContactListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCampaignResponderContactListsQuery, Types.AllCampaignResponderContactListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCampaignResponderContactListsQuery, Types.AllCampaignResponderContactListsQueryVariables>(AllCampaignResponderContactListsDocument, options);
        }
export type AllCampaignResponderContactListsQueryHookResult = ReturnType<typeof useAllCampaignResponderContactListsQuery>;
export type AllCampaignResponderContactListsLazyQueryHookResult = ReturnType<typeof useAllCampaignResponderContactListsLazyQuery>;
export type AllCampaignResponderContactListsQueryResult = Apollo.QueryResult<Types.AllCampaignResponderContactListsQuery, Types.AllCampaignResponderContactListsQueryVariables>;
export const CampaignResponderContactListsByFilterDocument = gql`
    query CampaignResponderContactListsByFilter($filter: campaign_responder_contact_list_bool_exp, $limit: Int, $offset: Int) {
  campaignResponderContactLists: campaign_responder_contact_list(
    where: $filter
    limit: $limit
    offset: $offset
  ) {
    ...CampaignResponderContactListFields
  }
  campaign_responder_contact_list_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    ${CampaignResponderContactListFieldsFragmentDoc}`;

/**
 * __useCampaignResponderContactListsByFilterQuery__
 *
 * To run a query within a React component, call `useCampaignResponderContactListsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignResponderContactListsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignResponderContactListsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCampaignResponderContactListsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CampaignResponderContactListsByFilterQuery, Types.CampaignResponderContactListsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CampaignResponderContactListsByFilterQuery, Types.CampaignResponderContactListsByFilterQueryVariables>(CampaignResponderContactListsByFilterDocument, options);
      }
export function useCampaignResponderContactListsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CampaignResponderContactListsByFilterQuery, Types.CampaignResponderContactListsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CampaignResponderContactListsByFilterQuery, Types.CampaignResponderContactListsByFilterQueryVariables>(CampaignResponderContactListsByFilterDocument, options);
        }
export type CampaignResponderContactListsByFilterQueryHookResult = ReturnType<typeof useCampaignResponderContactListsByFilterQuery>;
export type CampaignResponderContactListsByFilterLazyQueryHookResult = ReturnType<typeof useCampaignResponderContactListsByFilterLazyQuery>;
export type CampaignResponderContactListsByFilterQueryResult = Apollo.QueryResult<Types.CampaignResponderContactListsByFilterQuery, Types.CampaignResponderContactListsByFilterQueryVariables>;
export const InsertCampaignResponderContactListDocument = gql`
    mutation InsertCampaignResponderContactList($object: campaign_responder_contact_list_insert_input!) {
  insertCampaignResponderContactList: insert_campaign_responder_contact_list(
    objects: [$object]
  ) {
    returning {
      ...CampaignResponderContactListFields
    }
  }
}
    ${CampaignResponderContactListFieldsFragmentDoc}`;
export type InsertCampaignResponderContactListMutationFn = Apollo.MutationFunction<Types.InsertCampaignResponderContactListMutation, Types.InsertCampaignResponderContactListMutationVariables>;

/**
 * __useInsertCampaignResponderContactListMutation__
 *
 * To run a mutation, you first call `useInsertCampaignResponderContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCampaignResponderContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCampaignResponderContactListMutation, { data, loading, error }] = useInsertCampaignResponderContactListMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCampaignResponderContactListMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCampaignResponderContactListMutation, Types.InsertCampaignResponderContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCampaignResponderContactListMutation, Types.InsertCampaignResponderContactListMutationVariables>(InsertCampaignResponderContactListDocument, options);
      }
export type InsertCampaignResponderContactListMutationHookResult = ReturnType<typeof useInsertCampaignResponderContactListMutation>;
export type InsertCampaignResponderContactListMutationResult = Apollo.MutationResult<Types.InsertCampaignResponderContactListMutation>;
export type InsertCampaignResponderContactListMutationOptions = Apollo.BaseMutationOptions<Types.InsertCampaignResponderContactListMutation, Types.InsertCampaignResponderContactListMutationVariables>;
export const UpdateCampaignResponderContactListDocument = gql`
    mutation UpdateCampaignResponderContactList($campaignResponderId: Int!, $contactListId: Int!, $changes: campaign_responder_contact_list_set_input) {
  updateCampaignResponderContactList: update_campaign_responder_contact_list(
    where: {campaign_responder_id: {_eq: $campaignResponderId}, contact_list_id: {_eq: $contactListId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCampaignResponderContactListMutationFn = Apollo.MutationFunction<Types.UpdateCampaignResponderContactListMutation, Types.UpdateCampaignResponderContactListMutationVariables>;

/**
 * __useUpdateCampaignResponderContactListMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignResponderContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignResponderContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignResponderContactListMutation, { data, loading, error }] = useUpdateCampaignResponderContactListMutation({
 *   variables: {
 *      campaignResponderId: // value for 'campaignResponderId'
 *      contactListId: // value for 'contactListId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignResponderContactListMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCampaignResponderContactListMutation, Types.UpdateCampaignResponderContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCampaignResponderContactListMutation, Types.UpdateCampaignResponderContactListMutationVariables>(UpdateCampaignResponderContactListDocument, options);
      }
export type UpdateCampaignResponderContactListMutationHookResult = ReturnType<typeof useUpdateCampaignResponderContactListMutation>;
export type UpdateCampaignResponderContactListMutationResult = Apollo.MutationResult<Types.UpdateCampaignResponderContactListMutation>;
export type UpdateCampaignResponderContactListMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCampaignResponderContactListMutation, Types.UpdateCampaignResponderContactListMutationVariables>;
export const DeleteCampaignResponderContactListDocument = gql`
    mutation DeleteCampaignResponderContactList($campaignResponderId: Int!, $contactListId: Int!) {
  deleteCampaignResponderContactList: delete_campaign_responder_contact_list(
    where: {campaign_responder_id: {_eq: $campaignResponderId}, contact_list_id: {_eq: $contactListId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCampaignResponderContactListMutationFn = Apollo.MutationFunction<Types.DeleteCampaignResponderContactListMutation, Types.DeleteCampaignResponderContactListMutationVariables>;

/**
 * __useDeleteCampaignResponderContactListMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignResponderContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignResponderContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignResponderContactListMutation, { data, loading, error }] = useDeleteCampaignResponderContactListMutation({
 *   variables: {
 *      campaignResponderId: // value for 'campaignResponderId'
 *      contactListId: // value for 'contactListId'
 *   },
 * });
 */
export function useDeleteCampaignResponderContactListMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCampaignResponderContactListMutation, Types.DeleteCampaignResponderContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCampaignResponderContactListMutation, Types.DeleteCampaignResponderContactListMutationVariables>(DeleteCampaignResponderContactListDocument, options);
      }
export type DeleteCampaignResponderContactListMutationHookResult = ReturnType<typeof useDeleteCampaignResponderContactListMutation>;
export type DeleteCampaignResponderContactListMutationResult = Apollo.MutationResult<Types.DeleteCampaignResponderContactListMutation>;
export type DeleteCampaignResponderContactListMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCampaignResponderContactListMutation, Types.DeleteCampaignResponderContactListMutationVariables>;
export const AllCampaignRespondersDocument = gql`
    query AllCampaignResponders($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  campaignResponders: campaign_responder(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {id: asc}
  ) {
    ...CampaignResponderFields
  }
}
    ${CampaignResponderFieldsFragmentDoc}`;

/**
 * __useAllCampaignRespondersQuery__
 *
 * To run a query within a React component, call `useAllCampaignRespondersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCampaignRespondersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCampaignRespondersQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllCampaignRespondersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCampaignRespondersQuery, Types.AllCampaignRespondersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCampaignRespondersQuery, Types.AllCampaignRespondersQueryVariables>(AllCampaignRespondersDocument, options);
      }
export function useAllCampaignRespondersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCampaignRespondersQuery, Types.AllCampaignRespondersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCampaignRespondersQuery, Types.AllCampaignRespondersQueryVariables>(AllCampaignRespondersDocument, options);
        }
export type AllCampaignRespondersQueryHookResult = ReturnType<typeof useAllCampaignRespondersQuery>;
export type AllCampaignRespondersLazyQueryHookResult = ReturnType<typeof useAllCampaignRespondersLazyQuery>;
export type AllCampaignRespondersQueryResult = Apollo.QueryResult<Types.AllCampaignRespondersQuery, Types.AllCampaignRespondersQueryVariables>;
export const CampaignResponderByIdDocument = gql`
    query CampaignResponderById($id: Int!) {
  campaignResponder: campaign_responder_by_pk(id: $id) {
    ...CampaignResponderFields
  }
  campaign_responder_contact_list_aggregate(
    where: {campaign_responder_id: {_eq: $id}}
  ) {
    aggregate {
      count
    }
  }
}
    ${CampaignResponderFieldsFragmentDoc}`;

/**
 * __useCampaignResponderByIdQuery__
 *
 * To run a query within a React component, call `useCampaignResponderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignResponderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignResponderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignResponderByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CampaignResponderByIdQuery, Types.CampaignResponderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CampaignResponderByIdQuery, Types.CampaignResponderByIdQueryVariables>(CampaignResponderByIdDocument, options);
      }
export function useCampaignResponderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CampaignResponderByIdQuery, Types.CampaignResponderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CampaignResponderByIdQuery, Types.CampaignResponderByIdQueryVariables>(CampaignResponderByIdDocument, options);
        }
export type CampaignResponderByIdQueryHookResult = ReturnType<typeof useCampaignResponderByIdQuery>;
export type CampaignResponderByIdLazyQueryHookResult = ReturnType<typeof useCampaignResponderByIdLazyQuery>;
export type CampaignResponderByIdQueryResult = Apollo.QueryResult<Types.CampaignResponderByIdQuery, Types.CampaignResponderByIdQueryVariables>;
export const CampaignRespondersByFilterDocument = gql`
    query CampaignRespondersByFilter($filter: campaign_responder_bool_exp, $limit: Int, $offset: Int) {
  campaignResponders: campaign_responder(
    where: $filter
    limit: $limit
    offset: $offset
    order_by: {id: asc}
  ) {
    ...CampaignResponderFields
  }
  campaign_responder_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    ${CampaignResponderFieldsFragmentDoc}`;

/**
 * __useCampaignRespondersByFilterQuery__
 *
 * To run a query within a React component, call `useCampaignRespondersByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignRespondersByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignRespondersByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCampaignRespondersByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CampaignRespondersByFilterQuery, Types.CampaignRespondersByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CampaignRespondersByFilterQuery, Types.CampaignRespondersByFilterQueryVariables>(CampaignRespondersByFilterDocument, options);
      }
export function useCampaignRespondersByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CampaignRespondersByFilterQuery, Types.CampaignRespondersByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CampaignRespondersByFilterQuery, Types.CampaignRespondersByFilterQueryVariables>(CampaignRespondersByFilterDocument, options);
        }
export type CampaignRespondersByFilterQueryHookResult = ReturnType<typeof useCampaignRespondersByFilterQuery>;
export type CampaignRespondersByFilterLazyQueryHookResult = ReturnType<typeof useCampaignRespondersByFilterLazyQuery>;
export type CampaignRespondersByFilterQueryResult = Apollo.QueryResult<Types.CampaignRespondersByFilterQuery, Types.CampaignRespondersByFilterQueryVariables>;
export const InsertCampaignResponderDocument = gql`
    mutation InsertCampaignResponder($campaignResponder: campaign_responder_insert_input!) {
  insert_campaign_responder(objects: [$campaignResponder]) {
    returning {
      id
    }
  }
}
    `;
export type InsertCampaignResponderMutationFn = Apollo.MutationFunction<Types.InsertCampaignResponderMutation, Types.InsertCampaignResponderMutationVariables>;

/**
 * __useInsertCampaignResponderMutation__
 *
 * To run a mutation, you first call `useInsertCampaignResponderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCampaignResponderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCampaignResponderMutation, { data, loading, error }] = useInsertCampaignResponderMutation({
 *   variables: {
 *      campaignResponder: // value for 'campaignResponder'
 *   },
 * });
 */
export function useInsertCampaignResponderMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCampaignResponderMutation, Types.InsertCampaignResponderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCampaignResponderMutation, Types.InsertCampaignResponderMutationVariables>(InsertCampaignResponderDocument, options);
      }
export type InsertCampaignResponderMutationHookResult = ReturnType<typeof useInsertCampaignResponderMutation>;
export type InsertCampaignResponderMutationResult = Apollo.MutationResult<Types.InsertCampaignResponderMutation>;
export type InsertCampaignResponderMutationOptions = Apollo.BaseMutationOptions<Types.InsertCampaignResponderMutation, Types.InsertCampaignResponderMutationVariables>;
export const UpdateCampaignResponderDocument = gql`
    mutation UpdateCampaignResponder($id: Int!, $changes: campaign_responder_set_input) {
  updateCampaignResponder: update_campaign_responder(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCampaignResponderMutationFn = Apollo.MutationFunction<Types.UpdateCampaignResponderMutation, Types.UpdateCampaignResponderMutationVariables>;

/**
 * __useUpdateCampaignResponderMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignResponderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignResponderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignResponderMutation, { data, loading, error }] = useUpdateCampaignResponderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignResponderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCampaignResponderMutation, Types.UpdateCampaignResponderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCampaignResponderMutation, Types.UpdateCampaignResponderMutationVariables>(UpdateCampaignResponderDocument, options);
      }
export type UpdateCampaignResponderMutationHookResult = ReturnType<typeof useUpdateCampaignResponderMutation>;
export type UpdateCampaignResponderMutationResult = Apollo.MutationResult<Types.UpdateCampaignResponderMutation>;
export type UpdateCampaignResponderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCampaignResponderMutation, Types.UpdateCampaignResponderMutationVariables>;
export const DeleteCampaignResponderDocument = gql`
    mutation DeleteCampaignResponder($id: Int!) {
  deleteCampaignResponder: delete_campaign_responder(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCampaignResponderMutationFn = Apollo.MutationFunction<Types.DeleteCampaignResponderMutation, Types.DeleteCampaignResponderMutationVariables>;

/**
 * __useDeleteCampaignResponderMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignResponderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignResponderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignResponderMutation, { data, loading, error }] = useDeleteCampaignResponderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCampaignResponderMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCampaignResponderMutation, Types.DeleteCampaignResponderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCampaignResponderMutation, Types.DeleteCampaignResponderMutationVariables>(DeleteCampaignResponderDocument, options);
      }
export type DeleteCampaignResponderMutationHookResult = ReturnType<typeof useDeleteCampaignResponderMutation>;
export type DeleteCampaignResponderMutationResult = Apollo.MutationResult<Types.DeleteCampaignResponderMutation>;
export type DeleteCampaignResponderMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCampaignResponderMutation, Types.DeleteCampaignResponderMutationVariables>;
export const AllCannedResponsesDocument = gql`
    query AllCannedResponses($belongsToRoleUserId: Int!, $limit: Int, $offset: Int) {
  cannedResponses: canned_response(
    limit: $limit
    offset: $offset
    where: {belongs_to_role_user_id: {_eq: $belongsToRoleUserId}}
    order_by: {slash_command: asc}
  ) {
    ...CannedResponseFields
  }
  canned_response_aggregate(
    where: {belongs_to_role_user_id: {_eq: $belongsToRoleUserId}}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${CannedResponseFieldsFragmentDoc}`;

/**
 * __useAllCannedResponsesQuery__
 *
 * To run a query within a React component, call `useAllCannedResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCannedResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCannedResponsesQuery({
 *   variables: {
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllCannedResponsesQuery(baseOptions: Apollo.QueryHookOptions<Types.AllCannedResponsesQuery, Types.AllCannedResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCannedResponsesQuery, Types.AllCannedResponsesQueryVariables>(AllCannedResponsesDocument, options);
      }
export function useAllCannedResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCannedResponsesQuery, Types.AllCannedResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCannedResponsesQuery, Types.AllCannedResponsesQueryVariables>(AllCannedResponsesDocument, options);
        }
export type AllCannedResponsesQueryHookResult = ReturnType<typeof useAllCannedResponsesQuery>;
export type AllCannedResponsesLazyQueryHookResult = ReturnType<typeof useAllCannedResponsesLazyQuery>;
export type AllCannedResponsesQueryResult = Apollo.QueryResult<Types.AllCannedResponsesQuery, Types.AllCannedResponsesQueryVariables>;
export const CannedResponseByIdDocument = gql`
    query CannedResponseById($id: Int!) {
  cannedResponse: canned_response(where: {id: {_eq: $id}}) {
    ...CannedResponseFields
  }
}
    ${CannedResponseFieldsFragmentDoc}`;

/**
 * __useCannedResponseByIdQuery__
 *
 * To run a query within a React component, call `useCannedResponseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCannedResponseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCannedResponseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCannedResponseByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CannedResponseByIdQuery, Types.CannedResponseByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CannedResponseByIdQuery, Types.CannedResponseByIdQueryVariables>(CannedResponseByIdDocument, options);
      }
export function useCannedResponseByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CannedResponseByIdQuery, Types.CannedResponseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CannedResponseByIdQuery, Types.CannedResponseByIdQueryVariables>(CannedResponseByIdDocument, options);
        }
export type CannedResponseByIdQueryHookResult = ReturnType<typeof useCannedResponseByIdQuery>;
export type CannedResponseByIdLazyQueryHookResult = ReturnType<typeof useCannedResponseByIdLazyQuery>;
export type CannedResponseByIdQueryResult = Apollo.QueryResult<Types.CannedResponseByIdQuery, Types.CannedResponseByIdQueryVariables>;
export const UpdateCannedResponseDocument = gql`
    mutation UpdateCannedResponse($id: Int!, $changes: canned_response_set_input) {
  updateCannedResponse: update_canned_response(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCannedResponseMutationFn = Apollo.MutationFunction<Types.UpdateCannedResponseMutation, Types.UpdateCannedResponseMutationVariables>;

/**
 * __useUpdateCannedResponseMutation__
 *
 * To run a mutation, you first call `useUpdateCannedResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCannedResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCannedResponseMutation, { data, loading, error }] = useUpdateCannedResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCannedResponseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCannedResponseMutation, Types.UpdateCannedResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCannedResponseMutation, Types.UpdateCannedResponseMutationVariables>(UpdateCannedResponseDocument, options);
      }
export type UpdateCannedResponseMutationHookResult = ReturnType<typeof useUpdateCannedResponseMutation>;
export type UpdateCannedResponseMutationResult = Apollo.MutationResult<Types.UpdateCannedResponseMutation>;
export type UpdateCannedResponseMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCannedResponseMutation, Types.UpdateCannedResponseMutationVariables>;
export const InsertCannedResponseDocument = gql`
    mutation InsertCannedResponse($objectArray: [canned_response_insert_input!]!) {
  insert_canned_response(objects: $objectArray) {
    returning {
      id
    }
  }
}
    `;
export type InsertCannedResponseMutationFn = Apollo.MutationFunction<Types.InsertCannedResponseMutation, Types.InsertCannedResponseMutationVariables>;

/**
 * __useInsertCannedResponseMutation__
 *
 * To run a mutation, you first call `useInsertCannedResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCannedResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCannedResponseMutation, { data, loading, error }] = useInsertCannedResponseMutation({
 *   variables: {
 *      objectArray: // value for 'objectArray'
 *   },
 * });
 */
export function useInsertCannedResponseMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCannedResponseMutation, Types.InsertCannedResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCannedResponseMutation, Types.InsertCannedResponseMutationVariables>(InsertCannedResponseDocument, options);
      }
export type InsertCannedResponseMutationHookResult = ReturnType<typeof useInsertCannedResponseMutation>;
export type InsertCannedResponseMutationResult = Apollo.MutationResult<Types.InsertCannedResponseMutation>;
export type InsertCannedResponseMutationOptions = Apollo.BaseMutationOptions<Types.InsertCannedResponseMutation, Types.InsertCannedResponseMutationVariables>;
export const DeleteCannedResponseDocument = gql`
    mutation DeleteCannedResponse($id: Int!) {
  delete_canned_response(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCannedResponseMutationFn = Apollo.MutationFunction<Types.DeleteCannedResponseMutation, Types.DeleteCannedResponseMutationVariables>;

/**
 * __useDeleteCannedResponseMutation__
 *
 * To run a mutation, you first call `useDeleteCannedResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCannedResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCannedResponseMutation, { data, loading, error }] = useDeleteCannedResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCannedResponseMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCannedResponseMutation, Types.DeleteCannedResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCannedResponseMutation, Types.DeleteCannedResponseMutationVariables>(DeleteCannedResponseDocument, options);
      }
export type DeleteCannedResponseMutationHookResult = ReturnType<typeof useDeleteCannedResponseMutation>;
export type DeleteCannedResponseMutationResult = Apollo.MutationResult<Types.DeleteCannedResponseMutation>;
export type DeleteCannedResponseMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCannedResponseMutation, Types.DeleteCannedResponseMutationVariables>;
export const GetAllCarriersDocument = gql`
    query GetAllCarriers($limit: Int, $offset: Int) {
  carriers: carrier(
    limit: $limit
    offset: $offset
    order_by: {contacts_aggregate: {count: desc}}
  ) {
    ...CarrierFields
  }
  carrier_aggregate(order_by: {id: desc}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${CarrierFieldsFragmentDoc}`;

/**
 * __useGetAllCarriersQuery__
 *
 * To run a query within a React component, call `useGetAllCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCarriersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllCarriersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllCarriersQuery, Types.GetAllCarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllCarriersQuery, Types.GetAllCarriersQueryVariables>(GetAllCarriersDocument, options);
      }
export function useGetAllCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllCarriersQuery, Types.GetAllCarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllCarriersQuery, Types.GetAllCarriersQueryVariables>(GetAllCarriersDocument, options);
        }
export type GetAllCarriersQueryHookResult = ReturnType<typeof useGetAllCarriersQuery>;
export type GetAllCarriersLazyQueryHookResult = ReturnType<typeof useGetAllCarriersLazyQuery>;
export type GetAllCarriersQueryResult = Apollo.QueryResult<Types.GetAllCarriersQuery, Types.GetAllCarriersQueryVariables>;
export const GetCarrierDocument = gql`
    query GetCarrier($id: Int!) {
  carriers: carrier(where: {id: {_eq: $id}}) {
    ...CarrierFields
  }
}
    ${CarrierFieldsFragmentDoc}`;

/**
 * __useGetCarrierQuery__
 *
 * To run a query within a React component, call `useGetCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarrierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCarrierQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCarrierQuery, Types.GetCarrierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCarrierQuery, Types.GetCarrierQueryVariables>(GetCarrierDocument, options);
      }
export function useGetCarrierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCarrierQuery, Types.GetCarrierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCarrierQuery, Types.GetCarrierQueryVariables>(GetCarrierDocument, options);
        }
export type GetCarrierQueryHookResult = ReturnType<typeof useGetCarrierQuery>;
export type GetCarrierLazyQueryHookResult = ReturnType<typeof useGetCarrierLazyQuery>;
export type GetCarrierQueryResult = Apollo.QueryResult<Types.GetCarrierQuery, Types.GetCarrierQueryVariables>;
export const UpdateCarrierDocument = gql`
    mutation UpdateCarrier($id: Int!, $changes: carrier_set_input) {
  updateCarrier: update_carrier(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateCarrierMutationFn = Apollo.MutationFunction<Types.UpdateCarrierMutation, Types.UpdateCarrierMutationVariables>;

/**
 * __useUpdateCarrierMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierMutation, { data, loading, error }] = useUpdateCarrierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCarrierMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCarrierMutation, Types.UpdateCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCarrierMutation, Types.UpdateCarrierMutationVariables>(UpdateCarrierDocument, options);
      }
export type UpdateCarrierMutationHookResult = ReturnType<typeof useUpdateCarrierMutation>;
export type UpdateCarrierMutationResult = Apollo.MutationResult<Types.UpdateCarrierMutation>;
export type UpdateCarrierMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCarrierMutation, Types.UpdateCarrierMutationVariables>;
export const InsertCarrierDocument = gql`
    mutation InsertCarrier($object: carrier_insert_input!) {
  insert_carrier(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertCarrierMutationFn = Apollo.MutationFunction<Types.InsertCarrierMutation, Types.InsertCarrierMutationVariables>;

/**
 * __useInsertCarrierMutation__
 *
 * To run a mutation, you first call `useInsertCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCarrierMutation, { data, loading, error }] = useInsertCarrierMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCarrierMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCarrierMutation, Types.InsertCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCarrierMutation, Types.InsertCarrierMutationVariables>(InsertCarrierDocument, options);
      }
export type InsertCarrierMutationHookResult = ReturnType<typeof useInsertCarrierMutation>;
export type InsertCarrierMutationResult = Apollo.MutationResult<Types.InsertCarrierMutation>;
export type InsertCarrierMutationOptions = Apollo.BaseMutationOptions<Types.InsertCarrierMutation, Types.InsertCarrierMutationVariables>;
export const DeleteCarrierDocument = gql`
    mutation DeleteCarrier($id: Int!) {
  delete_carrier(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCarrierMutationFn = Apollo.MutationFunction<Types.DeleteCarrierMutation, Types.DeleteCarrierMutationVariables>;

/**
 * __useDeleteCarrierMutation__
 *
 * To run a mutation, you first call `useDeleteCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarrierMutation, { data, loading, error }] = useDeleteCarrierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarrierMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCarrierMutation, Types.DeleteCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCarrierMutation, Types.DeleteCarrierMutationVariables>(DeleteCarrierDocument, options);
      }
export type DeleteCarrierMutationHookResult = ReturnType<typeof useDeleteCarrierMutation>;
export type DeleteCarrierMutationResult = Apollo.MutationResult<Types.DeleteCarrierMutation>;
export type DeleteCarrierMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCarrierMutation, Types.DeleteCarrierMutationVariables>;
export const GetAllCategoriesDocument = gql`
    query GetAllCategories($belongsToCompanyId: Int!, $belongsToRoleAffiliateId: Int) {
  categories: category(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {category_name: asc}
  ) {
    id
    categoryName: category_name
  }
}
    `;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllCategoriesQuery, Types.GetAllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllCategoriesQuery, Types.GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllCategoriesQuery, Types.GetAllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllCategoriesQuery, Types.GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<Types.GetAllCategoriesQuery, Types.GetAllCategoriesQueryVariables>;
export const GetCategoryDocument = gql`
    query GetCategory($id: Int!) {
  category: category(where: {id: {_eq: $id}}) {
    id
    categoryName: category_name
  }
}
    `;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCategoryQuery, Types.GetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCategoryQuery, Types.GetCategoryQueryVariables>(GetCategoryDocument, options);
      }
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCategoryQuery, Types.GetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCategoryQuery, Types.GetCategoryQueryVariables>(GetCategoryDocument, options);
        }
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<Types.GetCategoryQuery, Types.GetCategoryQueryVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: Int!, $changes: category_set_input) {
  updateCategory: update_category(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<Types.UpdateCategoryMutation, Types.UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCategoryMutation, Types.UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCategoryMutation, Types.UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<Types.UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCategoryMutation, Types.UpdateCategoryMutationVariables>;
export const InsertCategoryDocument = gql`
    mutation InsertCategory($object: category_insert_input!) {
  insert_category(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertCategoryMutationFn = Apollo.MutationFunction<Types.InsertCategoryMutation, Types.InsertCategoryMutationVariables>;

/**
 * __useInsertCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCategoryMutation, { data, loading, error }] = useInsertCategoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCategoryMutation, Types.InsertCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCategoryMutation, Types.InsertCategoryMutationVariables>(InsertCategoryDocument, options);
      }
export type InsertCategoryMutationHookResult = ReturnType<typeof useInsertCategoryMutation>;
export type InsertCategoryMutationResult = Apollo.MutationResult<Types.InsertCategoryMutation>;
export type InsertCategoryMutationOptions = Apollo.BaseMutationOptions<Types.InsertCategoryMutation, Types.InsertCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: Int!) {
  delete_category(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<Types.DeleteCategoryMutation, Types.DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCategoryMutation, Types.DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCategoryMutation, Types.DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<Types.DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCategoryMutation, Types.DeleteCategoryMutationVariables>;
export const InsertChatbotMessageDocument = gql`
    mutation insertChatbotMessage($text: String!, $intentId: Int, $messageType: String, $flowId: Int, $rank: Int) {
  insertChatbotMessage: insert_chatbot_message(
    objects: [{text: $text, intent_id: $intentId, message_type: $messageType, flow_id: $flowId, rank: $rank}]
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertChatbotMessageMutationFn = Apollo.MutationFunction<Types.InsertChatbotMessageMutation, Types.InsertChatbotMessageMutationVariables>;

/**
 * __useInsertChatbotMessageMutation__
 *
 * To run a mutation, you first call `useInsertChatbotMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChatbotMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChatbotMessageMutation, { data, loading, error }] = useInsertChatbotMessageMutation({
 *   variables: {
 *      text: // value for 'text'
 *      intentId: // value for 'intentId'
 *      messageType: // value for 'messageType'
 *      flowId: // value for 'flowId'
 *      rank: // value for 'rank'
 *   },
 * });
 */
export function useInsertChatbotMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertChatbotMessageMutation, Types.InsertChatbotMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertChatbotMessageMutation, Types.InsertChatbotMessageMutationVariables>(InsertChatbotMessageDocument, options);
      }
export type InsertChatbotMessageMutationHookResult = ReturnType<typeof useInsertChatbotMessageMutation>;
export type InsertChatbotMessageMutationResult = Apollo.MutationResult<Types.InsertChatbotMessageMutation>;
export type InsertChatbotMessageMutationOptions = Apollo.BaseMutationOptions<Types.InsertChatbotMessageMutation, Types.InsertChatbotMessageMutationVariables>;
export const UpdateChatbotMessageDocument = gql`
    mutation updateChatbotMessage($id: Int!, $changes: chatbot_message_set_input) {
  updateChatbotMessage: update_chatbot_message(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateChatbotMessageMutationFn = Apollo.MutationFunction<Types.UpdateChatbotMessageMutation, Types.UpdateChatbotMessageMutationVariables>;

/**
 * __useUpdateChatbotMessageMutation__
 *
 * To run a mutation, you first call `useUpdateChatbotMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatbotMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatbotMessageMutation, { data, loading, error }] = useUpdateChatbotMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateChatbotMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChatbotMessageMutation, Types.UpdateChatbotMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChatbotMessageMutation, Types.UpdateChatbotMessageMutationVariables>(UpdateChatbotMessageDocument, options);
      }
export type UpdateChatbotMessageMutationHookResult = ReturnType<typeof useUpdateChatbotMessageMutation>;
export type UpdateChatbotMessageMutationResult = Apollo.MutationResult<Types.UpdateChatbotMessageMutation>;
export type UpdateChatbotMessageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChatbotMessageMutation, Types.UpdateChatbotMessageMutationVariables>;
export const DeleteChatbotMessageDocument = gql`
    mutation deleteChatbotMessage($id: Int!) {
  deleteChatbotMessage: delete_chatbot_message(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteChatbotMessageMutationFn = Apollo.MutationFunction<Types.DeleteChatbotMessageMutation, Types.DeleteChatbotMessageMutationVariables>;

/**
 * __useDeleteChatbotMessageMutation__
 *
 * To run a mutation, you first call `useDeleteChatbotMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatbotMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatbotMessageMutation, { data, loading, error }] = useDeleteChatbotMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatbotMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteChatbotMessageMutation, Types.DeleteChatbotMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteChatbotMessageMutation, Types.DeleteChatbotMessageMutationVariables>(DeleteChatbotMessageDocument, options);
      }
export type DeleteChatbotMessageMutationHookResult = ReturnType<typeof useDeleteChatbotMessageMutation>;
export type DeleteChatbotMessageMutationResult = Apollo.MutationResult<Types.DeleteChatbotMessageMutation>;
export type DeleteChatbotMessageMutationOptions = Apollo.BaseMutationOptions<Types.DeleteChatbotMessageMutation, Types.DeleteChatbotMessageMutationVariables>;
export const AllCompaniesDocument = gql`
    query AllCompanies {
  companies: company(order_by: {name: asc}) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useAllCompaniesQuery__
 *
 * To run a query within a React component, call `useAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCompaniesQuery, Types.AllCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompaniesQuery, Types.AllCompaniesQueryVariables>(AllCompaniesDocument, options);
      }
export function useAllCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompaniesQuery, Types.AllCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompaniesQuery, Types.AllCompaniesQueryVariables>(AllCompaniesDocument, options);
        }
export type AllCompaniesQueryHookResult = ReturnType<typeof useAllCompaniesQuery>;
export type AllCompaniesLazyQueryHookResult = ReturnType<typeof useAllCompaniesLazyQuery>;
export type AllCompaniesQueryResult = Apollo.QueryResult<Types.AllCompaniesQuery, Types.AllCompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: Int!) {
  company: company(where: {id: {_eq: $id}}) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyQuery, Types.CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyQuery, Types.CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyQuery, Types.CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyQuery, Types.CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<Types.CompanyQuery, Types.CompanyQueryVariables>;
export const CompanyByFilterDocument = gql`
    query CompanyByFilter($filter: company_bool_exp) {
  companies: company(where: $filter) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useCompanyByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyByFilterQuery, Types.CompanyByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyByFilterQuery, Types.CompanyByFilterQueryVariables>(CompanyByFilterDocument, options);
      }
export function useCompanyByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyByFilterQuery, Types.CompanyByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyByFilterQuery, Types.CompanyByFilterQueryVariables>(CompanyByFilterDocument, options);
        }
export type CompanyByFilterQueryHookResult = ReturnType<typeof useCompanyByFilterQuery>;
export type CompanyByFilterLazyQueryHookResult = ReturnType<typeof useCompanyByFilterLazyQuery>;
export type CompanyByFilterQueryResult = Apollo.QueryResult<Types.CompanyByFilterQuery, Types.CompanyByFilterQueryVariables>;
export const CompanyDomainsDocument = gql`
    query CompanyDomains($id: Int!) {
  company: company(where: {id: {_eq: $id}}) {
    domain: domain
    cfDomainIdentifier: cf_domain_identifier
    cfDomainConfirmed: cf_domain_confirmed
    apiDomain: api_domain
    cfApiDomainIdentifier: cf_api_domain_identifier
    cfApiDomainConfirmed: cf_api_domain_confirmed
    trackingDomain: tracking_domain
    cfTrackingDomainIdentifier: cf_tracking_domain_identifier
    cfTrackingDomainConfirmed: cf_tracking_domain_confirmed
  }
}
    `;

/**
 * __useCompanyDomainsQuery__
 *
 * To run a query within a React component, call `useCompanyDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDomainsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyDomainsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyDomainsQuery, Types.CompanyDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyDomainsQuery, Types.CompanyDomainsQueryVariables>(CompanyDomainsDocument, options);
      }
export function useCompanyDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyDomainsQuery, Types.CompanyDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyDomainsQuery, Types.CompanyDomainsQueryVariables>(CompanyDomainsDocument, options);
        }
export type CompanyDomainsQueryHookResult = ReturnType<typeof useCompanyDomainsQuery>;
export type CompanyDomainsLazyQueryHookResult = ReturnType<typeof useCompanyDomainsLazyQuery>;
export type CompanyDomainsQueryResult = Apollo.QueryResult<Types.CompanyDomainsQuery, Types.CompanyDomainsQueryVariables>;
export const GetAllCompanyDomainsDocument = gql`
    query GetAllCompanyDomains {
  companies: company(order_by: {name: asc}, where: {domain: {_is_null: false}}) {
    domain
    cf_domain_identifier
    tracking_domain
    cf_api_domain_identifier
    api_domain
    cf_tracking_domain_identifier
  }
}
    `;

/**
 * __useGetAllCompanyDomainsQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompanyDomainsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllCompanyDomainsQuery, Types.GetAllCompanyDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllCompanyDomainsQuery, Types.GetAllCompanyDomainsQueryVariables>(GetAllCompanyDomainsDocument, options);
      }
export function useGetAllCompanyDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllCompanyDomainsQuery, Types.GetAllCompanyDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllCompanyDomainsQuery, Types.GetAllCompanyDomainsQueryVariables>(GetAllCompanyDomainsDocument, options);
        }
export type GetAllCompanyDomainsQueryHookResult = ReturnType<typeof useGetAllCompanyDomainsQuery>;
export type GetAllCompanyDomainsLazyQueryHookResult = ReturnType<typeof useGetAllCompanyDomainsLazyQuery>;
export type GetAllCompanyDomainsQueryResult = Apollo.QueryResult<Types.GetAllCompanyDomainsQuery, Types.GetAllCompanyDomainsQueryVariables>;
export const AllAdvertiserCompaniesDocument = gql`
    query AllAdvertiserCompanies {
  companies: company(
    order_by: {name: asc}
    where: {company_type: {shortcode: {_eq: "advertiser"}}}
  ) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useAllAdvertiserCompaniesQuery__
 *
 * To run a query within a React component, call `useAllAdvertiserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAdvertiserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAdvertiserCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAdvertiserCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllAdvertiserCompaniesQuery, Types.AllAdvertiserCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllAdvertiserCompaniesQuery, Types.AllAdvertiserCompaniesQueryVariables>(AllAdvertiserCompaniesDocument, options);
      }
export function useAllAdvertiserCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllAdvertiserCompaniesQuery, Types.AllAdvertiserCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllAdvertiserCompaniesQuery, Types.AllAdvertiserCompaniesQueryVariables>(AllAdvertiserCompaniesDocument, options);
        }
export type AllAdvertiserCompaniesQueryHookResult = ReturnType<typeof useAllAdvertiserCompaniesQuery>;
export type AllAdvertiserCompaniesLazyQueryHookResult = ReturnType<typeof useAllAdvertiserCompaniesLazyQuery>;
export type AllAdvertiserCompaniesQueryResult = Apollo.QueryResult<Types.AllAdvertiserCompaniesQuery, Types.AllAdvertiserCompaniesQueryVariables>;
export const AllChatCompaniesDocument = gql`
    query AllChatCompanies {
  companies: company(
    order_by: {name: asc}
    where: {company_type: {shortcode: {_eq: "chat_company"}}}
  ) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useAllChatCompaniesQuery__
 *
 * To run a query within a React component, call `useAllChatCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllChatCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllChatCompaniesQuery, Types.AllChatCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllChatCompaniesQuery, Types.AllChatCompaniesQueryVariables>(AllChatCompaniesDocument, options);
      }
export function useAllChatCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllChatCompaniesQuery, Types.AllChatCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllChatCompaniesQuery, Types.AllChatCompaniesQueryVariables>(AllChatCompaniesDocument, options);
        }
export type AllChatCompaniesQueryHookResult = ReturnType<typeof useAllChatCompaniesQuery>;
export type AllChatCompaniesLazyQueryHookResult = ReturnType<typeof useAllChatCompaniesLazyQuery>;
export type AllChatCompaniesQueryResult = Apollo.QueryResult<Types.AllChatCompaniesQuery, Types.AllChatCompaniesQueryVariables>;
export const AllWhiteLabelCompaniesDocument = gql`
    query AllWhiteLabelCompanies {
  companies: company(
    order_by: {name: asc}
    where: {company_type: {shortcode: {_eq: "whitelabel"}}}
  ) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useAllWhiteLabelCompaniesQuery__
 *
 * To run a query within a React component, call `useAllWhiteLabelCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWhiteLabelCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWhiteLabelCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWhiteLabelCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllWhiteLabelCompaniesQuery, Types.AllWhiteLabelCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllWhiteLabelCompaniesQuery, Types.AllWhiteLabelCompaniesQueryVariables>(AllWhiteLabelCompaniesDocument, options);
      }
export function useAllWhiteLabelCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllWhiteLabelCompaniesQuery, Types.AllWhiteLabelCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllWhiteLabelCompaniesQuery, Types.AllWhiteLabelCompaniesQueryVariables>(AllWhiteLabelCompaniesDocument, options);
        }
export type AllWhiteLabelCompaniesQueryHookResult = ReturnType<typeof useAllWhiteLabelCompaniesQuery>;
export type AllWhiteLabelCompaniesLazyQueryHookResult = ReturnType<typeof useAllWhiteLabelCompaniesLazyQuery>;
export type AllWhiteLabelCompaniesQueryResult = Apollo.QueryResult<Types.AllWhiteLabelCompaniesQuery, Types.AllWhiteLabelCompaniesQueryVariables>;
export const CompanyByDomainQueryDocument = gql`
    query CompanyByDomainQuery($domain: String!) {
  company(where: {domain: {_eq: $domain}}) {
    ...CompanySafeFields
  }
  defaultCompany: company(where: {id: {_eq: 1}}) {
    ...CompanySafeFields
  }
}
    ${CompanySafeFieldsFragmentDoc}`;

/**
 * __useCompanyByDomainQuery__
 *
 * To run a query within a React component, call `useCompanyByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useCompanyByDomainQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyByDomainQuery, Types.CompanyByDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyByDomainQuery, Types.CompanyByDomainQueryVariables>(CompanyByDomainQueryDocument, options);
      }
export function useCompanyByDomainQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyByDomainQuery, Types.CompanyByDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyByDomainQuery, Types.CompanyByDomainQueryVariables>(CompanyByDomainQueryDocument, options);
        }
export type CompanyByDomainQueryHookResult = ReturnType<typeof useCompanyByDomainQuery>;
export type CompanyByDomainQueryLazyQueryHookResult = ReturnType<typeof useCompanyByDomainQueryLazyQuery>;
export type CompanyByDomainQueryQueryResult = Apollo.QueryResult<Types.CompanyByDomainQuery, Types.CompanyByDomainQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: Int!, $changes: company_set_input) {
  UpdateCompany: update_company(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<Types.UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyMutation, Types.UpdateCompanyMutationVariables>;
export const InsertCompanyDocument = gql`
    mutation InsertCompany($object: company_insert_input!) {
  insert_company(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertCompanyMutationFn = Apollo.MutationFunction<Types.InsertCompanyMutation, Types.InsertCompanyMutationVariables>;

/**
 * __useInsertCompanyMutation__
 *
 * To run a mutation, you first call `useInsertCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyMutation, { data, loading, error }] = useInsertCompanyMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyMutation, Types.InsertCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyMutation, Types.InsertCompanyMutationVariables>(InsertCompanyDocument, options);
      }
export type InsertCompanyMutationHookResult = ReturnType<typeof useInsertCompanyMutation>;
export type InsertCompanyMutationResult = Apollo.MutationResult<Types.InsertCompanyMutation>;
export type InsertCompanyMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyMutation, Types.InsertCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: Int!) {
  delete_company(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<Types.DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>;
export const GetCompanyByUserNameDocument = gql`
    query getCompanyByUserName($username: String!) {
  company: company(limit: 1, where: {username: {_eq: $username}}) {
    username
  }
}
    `;

/**
 * __useGetCompanyByUserNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyByUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByUserNameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetCompanyByUserNameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyByUserNameQuery, Types.GetCompanyByUserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyByUserNameQuery, Types.GetCompanyByUserNameQueryVariables>(GetCompanyByUserNameDocument, options);
      }
export function useGetCompanyByUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyByUserNameQuery, Types.GetCompanyByUserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyByUserNameQuery, Types.GetCompanyByUserNameQueryVariables>(GetCompanyByUserNameDocument, options);
        }
export type GetCompanyByUserNameQueryHookResult = ReturnType<typeof useGetCompanyByUserNameQuery>;
export type GetCompanyByUserNameLazyQueryHookResult = ReturnType<typeof useGetCompanyByUserNameLazyQuery>;
export type GetCompanyByUserNameQueryResult = Apollo.QueryResult<Types.GetCompanyByUserNameQuery, Types.GetCompanyByUserNameQueryVariables>;
export const GetCompanyByAnyDomainsDocument = gql`
    query getCompanyByAnyDomains($domain: String!) {
  company: company(
    limit: 1
    where: {_or: [{domain: {_eq: $domain}}, {api_domain: {_eq: $domain}}, {tracking_domain: {_eq: $domain}}]}
  ) {
    id
    domain
    api_domain
    tracking_domain
  }
}
    `;

/**
 * __useGetCompanyByAnyDomainsQuery__
 *
 * To run a query within a React component, call `useGetCompanyByAnyDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByAnyDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByAnyDomainsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetCompanyByAnyDomainsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyByAnyDomainsQuery, Types.GetCompanyByAnyDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyByAnyDomainsQuery, Types.GetCompanyByAnyDomainsQueryVariables>(GetCompanyByAnyDomainsDocument, options);
      }
export function useGetCompanyByAnyDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyByAnyDomainsQuery, Types.GetCompanyByAnyDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyByAnyDomainsQuery, Types.GetCompanyByAnyDomainsQueryVariables>(GetCompanyByAnyDomainsDocument, options);
        }
export type GetCompanyByAnyDomainsQueryHookResult = ReturnType<typeof useGetCompanyByAnyDomainsQuery>;
export type GetCompanyByAnyDomainsLazyQueryHookResult = ReturnType<typeof useGetCompanyByAnyDomainsLazyQuery>;
export type GetCompanyByAnyDomainsQueryResult = Apollo.QueryResult<Types.GetCompanyByAnyDomainsQuery, Types.GetCompanyByAnyDomainsQueryVariables>;
export const GetCompanyByCompanyDomainDocument = gql`
    query getCompanyByCompanyDomain($domain: String!) {
  company: company(limit: 1, where: {domain: {_eq: $domain}}) {
    id
    domain
  }
}
    `;

/**
 * __useGetCompanyByCompanyDomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyByCompanyDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByCompanyDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByCompanyDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetCompanyByCompanyDomainQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyByCompanyDomainQuery, Types.GetCompanyByCompanyDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyByCompanyDomainQuery, Types.GetCompanyByCompanyDomainQueryVariables>(GetCompanyByCompanyDomainDocument, options);
      }
export function useGetCompanyByCompanyDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyByCompanyDomainQuery, Types.GetCompanyByCompanyDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyByCompanyDomainQuery, Types.GetCompanyByCompanyDomainQueryVariables>(GetCompanyByCompanyDomainDocument, options);
        }
export type GetCompanyByCompanyDomainQueryHookResult = ReturnType<typeof useGetCompanyByCompanyDomainQuery>;
export type GetCompanyByCompanyDomainLazyQueryHookResult = ReturnType<typeof useGetCompanyByCompanyDomainLazyQuery>;
export type GetCompanyByCompanyDomainQueryResult = Apollo.QueryResult<Types.GetCompanyByCompanyDomainQuery, Types.GetCompanyByCompanyDomainQueryVariables>;
export const GetCompanyByCompanyApiDomainDocument = gql`
    query getCompanyByCompanyApiDomain($apiDomain: String!) {
  company: company(limit: 1, where: {api_domain: {_eq: $apiDomain}}) {
    id
    api_domain
  }
}
    `;

/**
 * __useGetCompanyByCompanyApiDomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyByCompanyApiDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByCompanyApiDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByCompanyApiDomainQuery({
 *   variables: {
 *      apiDomain: // value for 'apiDomain'
 *   },
 * });
 */
export function useGetCompanyByCompanyApiDomainQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyByCompanyApiDomainQuery, Types.GetCompanyByCompanyApiDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyByCompanyApiDomainQuery, Types.GetCompanyByCompanyApiDomainQueryVariables>(GetCompanyByCompanyApiDomainDocument, options);
      }
export function useGetCompanyByCompanyApiDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyByCompanyApiDomainQuery, Types.GetCompanyByCompanyApiDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyByCompanyApiDomainQuery, Types.GetCompanyByCompanyApiDomainQueryVariables>(GetCompanyByCompanyApiDomainDocument, options);
        }
export type GetCompanyByCompanyApiDomainQueryHookResult = ReturnType<typeof useGetCompanyByCompanyApiDomainQuery>;
export type GetCompanyByCompanyApiDomainLazyQueryHookResult = ReturnType<typeof useGetCompanyByCompanyApiDomainLazyQuery>;
export type GetCompanyByCompanyApiDomainQueryResult = Apollo.QueryResult<Types.GetCompanyByCompanyApiDomainQuery, Types.GetCompanyByCompanyApiDomainQueryVariables>;
export const GetCompanyByCompanyTrackingDomainDocument = gql`
    query getCompanyByCompanyTrackingDomain($trackingDomain: String!) {
  company: company(limit: 1, where: {tracking_domain: {_eq: $trackingDomain}}) {
    id
    tracking_domain
  }
}
    `;

/**
 * __useGetCompanyByCompanyTrackingDomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyByCompanyTrackingDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByCompanyTrackingDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByCompanyTrackingDomainQuery({
 *   variables: {
 *      trackingDomain: // value for 'trackingDomain'
 *   },
 * });
 */
export function useGetCompanyByCompanyTrackingDomainQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyByCompanyTrackingDomainQuery, Types.GetCompanyByCompanyTrackingDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyByCompanyTrackingDomainQuery, Types.GetCompanyByCompanyTrackingDomainQueryVariables>(GetCompanyByCompanyTrackingDomainDocument, options);
      }
export function useGetCompanyByCompanyTrackingDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyByCompanyTrackingDomainQuery, Types.GetCompanyByCompanyTrackingDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyByCompanyTrackingDomainQuery, Types.GetCompanyByCompanyTrackingDomainQueryVariables>(GetCompanyByCompanyTrackingDomainDocument, options);
        }
export type GetCompanyByCompanyTrackingDomainQueryHookResult = ReturnType<typeof useGetCompanyByCompanyTrackingDomainQuery>;
export type GetCompanyByCompanyTrackingDomainLazyQueryHookResult = ReturnType<typeof useGetCompanyByCompanyTrackingDomainLazyQuery>;
export type GetCompanyByCompanyTrackingDomainQueryResult = Apollo.QueryResult<Types.GetCompanyByCompanyTrackingDomainQuery, Types.GetCompanyByCompanyTrackingDomainQueryVariables>;
export const AllChatCompaniesByCompanyIdQueryDocument = gql`
    query AllChatCompaniesByCompanyIdQuery($companyId: Int!) {
  companies: company_chat_company(
    where: {company_id: {_eq: $companyId}}
    order_by: {chat_company: {name: asc}}
  ) {
    ...CompanyChatCompanyFields
  }
}
    ${CompanyChatCompanyFieldsFragmentDoc}`;

/**
 * __useAllChatCompaniesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useAllChatCompaniesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatCompaniesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatCompaniesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllChatCompaniesByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllChatCompaniesByCompanyIdQuery, Types.AllChatCompaniesByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllChatCompaniesByCompanyIdQuery, Types.AllChatCompaniesByCompanyIdQueryVariables>(AllChatCompaniesByCompanyIdQueryDocument, options);
      }
export function useAllChatCompaniesByCompanyIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllChatCompaniesByCompanyIdQuery, Types.AllChatCompaniesByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllChatCompaniesByCompanyIdQuery, Types.AllChatCompaniesByCompanyIdQueryVariables>(AllChatCompaniesByCompanyIdQueryDocument, options);
        }
export type AllChatCompaniesByCompanyIdQueryHookResult = ReturnType<typeof useAllChatCompaniesByCompanyIdQuery>;
export type AllChatCompaniesByCompanyIdQueryLazyQueryHookResult = ReturnType<typeof useAllChatCompaniesByCompanyIdQueryLazyQuery>;
export type AllChatCompaniesByCompanyIdQueryQueryResult = Apollo.QueryResult<Types.AllChatCompaniesByCompanyIdQuery, Types.AllChatCompaniesByCompanyIdQueryVariables>;
export const AllCompaniesByChatCompanyIdQueryDocument = gql`
    query AllCompaniesByChatCompanyIdQuery($chatCompanyId: Int!) {
  companies: company_chat_company(
    where: {chat_company_id: {_eq: $chatCompanyId}}
    order_by: {company: {name: asc}}
  ) {
    ...CompanyChatCompanyFields
  }
}
    ${CompanyChatCompanyFieldsFragmentDoc}`;

/**
 * __useAllCompaniesByChatCompanyIdQuery__
 *
 * To run a query within a React component, call `useAllCompaniesByChatCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesByChatCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesByChatCompanyIdQuery({
 *   variables: {
 *      chatCompanyId: // value for 'chatCompanyId'
 *   },
 * });
 */
export function useAllCompaniesByChatCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllCompaniesByChatCompanyIdQuery, Types.AllCompaniesByChatCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompaniesByChatCompanyIdQuery, Types.AllCompaniesByChatCompanyIdQueryVariables>(AllCompaniesByChatCompanyIdQueryDocument, options);
      }
export function useAllCompaniesByChatCompanyIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompaniesByChatCompanyIdQuery, Types.AllCompaniesByChatCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompaniesByChatCompanyIdQuery, Types.AllCompaniesByChatCompanyIdQueryVariables>(AllCompaniesByChatCompanyIdQueryDocument, options);
        }
export type AllCompaniesByChatCompanyIdQueryHookResult = ReturnType<typeof useAllCompaniesByChatCompanyIdQuery>;
export type AllCompaniesByChatCompanyIdQueryLazyQueryHookResult = ReturnType<typeof useAllCompaniesByChatCompanyIdQueryLazyQuery>;
export type AllCompaniesByChatCompanyIdQueryQueryResult = Apollo.QueryResult<Types.AllCompaniesByChatCompanyIdQuery, Types.AllCompaniesByChatCompanyIdQueryVariables>;
export const UpdateCompanyChatCompanyDocument = gql`
    mutation UpdateCompanyChatCompany($companyId: Int!, $changes: company_chat_company_set_input) {
  UpdateCompanyChatCompany: update_company_chat_company(
    where: {company_id: {_eq: $companyId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyChatCompanyMutationFn = Apollo.MutationFunction<Types.UpdateCompanyChatCompanyMutation, Types.UpdateCompanyChatCompanyMutationVariables>;

/**
 * __useUpdateCompanyChatCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyChatCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyChatCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyChatCompanyMutation, { data, loading, error }] = useUpdateCompanyChatCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyChatCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyChatCompanyMutation, Types.UpdateCompanyChatCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyChatCompanyMutation, Types.UpdateCompanyChatCompanyMutationVariables>(UpdateCompanyChatCompanyDocument, options);
      }
export type UpdateCompanyChatCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyChatCompanyMutation>;
export type UpdateCompanyChatCompanyMutationResult = Apollo.MutationResult<Types.UpdateCompanyChatCompanyMutation>;
export type UpdateCompanyChatCompanyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyChatCompanyMutation, Types.UpdateCompanyChatCompanyMutationVariables>;
export const InsertCompanyChatCompanyDocument = gql`
    mutation InsertCompanyChatCompany($object: company_chat_company_insert_input!) {
  insert_company_chat_company(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertCompanyChatCompanyMutationFn = Apollo.MutationFunction<Types.InsertCompanyChatCompanyMutation, Types.InsertCompanyChatCompanyMutationVariables>;

/**
 * __useInsertCompanyChatCompanyMutation__
 *
 * To run a mutation, you first call `useInsertCompanyChatCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyChatCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyChatCompanyMutation, { data, loading, error }] = useInsertCompanyChatCompanyMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyChatCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyChatCompanyMutation, Types.InsertCompanyChatCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyChatCompanyMutation, Types.InsertCompanyChatCompanyMutationVariables>(InsertCompanyChatCompanyDocument, options);
      }
export type InsertCompanyChatCompanyMutationHookResult = ReturnType<typeof useInsertCompanyChatCompanyMutation>;
export type InsertCompanyChatCompanyMutationResult = Apollo.MutationResult<Types.InsertCompanyChatCompanyMutation>;
export type InsertCompanyChatCompanyMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyChatCompanyMutation, Types.InsertCompanyChatCompanyMutationVariables>;
export const DeleteCompanyChatCompanyDocument = gql`
    mutation DeleteCompanyChatCompany($id: Int!) {
  delete_company_chat_company(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyChatCompanyMutationFn = Apollo.MutationFunction<Types.DeleteCompanyChatCompanyMutation, Types.DeleteCompanyChatCompanyMutationVariables>;

/**
 * __useDeleteCompanyChatCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyChatCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyChatCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyChatCompanyMutation, { data, loading, error }] = useDeleteCompanyChatCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyChatCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyChatCompanyMutation, Types.DeleteCompanyChatCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyChatCompanyMutation, Types.DeleteCompanyChatCompanyMutationVariables>(DeleteCompanyChatCompanyDocument, options);
      }
export type DeleteCompanyChatCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyChatCompanyMutation>;
export type DeleteCompanyChatCompanyMutationResult = Apollo.MutationResult<Types.DeleteCompanyChatCompanyMutation>;
export type DeleteCompanyChatCompanyMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyChatCompanyMutation, Types.DeleteCompanyChatCompanyMutationVariables>;
export const CompanyChatCompanyRotationDocument = gql`
    query CompanyChatCompanyRotation($id: Int) {
  companyChatCompanyRotation: company_chat_company_rotation(
    where: {id: {_eq: $id}}
    order_by: {chat_company: {name: asc}}
  ) {
    ...CompanyChatCompanyRotationFields
  }
}
    ${CompanyChatCompanyRotationFieldsFragmentDoc}`;

/**
 * __useCompanyChatCompanyRotationQuery__
 *
 * To run a query within a React component, call `useCompanyChatCompanyRotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyChatCompanyRotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyChatCompanyRotationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyChatCompanyRotationQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyChatCompanyRotationQuery, Types.CompanyChatCompanyRotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyChatCompanyRotationQuery, Types.CompanyChatCompanyRotationQueryVariables>(CompanyChatCompanyRotationDocument, options);
      }
export function useCompanyChatCompanyRotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyChatCompanyRotationQuery, Types.CompanyChatCompanyRotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyChatCompanyRotationQuery, Types.CompanyChatCompanyRotationQueryVariables>(CompanyChatCompanyRotationDocument, options);
        }
export type CompanyChatCompanyRotationQueryHookResult = ReturnType<typeof useCompanyChatCompanyRotationQuery>;
export type CompanyChatCompanyRotationLazyQueryHookResult = ReturnType<typeof useCompanyChatCompanyRotationLazyQuery>;
export type CompanyChatCompanyRotationQueryResult = Apollo.QueryResult<Types.CompanyChatCompanyRotationQuery, Types.CompanyChatCompanyRotationQueryVariables>;
export const CompanyChatCompanyRotationsByFilterDocument = gql`
    query CompanyChatCompanyRotationsByFilter($filter: company_chat_company_rotation_bool_exp) {
  companyChatCompanyRotation: company_chat_company_rotation(where: $filter) {
    ...CompanyChatCompanyRotationFields
  }
}
    ${CompanyChatCompanyRotationFieldsFragmentDoc}`;

/**
 * __useCompanyChatCompanyRotationsByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyChatCompanyRotationsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyChatCompanyRotationsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyChatCompanyRotationsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyChatCompanyRotationsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyChatCompanyRotationsByFilterQuery, Types.CompanyChatCompanyRotationsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyChatCompanyRotationsByFilterQuery, Types.CompanyChatCompanyRotationsByFilterQueryVariables>(CompanyChatCompanyRotationsByFilterDocument, options);
      }
export function useCompanyChatCompanyRotationsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyChatCompanyRotationsByFilterQuery, Types.CompanyChatCompanyRotationsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyChatCompanyRotationsByFilterQuery, Types.CompanyChatCompanyRotationsByFilterQueryVariables>(CompanyChatCompanyRotationsByFilterDocument, options);
        }
export type CompanyChatCompanyRotationsByFilterQueryHookResult = ReturnType<typeof useCompanyChatCompanyRotationsByFilterQuery>;
export type CompanyChatCompanyRotationsByFilterLazyQueryHookResult = ReturnType<typeof useCompanyChatCompanyRotationsByFilterLazyQuery>;
export type CompanyChatCompanyRotationsByFilterQueryResult = Apollo.QueryResult<Types.CompanyChatCompanyRotationsByFilterQuery, Types.CompanyChatCompanyRotationsByFilterQueryVariables>;
export const UpdateCompanyChatCompanyRotationDocument = gql`
    mutation UpdateCompanyChatCompanyRotation($id: Int!, $changes: company_chat_company_rotation_set_input) {
  UpdateCompanyChatCompanyRotation: update_company_chat_company_rotation(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyChatCompanyRotationMutationFn = Apollo.MutationFunction<Types.UpdateCompanyChatCompanyRotationMutation, Types.UpdateCompanyChatCompanyRotationMutationVariables>;

/**
 * __useUpdateCompanyChatCompanyRotationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyChatCompanyRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyChatCompanyRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyChatCompanyRotationMutation, { data, loading, error }] = useUpdateCompanyChatCompanyRotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyChatCompanyRotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyChatCompanyRotationMutation, Types.UpdateCompanyChatCompanyRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyChatCompanyRotationMutation, Types.UpdateCompanyChatCompanyRotationMutationVariables>(UpdateCompanyChatCompanyRotationDocument, options);
      }
export type UpdateCompanyChatCompanyRotationMutationHookResult = ReturnType<typeof useUpdateCompanyChatCompanyRotationMutation>;
export type UpdateCompanyChatCompanyRotationMutationResult = Apollo.MutationResult<Types.UpdateCompanyChatCompanyRotationMutation>;
export type UpdateCompanyChatCompanyRotationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyChatCompanyRotationMutation, Types.UpdateCompanyChatCompanyRotationMutationVariables>;
export const InsertCompanyChatCompanyRotationDocument = gql`
    mutation InsertCompanyChatCompanyRotation($object: company_chat_company_rotation_insert_input!) {
  insert_company_chat_company_rotation(objects: [$object]) {
    returning {
      ...CompanyChatCompanyRotationFields
    }
  }
}
    ${CompanyChatCompanyRotationFieldsFragmentDoc}`;
export type InsertCompanyChatCompanyRotationMutationFn = Apollo.MutationFunction<Types.InsertCompanyChatCompanyRotationMutation, Types.InsertCompanyChatCompanyRotationMutationVariables>;

/**
 * __useInsertCompanyChatCompanyRotationMutation__
 *
 * To run a mutation, you first call `useInsertCompanyChatCompanyRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyChatCompanyRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyChatCompanyRotationMutation, { data, loading, error }] = useInsertCompanyChatCompanyRotationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyChatCompanyRotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyChatCompanyRotationMutation, Types.InsertCompanyChatCompanyRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyChatCompanyRotationMutation, Types.InsertCompanyChatCompanyRotationMutationVariables>(InsertCompanyChatCompanyRotationDocument, options);
      }
export type InsertCompanyChatCompanyRotationMutationHookResult = ReturnType<typeof useInsertCompanyChatCompanyRotationMutation>;
export type InsertCompanyChatCompanyRotationMutationResult = Apollo.MutationResult<Types.InsertCompanyChatCompanyRotationMutation>;
export type InsertCompanyChatCompanyRotationMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyChatCompanyRotationMutation, Types.InsertCompanyChatCompanyRotationMutationVariables>;
export const DeleteCompanyChatCompanyRotationDocument = gql`
    mutation DeleteCompanyChatCompanyRotation($id: Int!) {
  delete_company_chat_company_rotation(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyChatCompanyRotationMutationFn = Apollo.MutationFunction<Types.DeleteCompanyChatCompanyRotationMutation, Types.DeleteCompanyChatCompanyRotationMutationVariables>;

/**
 * __useDeleteCompanyChatCompanyRotationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyChatCompanyRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyChatCompanyRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyChatCompanyRotationMutation, { data, loading, error }] = useDeleteCompanyChatCompanyRotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyChatCompanyRotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyChatCompanyRotationMutation, Types.DeleteCompanyChatCompanyRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyChatCompanyRotationMutation, Types.DeleteCompanyChatCompanyRotationMutationVariables>(DeleteCompanyChatCompanyRotationDocument, options);
      }
export type DeleteCompanyChatCompanyRotationMutationHookResult = ReturnType<typeof useDeleteCompanyChatCompanyRotationMutation>;
export type DeleteCompanyChatCompanyRotationMutationResult = Apollo.MutationResult<Types.DeleteCompanyChatCompanyRotationMutation>;
export type DeleteCompanyChatCompanyRotationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyChatCompanyRotationMutation, Types.DeleteCompanyChatCompanyRotationMutationVariables>;
export const CompanyFeatureDocument = gql`
    query CompanyFeature($companyId: Int) {
  companyFeature: company_feature(where: {company_id: {_eq: $companyId}}) {
    ...CompanyFeatureFields
  }
}
    ${CompanyFeatureFieldsFragmentDoc}`;

/**
 * __useCompanyFeatureQuery__
 *
 * To run a query within a React component, call `useCompanyFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFeatureQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyFeatureQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyFeatureQuery, Types.CompanyFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyFeatureQuery, Types.CompanyFeatureQueryVariables>(CompanyFeatureDocument, options);
      }
export function useCompanyFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyFeatureQuery, Types.CompanyFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyFeatureQuery, Types.CompanyFeatureQueryVariables>(CompanyFeatureDocument, options);
        }
export type CompanyFeatureQueryHookResult = ReturnType<typeof useCompanyFeatureQuery>;
export type CompanyFeatureLazyQueryHookResult = ReturnType<typeof useCompanyFeatureLazyQuery>;
export type CompanyFeatureQueryResult = Apollo.QueryResult<Types.CompanyFeatureQuery, Types.CompanyFeatureQueryVariables>;
export const UpdateCompanyFeatureDocument = gql`
    mutation UpdateCompanyFeature($companyId: Int!, $changes: company_feature_set_input) {
  UpdateCompanyFeature: update_company_feature(
    where: {company_id: {_eq: $companyId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyFeatureMutationFn = Apollo.MutationFunction<Types.UpdateCompanyFeatureMutation, Types.UpdateCompanyFeatureMutationVariables>;

/**
 * __useUpdateCompanyFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFeatureMutation, { data, loading, error }] = useUpdateCompanyFeatureMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyFeatureMutation, Types.UpdateCompanyFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyFeatureMutation, Types.UpdateCompanyFeatureMutationVariables>(UpdateCompanyFeatureDocument, options);
      }
export type UpdateCompanyFeatureMutationHookResult = ReturnType<typeof useUpdateCompanyFeatureMutation>;
export type UpdateCompanyFeatureMutationResult = Apollo.MutationResult<Types.UpdateCompanyFeatureMutation>;
export type UpdateCompanyFeatureMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyFeatureMutation, Types.UpdateCompanyFeatureMutationVariables>;
export const InsertCompanyFeatureDocument = gql`
    mutation InsertCompanyFeature($object: [company_feature_insert_input!]!) {
  insert_company_feature(objects: $object) {
    returning {
      companyId: company_id
      featureId: feature_id
    }
  }
}
    `;
export type InsertCompanyFeatureMutationFn = Apollo.MutationFunction<Types.InsertCompanyFeatureMutation, Types.InsertCompanyFeatureMutationVariables>;

/**
 * __useInsertCompanyFeatureMutation__
 *
 * To run a mutation, you first call `useInsertCompanyFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyFeatureMutation, { data, loading, error }] = useInsertCompanyFeatureMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyFeatureMutation, Types.InsertCompanyFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyFeatureMutation, Types.InsertCompanyFeatureMutationVariables>(InsertCompanyFeatureDocument, options);
      }
export type InsertCompanyFeatureMutationHookResult = ReturnType<typeof useInsertCompanyFeatureMutation>;
export type InsertCompanyFeatureMutationResult = Apollo.MutationResult<Types.InsertCompanyFeatureMutation>;
export type InsertCompanyFeatureMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyFeatureMutation, Types.InsertCompanyFeatureMutationVariables>;
export const DeleteCompanyFeatureDocument = gql`
    mutation DeleteCompanyFeature($companyId: Int!, $featureId: Int) {
  delete_company_feature(
    where: {company_id: {_eq: $companyId}, feature_id: {_eq: $featureId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyFeatureMutationFn = Apollo.MutationFunction<Types.DeleteCompanyFeatureMutation, Types.DeleteCompanyFeatureMutationVariables>;

/**
 * __useDeleteCompanyFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyFeatureMutation, { data, loading, error }] = useDeleteCompanyFeatureMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useDeleteCompanyFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyFeatureMutation, Types.DeleteCompanyFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyFeatureMutation, Types.DeleteCompanyFeatureMutationVariables>(DeleteCompanyFeatureDocument, options);
      }
export type DeleteCompanyFeatureMutationHookResult = ReturnType<typeof useDeleteCompanyFeatureMutation>;
export type DeleteCompanyFeatureMutationResult = Apollo.MutationResult<Types.DeleteCompanyFeatureMutation>;
export type DeleteCompanyFeatureMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyFeatureMutation, Types.DeleteCompanyFeatureMutationVariables>;
export const CompanyFlowRotationDocument = gql`
    query CompanyFlowRotation($id: Int) {
  companyFlowRotation: company_flow_rotation(where: {id: {_eq: $id}}) {
    ...CompanyFlowRotationFields
  }
}
    ${CompanyFlowRotationFieldsFragmentDoc}`;

/**
 * __useCompanyFlowRotationQuery__
 *
 * To run a query within a React component, call `useCompanyFlowRotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFlowRotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFlowRotationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyFlowRotationQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyFlowRotationQuery, Types.CompanyFlowRotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyFlowRotationQuery, Types.CompanyFlowRotationQueryVariables>(CompanyFlowRotationDocument, options);
      }
export function useCompanyFlowRotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyFlowRotationQuery, Types.CompanyFlowRotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyFlowRotationQuery, Types.CompanyFlowRotationQueryVariables>(CompanyFlowRotationDocument, options);
        }
export type CompanyFlowRotationQueryHookResult = ReturnType<typeof useCompanyFlowRotationQuery>;
export type CompanyFlowRotationLazyQueryHookResult = ReturnType<typeof useCompanyFlowRotationLazyQuery>;
export type CompanyFlowRotationQueryResult = Apollo.QueryResult<Types.CompanyFlowRotationQuery, Types.CompanyFlowRotationQueryVariables>;
export const CompanyFlowRotationsByFilterDocument = gql`
    query CompanyFlowRotationsByFilter($filter: company_flow_rotation_bool_exp) {
  companyFlowRotation: company_flow_rotation(where: $filter) {
    ...CompanyFlowRotationFields
  }
}
    ${CompanyFlowRotationFieldsFragmentDoc}`;

/**
 * __useCompanyFlowRotationsByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyFlowRotationsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFlowRotationsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFlowRotationsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyFlowRotationsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyFlowRotationsByFilterQuery, Types.CompanyFlowRotationsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyFlowRotationsByFilterQuery, Types.CompanyFlowRotationsByFilterQueryVariables>(CompanyFlowRotationsByFilterDocument, options);
      }
export function useCompanyFlowRotationsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyFlowRotationsByFilterQuery, Types.CompanyFlowRotationsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyFlowRotationsByFilterQuery, Types.CompanyFlowRotationsByFilterQueryVariables>(CompanyFlowRotationsByFilterDocument, options);
        }
export type CompanyFlowRotationsByFilterQueryHookResult = ReturnType<typeof useCompanyFlowRotationsByFilterQuery>;
export type CompanyFlowRotationsByFilterLazyQueryHookResult = ReturnType<typeof useCompanyFlowRotationsByFilterLazyQuery>;
export type CompanyFlowRotationsByFilterQueryResult = Apollo.QueryResult<Types.CompanyFlowRotationsByFilterQuery, Types.CompanyFlowRotationsByFilterQueryVariables>;
export const UpdateCompanyFlowRotationDocument = gql`
    mutation UpdateCompanyFlowRotation($id: Int!, $changes: company_flow_rotation_set_input) {
  UpdateCompanyFlowRotation: update_company_flow_rotation(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyFlowRotationMutationFn = Apollo.MutationFunction<Types.UpdateCompanyFlowRotationMutation, Types.UpdateCompanyFlowRotationMutationVariables>;

/**
 * __useUpdateCompanyFlowRotationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFlowRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFlowRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFlowRotationMutation, { data, loading, error }] = useUpdateCompanyFlowRotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyFlowRotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyFlowRotationMutation, Types.UpdateCompanyFlowRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyFlowRotationMutation, Types.UpdateCompanyFlowRotationMutationVariables>(UpdateCompanyFlowRotationDocument, options);
      }
export type UpdateCompanyFlowRotationMutationHookResult = ReturnType<typeof useUpdateCompanyFlowRotationMutation>;
export type UpdateCompanyFlowRotationMutationResult = Apollo.MutationResult<Types.UpdateCompanyFlowRotationMutation>;
export type UpdateCompanyFlowRotationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyFlowRotationMutation, Types.UpdateCompanyFlowRotationMutationVariables>;
export const InsertCompanyFlowRotationDocument = gql`
    mutation InsertCompanyFlowRotation($object: company_flow_rotation_insert_input!) {
  insert_company_flow_rotation(objects: [$object]) {
    returning {
      ...CompanyFlowRotationFields
    }
  }
}
    ${CompanyFlowRotationFieldsFragmentDoc}`;
export type InsertCompanyFlowRotationMutationFn = Apollo.MutationFunction<Types.InsertCompanyFlowRotationMutation, Types.InsertCompanyFlowRotationMutationVariables>;

/**
 * __useInsertCompanyFlowRotationMutation__
 *
 * To run a mutation, you first call `useInsertCompanyFlowRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyFlowRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyFlowRotationMutation, { data, loading, error }] = useInsertCompanyFlowRotationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyFlowRotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyFlowRotationMutation, Types.InsertCompanyFlowRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyFlowRotationMutation, Types.InsertCompanyFlowRotationMutationVariables>(InsertCompanyFlowRotationDocument, options);
      }
export type InsertCompanyFlowRotationMutationHookResult = ReturnType<typeof useInsertCompanyFlowRotationMutation>;
export type InsertCompanyFlowRotationMutationResult = Apollo.MutationResult<Types.InsertCompanyFlowRotationMutation>;
export type InsertCompanyFlowRotationMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyFlowRotationMutation, Types.InsertCompanyFlowRotationMutationVariables>;
export const DeleteCompanyFlowRotationDocument = gql`
    mutation DeleteCompanyFlowRotation($id: Int!) {
  delete_company_flow_rotation(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyFlowRotationMutationFn = Apollo.MutationFunction<Types.DeleteCompanyFlowRotationMutation, Types.DeleteCompanyFlowRotationMutationVariables>;

/**
 * __useDeleteCompanyFlowRotationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyFlowRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyFlowRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyFlowRotationMutation, { data, loading, error }] = useDeleteCompanyFlowRotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyFlowRotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyFlowRotationMutation, Types.DeleteCompanyFlowRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyFlowRotationMutation, Types.DeleteCompanyFlowRotationMutationVariables>(DeleteCompanyFlowRotationDocument, options);
      }
export type DeleteCompanyFlowRotationMutationHookResult = ReturnType<typeof useDeleteCompanyFlowRotationMutation>;
export type DeleteCompanyFlowRotationMutationResult = Apollo.MutationResult<Types.DeleteCompanyFlowRotationMutation>;
export type DeleteCompanyFlowRotationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyFlowRotationMutation, Types.DeleteCompanyFlowRotationMutationVariables>;
export const CompanyOfferCampaignUrlsByCompanyOfferIdDocument = gql`
    query CompanyOfferCampaignUrlsByCompanyOfferId($companyOfferId: Int) {
  companyOfferCampaignUrl: company_offer_campaign_url(
    where: {company_offer_id: {_eq: $companyOfferId}}
  ) {
    ...CompanyOfferCampaignUrlFields
  }
}
    ${CompanyOfferCampaignUrlFieldsFragmentDoc}`;

/**
 * __useCompanyOfferCampaignUrlsByCompanyOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferCampaignUrlsByCompanyOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferCampaignUrlsByCompanyOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferCampaignUrlsByCompanyOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *   },
 * });
 */
export function useCompanyOfferCampaignUrlsByCompanyOfferIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferCampaignUrlsByCompanyOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferCampaignUrlsByCompanyOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyOfferIdQueryVariables>(CompanyOfferCampaignUrlsByCompanyOfferIdDocument, options);
      }
export function useCompanyOfferCampaignUrlsByCompanyOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferCampaignUrlsByCompanyOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferCampaignUrlsByCompanyOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyOfferIdQueryVariables>(CompanyOfferCampaignUrlsByCompanyOfferIdDocument, options);
        }
export type CompanyOfferCampaignUrlsByCompanyOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferCampaignUrlsByCompanyOfferIdQuery>;
export type CompanyOfferCampaignUrlsByCompanyOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferCampaignUrlsByCompanyOfferIdLazyQuery>;
export type CompanyOfferCampaignUrlsByCompanyOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferCampaignUrlsByCompanyOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyOfferIdQueryVariables>;
export const CompanyOfferCampaignUrlsByCompanyIdAndOfferIdDocument = gql`
    query CompanyOfferCampaignUrlsByCompanyIdAndOfferId($companyOfferId: Int, $belongsToCompanyId: Int, $offerId: Int!) {
  companyOfferCampaignUrl: company_offer_campaign_url(
    where: {company_offer_id: {_eq: $companyOfferId}, belongs_to_company_id: {_eq: $belongsToCompanyId}, offer_id: {_eq: $offerId}}
  ) {
    ...CompanyOfferCampaignUrlFields
  }
}
    ${CompanyOfferCampaignUrlFieldsFragmentDoc}`;

/**
 * __useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryVariables>(CompanyOfferCampaignUrlsByCompanyIdAndOfferIdDocument, options);
      }
export function useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryVariables>(CompanyOfferCampaignUrlsByCompanyIdAndOfferIdDocument, options);
        }
export type CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery>;
export type CompanyOfferCampaignUrlsByCompanyIdAndOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferCampaignUrlsByCompanyIdAndOfferIdLazyQuery>;
export type CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQuery, Types.CompanyOfferCampaignUrlsByCompanyIdAndOfferIdQueryVariables>;
export const CompanyOfferCampaignUrlsByFilterDocument = gql`
    query CompanyOfferCampaignUrlsByFilter($filter: company_offer_campaign_url_bool_exp) {
  companyOfferCampaignUrl: company_offer_campaign_url(where: $filter) {
    ...CompanyOfferCampaignUrlFields
  }
}
    ${CompanyOfferCampaignUrlFieldsFragmentDoc}`;

/**
 * __useCompanyOfferCampaignUrlsByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyOfferCampaignUrlsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferCampaignUrlsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferCampaignUrlsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyOfferCampaignUrlsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferCampaignUrlsByFilterQuery, Types.CompanyOfferCampaignUrlsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferCampaignUrlsByFilterQuery, Types.CompanyOfferCampaignUrlsByFilterQueryVariables>(CompanyOfferCampaignUrlsByFilterDocument, options);
      }
export function useCompanyOfferCampaignUrlsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferCampaignUrlsByFilterQuery, Types.CompanyOfferCampaignUrlsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferCampaignUrlsByFilterQuery, Types.CompanyOfferCampaignUrlsByFilterQueryVariables>(CompanyOfferCampaignUrlsByFilterDocument, options);
        }
export type CompanyOfferCampaignUrlsByFilterQueryHookResult = ReturnType<typeof useCompanyOfferCampaignUrlsByFilterQuery>;
export type CompanyOfferCampaignUrlsByFilterLazyQueryHookResult = ReturnType<typeof useCompanyOfferCampaignUrlsByFilterLazyQuery>;
export type CompanyOfferCampaignUrlsByFilterQueryResult = Apollo.QueryResult<Types.CompanyOfferCampaignUrlsByFilterQuery, Types.CompanyOfferCampaignUrlsByFilterQueryVariables>;
export const UpdateCompanyOfferCampaignUrlDocument = gql`
    mutation UpdateCompanyOfferCampaignUrl($id: Int!, $changes: company_offer_campaign_url_set_input) {
  updateCompanyOfferCampaignUrl: update_company_offer_campaign_url(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyOfferCampaignUrlMutationFn = Apollo.MutationFunction<Types.UpdateCompanyOfferCampaignUrlMutation, Types.UpdateCompanyOfferCampaignUrlMutationVariables>;

/**
 * __useUpdateCompanyOfferCampaignUrlMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOfferCampaignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOfferCampaignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOfferCampaignUrlMutation, { data, loading, error }] = useUpdateCompanyOfferCampaignUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyOfferCampaignUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyOfferCampaignUrlMutation, Types.UpdateCompanyOfferCampaignUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyOfferCampaignUrlMutation, Types.UpdateCompanyOfferCampaignUrlMutationVariables>(UpdateCompanyOfferCampaignUrlDocument, options);
      }
export type UpdateCompanyOfferCampaignUrlMutationHookResult = ReturnType<typeof useUpdateCompanyOfferCampaignUrlMutation>;
export type UpdateCompanyOfferCampaignUrlMutationResult = Apollo.MutationResult<Types.UpdateCompanyOfferCampaignUrlMutation>;
export type UpdateCompanyOfferCampaignUrlMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyOfferCampaignUrlMutation, Types.UpdateCompanyOfferCampaignUrlMutationVariables>;
export const InsertCompanyOfferCampaignUrlDocument = gql`
    mutation InsertCompanyOfferCampaignUrl($object: company_offer_campaign_url_insert_input!) {
  insert_company_offer_campaign_url(objects: [$object]) {
    returning {
      ...CompanyOfferCampaignUrlFields
    }
  }
}
    ${CompanyOfferCampaignUrlFieldsFragmentDoc}`;
export type InsertCompanyOfferCampaignUrlMutationFn = Apollo.MutationFunction<Types.InsertCompanyOfferCampaignUrlMutation, Types.InsertCompanyOfferCampaignUrlMutationVariables>;

/**
 * __useInsertCompanyOfferCampaignUrlMutation__
 *
 * To run a mutation, you first call `useInsertCompanyOfferCampaignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyOfferCampaignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyOfferCampaignUrlMutation, { data, loading, error }] = useInsertCompanyOfferCampaignUrlMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyOfferCampaignUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyOfferCampaignUrlMutation, Types.InsertCompanyOfferCampaignUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyOfferCampaignUrlMutation, Types.InsertCompanyOfferCampaignUrlMutationVariables>(InsertCompanyOfferCampaignUrlDocument, options);
      }
export type InsertCompanyOfferCampaignUrlMutationHookResult = ReturnType<typeof useInsertCompanyOfferCampaignUrlMutation>;
export type InsertCompanyOfferCampaignUrlMutationResult = Apollo.MutationResult<Types.InsertCompanyOfferCampaignUrlMutation>;
export type InsertCompanyOfferCampaignUrlMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyOfferCampaignUrlMutation, Types.InsertCompanyOfferCampaignUrlMutationVariables>;
export const DeleteCompanyOfferCampaignUrlDocument = gql`
    mutation DeleteCompanyOfferCampaignUrl($id: Int!) {
  delete_company_offer_campaign_url(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyOfferCampaignUrlMutationFn = Apollo.MutationFunction<Types.DeleteCompanyOfferCampaignUrlMutation, Types.DeleteCompanyOfferCampaignUrlMutationVariables>;

/**
 * __useDeleteCompanyOfferCampaignUrlMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfferCampaignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfferCampaignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfferCampaignUrlMutation, { data, loading, error }] = useDeleteCompanyOfferCampaignUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyOfferCampaignUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyOfferCampaignUrlMutation, Types.DeleteCompanyOfferCampaignUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyOfferCampaignUrlMutation, Types.DeleteCompanyOfferCampaignUrlMutationVariables>(DeleteCompanyOfferCampaignUrlDocument, options);
      }
export type DeleteCompanyOfferCampaignUrlMutationHookResult = ReturnType<typeof useDeleteCompanyOfferCampaignUrlMutation>;
export type DeleteCompanyOfferCampaignUrlMutationResult = Apollo.MutationResult<Types.DeleteCompanyOfferCampaignUrlMutation>;
export type DeleteCompanyOfferCampaignUrlMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyOfferCampaignUrlMutation, Types.DeleteCompanyOfferCampaignUrlMutationVariables>;
export const CompanyOfferPayoutByCompanyOfferIdDocument = gql`
    query CompanyOfferPayoutByCompanyOfferId($companyOfferId: Int) {
  companyOfferPayouts: company_offer_payout(
    where: {company_offer_id: {_eq: $companyOfferId}}
  ) {
    ...CompanyOfferPayoutFields
  }
}
    ${CompanyOfferPayoutFieldsFragmentDoc}`;

/**
 * __useCompanyOfferPayoutByCompanyOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferPayoutByCompanyOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferPayoutByCompanyOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferPayoutByCompanyOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *   },
 * });
 */
export function useCompanyOfferPayoutByCompanyOfferIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferPayoutByCompanyOfferIdQuery, Types.CompanyOfferPayoutByCompanyOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferPayoutByCompanyOfferIdQuery, Types.CompanyOfferPayoutByCompanyOfferIdQueryVariables>(CompanyOfferPayoutByCompanyOfferIdDocument, options);
      }
export function useCompanyOfferPayoutByCompanyOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferPayoutByCompanyOfferIdQuery, Types.CompanyOfferPayoutByCompanyOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferPayoutByCompanyOfferIdQuery, Types.CompanyOfferPayoutByCompanyOfferIdQueryVariables>(CompanyOfferPayoutByCompanyOfferIdDocument, options);
        }
export type CompanyOfferPayoutByCompanyOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferPayoutByCompanyOfferIdQuery>;
export type CompanyOfferPayoutByCompanyOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferPayoutByCompanyOfferIdLazyQuery>;
export type CompanyOfferPayoutByCompanyOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferPayoutByCompanyOfferIdQuery, Types.CompanyOfferPayoutByCompanyOfferIdQueryVariables>;
export const CompanyOfferPayoutsByCompanyIdAndOfferIdDocument = gql`
    query CompanyOfferPayoutsByCompanyIdAndOfferId($companyOfferId: Int, $belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $offerId: Int!) {
  companyOfferPayouts: company_offer_payout(
    where: {company_offer_id: {_eq: $companyOfferId}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, offer_id: {_eq: $offerId}}
    order_by: {belongs_to_role_affiliate: {user: {username: asc}}, company_offer: {offer_payout_type: {payout_type: {name: asc}}}}
  ) {
    ...CompanyOfferPayoutFields
  }
}
    ${CompanyOfferPayoutFieldsFragmentDoc}`;

/**
 * __useCompanyOfferPayoutsByCompanyIdAndOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferPayoutsByCompanyIdAndOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferPayoutsByCompanyIdAndOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferPayoutsByCompanyIdAndOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCompanyOfferPayoutsByCompanyIdAndOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQuery, Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQuery, Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQueryVariables>(CompanyOfferPayoutsByCompanyIdAndOfferIdDocument, options);
      }
export function useCompanyOfferPayoutsByCompanyIdAndOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQuery, Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQuery, Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQueryVariables>(CompanyOfferPayoutsByCompanyIdAndOfferIdDocument, options);
        }
export type CompanyOfferPayoutsByCompanyIdAndOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferPayoutsByCompanyIdAndOfferIdQuery>;
export type CompanyOfferPayoutsByCompanyIdAndOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferPayoutsByCompanyIdAndOfferIdLazyQuery>;
export type CompanyOfferPayoutsByCompanyIdAndOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQuery, Types.CompanyOfferPayoutsByCompanyIdAndOfferIdQueryVariables>;
export const CompanyOfferPayoutByFilterDocument = gql`
    query CompanyOfferPayoutByFilter($filter: company_offer_payout_bool_exp, $orderBy: [company_offer_payout_order_by!]) {
  companyOfferPayouts: company_offer_payout(where: $filter, order_by: $orderBy) {
    ...CompanyOfferPayoutFields
  }
}
    ${CompanyOfferPayoutFieldsFragmentDoc}`;

/**
 * __useCompanyOfferPayoutByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyOfferPayoutByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferPayoutByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferPayoutByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCompanyOfferPayoutByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferPayoutByFilterQuery, Types.CompanyOfferPayoutByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferPayoutByFilterQuery, Types.CompanyOfferPayoutByFilterQueryVariables>(CompanyOfferPayoutByFilterDocument, options);
      }
export function useCompanyOfferPayoutByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferPayoutByFilterQuery, Types.CompanyOfferPayoutByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferPayoutByFilterQuery, Types.CompanyOfferPayoutByFilterQueryVariables>(CompanyOfferPayoutByFilterDocument, options);
        }
export type CompanyOfferPayoutByFilterQueryHookResult = ReturnType<typeof useCompanyOfferPayoutByFilterQuery>;
export type CompanyOfferPayoutByFilterLazyQueryHookResult = ReturnType<typeof useCompanyOfferPayoutByFilterLazyQuery>;
export type CompanyOfferPayoutByFilterQueryResult = Apollo.QueryResult<Types.CompanyOfferPayoutByFilterQuery, Types.CompanyOfferPayoutByFilterQueryVariables>;
export const UpdateCompanyOfferPayoutDocument = gql`
    mutation UpdateCompanyOfferPayout($id: Int!, $changes: company_offer_payout_set_input) {
  updateCompanyOfferPayout: update_company_offer_payout(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyOfferPayoutMutationFn = Apollo.MutationFunction<Types.UpdateCompanyOfferPayoutMutation, Types.UpdateCompanyOfferPayoutMutationVariables>;

/**
 * __useUpdateCompanyOfferPayoutMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOfferPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOfferPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOfferPayoutMutation, { data, loading, error }] = useUpdateCompanyOfferPayoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyOfferPayoutMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyOfferPayoutMutation, Types.UpdateCompanyOfferPayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyOfferPayoutMutation, Types.UpdateCompanyOfferPayoutMutationVariables>(UpdateCompanyOfferPayoutDocument, options);
      }
export type UpdateCompanyOfferPayoutMutationHookResult = ReturnType<typeof useUpdateCompanyOfferPayoutMutation>;
export type UpdateCompanyOfferPayoutMutationResult = Apollo.MutationResult<Types.UpdateCompanyOfferPayoutMutation>;
export type UpdateCompanyOfferPayoutMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyOfferPayoutMutation, Types.UpdateCompanyOfferPayoutMutationVariables>;
export const InsertCompanyOfferPayoutDocument = gql`
    mutation InsertCompanyOfferPayout($object: company_offer_payout_insert_input!) {
  insert_company_offer_payout(objects: [$object]) {
    returning {
      ...CompanyOfferPayoutFields
    }
  }
}
    ${CompanyOfferPayoutFieldsFragmentDoc}`;
export type InsertCompanyOfferPayoutMutationFn = Apollo.MutationFunction<Types.InsertCompanyOfferPayoutMutation, Types.InsertCompanyOfferPayoutMutationVariables>;

/**
 * __useInsertCompanyOfferPayoutMutation__
 *
 * To run a mutation, you first call `useInsertCompanyOfferPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyOfferPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyOfferPayoutMutation, { data, loading, error }] = useInsertCompanyOfferPayoutMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyOfferPayoutMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyOfferPayoutMutation, Types.InsertCompanyOfferPayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyOfferPayoutMutation, Types.InsertCompanyOfferPayoutMutationVariables>(InsertCompanyOfferPayoutDocument, options);
      }
export type InsertCompanyOfferPayoutMutationHookResult = ReturnType<typeof useInsertCompanyOfferPayoutMutation>;
export type InsertCompanyOfferPayoutMutationResult = Apollo.MutationResult<Types.InsertCompanyOfferPayoutMutation>;
export type InsertCompanyOfferPayoutMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyOfferPayoutMutation, Types.InsertCompanyOfferPayoutMutationVariables>;
export const DeleteCompanyOfferPayoutDocument = gql`
    mutation DeleteCompanyOfferPayout($id: Int!) {
  delete_company_offer_payout(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyOfferPayoutMutationFn = Apollo.MutationFunction<Types.DeleteCompanyOfferPayoutMutation, Types.DeleteCompanyOfferPayoutMutationVariables>;

/**
 * __useDeleteCompanyOfferPayoutMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfferPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfferPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfferPayoutMutation, { data, loading, error }] = useDeleteCompanyOfferPayoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyOfferPayoutMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyOfferPayoutMutation, Types.DeleteCompanyOfferPayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyOfferPayoutMutation, Types.DeleteCompanyOfferPayoutMutationVariables>(DeleteCompanyOfferPayoutDocument, options);
      }
export type DeleteCompanyOfferPayoutMutationHookResult = ReturnType<typeof useDeleteCompanyOfferPayoutMutation>;
export type DeleteCompanyOfferPayoutMutationResult = Apollo.MutationResult<Types.DeleteCompanyOfferPayoutMutation>;
export type DeleteCompanyOfferPayoutMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyOfferPayoutMutation, Types.DeleteCompanyOfferPayoutMutationVariables>;
export const CompanyOfferPostbackByCompanyOfferIdDocument = gql`
    query CompanyOfferPostbackByCompanyOfferId($companyOfferId: Int) {
  postbacks: company_offer_postback(
    where: {company_offer_id: {_eq: $companyOfferId}}
  ) {
    ...CompanyOfferPostbackFields
  }
}
    ${CompanyOfferPostbackFieldsFragmentDoc}`;

/**
 * __useCompanyOfferPostbackByCompanyOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferPostbackByCompanyOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferPostbackByCompanyOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferPostbackByCompanyOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *   },
 * });
 */
export function useCompanyOfferPostbackByCompanyOfferIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferPostbackByCompanyOfferIdQuery, Types.CompanyOfferPostbackByCompanyOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferPostbackByCompanyOfferIdQuery, Types.CompanyOfferPostbackByCompanyOfferIdQueryVariables>(CompanyOfferPostbackByCompanyOfferIdDocument, options);
      }
export function useCompanyOfferPostbackByCompanyOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferPostbackByCompanyOfferIdQuery, Types.CompanyOfferPostbackByCompanyOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferPostbackByCompanyOfferIdQuery, Types.CompanyOfferPostbackByCompanyOfferIdQueryVariables>(CompanyOfferPostbackByCompanyOfferIdDocument, options);
        }
export type CompanyOfferPostbackByCompanyOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferPostbackByCompanyOfferIdQuery>;
export type CompanyOfferPostbackByCompanyOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferPostbackByCompanyOfferIdLazyQuery>;
export type CompanyOfferPostbackByCompanyOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferPostbackByCompanyOfferIdQuery, Types.CompanyOfferPostbackByCompanyOfferIdQueryVariables>;
export const CompanyOfferPostbackByCompanyIdAndOfferIdDocument = gql`
    query CompanyOfferPostbackByCompanyIdAndOfferId($companyOfferId: Int, $belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $offerId: Int!) {
  postbacks: company_offer_postback(
    where: {company_offer_id: {_eq: $companyOfferId}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, offer_id: {_eq: $offerId}}
  ) {
    ...CompanyOfferPostbackFields
  }
}
    ${CompanyOfferPostbackFieldsFragmentDoc}`;

/**
 * __useCompanyOfferPostbackByCompanyIdAndOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferPostbackByCompanyIdAndOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferPostbackByCompanyIdAndOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferPostbackByCompanyIdAndOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCompanyOfferPostbackByCompanyIdAndOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyOfferPostbackByCompanyIdAndOfferIdQuery, Types.CompanyOfferPostbackByCompanyIdAndOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferPostbackByCompanyIdAndOfferIdQuery, Types.CompanyOfferPostbackByCompanyIdAndOfferIdQueryVariables>(CompanyOfferPostbackByCompanyIdAndOfferIdDocument, options);
      }
export function useCompanyOfferPostbackByCompanyIdAndOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferPostbackByCompanyIdAndOfferIdQuery, Types.CompanyOfferPostbackByCompanyIdAndOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferPostbackByCompanyIdAndOfferIdQuery, Types.CompanyOfferPostbackByCompanyIdAndOfferIdQueryVariables>(CompanyOfferPostbackByCompanyIdAndOfferIdDocument, options);
        }
export type CompanyOfferPostbackByCompanyIdAndOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferPostbackByCompanyIdAndOfferIdQuery>;
export type CompanyOfferPostbackByCompanyIdAndOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferPostbackByCompanyIdAndOfferIdLazyQuery>;
export type CompanyOfferPostbackByCompanyIdAndOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferPostbackByCompanyIdAndOfferIdQuery, Types.CompanyOfferPostbackByCompanyIdAndOfferIdQueryVariables>;
export const CompanyOfferPostbackByFilterDocument = gql`
    query CompanyOfferPostbackByFilter($filter: company_offer_postback_bool_exp) {
  postbacks: company_offer_postback(where: $filter) {
    ...CompanyOfferPostbackFields
  }
}
    ${CompanyOfferPostbackFieldsFragmentDoc}`;

/**
 * __useCompanyOfferPostbackByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyOfferPostbackByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferPostbackByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferPostbackByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyOfferPostbackByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferPostbackByFilterQuery, Types.CompanyOfferPostbackByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferPostbackByFilterQuery, Types.CompanyOfferPostbackByFilterQueryVariables>(CompanyOfferPostbackByFilterDocument, options);
      }
export function useCompanyOfferPostbackByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferPostbackByFilterQuery, Types.CompanyOfferPostbackByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferPostbackByFilterQuery, Types.CompanyOfferPostbackByFilterQueryVariables>(CompanyOfferPostbackByFilterDocument, options);
        }
export type CompanyOfferPostbackByFilterQueryHookResult = ReturnType<typeof useCompanyOfferPostbackByFilterQuery>;
export type CompanyOfferPostbackByFilterLazyQueryHookResult = ReturnType<typeof useCompanyOfferPostbackByFilterLazyQuery>;
export type CompanyOfferPostbackByFilterQueryResult = Apollo.QueryResult<Types.CompanyOfferPostbackByFilterQuery, Types.CompanyOfferPostbackByFilterQueryVariables>;
export const UpdateCompanyOfferPostbackDocument = gql`
    mutation UpdateCompanyOfferPostback($id: Int!, $changes: company_offer_postback_set_input) {
  updateCompanyOfferPostback: update_company_offer_postback(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyOfferPostbackMutationFn = Apollo.MutationFunction<Types.UpdateCompanyOfferPostbackMutation, Types.UpdateCompanyOfferPostbackMutationVariables>;

/**
 * __useUpdateCompanyOfferPostbackMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOfferPostbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOfferPostbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOfferPostbackMutation, { data, loading, error }] = useUpdateCompanyOfferPostbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyOfferPostbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyOfferPostbackMutation, Types.UpdateCompanyOfferPostbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyOfferPostbackMutation, Types.UpdateCompanyOfferPostbackMutationVariables>(UpdateCompanyOfferPostbackDocument, options);
      }
export type UpdateCompanyOfferPostbackMutationHookResult = ReturnType<typeof useUpdateCompanyOfferPostbackMutation>;
export type UpdateCompanyOfferPostbackMutationResult = Apollo.MutationResult<Types.UpdateCompanyOfferPostbackMutation>;
export type UpdateCompanyOfferPostbackMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyOfferPostbackMutation, Types.UpdateCompanyOfferPostbackMutationVariables>;
export const InsertCompanyOfferPostbackDocument = gql`
    mutation InsertCompanyOfferPostback($object: company_offer_postback_insert_input!) {
  insert_company_offer_postback(objects: [$object]) {
    returning {
      ...CompanyOfferPostbackFields
    }
  }
}
    ${CompanyOfferPostbackFieldsFragmentDoc}`;
export type InsertCompanyOfferPostbackMutationFn = Apollo.MutationFunction<Types.InsertCompanyOfferPostbackMutation, Types.InsertCompanyOfferPostbackMutationVariables>;

/**
 * __useInsertCompanyOfferPostbackMutation__
 *
 * To run a mutation, you first call `useInsertCompanyOfferPostbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyOfferPostbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyOfferPostbackMutation, { data, loading, error }] = useInsertCompanyOfferPostbackMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyOfferPostbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyOfferPostbackMutation, Types.InsertCompanyOfferPostbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyOfferPostbackMutation, Types.InsertCompanyOfferPostbackMutationVariables>(InsertCompanyOfferPostbackDocument, options);
      }
export type InsertCompanyOfferPostbackMutationHookResult = ReturnType<typeof useInsertCompanyOfferPostbackMutation>;
export type InsertCompanyOfferPostbackMutationResult = Apollo.MutationResult<Types.InsertCompanyOfferPostbackMutation>;
export type InsertCompanyOfferPostbackMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyOfferPostbackMutation, Types.InsertCompanyOfferPostbackMutationVariables>;
export const DeleteCompanyOfferPostbackDocument = gql`
    mutation DeleteCompanyOfferPostback($id: Int!) {
  delete_company_offer_postback(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyOfferPostbackMutationFn = Apollo.MutationFunction<Types.DeleteCompanyOfferPostbackMutation, Types.DeleteCompanyOfferPostbackMutationVariables>;

/**
 * __useDeleteCompanyOfferPostbackMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfferPostbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfferPostbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfferPostbackMutation, { data, loading, error }] = useDeleteCompanyOfferPostbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyOfferPostbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyOfferPostbackMutation, Types.DeleteCompanyOfferPostbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyOfferPostbackMutation, Types.DeleteCompanyOfferPostbackMutationVariables>(DeleteCompanyOfferPostbackDocument, options);
      }
export type DeleteCompanyOfferPostbackMutationHookResult = ReturnType<typeof useDeleteCompanyOfferPostbackMutation>;
export type DeleteCompanyOfferPostbackMutationResult = Apollo.MutationResult<Types.DeleteCompanyOfferPostbackMutation>;
export type DeleteCompanyOfferPostbackMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyOfferPostbackMutation, Types.DeleteCompanyOfferPostbackMutationVariables>;
export const AllCompanyOffersDocument = gql`
    query AllCompanyOffers {
  companyOffers: company_offer(
    order_by: {company: {name: asc}, chat_company: {name: asc}, offer_payout_type: {payout_type: {name: asc}}}
  ) {
    ...CompanyOfferFields
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;

/**
 * __useAllCompanyOffersQuery__
 *
 * To run a query within a React component, call `useAllCompanyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCompanyOffersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCompanyOffersQuery, Types.AllCompanyOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyOffersQuery, Types.AllCompanyOffersQueryVariables>(AllCompanyOffersDocument, options);
      }
export function useAllCompanyOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyOffersQuery, Types.AllCompanyOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyOffersQuery, Types.AllCompanyOffersQueryVariables>(AllCompanyOffersDocument, options);
        }
export type AllCompanyOffersQueryHookResult = ReturnType<typeof useAllCompanyOffersQuery>;
export type AllCompanyOffersLazyQueryHookResult = ReturnType<typeof useAllCompanyOffersLazyQuery>;
export type AllCompanyOffersQueryResult = Apollo.QueryResult<Types.AllCompanyOffersQuery, Types.AllCompanyOffersQueryVariables>;
export const AllCompanyOffersByCompanyIdDocument = gql`
    query AllCompanyOffersByCompanyId($belongsToCompanyId: Int, $belongsToChatCompanyId: Int) {
  companyOffers: company_offer(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, offer: {is_private: {_eq: false}}}
    order_by: {offer: {name: asc}}
  ) {
    ...CompanyOfferFields
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;

/**
 * __useAllCompanyOffersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useAllCompanyOffersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyOffersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyOffersByCompanyIdQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *   },
 * });
 */
export function useAllCompanyOffersByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCompanyOffersByCompanyIdQuery, Types.AllCompanyOffersByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyOffersByCompanyIdQuery, Types.AllCompanyOffersByCompanyIdQueryVariables>(AllCompanyOffersByCompanyIdDocument, options);
      }
export function useAllCompanyOffersByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyOffersByCompanyIdQuery, Types.AllCompanyOffersByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyOffersByCompanyIdQuery, Types.AllCompanyOffersByCompanyIdQueryVariables>(AllCompanyOffersByCompanyIdDocument, options);
        }
export type AllCompanyOffersByCompanyIdQueryHookResult = ReturnType<typeof useAllCompanyOffersByCompanyIdQuery>;
export type AllCompanyOffersByCompanyIdLazyQueryHookResult = ReturnType<typeof useAllCompanyOffersByCompanyIdLazyQuery>;
export type AllCompanyOffersByCompanyIdQueryResult = Apollo.QueryResult<Types.AllCompanyOffersByCompanyIdQuery, Types.AllCompanyOffersByCompanyIdQueryVariables>;
export const AllCompanyOffersByOfferIdDocument = gql`
    query AllCompanyOffersByOfferId($offerId: Int!) {
  companyOffers: company_offer(
    where: {offer_id: {_eq: $offerId}}
    order_by: {company: {name: asc}, chat_company: {name: asc}, offer_payout_type: {payout_type: {name: asc}}}
  ) {
    ...CompanyOfferFields
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;

/**
 * __useAllCompanyOffersByOfferIdQuery__
 *
 * To run a query within a React component, call `useAllCompanyOffersByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyOffersByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyOffersByOfferIdQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAllCompanyOffersByOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllCompanyOffersByOfferIdQuery, Types.AllCompanyOffersByOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyOffersByOfferIdQuery, Types.AllCompanyOffersByOfferIdQueryVariables>(AllCompanyOffersByOfferIdDocument, options);
      }
export function useAllCompanyOffersByOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyOffersByOfferIdQuery, Types.AllCompanyOffersByOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyOffersByOfferIdQuery, Types.AllCompanyOffersByOfferIdQueryVariables>(AllCompanyOffersByOfferIdDocument, options);
        }
export type AllCompanyOffersByOfferIdQueryHookResult = ReturnType<typeof useAllCompanyOffersByOfferIdQuery>;
export type AllCompanyOffersByOfferIdLazyQueryHookResult = ReturnType<typeof useAllCompanyOffersByOfferIdLazyQuery>;
export type AllCompanyOffersByOfferIdQueryResult = Apollo.QueryResult<Types.AllCompanyOffersByOfferIdQuery, Types.AllCompanyOffersByOfferIdQueryVariables>;
export const AllCompanyOffersByCompanyIdAndOfferIdDocument = gql`
    query AllCompanyOffersByCompanyIdAndOfferId($belongsToCompanyId: Int, $offerId: Int!, $belongsToChatCompanyId: Int) {
  companyOffers: company_offer(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, offer_id: {_eq: $offerId}}
    order_by: {company: {name: asc}, chat_company: {name: asc}, offer_payout_type: {payout_type: {name: asc}}}
  ) {
    ...CompanyOfferFields
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;

/**
 * __useAllCompanyOffersByCompanyIdAndOfferIdQuery__
 *
 * To run a query within a React component, call `useAllCompanyOffersByCompanyIdAndOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyOffersByCompanyIdAndOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyOffersByCompanyIdAndOfferIdQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      offerId: // value for 'offerId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *   },
 * });
 */
export function useAllCompanyOffersByCompanyIdAndOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllCompanyOffersByCompanyIdAndOfferIdQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyOffersByCompanyIdAndOfferIdQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdQueryVariables>(AllCompanyOffersByCompanyIdAndOfferIdDocument, options);
      }
export function useAllCompanyOffersByCompanyIdAndOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyOffersByCompanyIdAndOfferIdQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyOffersByCompanyIdAndOfferIdQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdQueryVariables>(AllCompanyOffersByCompanyIdAndOfferIdDocument, options);
        }
export type AllCompanyOffersByCompanyIdAndOfferIdQueryHookResult = ReturnType<typeof useAllCompanyOffersByCompanyIdAndOfferIdQuery>;
export type AllCompanyOffersByCompanyIdAndOfferIdLazyQueryHookResult = ReturnType<typeof useAllCompanyOffersByCompanyIdAndOfferIdLazyQuery>;
export type AllCompanyOffersByCompanyIdAndOfferIdQueryResult = Apollo.QueryResult<Types.AllCompanyOffersByCompanyIdAndOfferIdQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdQueryVariables>;
export const AllCompanyOffersByCompanyIdAndOfferIdNotNullDocument = gql`
    query AllCompanyOffersByCompanyIdAndOfferIdNotNull($belongsToCompanyId: Int!, $offerId: Int!) {
  companyOffers: company_offer(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, offer_id: {_eq: $offerId}, belongs_to_chat_company_id: {_is_null: false}}
    order_by: {company: {name: asc}, chat_company: {name: asc}, offer_payout_type: {payout_type: {name: asc}}}
  ) {
    ...CompanyOfferFields
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;

/**
 * __useAllCompanyOffersByCompanyIdAndOfferIdNotNullQuery__
 *
 * To run a query within a React component, call `useAllCompanyOffersByCompanyIdAndOfferIdNotNullQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyOffersByCompanyIdAndOfferIdNotNullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyOffersByCompanyIdAndOfferIdNotNullQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAllCompanyOffersByCompanyIdAndOfferIdNotNullQuery(baseOptions: Apollo.QueryHookOptions<Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryVariables>(AllCompanyOffersByCompanyIdAndOfferIdNotNullDocument, options);
      }
export function useAllCompanyOffersByCompanyIdAndOfferIdNotNullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryVariables>(AllCompanyOffersByCompanyIdAndOfferIdNotNullDocument, options);
        }
export type AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryHookResult = ReturnType<typeof useAllCompanyOffersByCompanyIdAndOfferIdNotNullQuery>;
export type AllCompanyOffersByCompanyIdAndOfferIdNotNullLazyQueryHookResult = ReturnType<typeof useAllCompanyOffersByCompanyIdAndOfferIdNotNullLazyQuery>;
export type AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryResult = Apollo.QueryResult<Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQuery, Types.AllCompanyOffersByCompanyIdAndOfferIdNotNullQueryVariables>;
export const CompanyOfferByIdDocument = gql`
    query CompanyOfferById($id: Int!) {
  companyOffer: company_offer(where: {id: {_eq: $id}}) {
    ...CompanyOfferFields
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;

/**
 * __useCompanyOfferByIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyOfferByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyOfferByIdQuery, Types.CompanyOfferByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferByIdQuery, Types.CompanyOfferByIdQueryVariables>(CompanyOfferByIdDocument, options);
      }
export function useCompanyOfferByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferByIdQuery, Types.CompanyOfferByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferByIdQuery, Types.CompanyOfferByIdQueryVariables>(CompanyOfferByIdDocument, options);
        }
export type CompanyOfferByIdQueryHookResult = ReturnType<typeof useCompanyOfferByIdQuery>;
export type CompanyOfferByIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferByIdLazyQuery>;
export type CompanyOfferByIdQueryResult = Apollo.QueryResult<Types.CompanyOfferByIdQuery, Types.CompanyOfferByIdQueryVariables>;
export const UpdateCompanyOfferDocument = gql`
    mutation UpdateCompanyOffer($id: Int!, $changes: company_offer_set_input) {
  UpdateCompanyOffer: update_company_offer(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyOfferMutationFn = Apollo.MutationFunction<Types.UpdateCompanyOfferMutation, Types.UpdateCompanyOfferMutationVariables>;

/**
 * __useUpdateCompanyOfferMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOfferMutation, { data, loading, error }] = useUpdateCompanyOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyOfferMutation, Types.UpdateCompanyOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyOfferMutation, Types.UpdateCompanyOfferMutationVariables>(UpdateCompanyOfferDocument, options);
      }
export type UpdateCompanyOfferMutationHookResult = ReturnType<typeof useUpdateCompanyOfferMutation>;
export type UpdateCompanyOfferMutationResult = Apollo.MutationResult<Types.UpdateCompanyOfferMutation>;
export type UpdateCompanyOfferMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyOfferMutation, Types.UpdateCompanyOfferMutationVariables>;
export const InsertCompanyOfferDocument = gql`
    mutation InsertCompanyOffer($object: company_offer_insert_input!) {
  insert_company_offer(objects: [$object]) {
    returning {
      ...CompanyOfferFields
    }
  }
}
    ${CompanyOfferFieldsFragmentDoc}`;
export type InsertCompanyOfferMutationFn = Apollo.MutationFunction<Types.InsertCompanyOfferMutation, Types.InsertCompanyOfferMutationVariables>;

/**
 * __useInsertCompanyOfferMutation__
 *
 * To run a mutation, you first call `useInsertCompanyOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyOfferMutation, { data, loading, error }] = useInsertCompanyOfferMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyOfferMutation, Types.InsertCompanyOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyOfferMutation, Types.InsertCompanyOfferMutationVariables>(InsertCompanyOfferDocument, options);
      }
export type InsertCompanyOfferMutationHookResult = ReturnType<typeof useInsertCompanyOfferMutation>;
export type InsertCompanyOfferMutationResult = Apollo.MutationResult<Types.InsertCompanyOfferMutation>;
export type InsertCompanyOfferMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyOfferMutation, Types.InsertCompanyOfferMutationVariables>;
export const DeleteCompanyOfferDocument = gql`
    mutation DeleteCompanyOffer($id: Int!) {
  delete_company_offer(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyOfferMutationFn = Apollo.MutationFunction<Types.DeleteCompanyOfferMutation, Types.DeleteCompanyOfferMutationVariables>;

/**
 * __useDeleteCompanyOfferMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfferMutation, { data, loading, error }] = useDeleteCompanyOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyOfferMutation, Types.DeleteCompanyOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyOfferMutation, Types.DeleteCompanyOfferMutationVariables>(DeleteCompanyOfferDocument, options);
      }
export type DeleteCompanyOfferMutationHookResult = ReturnType<typeof useDeleteCompanyOfferMutation>;
export type DeleteCompanyOfferMutationResult = Apollo.MutationResult<Types.DeleteCompanyOfferMutation>;
export type DeleteCompanyOfferMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyOfferMutation, Types.DeleteCompanyOfferMutationVariables>;
export const DeleteCompanyOfferByCompanyIdAndOfferIdDocument = gql`
    mutation DeleteCompanyOfferByCompanyIdAndOfferId($belongsToCompanyId: Int!, $belongsToChatCompanyId: Int, $offerId: Int!) {
  delete_company_offer(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, offer_id: {_eq: $offerId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyOfferByCompanyIdAndOfferIdMutationFn = Apollo.MutationFunction<Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutation, Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutationVariables>;

/**
 * __useDeleteCompanyOfferByCompanyIdAndOfferIdMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfferByCompanyIdAndOfferIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfferByCompanyIdAndOfferIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfferByCompanyIdAndOfferIdMutation, { data, loading, error }] = useDeleteCompanyOfferByCompanyIdAndOfferIdMutation({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useDeleteCompanyOfferByCompanyIdAndOfferIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutation, Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutation, Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutationVariables>(DeleteCompanyOfferByCompanyIdAndOfferIdDocument, options);
      }
export type DeleteCompanyOfferByCompanyIdAndOfferIdMutationHookResult = ReturnType<typeof useDeleteCompanyOfferByCompanyIdAndOfferIdMutation>;
export type DeleteCompanyOfferByCompanyIdAndOfferIdMutationResult = Apollo.MutationResult<Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutation>;
export type DeleteCompanyOfferByCompanyIdAndOfferIdMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutation, Types.DeleteCompanyOfferByCompanyIdAndOfferIdMutationVariables>;
export const CompanyOfferRedirectsByCompanyOfferIdDocument = gql`
    query CompanyOfferRedirectsByCompanyOfferId($companyOfferId: Int) {
  redirects: company_offer_redirect(
    where: {company_offer_id: {_eq: $companyOfferId}}
  ) {
    ...CompanyOfferRedirectFields
  }
}
    ${CompanyOfferRedirectFieldsFragmentDoc}`;

/**
 * __useCompanyOfferRedirectsByCompanyOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferRedirectsByCompanyOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferRedirectsByCompanyOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferRedirectsByCompanyOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *   },
 * });
 */
export function useCompanyOfferRedirectsByCompanyOfferIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferRedirectsByCompanyOfferIdQuery, Types.CompanyOfferRedirectsByCompanyOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferRedirectsByCompanyOfferIdQuery, Types.CompanyOfferRedirectsByCompanyOfferIdQueryVariables>(CompanyOfferRedirectsByCompanyOfferIdDocument, options);
      }
export function useCompanyOfferRedirectsByCompanyOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferRedirectsByCompanyOfferIdQuery, Types.CompanyOfferRedirectsByCompanyOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferRedirectsByCompanyOfferIdQuery, Types.CompanyOfferRedirectsByCompanyOfferIdQueryVariables>(CompanyOfferRedirectsByCompanyOfferIdDocument, options);
        }
export type CompanyOfferRedirectsByCompanyOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferRedirectsByCompanyOfferIdQuery>;
export type CompanyOfferRedirectsByCompanyOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferRedirectsByCompanyOfferIdLazyQuery>;
export type CompanyOfferRedirectsByCompanyOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferRedirectsByCompanyOfferIdQuery, Types.CompanyOfferRedirectsByCompanyOfferIdQueryVariables>;
export const CompanyOfferRedirectsByCompanyIdAndOfferIdDocument = gql`
    query CompanyOfferRedirectsByCompanyIdAndOfferId($companyOfferId: Int, $belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $offerId: Int!) {
  redirects: company_offer_redirect(
    where: {company_offer_id: {_eq: $companyOfferId}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, offer_id: {_eq: $offerId}}
  ) {
    ...CompanyOfferRedirectFields
  }
}
    ${CompanyOfferRedirectFieldsFragmentDoc}`;

/**
 * __useCompanyOfferRedirectsByCompanyIdAndOfferIdQuery__
 *
 * To run a query within a React component, call `useCompanyOfferRedirectsByCompanyIdAndOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferRedirectsByCompanyIdAndOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferRedirectsByCompanyIdAndOfferIdQuery({
 *   variables: {
 *      companyOfferId: // value for 'companyOfferId'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCompanyOfferRedirectsByCompanyIdAndOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQuery, Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQuery, Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQueryVariables>(CompanyOfferRedirectsByCompanyIdAndOfferIdDocument, options);
      }
export function useCompanyOfferRedirectsByCompanyIdAndOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQuery, Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQuery, Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQueryVariables>(CompanyOfferRedirectsByCompanyIdAndOfferIdDocument, options);
        }
export type CompanyOfferRedirectsByCompanyIdAndOfferIdQueryHookResult = ReturnType<typeof useCompanyOfferRedirectsByCompanyIdAndOfferIdQuery>;
export type CompanyOfferRedirectsByCompanyIdAndOfferIdLazyQueryHookResult = ReturnType<typeof useCompanyOfferRedirectsByCompanyIdAndOfferIdLazyQuery>;
export type CompanyOfferRedirectsByCompanyIdAndOfferIdQueryResult = Apollo.QueryResult<Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQuery, Types.CompanyOfferRedirectsByCompanyIdAndOfferIdQueryVariables>;
export const CompanyOfferRedirectsByFilterDocument = gql`
    query CompanyOfferRedirectsByFilter($filter: company_offer_redirect_bool_exp) {
  redirects: company_offer_redirect(where: $filter) {
    ...CompanyOfferRedirectFields
  }
}
    ${CompanyOfferRedirectFieldsFragmentDoc}`;

/**
 * __useCompanyOfferRedirectsByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyOfferRedirectsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOfferRedirectsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOfferRedirectsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyOfferRedirectsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyOfferRedirectsByFilterQuery, Types.CompanyOfferRedirectsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyOfferRedirectsByFilterQuery, Types.CompanyOfferRedirectsByFilterQueryVariables>(CompanyOfferRedirectsByFilterDocument, options);
      }
export function useCompanyOfferRedirectsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyOfferRedirectsByFilterQuery, Types.CompanyOfferRedirectsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyOfferRedirectsByFilterQuery, Types.CompanyOfferRedirectsByFilterQueryVariables>(CompanyOfferRedirectsByFilterDocument, options);
        }
export type CompanyOfferRedirectsByFilterQueryHookResult = ReturnType<typeof useCompanyOfferRedirectsByFilterQuery>;
export type CompanyOfferRedirectsByFilterLazyQueryHookResult = ReturnType<typeof useCompanyOfferRedirectsByFilterLazyQuery>;
export type CompanyOfferRedirectsByFilterQueryResult = Apollo.QueryResult<Types.CompanyOfferRedirectsByFilterQuery, Types.CompanyOfferRedirectsByFilterQueryVariables>;
export const UpdateCompanyOfferRedirectDocument = gql`
    mutation UpdateCompanyOfferRedirect($id: Int!, $changes: company_offer_redirect_set_input) {
  updateCompanyOfferRedirect: update_company_offer_redirect(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyOfferRedirectMutationFn = Apollo.MutationFunction<Types.UpdateCompanyOfferRedirectMutation, Types.UpdateCompanyOfferRedirectMutationVariables>;

/**
 * __useUpdateCompanyOfferRedirectMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOfferRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOfferRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOfferRedirectMutation, { data, loading, error }] = useUpdateCompanyOfferRedirectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyOfferRedirectMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyOfferRedirectMutation, Types.UpdateCompanyOfferRedirectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyOfferRedirectMutation, Types.UpdateCompanyOfferRedirectMutationVariables>(UpdateCompanyOfferRedirectDocument, options);
      }
export type UpdateCompanyOfferRedirectMutationHookResult = ReturnType<typeof useUpdateCompanyOfferRedirectMutation>;
export type UpdateCompanyOfferRedirectMutationResult = Apollo.MutationResult<Types.UpdateCompanyOfferRedirectMutation>;
export type UpdateCompanyOfferRedirectMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyOfferRedirectMutation, Types.UpdateCompanyOfferRedirectMutationVariables>;
export const InsertCompanyOfferRedirectDocument = gql`
    mutation InsertCompanyOfferRedirect($object: company_offer_redirect_insert_input!) {
  insert_company_offer_redirect(objects: [$object]) {
    returning {
      ...CompanyOfferRedirectFields
    }
  }
}
    ${CompanyOfferRedirectFieldsFragmentDoc}`;
export type InsertCompanyOfferRedirectMutationFn = Apollo.MutationFunction<Types.InsertCompanyOfferRedirectMutation, Types.InsertCompanyOfferRedirectMutationVariables>;

/**
 * __useInsertCompanyOfferRedirectMutation__
 *
 * To run a mutation, you first call `useInsertCompanyOfferRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyOfferRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyOfferRedirectMutation, { data, loading, error }] = useInsertCompanyOfferRedirectMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyOfferRedirectMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyOfferRedirectMutation, Types.InsertCompanyOfferRedirectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyOfferRedirectMutation, Types.InsertCompanyOfferRedirectMutationVariables>(InsertCompanyOfferRedirectDocument, options);
      }
export type InsertCompanyOfferRedirectMutationHookResult = ReturnType<typeof useInsertCompanyOfferRedirectMutation>;
export type InsertCompanyOfferRedirectMutationResult = Apollo.MutationResult<Types.InsertCompanyOfferRedirectMutation>;
export type InsertCompanyOfferRedirectMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyOfferRedirectMutation, Types.InsertCompanyOfferRedirectMutationVariables>;
export const DeleteCompanyOfferRedirectDocument = gql`
    mutation DeleteCompanyOfferRedirect($id: Int!) {
  delete_company_offer_redirect(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyOfferRedirectMutationFn = Apollo.MutationFunction<Types.DeleteCompanyOfferRedirectMutation, Types.DeleteCompanyOfferRedirectMutationVariables>;

/**
 * __useDeleteCompanyOfferRedirectMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyOfferRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyOfferRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyOfferRedirectMutation, { data, loading, error }] = useDeleteCompanyOfferRedirectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyOfferRedirectMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyOfferRedirectMutation, Types.DeleteCompanyOfferRedirectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyOfferRedirectMutation, Types.DeleteCompanyOfferRedirectMutationVariables>(DeleteCompanyOfferRedirectDocument, options);
      }
export type DeleteCompanyOfferRedirectMutationHookResult = ReturnType<typeof useDeleteCompanyOfferRedirectMutation>;
export type DeleteCompanyOfferRedirectMutationResult = Apollo.MutationResult<Types.DeleteCompanyOfferRedirectMutation>;
export type DeleteCompanyOfferRedirectMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyOfferRedirectMutation, Types.DeleteCompanyOfferRedirectMutationVariables>;
export const AllCompanyRoleUserPermissionsDocument = gql`
    query AllCompanyRoleUserPermissions {
  companyRoleUserPermissions: company_role_user_permission {
    ...CompanyRoleUserPermissionFields
  }
}
    ${CompanyRoleUserPermissionFieldsFragmentDoc}`;

/**
 * __useAllCompanyRoleUserPermissionsQuery__
 *
 * To run a query within a React component, call `useAllCompanyRoleUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyRoleUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyRoleUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCompanyRoleUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCompanyRoleUserPermissionsQuery, Types.AllCompanyRoleUserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyRoleUserPermissionsQuery, Types.AllCompanyRoleUserPermissionsQueryVariables>(AllCompanyRoleUserPermissionsDocument, options);
      }
export function useAllCompanyRoleUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyRoleUserPermissionsQuery, Types.AllCompanyRoleUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyRoleUserPermissionsQuery, Types.AllCompanyRoleUserPermissionsQueryVariables>(AllCompanyRoleUserPermissionsDocument, options);
        }
export type AllCompanyRoleUserPermissionsQueryHookResult = ReturnType<typeof useAllCompanyRoleUserPermissionsQuery>;
export type AllCompanyRoleUserPermissionsLazyQueryHookResult = ReturnType<typeof useAllCompanyRoleUserPermissionsLazyQuery>;
export type AllCompanyRoleUserPermissionsQueryResult = Apollo.QueryResult<Types.AllCompanyRoleUserPermissionsQuery, Types.AllCompanyRoleUserPermissionsQueryVariables>;
export const CompanyRoleUserPermissionDocument = gql`
    query CompanyRoleUserPermission($id: Int!) {
  CompanyRoleUserPermission: company_role_user_permission(where: {id: {_eq: $id}}) {
    ...CompanyRoleUserPermissionFields
  }
}
    ${CompanyRoleUserPermissionFieldsFragmentDoc}`;

/**
 * __useCompanyRoleUserPermissionQuery__
 *
 * To run a query within a React component, call `useCompanyRoleUserPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRoleUserPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRoleUserPermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyRoleUserPermissionQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyRoleUserPermissionQuery, Types.CompanyRoleUserPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRoleUserPermissionQuery, Types.CompanyRoleUserPermissionQueryVariables>(CompanyRoleUserPermissionDocument, options);
      }
export function useCompanyRoleUserPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRoleUserPermissionQuery, Types.CompanyRoleUserPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRoleUserPermissionQuery, Types.CompanyRoleUserPermissionQueryVariables>(CompanyRoleUserPermissionDocument, options);
        }
export type CompanyRoleUserPermissionQueryHookResult = ReturnType<typeof useCompanyRoleUserPermissionQuery>;
export type CompanyRoleUserPermissionLazyQueryHookResult = ReturnType<typeof useCompanyRoleUserPermissionLazyQuery>;
export type CompanyRoleUserPermissionQueryResult = Apollo.QueryResult<Types.CompanyRoleUserPermissionQuery, Types.CompanyRoleUserPermissionQueryVariables>;
export const UpdateCompanyRoleUserPermissionDocument = gql`
    mutation UpdateCompanyRoleUserPermission($id: Int!, $changes: company_role_user_permission_set_input) {
  UpdateCompanyRoleuserPermission: update_company_role_user_permission(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyRoleUserPermissionMutationFn = Apollo.MutationFunction<Types.UpdateCompanyRoleUserPermissionMutation, Types.UpdateCompanyRoleUserPermissionMutationVariables>;

/**
 * __useUpdateCompanyRoleUserPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRoleUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRoleUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRoleUserPermissionMutation, { data, loading, error }] = useUpdateCompanyRoleUserPermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyRoleUserPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyRoleUserPermissionMutation, Types.UpdateCompanyRoleUserPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyRoleUserPermissionMutation, Types.UpdateCompanyRoleUserPermissionMutationVariables>(UpdateCompanyRoleUserPermissionDocument, options);
      }
export type UpdateCompanyRoleUserPermissionMutationHookResult = ReturnType<typeof useUpdateCompanyRoleUserPermissionMutation>;
export type UpdateCompanyRoleUserPermissionMutationResult = Apollo.MutationResult<Types.UpdateCompanyRoleUserPermissionMutation>;
export type UpdateCompanyRoleUserPermissionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyRoleUserPermissionMutation, Types.UpdateCompanyRoleUserPermissionMutationVariables>;
export const InsertCompanyRoleUserPermissionDocument = gql`
    mutation InsertCompanyRoleUserPermission($object: [company_role_user_permission_insert_input!]!) {
  insert_company_role_user_permission(objects: $object) {
    returning {
      id
    }
  }
}
    `;
export type InsertCompanyRoleUserPermissionMutationFn = Apollo.MutationFunction<Types.InsertCompanyRoleUserPermissionMutation, Types.InsertCompanyRoleUserPermissionMutationVariables>;

/**
 * __useInsertCompanyRoleUserPermissionMutation__
 *
 * To run a mutation, you first call `useInsertCompanyRoleUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyRoleUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyRoleUserPermissionMutation, { data, loading, error }] = useInsertCompanyRoleUserPermissionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyRoleUserPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyRoleUserPermissionMutation, Types.InsertCompanyRoleUserPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyRoleUserPermissionMutation, Types.InsertCompanyRoleUserPermissionMutationVariables>(InsertCompanyRoleUserPermissionDocument, options);
      }
export type InsertCompanyRoleUserPermissionMutationHookResult = ReturnType<typeof useInsertCompanyRoleUserPermissionMutation>;
export type InsertCompanyRoleUserPermissionMutationResult = Apollo.MutationResult<Types.InsertCompanyRoleUserPermissionMutation>;
export type InsertCompanyRoleUserPermissionMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyRoleUserPermissionMutation, Types.InsertCompanyRoleUserPermissionMutationVariables>;
export const DeleteCompanyRoleUserPermissionDocument = gql`
    mutation DeleteCompanyRoleUserPermission($id: Int!) {
  delete_company_role_user_permission(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyRoleUserPermissionMutationFn = Apollo.MutationFunction<Types.DeleteCompanyRoleUserPermissionMutation, Types.DeleteCompanyRoleUserPermissionMutationVariables>;

/**
 * __useDeleteCompanyRoleUserPermissionMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyRoleUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyRoleUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyRoleUserPermissionMutation, { data, loading, error }] = useDeleteCompanyRoleUserPermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyRoleUserPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyRoleUserPermissionMutation, Types.DeleteCompanyRoleUserPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyRoleUserPermissionMutation, Types.DeleteCompanyRoleUserPermissionMutationVariables>(DeleteCompanyRoleUserPermissionDocument, options);
      }
export type DeleteCompanyRoleUserPermissionMutationHookResult = ReturnType<typeof useDeleteCompanyRoleUserPermissionMutation>;
export type DeleteCompanyRoleUserPermissionMutationResult = Apollo.MutationResult<Types.DeleteCompanyRoleUserPermissionMutation>;
export type DeleteCompanyRoleUserPermissionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyRoleUserPermissionMutation, Types.DeleteCompanyRoleUserPermissionMutationVariables>;
export const DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationDocument = gql`
    mutation DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation($companyRoleUserId: Int!) {
  delete_company_role_user_permission(
    where: {company_role_user_id: {_eq: $companyRoleUserId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationMutationFn = Apollo.MutationFunction<Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation, Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationVariables>;

/**
 * __useDeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation, { data, loading, error }] = useDeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation({
 *   variables: {
 *      companyRoleUserId: // value for 'companyRoleUserId'
 *   },
 * });
 */
export function useDeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation, Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation, Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationVariables>(DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationDocument, options);
      }
export type DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationHookResult = ReturnType<typeof useDeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation>;
export type DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationMutationResult = Apollo.MutationResult<Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation>;
export type DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutation, Types.DeleteCompanyRoleUserPermissionByCompanyRoleUserIdMutationVariables>;
export const CompanyRoleUserByFilterDocument = gql`
    query CompanyRoleUserByFilter($filter: company_role_user_bool_exp, $orderBy: [company_role_user_order_by!]) {
  companyRoleUsers: company_role_user(where: $filter, order_by: $orderBy) {
    ...CompanyRoleUserFields
  }
}
    ${CompanyRoleUserFieldsFragmentDoc}`;

/**
 * __useCompanyRoleUserByFilterQuery__
 *
 * To run a query within a React component, call `useCompanyRoleUserByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRoleUserByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRoleUserByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCompanyRoleUserByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyRoleUserByFilterQuery, Types.CompanyRoleUserByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRoleUserByFilterQuery, Types.CompanyRoleUserByFilterQueryVariables>(CompanyRoleUserByFilterDocument, options);
      }
export function useCompanyRoleUserByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRoleUserByFilterQuery, Types.CompanyRoleUserByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRoleUserByFilterQuery, Types.CompanyRoleUserByFilterQueryVariables>(CompanyRoleUserByFilterDocument, options);
        }
export type CompanyRoleUserByFilterQueryHookResult = ReturnType<typeof useCompanyRoleUserByFilterQuery>;
export type CompanyRoleUserByFilterLazyQueryHookResult = ReturnType<typeof useCompanyRoleUserByFilterLazyQuery>;
export type CompanyRoleUserByFilterQueryResult = Apollo.QueryResult<Types.CompanyRoleUserByFilterQuery, Types.CompanyRoleUserByFilterQueryVariables>;
export const CompanyRoleUserByIdDocument = gql`
    query CompanyRoleUserById($companyRoleUserId: Int!) {
  companyRoleUser: company_role_user(where: {id: {_eq: $companyRoleUserId}}) {
    ...CompanyRoleUserFields
  }
}
    ${CompanyRoleUserFieldsFragmentDoc}`;

/**
 * __useCompanyRoleUserByIdQuery__
 *
 * To run a query within a React component, call `useCompanyRoleUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRoleUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRoleUserByIdQuery({
 *   variables: {
 *      companyRoleUserId: // value for 'companyRoleUserId'
 *   },
 * });
 */
export function useCompanyRoleUserByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyRoleUserByIdQuery, Types.CompanyRoleUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRoleUserByIdQuery, Types.CompanyRoleUserByIdQueryVariables>(CompanyRoleUserByIdDocument, options);
      }
export function useCompanyRoleUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRoleUserByIdQuery, Types.CompanyRoleUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRoleUserByIdQuery, Types.CompanyRoleUserByIdQueryVariables>(CompanyRoleUserByIdDocument, options);
        }
export type CompanyRoleUserByIdQueryHookResult = ReturnType<typeof useCompanyRoleUserByIdQuery>;
export type CompanyRoleUserByIdLazyQueryHookResult = ReturnType<typeof useCompanyRoleUserByIdLazyQuery>;
export type CompanyRoleUserByIdQueryResult = Apollo.QueryResult<Types.CompanyRoleUserByIdQuery, Types.CompanyRoleUserByIdQueryVariables>;
export const CompanyRoleUserByOwnerDocument = gql`
    query CompanyRoleUserByOwner($companyId: Int) {
  companyRoleUser: company_role_user(
    where: {company_id: {_eq: $companyId}, role: {name: {_ilike: "%owner%"}}, is_approved: {_eq: true}, is_enabled: {_eq: true}}
  ) {
    id
    user {
      firstName: first_name
      lastName: last_name
      username: username
    }
    role {
      id
      name
    }
    inviteAccepted: invite_accepted
    isEnabled: is_enabled
    isApproved: is_approved
  }
}
    `;

/**
 * __useCompanyRoleUserByOwnerQuery__
 *
 * To run a query within a React component, call `useCompanyRoleUserByOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRoleUserByOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRoleUserByOwnerQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyRoleUserByOwnerQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyRoleUserByOwnerQuery, Types.CompanyRoleUserByOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRoleUserByOwnerQuery, Types.CompanyRoleUserByOwnerQueryVariables>(CompanyRoleUserByOwnerDocument, options);
      }
export function useCompanyRoleUserByOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRoleUserByOwnerQuery, Types.CompanyRoleUserByOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRoleUserByOwnerQuery, Types.CompanyRoleUserByOwnerQueryVariables>(CompanyRoleUserByOwnerDocument, options);
        }
export type CompanyRoleUserByOwnerQueryHookResult = ReturnType<typeof useCompanyRoleUserByOwnerQuery>;
export type CompanyRoleUserByOwnerLazyQueryHookResult = ReturnType<typeof useCompanyRoleUserByOwnerLazyQuery>;
export type CompanyRoleUserByOwnerQueryResult = Apollo.QueryResult<Types.CompanyRoleUserByOwnerQuery, Types.CompanyRoleUserByOwnerQueryVariables>;
export const AllInvitedUsersQueryDocument = gql`
    query AllInvitedUsersQuery {
  company_role_user(order_by: {user: {first_name: asc}, company: {name: asc}}) {
    id
    companyId: company_id
    isEnabled: is_enabled
    isApproved: is_approved
    inviteAccepted: invite_accepted
    user {
      ...UserFields
    }
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    company {
      ...CompanyFields
    }
    role {
      id
      name
    }
  }
}
    ${UserFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

/**
 * __useAllInvitedUsersQuery__
 *
 * To run a query within a React component, call `useAllInvitedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvitedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvitedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllInvitedUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllInvitedUsersQuery, Types.AllInvitedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllInvitedUsersQuery, Types.AllInvitedUsersQueryVariables>(AllInvitedUsersQueryDocument, options);
      }
export function useAllInvitedUsersQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllInvitedUsersQuery, Types.AllInvitedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllInvitedUsersQuery, Types.AllInvitedUsersQueryVariables>(AllInvitedUsersQueryDocument, options);
        }
export type AllInvitedUsersQueryHookResult = ReturnType<typeof useAllInvitedUsersQuery>;
export type AllInvitedUsersQueryLazyQueryHookResult = ReturnType<typeof useAllInvitedUsersQueryLazyQuery>;
export type AllInvitedUsersQueryQueryResult = Apollo.QueryResult<Types.AllInvitedUsersQuery, Types.AllInvitedUsersQueryVariables>;
export const AdminAllInvitedUsersByCompanyQueryDocument = gql`
    query AdminAllInvitedUsersByCompanyQuery($belongsToCompanyId: Int, $limit: Int, $offset: Int) {
  company_role_user(
    order_by: {user: {first_name: asc}, company: {name: asc}}
    where: {company_id: {_eq: $belongsToCompanyId}}
    limit: $limit
    offset: $offset
  ) {
    id
    companyId: company_id
    createdAt: created_at
    inviteAccepted: invite_accepted
    lastLoginAt: last_login_at
    isEnabled: is_enabled
    isApproved: is_approved
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    user {
      ...UserFields
    }
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    company {
      ...CompanyFields
    }
    role {
      id
      name
      shortcode
    }
  }
  company_role_user_aggregate(where: {company_id: {_eq: $belongsToCompanyId}}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${UserFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

/**
 * __useAdminAllInvitedUsersByCompanyQuery__
 *
 * To run a query within a React component, call `useAdminAllInvitedUsersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAllInvitedUsersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAllInvitedUsersByCompanyQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdminAllInvitedUsersByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdminAllInvitedUsersByCompanyQuery, Types.AdminAllInvitedUsersByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminAllInvitedUsersByCompanyQuery, Types.AdminAllInvitedUsersByCompanyQueryVariables>(AdminAllInvitedUsersByCompanyQueryDocument, options);
      }
export function useAdminAllInvitedUsersByCompanyQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminAllInvitedUsersByCompanyQuery, Types.AdminAllInvitedUsersByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminAllInvitedUsersByCompanyQuery, Types.AdminAllInvitedUsersByCompanyQueryVariables>(AdminAllInvitedUsersByCompanyQueryDocument, options);
        }
export type AdminAllInvitedUsersByCompanyQueryHookResult = ReturnType<typeof useAdminAllInvitedUsersByCompanyQuery>;
export type AdminAllInvitedUsersByCompanyQueryLazyQueryHookResult = ReturnType<typeof useAdminAllInvitedUsersByCompanyQueryLazyQuery>;
export type AdminAllInvitedUsersByCompanyQueryQueryResult = Apollo.QueryResult<Types.AdminAllInvitedUsersByCompanyQuery, Types.AdminAllInvitedUsersByCompanyQueryVariables>;
export const AllInvitedUsersByCompanyQueryDocument = gql`
    query AllInvitedUsersByCompanyQuery($belongsToCompanyId: Int, $limit: Int, $offset: Int) {
  company_role_user(
    order_by: {user: {first_name: asc}}
    where: {company_id: {_eq: $belongsToCompanyId}, role: {admin_only: {_eq: false}}}
    limit: $limit
    offset: $offset
  ) {
    id
    companyId: company_id
    createdAt: created_at
    inviteAccepted: invite_accepted
    lastLoginAt: last_login_at
    isEnabled: is_enabled
    isApproved: is_approved
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    user {
      ...UserFields
    }
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    company {
      ...CompanyFields
    }
    role {
      id
      name
      shortcode
    }
  }
  company_role_user_aggregate(where: {company_id: {_eq: $belongsToCompanyId}}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${UserFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

/**
 * __useAllInvitedUsersByCompanyQuery__
 *
 * To run a query within a React component, call `useAllInvitedUsersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvitedUsersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvitedUsersByCompanyQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllInvitedUsersByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllInvitedUsersByCompanyQuery, Types.AllInvitedUsersByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllInvitedUsersByCompanyQuery, Types.AllInvitedUsersByCompanyQueryVariables>(AllInvitedUsersByCompanyQueryDocument, options);
      }
export function useAllInvitedUsersByCompanyQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllInvitedUsersByCompanyQuery, Types.AllInvitedUsersByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllInvitedUsersByCompanyQuery, Types.AllInvitedUsersByCompanyQueryVariables>(AllInvitedUsersByCompanyQueryDocument, options);
        }
export type AllInvitedUsersByCompanyQueryHookResult = ReturnType<typeof useAllInvitedUsersByCompanyQuery>;
export type AllInvitedUsersByCompanyQueryLazyQueryHookResult = ReturnType<typeof useAllInvitedUsersByCompanyQueryLazyQuery>;
export type AllInvitedUsersByCompanyQueryQueryResult = Apollo.QueryResult<Types.AllInvitedUsersByCompanyQuery, Types.AllInvitedUsersByCompanyQueryVariables>;
export const CompanyRoleUserByUserIdDocument = gql`
    query CompanyRoleUserByUserId($userId: Int) {
  companyRoleUser: company_role_user(where: {user_id: {_eq: $userId}}) {
    id
    company {
      ...CompanySafeFields
    }
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    user {
      ...UserFields
    }
  }
}
    ${CompanySafeFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

/**
 * __useCompanyRoleUserByUserIdQuery__
 *
 * To run a query within a React component, call `useCompanyRoleUserByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRoleUserByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRoleUserByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCompanyRoleUserByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyRoleUserByUserIdQuery, Types.CompanyRoleUserByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRoleUserByUserIdQuery, Types.CompanyRoleUserByUserIdQueryVariables>(CompanyRoleUserByUserIdDocument, options);
      }
export function useCompanyRoleUserByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRoleUserByUserIdQuery, Types.CompanyRoleUserByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRoleUserByUserIdQuery, Types.CompanyRoleUserByUserIdQueryVariables>(CompanyRoleUserByUserIdDocument, options);
        }
export type CompanyRoleUserByUserIdQueryHookResult = ReturnType<typeof useCompanyRoleUserByUserIdQuery>;
export type CompanyRoleUserByUserIdLazyQueryHookResult = ReturnType<typeof useCompanyRoleUserByUserIdLazyQuery>;
export type CompanyRoleUserByUserIdQueryResult = Apollo.QueryResult<Types.CompanyRoleUserByUserIdQuery, Types.CompanyRoleUserByUserIdQueryVariables>;
export const CompanyRolesUsersDocument = gql`
    query CompanyRolesUsers($companyId: Int, $userId: Int) {
  companyRoleUsers: company_role_user(
    where: {company_id: {_eq: $companyId}, user_id: {_eq: $userId}, is_enabled: {_eq: true}, is_approved: {_eq: true}, company: {is_enabled: {_eq: true}, is_approved: {_eq: true}}}
    order_by: {company: {name: asc}}
  ) {
    id
    company {
      ...CompanySafeFields
    }
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    user {
      ...UserFields
    }
  }
}
    ${CompanySafeFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

/**
 * __useCompanyRolesUsersQuery__
 *
 * To run a query within a React component, call `useCompanyRolesUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRolesUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRolesUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCompanyRolesUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyRolesUsersQuery, Types.CompanyRolesUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRolesUsersQuery, Types.CompanyRolesUsersQueryVariables>(CompanyRolesUsersDocument, options);
      }
export function useCompanyRolesUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRolesUsersQuery, Types.CompanyRolesUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRolesUsersQuery, Types.CompanyRolesUsersQueryVariables>(CompanyRolesUsersDocument, options);
        }
export type CompanyRolesUsersQueryHookResult = ReturnType<typeof useCompanyRolesUsersQuery>;
export type CompanyRolesUsersLazyQueryHookResult = ReturnType<typeof useCompanyRolesUsersLazyQuery>;
export type CompanyRolesUsersQueryResult = Apollo.QueryResult<Types.CompanyRolesUsersQuery, Types.CompanyRolesUsersQueryVariables>;
export const CompanyRolesUserSelectCompanyDocument = gql`
    query CompanyRolesUserSelectCompany($companyId: Int, $userId: Int!) {
  companyRoleUsers: company_role_user(
    where: {company_id: {_eq: $companyId}, user_id: {_eq: $userId}, is_enabled: {_eq: true}, is_approved: {_eq: true}, company: {is_enabled: {_eq: true}, is_approved: {_eq: true}}}
    order_by: {company: {name: asc}}
  ) {
    id
    company {
      id
      name
      companyCode: company_code
    }
  }
}
    `;

/**
 * __useCompanyRolesUserSelectCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyRolesUserSelectCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRolesUserSelectCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRolesUserSelectCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCompanyRolesUserSelectCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyRolesUserSelectCompanyQuery, Types.CompanyRolesUserSelectCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRolesUserSelectCompanyQuery, Types.CompanyRolesUserSelectCompanyQueryVariables>(CompanyRolesUserSelectCompanyDocument, options);
      }
export function useCompanyRolesUserSelectCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRolesUserSelectCompanyQuery, Types.CompanyRolesUserSelectCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRolesUserSelectCompanyQuery, Types.CompanyRolesUserSelectCompanyQueryVariables>(CompanyRolesUserSelectCompanyDocument, options);
        }
export type CompanyRolesUserSelectCompanyQueryHookResult = ReturnType<typeof useCompanyRolesUserSelectCompanyQuery>;
export type CompanyRolesUserSelectCompanyLazyQueryHookResult = ReturnType<typeof useCompanyRolesUserSelectCompanyLazyQuery>;
export type CompanyRolesUserSelectCompanyQueryResult = Apollo.QueryResult<Types.CompanyRolesUserSelectCompanyQuery, Types.CompanyRolesUserSelectCompanyQueryVariables>;
export const CompanyRolesUsersChatOperatorsDocument = gql`
    query CompanyRolesUsersChatOperators($companyId: [Int!]) {
  companyRoleUsersChatOperators: company_role_user(
    where: {company_id: {_in: $companyId}, role: {shortcode: {_eq: "chat_operator"}}, is_enabled: {_eq: true}, is_approved: {_eq: true}, company: {is_enabled: {_eq: true}, is_approved: {_eq: true}}}
    order_by: {user: {username: asc}}
  ) {
    id
    role_id
    role {
      name
    }
    company {
      ...CompanySafeFields
    }
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    user {
      ...UserFields
    }
  }
}
    ${CompanySafeFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

/**
 * __useCompanyRolesUsersChatOperatorsQuery__
 *
 * To run a query within a React component, call `useCompanyRolesUsersChatOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRolesUsersChatOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRolesUsersChatOperatorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyRolesUsersChatOperatorsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyRolesUsersChatOperatorsQuery, Types.CompanyRolesUsersChatOperatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRolesUsersChatOperatorsQuery, Types.CompanyRolesUsersChatOperatorsQueryVariables>(CompanyRolesUsersChatOperatorsDocument, options);
      }
export function useCompanyRolesUsersChatOperatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRolesUsersChatOperatorsQuery, Types.CompanyRolesUsersChatOperatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRolesUsersChatOperatorsQuery, Types.CompanyRolesUsersChatOperatorsQueryVariables>(CompanyRolesUsersChatOperatorsDocument, options);
        }
export type CompanyRolesUsersChatOperatorsQueryHookResult = ReturnType<typeof useCompanyRolesUsersChatOperatorsQuery>;
export type CompanyRolesUsersChatOperatorsLazyQueryHookResult = ReturnType<typeof useCompanyRolesUsersChatOperatorsLazyQuery>;
export type CompanyRolesUsersChatOperatorsQueryResult = Apollo.QueryResult<Types.CompanyRolesUsersChatOperatorsQuery, Types.CompanyRolesUsersChatOperatorsQueryVariables>;
export const CompanyRolesUsersAffiliatesDocument = gql`
    query CompanyRolesUsersAffiliates($companyId: Int, $userId: Int) {
  companyRoleUsersAffiliates: company_role_user(
    where: {company_id: {_eq: $companyId}, user_id: {_eq: $userId}, role: {is_affiliate: {_eq: true}}}
    order_by: {user: {username: asc}}
  ) {
    id
    role_id
    role {
      name
    }
    company {
      ...CompanySafeFields
    }
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission_id
      company_role_user_id
      permission {
        permissionKey: permission_key
        description
        id
      }
    }
    user {
      ...UserFields
    }
  }
}
    ${CompanySafeFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

/**
 * __useCompanyRolesUsersAffiliatesQuery__
 *
 * To run a query within a React component, call `useCompanyRolesUsersAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRolesUsersAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRolesUsersAffiliatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCompanyRolesUsersAffiliatesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyRolesUsersAffiliatesQuery, Types.CompanyRolesUsersAffiliatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyRolesUsersAffiliatesQuery, Types.CompanyRolesUsersAffiliatesQueryVariables>(CompanyRolesUsersAffiliatesDocument, options);
      }
export function useCompanyRolesUsersAffiliatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyRolesUsersAffiliatesQuery, Types.CompanyRolesUsersAffiliatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyRolesUsersAffiliatesQuery, Types.CompanyRolesUsersAffiliatesQueryVariables>(CompanyRolesUsersAffiliatesDocument, options);
        }
export type CompanyRolesUsersAffiliatesQueryHookResult = ReturnType<typeof useCompanyRolesUsersAffiliatesQuery>;
export type CompanyRolesUsersAffiliatesLazyQueryHookResult = ReturnType<typeof useCompanyRolesUsersAffiliatesLazyQuery>;
export type CompanyRolesUsersAffiliatesQueryResult = Apollo.QueryResult<Types.CompanyRolesUsersAffiliatesQuery, Types.CompanyRolesUsersAffiliatesQueryVariables>;
export const UsersByCompanyDocument = gql`
    query UsersByCompany($companyId: Int) {
  users: user(
    where: {current_company_id: {_eq: $companyId}}
    order_by: {username: asc}
  ) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUsersByCompanyQuery__
 *
 * To run a query within a React component, call `useUsersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUsersByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersByCompanyQuery, Types.UsersByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersByCompanyQuery, Types.UsersByCompanyQueryVariables>(UsersByCompanyDocument, options);
      }
export function useUsersByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersByCompanyQuery, Types.UsersByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersByCompanyQuery, Types.UsersByCompanyQueryVariables>(UsersByCompanyDocument, options);
        }
export type UsersByCompanyQueryHookResult = ReturnType<typeof useUsersByCompanyQuery>;
export type UsersByCompanyLazyQueryHookResult = ReturnType<typeof useUsersByCompanyLazyQuery>;
export type UsersByCompanyQueryResult = Apollo.QueryResult<Types.UsersByCompanyQuery, Types.UsersByCompanyQueryVariables>;
export const UserCompanyRoleDetailDocument = gql`
    query UserCompanyRoleDetail($userId: Int!, $roleId: Int!, $companyId: Int!) {
  roleDetail: company_role_user(
    where: {user_id: {_eq: $userId}, role_id: {_eq: $roleId}, company_id: {_eq: $companyId}}
  ) {
    id
    roleId: role_id
    companyId: company_id
    invitedByRoleUserId: invited_by_role_user_id
    createdByRoleUserId: created_by_role_user_id
    role {
      ...RoleFields
    }
    company {
      ...CompanySafeFields
    }
  }
}
    ${RoleFieldsFragmentDoc}
${CompanySafeFieldsFragmentDoc}`;

/**
 * __useUserCompanyRoleDetailQuery__
 *
 * To run a query within a React component, call `useUserCompanyRoleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompanyRoleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompanyRoleDetailQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      roleId: // value for 'roleId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUserCompanyRoleDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.UserCompanyRoleDetailQuery, Types.UserCompanyRoleDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserCompanyRoleDetailQuery, Types.UserCompanyRoleDetailQueryVariables>(UserCompanyRoleDetailDocument, options);
      }
export function useUserCompanyRoleDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserCompanyRoleDetailQuery, Types.UserCompanyRoleDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserCompanyRoleDetailQuery, Types.UserCompanyRoleDetailQueryVariables>(UserCompanyRoleDetailDocument, options);
        }
export type UserCompanyRoleDetailQueryHookResult = ReturnType<typeof useUserCompanyRoleDetailQuery>;
export type UserCompanyRoleDetailLazyQueryHookResult = ReturnType<typeof useUserCompanyRoleDetailLazyQuery>;
export type UserCompanyRoleDetailQueryResult = Apollo.QueryResult<Types.UserCompanyRoleDetailQuery, Types.UserCompanyRoleDetailQueryVariables>;
export const InsertCompanyRoleUserDocument = gql`
    mutation InsertCompanyRoleUser($object: company_role_user_insert_input!) {
  insert_company_role_user(objects: [$object]) {
    returning {
      id
      role_id
      userId: user_id
      companyId: company_id
      inviteResetCode: invite_reset_code
      invitedByRoleUserId: invited_by_role_user_id
      createdByRoleUserId: created_by_role_user_id
      role {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
}
    `;
export type InsertCompanyRoleUserMutationFn = Apollo.MutationFunction<Types.InsertCompanyRoleUserMutation, Types.InsertCompanyRoleUserMutationVariables>;

/**
 * __useInsertCompanyRoleUserMutation__
 *
 * To run a mutation, you first call `useInsertCompanyRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyRoleUserMutation, { data, loading, error }] = useInsertCompanyRoleUserMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyRoleUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyRoleUserMutation, Types.InsertCompanyRoleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyRoleUserMutation, Types.InsertCompanyRoleUserMutationVariables>(InsertCompanyRoleUserDocument, options);
      }
export type InsertCompanyRoleUserMutationHookResult = ReturnType<typeof useInsertCompanyRoleUserMutation>;
export type InsertCompanyRoleUserMutationResult = Apollo.MutationResult<Types.InsertCompanyRoleUserMutation>;
export type InsertCompanyRoleUserMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyRoleUserMutation, Types.InsertCompanyRoleUserMutationVariables>;
export const UpdateCompanyRoleUserDocument = gql`
    mutation UpdateCompanyRoleUser($id: Int!, $changes: company_role_user_set_input!) {
  UpdateCompanyRoleUser: update_company_role_user(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    returning {
      id
      role_id
      company_id
      is_enabled
      invited_by_role_user_id
      created_by_role_user_id
      postback_url
      role {
        id
        name
      }
      company {
        id
        name
      }
      user {
        ...UserFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateCompanyRoleUserMutationFn = Apollo.MutationFunction<Types.UpdateCompanyRoleUserMutation, Types.UpdateCompanyRoleUserMutationVariables>;

/**
 * __useUpdateCompanyRoleUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRoleUserMutation, { data, loading, error }] = useUpdateCompanyRoleUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyRoleUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyRoleUserMutation, Types.UpdateCompanyRoleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyRoleUserMutation, Types.UpdateCompanyRoleUserMutationVariables>(UpdateCompanyRoleUserDocument, options);
      }
export type UpdateCompanyRoleUserMutationHookResult = ReturnType<typeof useUpdateCompanyRoleUserMutation>;
export type UpdateCompanyRoleUserMutationResult = Apollo.MutationResult<Types.UpdateCompanyRoleUserMutation>;
export type UpdateCompanyRoleUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyRoleUserMutation, Types.UpdateCompanyRoleUserMutationVariables>;
export const UpdateCompanyRoleUserLastLoginDocument = gql`
    mutation UpdateCompanyRoleUserLastLogin($userId: Int!, $companyId: Int!, $changes: company_role_user_set_input!) {
  UpdateCompanyRoleUser: update_company_role_user(
    where: {user_id: {_eq: $userId}, company_id: {_eq: $companyId}}
    _set: $changes
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateCompanyRoleUserLastLoginMutationFn = Apollo.MutationFunction<Types.UpdateCompanyRoleUserLastLoginMutation, Types.UpdateCompanyRoleUserLastLoginMutationVariables>;

/**
 * __useUpdateCompanyRoleUserLastLoginMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRoleUserLastLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRoleUserLastLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRoleUserLastLoginMutation, { data, loading, error }] = useUpdateCompanyRoleUserLastLoginMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyRoleUserLastLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyRoleUserLastLoginMutation, Types.UpdateCompanyRoleUserLastLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyRoleUserLastLoginMutation, Types.UpdateCompanyRoleUserLastLoginMutationVariables>(UpdateCompanyRoleUserLastLoginDocument, options);
      }
export type UpdateCompanyRoleUserLastLoginMutationHookResult = ReturnType<typeof useUpdateCompanyRoleUserLastLoginMutation>;
export type UpdateCompanyRoleUserLastLoginMutationResult = Apollo.MutationResult<Types.UpdateCompanyRoleUserLastLoginMutation>;
export type UpdateCompanyRoleUserLastLoginMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyRoleUserLastLoginMutation, Types.UpdateCompanyRoleUserLastLoginMutationVariables>;
export const DeleteCompanyRoleUserDocument = gql`
    mutation DeleteCompanyRoleUser($user_id: Int!, $company: Int!) {
  delete_company_role_user(
    where: {user_id: {_eq: $user_id}, company_id: {_eq: $company}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyRoleUserMutationFn = Apollo.MutationFunction<Types.DeleteCompanyRoleUserMutation, Types.DeleteCompanyRoleUserMutationVariables>;

/**
 * __useDeleteCompanyRoleUserMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyRoleUserMutation, { data, loading, error }] = useDeleteCompanyRoleUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useDeleteCompanyRoleUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyRoleUserMutation, Types.DeleteCompanyRoleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyRoleUserMutation, Types.DeleteCompanyRoleUserMutationVariables>(DeleteCompanyRoleUserDocument, options);
      }
export type DeleteCompanyRoleUserMutationHookResult = ReturnType<typeof useDeleteCompanyRoleUserMutation>;
export type DeleteCompanyRoleUserMutationResult = Apollo.MutationResult<Types.DeleteCompanyRoleUserMutation>;
export type DeleteCompanyRoleUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyRoleUserMutation, Types.DeleteCompanyRoleUserMutationVariables>;
export const CompanyTypeFeatureDocument = gql`
    query CompanyTypeFeature($companyTypeId: Int) {
  companyTypeFeature: company_type_feature(
    where: {company_type_id: {_eq: $companyTypeId}}
  ) {
    ...CompanyTypeFeatureFields
  }
}
    ${CompanyTypeFeatureFieldsFragmentDoc}`;

/**
 * __useCompanyTypeFeatureQuery__
 *
 * To run a query within a React component, call `useCompanyTypeFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypeFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypeFeatureQuery({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *   },
 * });
 */
export function useCompanyTypeFeatureQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyTypeFeatureQuery, Types.CompanyTypeFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyTypeFeatureQuery, Types.CompanyTypeFeatureQueryVariables>(CompanyTypeFeatureDocument, options);
      }
export function useCompanyTypeFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyTypeFeatureQuery, Types.CompanyTypeFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyTypeFeatureQuery, Types.CompanyTypeFeatureQueryVariables>(CompanyTypeFeatureDocument, options);
        }
export type CompanyTypeFeatureQueryHookResult = ReturnType<typeof useCompanyTypeFeatureQuery>;
export type CompanyTypeFeatureLazyQueryHookResult = ReturnType<typeof useCompanyTypeFeatureLazyQuery>;
export type CompanyTypeFeatureQueryResult = Apollo.QueryResult<Types.CompanyTypeFeatureQuery, Types.CompanyTypeFeatureQueryVariables>;
export const UpdateCompanyTypeFeatureDocument = gql`
    mutation UpdateCompanyTypeFeature($companyTypeId: Int!, $changes: company_type_feature_set_input) {
  UpdateCompanyTypeFeature: update_company_type_feature(
    where: {company_type_id: {_eq: $companyTypeId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyTypeFeatureMutationFn = Apollo.MutationFunction<Types.UpdateCompanyTypeFeatureMutation, Types.UpdateCompanyTypeFeatureMutationVariables>;

/**
 * __useUpdateCompanyTypeFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyTypeFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyTypeFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyTypeFeatureMutation, { data, loading, error }] = useUpdateCompanyTypeFeatureMutation({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyTypeFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyTypeFeatureMutation, Types.UpdateCompanyTypeFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyTypeFeatureMutation, Types.UpdateCompanyTypeFeatureMutationVariables>(UpdateCompanyTypeFeatureDocument, options);
      }
export type UpdateCompanyTypeFeatureMutationHookResult = ReturnType<typeof useUpdateCompanyTypeFeatureMutation>;
export type UpdateCompanyTypeFeatureMutationResult = Apollo.MutationResult<Types.UpdateCompanyTypeFeatureMutation>;
export type UpdateCompanyTypeFeatureMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyTypeFeatureMutation, Types.UpdateCompanyTypeFeatureMutationVariables>;
export const InsertCompanyTypeFeatureDocument = gql`
    mutation InsertCompanyTypeFeature($object: [company_type_feature_insert_input!]!) {
  insert_company_type_feature(objects: $object) {
    returning {
      companyTypeId: company_type_id
      featureId: feature_id
    }
  }
}
    `;
export type InsertCompanyTypeFeatureMutationFn = Apollo.MutationFunction<Types.InsertCompanyTypeFeatureMutation, Types.InsertCompanyTypeFeatureMutationVariables>;

/**
 * __useInsertCompanyTypeFeatureMutation__
 *
 * To run a mutation, you first call `useInsertCompanyTypeFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyTypeFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyTypeFeatureMutation, { data, loading, error }] = useInsertCompanyTypeFeatureMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyTypeFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyTypeFeatureMutation, Types.InsertCompanyTypeFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyTypeFeatureMutation, Types.InsertCompanyTypeFeatureMutationVariables>(InsertCompanyTypeFeatureDocument, options);
      }
export type InsertCompanyTypeFeatureMutationHookResult = ReturnType<typeof useInsertCompanyTypeFeatureMutation>;
export type InsertCompanyTypeFeatureMutationResult = Apollo.MutationResult<Types.InsertCompanyTypeFeatureMutation>;
export type InsertCompanyTypeFeatureMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyTypeFeatureMutation, Types.InsertCompanyTypeFeatureMutationVariables>;
export const DeleteCompanyTypeFeatureDocument = gql`
    mutation DeleteCompanyTypeFeature($companyTypeId: Int!, $featureId: Int) {
  delete_company_type_feature(
    where: {company_type_id: {_eq: $companyTypeId}, feature_id: {_eq: $featureId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyTypeFeatureMutationFn = Apollo.MutationFunction<Types.DeleteCompanyTypeFeatureMutation, Types.DeleteCompanyTypeFeatureMutationVariables>;

/**
 * __useDeleteCompanyTypeFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyTypeFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyTypeFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyTypeFeatureMutation, { data, loading, error }] = useDeleteCompanyTypeFeatureMutation({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useDeleteCompanyTypeFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyTypeFeatureMutation, Types.DeleteCompanyTypeFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyTypeFeatureMutation, Types.DeleteCompanyTypeFeatureMutationVariables>(DeleteCompanyTypeFeatureDocument, options);
      }
export type DeleteCompanyTypeFeatureMutationHookResult = ReturnType<typeof useDeleteCompanyTypeFeatureMutation>;
export type DeleteCompanyTypeFeatureMutationResult = Apollo.MutationResult<Types.DeleteCompanyTypeFeatureMutation>;
export type DeleteCompanyTypeFeatureMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyTypeFeatureMutation, Types.DeleteCompanyTypeFeatureMutationVariables>;
export const CompanyTypePlanDocument = gql`
    query CompanyTypePlan($companyTypeId: Int!, $planId: Int) {
  companyTypePlan: company_type_plan(
    where: {company_type_id: {_eq: $companyTypeId}, plan_id: {_eq: $planId}}
    order_by: {plan: {price: asc}}
  ) {
    ...CompanyTypePlanFields
    plan {
      ...PlanFields
    }
  }
}
    ${CompanyTypePlanFieldsFragmentDoc}
${PlanFieldsFragmentDoc}`;

/**
 * __useCompanyTypePlanQuery__
 *
 * To run a query within a React component, call `useCompanyTypePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypePlanQuery({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCompanyTypePlanQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyTypePlanQuery, Types.CompanyTypePlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyTypePlanQuery, Types.CompanyTypePlanQueryVariables>(CompanyTypePlanDocument, options);
      }
export function useCompanyTypePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyTypePlanQuery, Types.CompanyTypePlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyTypePlanQuery, Types.CompanyTypePlanQueryVariables>(CompanyTypePlanDocument, options);
        }
export type CompanyTypePlanQueryHookResult = ReturnType<typeof useCompanyTypePlanQuery>;
export type CompanyTypePlanLazyQueryHookResult = ReturnType<typeof useCompanyTypePlanLazyQuery>;
export type CompanyTypePlanQueryResult = Apollo.QueryResult<Types.CompanyTypePlanQuery, Types.CompanyTypePlanQueryVariables>;
export const UpdateCompanyTypePlanDocument = gql`
    mutation UpdateCompanyTypePlan($companyTypeId: Int!, $changes: company_type_plan_set_input) {
  UpdateCompanyTypePlan: update_company_type_plan(
    where: {company_type_id: {_eq: $companyTypeId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyTypePlanMutationFn = Apollo.MutationFunction<Types.UpdateCompanyTypePlanMutation, Types.UpdateCompanyTypePlanMutationVariables>;

/**
 * __useUpdateCompanyTypePlanMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyTypePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyTypePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyTypePlanMutation, { data, loading, error }] = useUpdateCompanyTypePlanMutation({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyTypePlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyTypePlanMutation, Types.UpdateCompanyTypePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyTypePlanMutation, Types.UpdateCompanyTypePlanMutationVariables>(UpdateCompanyTypePlanDocument, options);
      }
export type UpdateCompanyTypePlanMutationHookResult = ReturnType<typeof useUpdateCompanyTypePlanMutation>;
export type UpdateCompanyTypePlanMutationResult = Apollo.MutationResult<Types.UpdateCompanyTypePlanMutation>;
export type UpdateCompanyTypePlanMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyTypePlanMutation, Types.UpdateCompanyTypePlanMutationVariables>;
export const InsertCompanyTypePlanDocument = gql`
    mutation InsertCompanyTypePlan($object: [company_type_plan_insert_input!]!) {
  insert_company_type_plan(objects: $object) {
    returning {
      companyTypeId: company_type_id
      planId: plan_id
    }
  }
}
    `;
export type InsertCompanyTypePlanMutationFn = Apollo.MutationFunction<Types.InsertCompanyTypePlanMutation, Types.InsertCompanyTypePlanMutationVariables>;

/**
 * __useInsertCompanyTypePlanMutation__
 *
 * To run a mutation, you first call `useInsertCompanyTypePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyTypePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyTypePlanMutation, { data, loading, error }] = useInsertCompanyTypePlanMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyTypePlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyTypePlanMutation, Types.InsertCompanyTypePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyTypePlanMutation, Types.InsertCompanyTypePlanMutationVariables>(InsertCompanyTypePlanDocument, options);
      }
export type InsertCompanyTypePlanMutationHookResult = ReturnType<typeof useInsertCompanyTypePlanMutation>;
export type InsertCompanyTypePlanMutationResult = Apollo.MutationResult<Types.InsertCompanyTypePlanMutation>;
export type InsertCompanyTypePlanMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyTypePlanMutation, Types.InsertCompanyTypePlanMutationVariables>;
export const DeleteCompanyTypePlanDocument = gql`
    mutation DeleteCompanyTypePlan($companyTypeId: Int!, $planId: Int) {
  delete_company_type_plan(
    where: {company_type_id: {_eq: $companyTypeId}, plan_id: {_eq: $planId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyTypePlanMutationFn = Apollo.MutationFunction<Types.DeleteCompanyTypePlanMutation, Types.DeleteCompanyTypePlanMutationVariables>;

/**
 * __useDeleteCompanyTypePlanMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyTypePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyTypePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyTypePlanMutation, { data, loading, error }] = useDeleteCompanyTypePlanMutation({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeleteCompanyTypePlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyTypePlanMutation, Types.DeleteCompanyTypePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyTypePlanMutation, Types.DeleteCompanyTypePlanMutationVariables>(DeleteCompanyTypePlanDocument, options);
      }
export type DeleteCompanyTypePlanMutationHookResult = ReturnType<typeof useDeleteCompanyTypePlanMutation>;
export type DeleteCompanyTypePlanMutationResult = Apollo.MutationResult<Types.DeleteCompanyTypePlanMutation>;
export type DeleteCompanyTypePlanMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyTypePlanMutation, Types.DeleteCompanyTypePlanMutationVariables>;
export const AllCompanyTypesDocument = gql`
    query AllCompanyTypes {
  companyTypes: company_type(order_by: {name: asc}) {
    ...CompanyTypeFields
  }
}
    ${CompanyTypeFieldsFragmentDoc}`;

/**
 * __useAllCompanyTypesQuery__
 *
 * To run a query within a React component, call `useAllCompanyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCompanyTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCompanyTypesQuery, Types.AllCompanyTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllCompanyTypesQuery, Types.AllCompanyTypesQueryVariables>(AllCompanyTypesDocument, options);
      }
export function useAllCompanyTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCompanyTypesQuery, Types.AllCompanyTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllCompanyTypesQuery, Types.AllCompanyTypesQueryVariables>(AllCompanyTypesDocument, options);
        }
export type AllCompanyTypesQueryHookResult = ReturnType<typeof useAllCompanyTypesQuery>;
export type AllCompanyTypesLazyQueryHookResult = ReturnType<typeof useAllCompanyTypesLazyQuery>;
export type AllCompanyTypesQueryResult = Apollo.QueryResult<Types.AllCompanyTypesQuery, Types.AllCompanyTypesQueryVariables>;
export const CompanyTypeDocument = gql`
    query CompanyType($id: Int!) {
  companyType: company_type(where: {id: {_eq: $id}}) {
    ...CompanyTypeFields
  }
}
    ${CompanyTypeFieldsFragmentDoc}`;

/**
 * __useCompanyTypeQuery__
 *
 * To run a query within a React component, call `useCompanyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyTypeQuery, Types.CompanyTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyTypeQuery, Types.CompanyTypeQueryVariables>(CompanyTypeDocument, options);
      }
export function useCompanyTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyTypeQuery, Types.CompanyTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyTypeQuery, Types.CompanyTypeQueryVariables>(CompanyTypeDocument, options);
        }
export type CompanyTypeQueryHookResult = ReturnType<typeof useCompanyTypeQuery>;
export type CompanyTypeLazyQueryHookResult = ReturnType<typeof useCompanyTypeLazyQuery>;
export type CompanyTypeQueryResult = Apollo.QueryResult<Types.CompanyTypeQuery, Types.CompanyTypeQueryVariables>;
export const UpdateCompanyTypeDocument = gql`
    mutation UpdateCompanyType($id: Int!, $changes: company_type_set_input) {
  UpdateCompanyType: update_company_type(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateCompanyTypeMutationFn = Apollo.MutationFunction<Types.UpdateCompanyTypeMutation, Types.UpdateCompanyTypeMutationVariables>;

/**
 * __useUpdateCompanyTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyTypeMutation, { data, loading, error }] = useUpdateCompanyTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyTypeMutation, Types.UpdateCompanyTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyTypeMutation, Types.UpdateCompanyTypeMutationVariables>(UpdateCompanyTypeDocument, options);
      }
export type UpdateCompanyTypeMutationHookResult = ReturnType<typeof useUpdateCompanyTypeMutation>;
export type UpdateCompanyTypeMutationResult = Apollo.MutationResult<Types.UpdateCompanyTypeMutation>;
export type UpdateCompanyTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyTypeMutation, Types.UpdateCompanyTypeMutationVariables>;
export const InsertCompanyTypeDocument = gql`
    mutation InsertCompanyType($object: company_type_insert_input!) {
  insert_company_type(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertCompanyTypeMutationFn = Apollo.MutationFunction<Types.InsertCompanyTypeMutation, Types.InsertCompanyTypeMutationVariables>;

/**
 * __useInsertCompanyTypeMutation__
 *
 * To run a mutation, you first call `useInsertCompanyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyTypeMutation, { data, loading, error }] = useInsertCompanyTypeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyTypeMutation, Types.InsertCompanyTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyTypeMutation, Types.InsertCompanyTypeMutationVariables>(InsertCompanyTypeDocument, options);
      }
export type InsertCompanyTypeMutationHookResult = ReturnType<typeof useInsertCompanyTypeMutation>;
export type InsertCompanyTypeMutationResult = Apollo.MutationResult<Types.InsertCompanyTypeMutation>;
export type InsertCompanyTypeMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyTypeMutation, Types.InsertCompanyTypeMutationVariables>;
export const DeleteCompanyTypeDocument = gql`
    mutation DeleteCompanyType($id: Int!) {
  delete_company_type(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCompanyTypeMutationFn = Apollo.MutationFunction<Types.DeleteCompanyTypeMutation, Types.DeleteCompanyTypeMutationVariables>;

/**
 * __useDeleteCompanyTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyTypeMutation, { data, loading, error }] = useDeleteCompanyTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyTypeMutation, Types.DeleteCompanyTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyTypeMutation, Types.DeleteCompanyTypeMutationVariables>(DeleteCompanyTypeDocument, options);
      }
export type DeleteCompanyTypeMutationHookResult = ReturnType<typeof useDeleteCompanyTypeMutation>;
export type DeleteCompanyTypeMutationResult = Apollo.MutationResult<Types.DeleteCompanyTypeMutation>;
export type DeleteCompanyTypeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyTypeMutation, Types.DeleteCompanyTypeMutationVariables>;
export const CompanyTypeRoleDocument = gql`
    query CompanyTypeRole($companyTypeId: Int) {
  companyTypeRole: company_type_role(
    where: {company_type_id: {_eq: $companyTypeId}}
  ) {
    ...CompanyTypeRoleFields
  }
}
    ${CompanyTypeRoleFieldsFragmentDoc}`;

/**
 * __useCompanyTypeRoleQuery__
 *
 * To run a query within a React component, call `useCompanyTypeRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypeRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypeRoleQuery({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *   },
 * });
 */
export function useCompanyTypeRoleQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyTypeRoleQuery, Types.CompanyTypeRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyTypeRoleQuery, Types.CompanyTypeRoleQueryVariables>(CompanyTypeRoleDocument, options);
      }
export function useCompanyTypeRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyTypeRoleQuery, Types.CompanyTypeRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyTypeRoleQuery, Types.CompanyTypeRoleQueryVariables>(CompanyTypeRoleDocument, options);
        }
export type CompanyTypeRoleQueryHookResult = ReturnType<typeof useCompanyTypeRoleQuery>;
export type CompanyTypeRoleLazyQueryHookResult = ReturnType<typeof useCompanyTypeRoleLazyQuery>;
export type CompanyTypeRoleQueryResult = Apollo.QueryResult<Types.CompanyTypeRoleQuery, Types.CompanyTypeRoleQueryVariables>;
export const CompanyTypeOwnerRoleDocument = gql`
    query CompanyTypeOwnerRole($companyTypeId: Int) {
  companyTypeRole: company_type_role(
    where: {company_type_id: {_eq: $companyTypeId}, is_owner: {_eq: true}}
  ) {
    ...CompanyTypeRoleFields
  }
}
    ${CompanyTypeRoleFieldsFragmentDoc}`;

/**
 * __useCompanyTypeOwnerRoleQuery__
 *
 * To run a query within a React component, call `useCompanyTypeOwnerRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypeOwnerRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypeOwnerRoleQuery({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *   },
 * });
 */
export function useCompanyTypeOwnerRoleQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyTypeOwnerRoleQuery, Types.CompanyTypeOwnerRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyTypeOwnerRoleQuery, Types.CompanyTypeOwnerRoleQueryVariables>(CompanyTypeOwnerRoleDocument, options);
      }
export function useCompanyTypeOwnerRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyTypeOwnerRoleQuery, Types.CompanyTypeOwnerRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyTypeOwnerRoleQuery, Types.CompanyTypeOwnerRoleQueryVariables>(CompanyTypeOwnerRoleDocument, options);
        }
export type CompanyTypeOwnerRoleQueryHookResult = ReturnType<typeof useCompanyTypeOwnerRoleQuery>;
export type CompanyTypeOwnerRoleLazyQueryHookResult = ReturnType<typeof useCompanyTypeOwnerRoleLazyQuery>;
export type CompanyTypeOwnerRoleQueryResult = Apollo.QueryResult<Types.CompanyTypeOwnerRoleQuery, Types.CompanyTypeOwnerRoleQueryVariables>;
export const UpdateCompanyTypeRoleDocument = gql`
    mutation UpdateCompanyTypeRole($companyTypeId: Int!, $changes: company_type_role_set_input) {
  UpdateCompanyTypeRole: update_company_type_role(
    where: {company_type_id: {_eq: $companyTypeId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateCompanyTypeRoleMutationFn = Apollo.MutationFunction<Types.UpdateCompanyTypeRoleMutation, Types.UpdateCompanyTypeRoleMutationVariables>;

/**
 * __useUpdateCompanyTypeRoleMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyTypeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyTypeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyTypeRoleMutation, { data, loading, error }] = useUpdateCompanyTypeRoleMutation({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCompanyTypeRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyTypeRoleMutation, Types.UpdateCompanyTypeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyTypeRoleMutation, Types.UpdateCompanyTypeRoleMutationVariables>(UpdateCompanyTypeRoleDocument, options);
      }
export type UpdateCompanyTypeRoleMutationHookResult = ReturnType<typeof useUpdateCompanyTypeRoleMutation>;
export type UpdateCompanyTypeRoleMutationResult = Apollo.MutationResult<Types.UpdateCompanyTypeRoleMutation>;
export type UpdateCompanyTypeRoleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyTypeRoleMutation, Types.UpdateCompanyTypeRoleMutationVariables>;
export const InsertCompanyTypeRoleDocument = gql`
    mutation InsertCompanyTypeRole($object: company_type_role_insert_input!) {
  insert_company_type_role(objects: [$object]) {
    returning {
      companyTypeId: company_type_id
      roleId: role_id
    }
  }
}
    `;
export type InsertCompanyTypeRoleMutationFn = Apollo.MutationFunction<Types.InsertCompanyTypeRoleMutation, Types.InsertCompanyTypeRoleMutationVariables>;

/**
 * __useInsertCompanyTypeRoleMutation__
 *
 * To run a mutation, you first call `useInsertCompanyTypeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyTypeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyTypeRoleMutation, { data, loading, error }] = useInsertCompanyTypeRoleMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyTypeRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyTypeRoleMutation, Types.InsertCompanyTypeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyTypeRoleMutation, Types.InsertCompanyTypeRoleMutationVariables>(InsertCompanyTypeRoleDocument, options);
      }
export type InsertCompanyTypeRoleMutationHookResult = ReturnType<typeof useInsertCompanyTypeRoleMutation>;
export type InsertCompanyTypeRoleMutationResult = Apollo.MutationResult<Types.InsertCompanyTypeRoleMutation>;
export type InsertCompanyTypeRoleMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyTypeRoleMutation, Types.InsertCompanyTypeRoleMutationVariables>;
export const InsertCompanyTypeRolesDocument = gql`
    mutation InsertCompanyTypeRoles($object: [company_type_role_insert_input!]!) {
  insert_company_type_role(objects: $object) {
    returning {
      companyTypeId: company_type_id
      roleId: role_id
    }
  }
}
    `;
export type InsertCompanyTypeRolesMutationFn = Apollo.MutationFunction<Types.InsertCompanyTypeRolesMutation, Types.InsertCompanyTypeRolesMutationVariables>;

/**
 * __useInsertCompanyTypeRolesMutation__
 *
 * To run a mutation, you first call `useInsertCompanyTypeRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompanyTypeRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompanyTypeRolesMutation, { data, loading, error }] = useInsertCompanyTypeRolesMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompanyTypeRolesMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCompanyTypeRolesMutation, Types.InsertCompanyTypeRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCompanyTypeRolesMutation, Types.InsertCompanyTypeRolesMutationVariables>(InsertCompanyTypeRolesDocument, options);
      }
export type InsertCompanyTypeRolesMutationHookResult = ReturnType<typeof useInsertCompanyTypeRolesMutation>;
export type InsertCompanyTypeRolesMutationResult = Apollo.MutationResult<Types.InsertCompanyTypeRolesMutation>;
export type InsertCompanyTypeRolesMutationOptions = Apollo.BaseMutationOptions<Types.InsertCompanyTypeRolesMutation, Types.InsertCompanyTypeRolesMutationVariables>;
export const DeleteCompanyTypeRoleDocument = gql`
    mutation DeleteCompanyTypeRole($companyTypeId: Int!, $roleId: Int) {
  delete_company_type_role(
    where: {company_type_id: {_eq: $companyTypeId}, role_id: {_eq: $roleId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCompanyTypeRoleMutationFn = Apollo.MutationFunction<Types.DeleteCompanyTypeRoleMutation, Types.DeleteCompanyTypeRoleMutationVariables>;

/**
 * __useDeleteCompanyTypeRoleMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyTypeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyTypeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyTypeRoleMutation, { data, loading, error }] = useDeleteCompanyTypeRoleMutation({
 *   variables: {
 *      companyTypeId: // value for 'companyTypeId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteCompanyTypeRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyTypeRoleMutation, Types.DeleteCompanyTypeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyTypeRoleMutation, Types.DeleteCompanyTypeRoleMutationVariables>(DeleteCompanyTypeRoleDocument, options);
      }
export type DeleteCompanyTypeRoleMutationHookResult = ReturnType<typeof useDeleteCompanyTypeRoleMutation>;
export type DeleteCompanyTypeRoleMutationResult = Apollo.MutationResult<Types.DeleteCompanyTypeRoleMutation>;
export type DeleteCompanyTypeRoleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyTypeRoleMutation, Types.DeleteCompanyTypeRoleMutationVariables>;
export const AllContactListContactsDocument = gql`
    query AllContactListContacts {
  contactListContacts: contact_list_contact {
    ...ContactListContactFields
  }
}
    ${ContactListContactFieldsFragmentDoc}`;

/**
 * __useAllContactListContactsQuery__
 *
 * To run a query within a React component, call `useAllContactListContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContactListContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContactListContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllContactListContactsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllContactListContactsQuery, Types.AllContactListContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllContactListContactsQuery, Types.AllContactListContactsQueryVariables>(AllContactListContactsDocument, options);
      }
export function useAllContactListContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllContactListContactsQuery, Types.AllContactListContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllContactListContactsQuery, Types.AllContactListContactsQueryVariables>(AllContactListContactsDocument, options);
        }
export type AllContactListContactsQueryHookResult = ReturnType<typeof useAllContactListContactsQuery>;
export type AllContactListContactsLazyQueryHookResult = ReturnType<typeof useAllContactListContactsLazyQuery>;
export type AllContactListContactsQueryResult = Apollo.QueryResult<Types.AllContactListContactsQuery, Types.AllContactListContactsQueryVariables>;
export const ContactListContactsByFilterDocument = gql`
    query ContactListContactsByFilter($filter: contact_list_contact_bool_exp, $limit: Int, $offset: Int) {
  contactListContacts: contact_list_contact(
    where: $filter
    limit: $limit
    offset: $offset
  ) {
    ...ContactListContactFields
  }
  contact_list_contact_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ContactListContactFieldsFragmentDoc}`;

/**
 * __useContactListContactsByFilterQuery__
 *
 * To run a query within a React component, call `useContactListContactsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListContactsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListContactsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactListContactsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.ContactListContactsByFilterQuery, Types.ContactListContactsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactListContactsByFilterQuery, Types.ContactListContactsByFilterQueryVariables>(ContactListContactsByFilterDocument, options);
      }
export function useContactListContactsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactListContactsByFilterQuery, Types.ContactListContactsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactListContactsByFilterQuery, Types.ContactListContactsByFilterQueryVariables>(ContactListContactsByFilterDocument, options);
        }
export type ContactListContactsByFilterQueryHookResult = ReturnType<typeof useContactListContactsByFilterQuery>;
export type ContactListContactsByFilterLazyQueryHookResult = ReturnType<typeof useContactListContactsByFilterLazyQuery>;
export type ContactListContactsByFilterQueryResult = Apollo.QueryResult<Types.ContactListContactsByFilterQuery, Types.ContactListContactsByFilterQueryVariables>;
export const InsertContactListContactDocument = gql`
    mutation InsertContactListContact($object: contact_list_contact_insert_input!) {
  insertContactListContact: insert_contact_list_contact(objects: [$object]) {
    returning {
      ...ContactListContactFields
    }
  }
}
    ${ContactListContactFieldsFragmentDoc}`;
export type InsertContactListContactMutationFn = Apollo.MutationFunction<Types.InsertContactListContactMutation, Types.InsertContactListContactMutationVariables>;

/**
 * __useInsertContactListContactMutation__
 *
 * To run a mutation, you first call `useInsertContactListContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactListContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactListContactMutation, { data, loading, error }] = useInsertContactListContactMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertContactListContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertContactListContactMutation, Types.InsertContactListContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertContactListContactMutation, Types.InsertContactListContactMutationVariables>(InsertContactListContactDocument, options);
      }
export type InsertContactListContactMutationHookResult = ReturnType<typeof useInsertContactListContactMutation>;
export type InsertContactListContactMutationResult = Apollo.MutationResult<Types.InsertContactListContactMutation>;
export type InsertContactListContactMutationOptions = Apollo.BaseMutationOptions<Types.InsertContactListContactMutation, Types.InsertContactListContactMutationVariables>;
export const UpdateContactListContactDocument = gql`
    mutation UpdateContactListContact($contactListId: Int!, $contactId: Int!, $changes: contact_list_contact_set_input) {
  updateContactListContact: update_contact_list_contact(
    where: {contact_list_id: {_eq: $contactListId}, contact_id: {_eq: $contactId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateContactListContactMutationFn = Apollo.MutationFunction<Types.UpdateContactListContactMutation, Types.UpdateContactListContactMutationVariables>;

/**
 * __useUpdateContactListContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactListContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactListContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactListContactMutation, { data, loading, error }] = useUpdateContactListContactMutation({
 *   variables: {
 *      contactListId: // value for 'contactListId'
 *      contactId: // value for 'contactId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateContactListContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactListContactMutation, Types.UpdateContactListContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContactListContactMutation, Types.UpdateContactListContactMutationVariables>(UpdateContactListContactDocument, options);
      }
export type UpdateContactListContactMutationHookResult = ReturnType<typeof useUpdateContactListContactMutation>;
export type UpdateContactListContactMutationResult = Apollo.MutationResult<Types.UpdateContactListContactMutation>;
export type UpdateContactListContactMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactListContactMutation, Types.UpdateContactListContactMutationVariables>;
export const DeleteContactListContactDocument = gql`
    mutation DeleteContactListContact($contactListId: Int!, $contactId: Int!) {
  deleteContactListContact: delete_contact_list_contact(
    where: {contact_list_id: {_eq: $contactListId}, contact_id: {_eq: $contactId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteContactListContactMutationFn = Apollo.MutationFunction<Types.DeleteContactListContactMutation, Types.DeleteContactListContactMutationVariables>;

/**
 * __useDeleteContactListContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactListContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactListContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactListContactMutation, { data, loading, error }] = useDeleteContactListContactMutation({
 *   variables: {
 *      contactListId: // value for 'contactListId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteContactListContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteContactListContactMutation, Types.DeleteContactListContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteContactListContactMutation, Types.DeleteContactListContactMutationVariables>(DeleteContactListContactDocument, options);
      }
export type DeleteContactListContactMutationHookResult = ReturnType<typeof useDeleteContactListContactMutation>;
export type DeleteContactListContactMutationResult = Apollo.MutationResult<Types.DeleteContactListContactMutation>;
export type DeleteContactListContactMutationOptions = Apollo.BaseMutationOptions<Types.DeleteContactListContactMutation, Types.DeleteContactListContactMutationVariables>;
export const AllContactListsDocument = gql`
    query AllContactLists($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  contactLists: contact_list(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {id: asc}
  ) {
    ...ContactListFields
  }
}
    ${ContactListFieldsFragmentDoc}`;

/**
 * __useAllContactListsQuery__
 *
 * To run a query within a React component, call `useAllContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContactListsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllContactListsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllContactListsQuery, Types.AllContactListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllContactListsQuery, Types.AllContactListsQueryVariables>(AllContactListsDocument, options);
      }
export function useAllContactListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllContactListsQuery, Types.AllContactListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllContactListsQuery, Types.AllContactListsQueryVariables>(AllContactListsDocument, options);
        }
export type AllContactListsQueryHookResult = ReturnType<typeof useAllContactListsQuery>;
export type AllContactListsLazyQueryHookResult = ReturnType<typeof useAllContactListsLazyQuery>;
export type AllContactListsQueryResult = Apollo.QueryResult<Types.AllContactListsQuery, Types.AllContactListsQueryVariables>;
export const ContactListsByFilterDocument = gql`
    query ContactListsByFilter($filter: contact_list_bool_exp, $limit: Int, $offset: Int) {
  contactLists: contact_list(
    where: $filter
    limit: $limit
    offset: $offset
    order_by: {id: asc}
  ) {
    ...ContactListFields
    contact_list_contacts_aggregate {
      aggregate {
        count
      }
    }
  }
  contact_list_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ContactListFieldsFragmentDoc}`;

/**
 * __useContactListsByFilterQuery__
 *
 * To run a query within a React component, call `useContactListsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactListsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.ContactListsByFilterQuery, Types.ContactListsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactListsByFilterQuery, Types.ContactListsByFilterQueryVariables>(ContactListsByFilterDocument, options);
      }
export function useContactListsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactListsByFilterQuery, Types.ContactListsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactListsByFilterQuery, Types.ContactListsByFilterQueryVariables>(ContactListsByFilterDocument, options);
        }
export type ContactListsByFilterQueryHookResult = ReturnType<typeof useContactListsByFilterQuery>;
export type ContactListsByFilterLazyQueryHookResult = ReturnType<typeof useContactListsByFilterLazyQuery>;
export type ContactListsByFilterQueryResult = Apollo.QueryResult<Types.ContactListsByFilterQuery, Types.ContactListsByFilterQueryVariables>;
export const ContactListsByFilterSubscriptionDocument = gql`
    subscription ContactListsByFilterSubscription($filter: contact_list_bool_exp, $limit: Int, $offset: Int) {
  contactLists: contact_list(
    where: $filter
    limit: $limit
    offset: $offset
    order_by: {id: asc}
  ) {
    ...ContactListFields
    contact_list_contacts_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ContactListFieldsFragmentDoc}`;

/**
 * __useContactListsByFilterSubscription__
 *
 * To run a query within a React component, call `useContactListsByFilterSubscription` and pass it any options that fit your needs.
 * When your component renders, `useContactListsByFilterSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListsByFilterSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactListsByFilterSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.ContactListsByFilterSubscription, Types.ContactListsByFilterSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ContactListsByFilterSubscription, Types.ContactListsByFilterSubscriptionVariables>(ContactListsByFilterSubscriptionDocument, options);
      }
export type ContactListsByFilterSubscriptionHookResult = ReturnType<typeof useContactListsByFilterSubscription>;
export type ContactListsByFilterSubscriptionSubscriptionResult = Apollo.SubscriptionResult<Types.ContactListsByFilterSubscription>;
export const ContactListByIdDocument = gql`
    query ContactListById($id: Int!) {
  contactList: contact_list_by_pk(id: $id) {
    ...ContactListFields
  }
  campaign_responder_contact_list_aggregate(where: {contact_list_id: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}
    ${ContactListFieldsFragmentDoc}`;

/**
 * __useContactListByIdQuery__
 *
 * To run a query within a React component, call `useContactListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactListByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ContactListByIdQuery, Types.ContactListByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactListByIdQuery, Types.ContactListByIdQueryVariables>(ContactListByIdDocument, options);
      }
export function useContactListByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactListByIdQuery, Types.ContactListByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactListByIdQuery, Types.ContactListByIdQueryVariables>(ContactListByIdDocument, options);
        }
export type ContactListByIdQueryHookResult = ReturnType<typeof useContactListByIdQuery>;
export type ContactListByIdLazyQueryHookResult = ReturnType<typeof useContactListByIdLazyQuery>;
export type ContactListByIdQueryResult = Apollo.QueryResult<Types.ContactListByIdQuery, Types.ContactListByIdQueryVariables>;
export const ContactListByIdSubscriptionDocument = gql`
    subscription ContactListByIdSubscription($id: Int!) {
  contactList: contact_list_by_pk(id: $id) {
    ...ContactListFields
  }
}
    ${ContactListFieldsFragmentDoc}`;

/**
 * __useContactListByIdSubscription__
 *
 * To run a query within a React component, call `useContactListByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useContactListByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactListByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ContactListByIdSubscription, Types.ContactListByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ContactListByIdSubscription, Types.ContactListByIdSubscriptionVariables>(ContactListByIdSubscriptionDocument, options);
      }
export type ContactListByIdSubscriptionHookResult = ReturnType<typeof useContactListByIdSubscription>;
export type ContactListByIdSubscriptionSubscriptionResult = Apollo.SubscriptionResult<Types.ContactListByIdSubscription>;
export const InsertContactListDocument = gql`
    mutation InsertContactList($contactList: contact_list_insert_input!) {
  insert_contact_list(objects: [$contactList]) {
    returning {
      id
    }
  }
}
    `;
export type InsertContactListMutationFn = Apollo.MutationFunction<Types.InsertContactListMutation, Types.InsertContactListMutationVariables>;

/**
 * __useInsertContactListMutation__
 *
 * To run a mutation, you first call `useInsertContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactListMutation, { data, loading, error }] = useInsertContactListMutation({
 *   variables: {
 *      contactList: // value for 'contactList'
 *   },
 * });
 */
export function useInsertContactListMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertContactListMutation, Types.InsertContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertContactListMutation, Types.InsertContactListMutationVariables>(InsertContactListDocument, options);
      }
export type InsertContactListMutationHookResult = ReturnType<typeof useInsertContactListMutation>;
export type InsertContactListMutationResult = Apollo.MutationResult<Types.InsertContactListMutation>;
export type InsertContactListMutationOptions = Apollo.BaseMutationOptions<Types.InsertContactListMutation, Types.InsertContactListMutationVariables>;
export const UpdateContactListDocument = gql`
    mutation UpdateContactList($id: Int!, $changes: contact_list_set_input) {
  updateContactList: update_contact_list(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateContactListMutationFn = Apollo.MutationFunction<Types.UpdateContactListMutation, Types.UpdateContactListMutationVariables>;

/**
 * __useUpdateContactListMutation__
 *
 * To run a mutation, you first call `useUpdateContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactListMutation, { data, loading, error }] = useUpdateContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateContactListMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactListMutation, Types.UpdateContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContactListMutation, Types.UpdateContactListMutationVariables>(UpdateContactListDocument, options);
      }
export type UpdateContactListMutationHookResult = ReturnType<typeof useUpdateContactListMutation>;
export type UpdateContactListMutationResult = Apollo.MutationResult<Types.UpdateContactListMutation>;
export type UpdateContactListMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactListMutation, Types.UpdateContactListMutationVariables>;
export const UpdateContactListIncrementCountDocument = gql`
    mutation UpdateContactListIncrementCount($id: Int!, $increment: contact_list_inc_input!) {
  updateContactList: update_contact_list(
    where: {id: {_eq: $id}}
    _inc: $increment
  ) {
    affected_rows
    returning {
      id
      totalContactCount: total_contact_count
      importedContactCount: imported_contact_count
      duplicateContactCount: duplicate_contact_count
      suppressedContactCount: suppressed_contact_count
      malformedContactCount: malformed_contact_count
      completedContactCount: completed_contact_count
    }
  }
}
    `;
export type UpdateContactListIncrementCountMutationFn = Apollo.MutationFunction<Types.UpdateContactListIncrementCountMutation, Types.UpdateContactListIncrementCountMutationVariables>;

/**
 * __useUpdateContactListIncrementCountMutation__
 *
 * To run a mutation, you first call `useUpdateContactListIncrementCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactListIncrementCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactListIncrementCountMutation, { data, loading, error }] = useUpdateContactListIncrementCountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      increment: // value for 'increment'
 *   },
 * });
 */
export function useUpdateContactListIncrementCountMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactListIncrementCountMutation, Types.UpdateContactListIncrementCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContactListIncrementCountMutation, Types.UpdateContactListIncrementCountMutationVariables>(UpdateContactListIncrementCountDocument, options);
      }
export type UpdateContactListIncrementCountMutationHookResult = ReturnType<typeof useUpdateContactListIncrementCountMutation>;
export type UpdateContactListIncrementCountMutationResult = Apollo.MutationResult<Types.UpdateContactListIncrementCountMutation>;
export type UpdateContactListIncrementCountMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactListIncrementCountMutation, Types.UpdateContactListIncrementCountMutationVariables>;
export const UpdateContactListSuppressedCountDocument = gql`
    mutation UpdateContactListSuppressedCount($id: Int!) {
  updateContactList: update_contact_list(
    where: {id: {_eq: $id}}
    _inc: {suppressed_contact_count: 1}
  ) {
    affected_rows
    returning {
      id
      suppressedContactCount: suppressed_contact_count
    }
  }
}
    `;
export type UpdateContactListSuppressedCountMutationFn = Apollo.MutationFunction<Types.UpdateContactListSuppressedCountMutation, Types.UpdateContactListSuppressedCountMutationVariables>;

/**
 * __useUpdateContactListSuppressedCountMutation__
 *
 * To run a mutation, you first call `useUpdateContactListSuppressedCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactListSuppressedCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactListSuppressedCountMutation, { data, loading, error }] = useUpdateContactListSuppressedCountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateContactListSuppressedCountMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactListSuppressedCountMutation, Types.UpdateContactListSuppressedCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContactListSuppressedCountMutation, Types.UpdateContactListSuppressedCountMutationVariables>(UpdateContactListSuppressedCountDocument, options);
      }
export type UpdateContactListSuppressedCountMutationHookResult = ReturnType<typeof useUpdateContactListSuppressedCountMutation>;
export type UpdateContactListSuppressedCountMutationResult = Apollo.MutationResult<Types.UpdateContactListSuppressedCountMutation>;
export type UpdateContactListSuppressedCountMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactListSuppressedCountMutation, Types.UpdateContactListSuppressedCountMutationVariables>;
export const DeleteContactListDocument = gql`
    mutation DeleteContactList($id: Int!) {
  deleteContactList: delete_contact_list(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteContactListMutationFn = Apollo.MutationFunction<Types.DeleteContactListMutation, Types.DeleteContactListMutationVariables>;

/**
 * __useDeleteContactListMutation__
 *
 * To run a mutation, you first call `useDeleteContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactListMutation, { data, loading, error }] = useDeleteContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactListMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteContactListMutation, Types.DeleteContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteContactListMutation, Types.DeleteContactListMutationVariables>(DeleteContactListDocument, options);
      }
export type DeleteContactListMutationHookResult = ReturnType<typeof useDeleteContactListMutation>;
export type DeleteContactListMutationResult = Apollo.MutationResult<Types.DeleteContactListMutation>;
export type DeleteContactListMutationOptions = Apollo.BaseMutationOptions<Types.DeleteContactListMutation, Types.DeleteContactListMutationVariables>;
export const AllContactsDocument = gql`
    query AllContacts {
  contacts: contact(order_by: {id: desc}) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useAllContactsQuery__
 *
 * To run a query within a React component, call `useAllContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllContactsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllContactsQuery, Types.AllContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllContactsQuery, Types.AllContactsQueryVariables>(AllContactsDocument, options);
      }
export function useAllContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllContactsQuery, Types.AllContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllContactsQuery, Types.AllContactsQueryVariables>(AllContactsDocument, options);
        }
export type AllContactsQueryHookResult = ReturnType<typeof useAllContactsQuery>;
export type AllContactsLazyQueryHookResult = ReturnType<typeof useAllContactsLazyQuery>;
export type AllContactsQueryResult = Apollo.QueryResult<Types.AllContactsQuery, Types.AllContactsQueryVariables>;
export const ContactDocument = gql`
    query Contact($id: Int!) {
  contact: contact_by_pk(id: $id) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactQuery(baseOptions: Apollo.QueryHookOptions<Types.ContactQuery, Types.ContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactQuery, Types.ContactQueryVariables>(ContactDocument, options);
      }
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactQuery, Types.ContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactQuery, Types.ContactQueryVariables>(ContactDocument, options);
        }
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<Types.ContactQuery, Types.ContactQueryVariables>;
export const ContactsByFilterDocument = gql`
    query ContactsByFilter($filter: contact_bool_exp, $limit: Int, $offset: Int) {
  contacts: contact(
    where: $filter
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    ...ContactSimpleFields
  }
  contact_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ContactSimpleFieldsFragmentDoc}`;

/**
 * __useContactsByFilterQuery__
 *
 * To run a query within a React component, call `useContactsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.ContactsByFilterQuery, Types.ContactsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactsByFilterQuery, Types.ContactsByFilterQueryVariables>(ContactsByFilterDocument, options);
      }
export function useContactsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactsByFilterQuery, Types.ContactsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactsByFilterQuery, Types.ContactsByFilterQueryVariables>(ContactsByFilterDocument, options);
        }
export type ContactsByFilterQueryHookResult = ReturnType<typeof useContactsByFilterQuery>;
export type ContactsByFilterLazyQueryHookResult = ReturnType<typeof useContactsByFilterLazyQuery>;
export type ContactsByFilterQueryResult = Apollo.QueryResult<Types.ContactsByFilterQuery, Types.ContactsByFilterQueryVariables>;
export const AllContactsByStatusDocument = gql`
    query AllContactsByStatus($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $createdByRoleUserId: Int, $isBlocked: Boolean, $limit: Int) {
  contacts: contact(
    limit: $limit
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, created_by_role_user_id: {_eq: $createdByRoleUserId}, blocked: {_eq: $isBlocked}, platform_id: {_neq: 11}}
    order_by: {created_at: desc}
  ) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useAllContactsByStatusQuery__
 *
 * To run a query within a React component, call `useAllContactsByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContactsByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContactsByStatusQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      createdByRoleUserId: // value for 'createdByRoleUserId'
 *      isBlocked: // value for 'isBlocked'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllContactsByStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllContactsByStatusQuery, Types.AllContactsByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllContactsByStatusQuery, Types.AllContactsByStatusQueryVariables>(AllContactsByStatusDocument, options);
      }
export function useAllContactsByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllContactsByStatusQuery, Types.AllContactsByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllContactsByStatusQuery, Types.AllContactsByStatusQueryVariables>(AllContactsByStatusDocument, options);
        }
export type AllContactsByStatusQueryHookResult = ReturnType<typeof useAllContactsByStatusQuery>;
export type AllContactsByStatusLazyQueryHookResult = ReturnType<typeof useAllContactsByStatusLazyQuery>;
export type AllContactsByStatusQueryResult = Apollo.QueryResult<Types.AllContactsByStatusQuery, Types.AllContactsByStatusQueryVariables>;
export const AllContactsByStatusCardsDocument = gql`
    query AllContactsByStatusCards($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $createdByRoleUserId: Int, $isBlocked: Boolean, $limit: Int, $offset: Int) {
  contacts: contact(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, created_by_role_user_id: {_eq: $createdByRoleUserId}, blocked: {_eq: $isBlocked}, platform_id: {_neq: 11}}
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
  ) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useAllContactsByStatusCardsQuery__
 *
 * To run a query within a React component, call `useAllContactsByStatusCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContactsByStatusCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContactsByStatusCardsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      createdByRoleUserId: // value for 'createdByRoleUserId'
 *      isBlocked: // value for 'isBlocked'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllContactsByStatusCardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllContactsByStatusCardsQuery, Types.AllContactsByStatusCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllContactsByStatusCardsQuery, Types.AllContactsByStatusCardsQueryVariables>(AllContactsByStatusCardsDocument, options);
      }
export function useAllContactsByStatusCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllContactsByStatusCardsQuery, Types.AllContactsByStatusCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllContactsByStatusCardsQuery, Types.AllContactsByStatusCardsQueryVariables>(AllContactsByStatusCardsDocument, options);
        }
export type AllContactsByStatusCardsQueryHookResult = ReturnType<typeof useAllContactsByStatusCardsQuery>;
export type AllContactsByStatusCardsLazyQueryHookResult = ReturnType<typeof useAllContactsByStatusCardsLazyQuery>;
export type AllContactsByStatusCardsQueryResult = Apollo.QueryResult<Types.AllContactsByStatusCardsQuery, Types.AllContactsByStatusCardsQueryVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($id: Int!, $changes: contact_set_input) {
  updateContact: update_contact(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<Types.UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>;
export const InsertContactDocument = gql`
    mutation InsertContact($contact: contact_insert_input!) {
  insert_contact(objects: [$contact]) {
    returning {
      id
    }
  }
}
    `;
export type InsertContactMutationFn = Apollo.MutationFunction<Types.InsertContactMutation, Types.InsertContactMutationVariables>;

/**
 * __useInsertContactMutation__
 *
 * To run a mutation, you first call `useInsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactMutation, { data, loading, error }] = useInsertContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useInsertContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertContactMutation, Types.InsertContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertContactMutation, Types.InsertContactMutationVariables>(InsertContactDocument, options);
      }
export type InsertContactMutationHookResult = ReturnType<typeof useInsertContactMutation>;
export type InsertContactMutationResult = Apollo.MutationResult<Types.InsertContactMutation>;
export type InsertContactMutationOptions = Apollo.BaseMutationOptions<Types.InsertContactMutation, Types.InsertContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: Int!) {
  delete_contact(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<Types.DeleteContactMutation, Types.DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteContactMutation, Types.DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteContactMutation, Types.DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<Types.DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<Types.DeleteContactMutation, Types.DeleteContactMutationVariables>;
export const AllAdminConversationsSubscriptionsDocument = gql`
    subscription AllAdminConversationsSubscriptions($readStatus: Boolean, $liveStatus: Boolean, $isBlocked: Boolean, $limit: Int, $offset: Int) {
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: {platform_id: {_neq: 11}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, contact: {blocked: {_eq: $isBlocked}}}
    order_by: {last_message_received_timestamp: desc}
  ) {
    ...ConversationTableFields
  }
}
    ${ConversationTableFieldsFragmentDoc}`;

/**
 * __useAllAdminConversationsSubscriptions__
 *
 * To run a query within a React component, call `useAllAdminConversationsSubscriptions` and pass it any options that fit your needs.
 * When your component renders, `useAllAdminConversationsSubscriptions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAdminConversationsSubscriptions({
 *   variables: {
 *      readStatus: // value for 'readStatus'
 *      liveStatus: // value for 'liveStatus'
 *      isBlocked: // value for 'isBlocked'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllAdminConversationsSubscriptions(baseOptions?: Apollo.SubscriptionHookOptions<Types.AllAdminConversationsSubscriptionsSubscription, Types.AllAdminConversationsSubscriptionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.AllAdminConversationsSubscriptionsSubscription, Types.AllAdminConversationsSubscriptionsSubscriptionVariables>(AllAdminConversationsSubscriptionsDocument, options);
      }
export type AllAdminConversationsSubscriptionsHookResult = ReturnType<typeof useAllAdminConversationsSubscriptions>;
export type AllAdminConversationsSubscriptionsSubscriptionResult = Apollo.SubscriptionResult<Types.AllAdminConversationsSubscriptionsSubscription>;
export const AllAdminConversationsDocument = gql`
    query AllAdminConversations($readStatus: Boolean, $liveStatus: Boolean, $isBlocked: Boolean, $limit: Int, $offset: Int) {
  conversation_aggregate(
    where: {platform_id: {_nin: [11, 12]}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, contact: {blocked: {_eq: $isBlocked}}}
  ) {
    aggregate {
      totalCount: count
    }
  }
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: {platform_id: {_nin: [11, 12]}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, contact: {blocked: {_eq: $isBlocked}}}
    order_by: {last_message_received_timestamp: desc}
  ) {
    ...ConversationTableFields
  }
}
    ${ConversationTableFieldsFragmentDoc}`;

/**
 * __useAllAdminConversationsQuery__
 *
 * To run a query within a React component, call `useAllAdminConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAdminConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAdminConversationsQuery({
 *   variables: {
 *      readStatus: // value for 'readStatus'
 *      liveStatus: // value for 'liveStatus'
 *      isBlocked: // value for 'isBlocked'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllAdminConversationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllAdminConversationsQuery, Types.AllAdminConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllAdminConversationsQuery, Types.AllAdminConversationsQueryVariables>(AllAdminConversationsDocument, options);
      }
export function useAllAdminConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllAdminConversationsQuery, Types.AllAdminConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllAdminConversationsQuery, Types.AllAdminConversationsQueryVariables>(AllAdminConversationsDocument, options);
        }
export type AllAdminConversationsQueryHookResult = ReturnType<typeof useAllAdminConversationsQuery>;
export type AllAdminConversationsLazyQueryHookResult = ReturnType<typeof useAllAdminConversationsLazyQuery>;
export type AllAdminConversationsQueryResult = Apollo.QueryResult<Types.AllAdminConversationsQuery, Types.AllAdminConversationsQueryVariables>;
export const AllConversationsByReadStatusOrLiveStatusSubscriptionDocument = gql`
    subscription AllConversationsByReadStatusOrLiveStatusSubscription($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $assignedToRoleUserIds: [Int!], $belongsToRoleAffiliateId: Int, $campaignResponderIds: [Int!], $campaignResponderIdsIsNull: Boolean, $contactListIds: [Int!], $contactListIdsIsNull: Boolean, $readStatus: Boolean, $liveStatus: Boolean, $isBlocked: Boolean, $conversionFilter: Int, $limit: Int, $offset: Int, $messageType: String, $messageFrom: String, $orderBy: [conversation_order_by!]) {
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: {platform_id: {_nin: [11, 12]}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, assigned_to_role_user_id: {_in: $assignedToRoleUserIds}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, _and: [{campaign_responder_id: {_in: $campaignResponderIds}}, {contact_list_id: {_in: $contactListIds}}, {contact: {blocked: {_eq: $isBlocked}}}, {conversation_messages: {message_type: {_eq: $messageType}, from: {_eq: $messageFrom}}}], conversions_aggregate: {count: {predicate: {_gte: $conversionFilter}}}}
    order_by: $orderBy
  ) {
    ...ConversationTableFields
  }
}
    ${ConversationTableFieldsFragmentDoc}`;

/**
 * __useAllConversationsByReadStatusOrLiveStatusSubscription__
 *
 * To run a query within a React component, call `useAllConversationsByReadStatusOrLiveStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsByReadStatusOrLiveStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsByReadStatusOrLiveStatusSubscription({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      assignedToRoleUserIds: // value for 'assignedToRoleUserIds'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      campaignResponderIds: // value for 'campaignResponderIds'
 *      campaignResponderIdsIsNull: // value for 'campaignResponderIdsIsNull'
 *      contactListIds: // value for 'contactListIds'
 *      contactListIdsIsNull: // value for 'contactListIdsIsNull'
 *      readStatus: // value for 'readStatus'
 *      liveStatus: // value for 'liveStatus'
 *      isBlocked: // value for 'isBlocked'
 *      conversionFilter: // value for 'conversionFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      messageType: // value for 'messageType'
 *      messageFrom: // value for 'messageFrom'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllConversationsByReadStatusOrLiveStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.AllConversationsByReadStatusOrLiveStatusSubscription, Types.AllConversationsByReadStatusOrLiveStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.AllConversationsByReadStatusOrLiveStatusSubscription, Types.AllConversationsByReadStatusOrLiveStatusSubscriptionVariables>(AllConversationsByReadStatusOrLiveStatusSubscriptionDocument, options);
      }
export type AllConversationsByReadStatusOrLiveStatusSubscriptionHookResult = ReturnType<typeof useAllConversationsByReadStatusOrLiveStatusSubscription>;
export type AllConversationsByReadStatusOrLiveStatusSubscriptionSubscriptionResult = Apollo.SubscriptionResult<Types.AllConversationsByReadStatusOrLiveStatusSubscription>;
export const AllConversationsCountByReadStatusOrLiveStatusDocument = gql`
    subscription AllConversationsCountByReadStatusOrLiveStatus($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $assignedToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $readStatus: Boolean, $liveStatus: Boolean, $isBlocked: Boolean) {
  conversation_aggregate(
    where: {platform_id: {_nin: [11, 12]}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, assigned_to_role_user_id: {_eq: $assignedToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, contact: {blocked: {_eq: $isBlocked}}}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    `;

/**
 * __useAllConversationsCountByReadStatusOrLiveStatusSubscription__
 *
 * To run a query within a React component, call `useAllConversationsCountByReadStatusOrLiveStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsCountByReadStatusOrLiveStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsCountByReadStatusOrLiveStatusSubscription({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      assignedToRoleUserId: // value for 'assignedToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      readStatus: // value for 'readStatus'
 *      liveStatus: // value for 'liveStatus'
 *      isBlocked: // value for 'isBlocked'
 *   },
 * });
 */
export function useAllConversationsCountByReadStatusOrLiveStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.AllConversationsCountByReadStatusOrLiveStatusSubscription, Types.AllConversationsCountByReadStatusOrLiveStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.AllConversationsCountByReadStatusOrLiveStatusSubscription, Types.AllConversationsCountByReadStatusOrLiveStatusSubscriptionVariables>(AllConversationsCountByReadStatusOrLiveStatusDocument, options);
      }
export type AllConversationsCountByReadStatusOrLiveStatusSubscriptionHookResult = ReturnType<typeof useAllConversationsCountByReadStatusOrLiveStatusSubscription>;
export type AllConversationsCountByReadStatusOrLiveStatusSubscriptionResult = Apollo.SubscriptionResult<Types.AllConversationsCountByReadStatusOrLiveStatusSubscription>;
export const AllConversationsByReadStatusOrLiveStatusSubscription2Document = gql`
    subscription AllConversationsByReadStatusOrLiveStatusSubscription2($filter: conversation_bool_exp, $limit: Int, $offset: Int, $orderBy: [conversation_order_by!]) {
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: $filter
    order_by: $orderBy
  ) {
    ...ConversationTableFields
  }
}
    ${ConversationTableFieldsFragmentDoc}`;

/**
 * __useAllConversationsByReadStatusOrLiveStatusSubscription2__
 *
 * To run a query within a React component, call `useAllConversationsByReadStatusOrLiveStatusSubscription2` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsByReadStatusOrLiveStatusSubscription2` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsByReadStatusOrLiveStatusSubscription2({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllConversationsByReadStatusOrLiveStatusSubscription2(baseOptions?: Apollo.SubscriptionHookOptions<Types.AllConversationsByReadStatusOrLiveStatusSubscription2Subscription, Types.AllConversationsByReadStatusOrLiveStatusSubscription2SubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.AllConversationsByReadStatusOrLiveStatusSubscription2Subscription, Types.AllConversationsByReadStatusOrLiveStatusSubscription2SubscriptionVariables>(AllConversationsByReadStatusOrLiveStatusSubscription2Document, options);
      }
export type AllConversationsByReadStatusOrLiveStatusSubscription2HookResult = ReturnType<typeof useAllConversationsByReadStatusOrLiveStatusSubscription2>;
export type AllConversationsByReadStatusOrLiveStatusSubscription2SubscriptionResult = Apollo.SubscriptionResult<Types.AllConversationsByReadStatusOrLiveStatusSubscription2Subscription>;
export const AllConversationsByReadStatusOrLiveStatusNewQueryDocument = gql`
    query AllConversationsByReadStatusOrLiveStatusNewQuery($filter: conversation_bool_exp, $limit: Int, $offset: Int, $orderBy: [conversation_order_by!]) {
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: $filter
    order_by: $orderBy
  ) {
    ...ConversationTableFields
  }
  conversation_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ConversationTableFieldsFragmentDoc}`;

/**
 * __useAllConversationsByReadStatusOrLiveStatusNewQuery__
 *
 * To run a query within a React component, call `useAllConversationsByReadStatusOrLiveStatusNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsByReadStatusOrLiveStatusNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsByReadStatusOrLiveStatusNewQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllConversationsByReadStatusOrLiveStatusNewQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllConversationsByReadStatusOrLiveStatusNewQuery, Types.AllConversationsByReadStatusOrLiveStatusNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllConversationsByReadStatusOrLiveStatusNewQuery, Types.AllConversationsByReadStatusOrLiveStatusNewQueryVariables>(AllConversationsByReadStatusOrLiveStatusNewQueryDocument, options);
      }
export function useAllConversationsByReadStatusOrLiveStatusNewQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllConversationsByReadStatusOrLiveStatusNewQuery, Types.AllConversationsByReadStatusOrLiveStatusNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllConversationsByReadStatusOrLiveStatusNewQuery, Types.AllConversationsByReadStatusOrLiveStatusNewQueryVariables>(AllConversationsByReadStatusOrLiveStatusNewQueryDocument, options);
        }
export type AllConversationsByReadStatusOrLiveStatusNewQueryHookResult = ReturnType<typeof useAllConversationsByReadStatusOrLiveStatusNewQuery>;
export type AllConversationsByReadStatusOrLiveStatusNewQueryLazyQueryHookResult = ReturnType<typeof useAllConversationsByReadStatusOrLiveStatusNewQueryLazyQuery>;
export type AllConversationsByReadStatusOrLiveStatusNewQueryQueryResult = Apollo.QueryResult<Types.AllConversationsByReadStatusOrLiveStatusNewQuery, Types.AllConversationsByReadStatusOrLiveStatusNewQueryVariables>;
export const AllConversationsCountByReadStatusOrLiveStatus2Document = gql`
    subscription AllConversationsCountByReadStatusOrLiveStatus2($filter: conversation_bool_exp) {
  conversation_aggregate(where: $filter) {
    aggregate {
      totalCount: count
    }
  }
}
    `;

/**
 * __useAllConversationsCountByReadStatusOrLiveStatus2Subscription__
 *
 * To run a query within a React component, call `useAllConversationsCountByReadStatusOrLiveStatus2Subscription` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsCountByReadStatusOrLiveStatus2Subscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsCountByReadStatusOrLiveStatus2Subscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllConversationsCountByReadStatusOrLiveStatus2Subscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.AllConversationsCountByReadStatusOrLiveStatus2Subscription, Types.AllConversationsCountByReadStatusOrLiveStatus2SubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.AllConversationsCountByReadStatusOrLiveStatus2Subscription, Types.AllConversationsCountByReadStatusOrLiveStatus2SubscriptionVariables>(AllConversationsCountByReadStatusOrLiveStatus2Document, options);
      }
export type AllConversationsCountByReadStatusOrLiveStatus2SubscriptionHookResult = ReturnType<typeof useAllConversationsCountByReadStatusOrLiveStatus2Subscription>;
export type AllConversationsCountByReadStatusOrLiveStatus2SubscriptionResult = Apollo.SubscriptionResult<Types.AllConversationsCountByReadStatusOrLiveStatus2Subscription>;
export const AllConversationsByReadStatusOrLiveStatusDocument = gql`
    query AllConversationsByReadStatusOrLiveStatus($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $assignedToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $readStatus: Boolean, $liveStatus: Boolean, $isBlocked: Boolean, $limit: Int, $offset: Int) {
  conversation_aggregate(
    where: {platform_id: {_nin: [11, 12]}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, assigned_to_role_user_id: {_eq: $assignedToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, contact: {blocked: {_eq: $isBlocked}}}
  ) {
    aggregate {
      totalCount: count
    }
  }
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: {platform_id: {_nin: [11, 12]}, read_status: {_eq: $readStatus}, live_status: {_eq: $liveStatus}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, assigned_to_role_user_id: {_eq: $assignedToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, contact: {blocked: {_eq: $isBlocked}}}
    order_by: {last_message_received_timestamp: desc}
  ) {
    ...ConversationTableFields
  }
}
    ${ConversationTableFieldsFragmentDoc}`;

/**
 * __useAllConversationsByReadStatusOrLiveStatusQuery__
 *
 * To run a query within a React component, call `useAllConversationsByReadStatusOrLiveStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsByReadStatusOrLiveStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsByReadStatusOrLiveStatusQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      assignedToRoleUserId: // value for 'assignedToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      readStatus: // value for 'readStatus'
 *      liveStatus: // value for 'liveStatus'
 *      isBlocked: // value for 'isBlocked'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllConversationsByReadStatusOrLiveStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllConversationsByReadStatusOrLiveStatusQuery, Types.AllConversationsByReadStatusOrLiveStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllConversationsByReadStatusOrLiveStatusQuery, Types.AllConversationsByReadStatusOrLiveStatusQueryVariables>(AllConversationsByReadStatusOrLiveStatusDocument, options);
      }
export function useAllConversationsByReadStatusOrLiveStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllConversationsByReadStatusOrLiveStatusQuery, Types.AllConversationsByReadStatusOrLiveStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllConversationsByReadStatusOrLiveStatusQuery, Types.AllConversationsByReadStatusOrLiveStatusQueryVariables>(AllConversationsByReadStatusOrLiveStatusDocument, options);
        }
export type AllConversationsByReadStatusOrLiveStatusQueryHookResult = ReturnType<typeof useAllConversationsByReadStatusOrLiveStatusQuery>;
export type AllConversationsByReadStatusOrLiveStatusLazyQueryHookResult = ReturnType<typeof useAllConversationsByReadStatusOrLiveStatusLazyQuery>;
export type AllConversationsByReadStatusOrLiveStatusQueryResult = Apollo.QueryResult<Types.AllConversationsByReadStatusOrLiveStatusQuery, Types.AllConversationsByReadStatusOrLiveStatusQueryVariables>;
export const AllConversationsWithReviewSubscriptionDocument = gql`
    subscription AllConversationsWithReviewSubscription($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: {platform_id: {_nin: [11, 12]}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, conversation_notes: {note_message: {_is_null: false}}}
    order_by: {last_message_received_timestamp: desc}
  ) {
    ...ConversationListFields
  }
}
    ${ConversationListFieldsFragmentDoc}`;

/**
 * __useAllConversationsWithReviewSubscription__
 *
 * To run a query within a React component, call `useAllConversationsWithReviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsWithReviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsWithReviewSubscription({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllConversationsWithReviewSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.AllConversationsWithReviewSubscription, Types.AllConversationsWithReviewSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.AllConversationsWithReviewSubscription, Types.AllConversationsWithReviewSubscriptionVariables>(AllConversationsWithReviewSubscriptionDocument, options);
      }
export type AllConversationsWithReviewSubscriptionHookResult = ReturnType<typeof useAllConversationsWithReviewSubscription>;
export type AllConversationsWithReviewSubscriptionSubscriptionResult = Apollo.SubscriptionResult<Types.AllConversationsWithReviewSubscription>;
export const AllConversationsWithReviewDocument = gql`
    query AllConversationsWithReview($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  conversation_aggregate(
    where: {platform_id: {_nin: [11, 12]}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, conversation_notes: {note_message: {_is_null: false}}}
  ) {
    aggregate {
      totalCount: count
    }
  }
  conversations: conversation(
    limit: $limit
    offset: $offset
    where: {platform_id: {_nin: [11, 12]}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, conversation_notes: {note_message: {_is_null: false}}}
    order_by: {last_message_received_timestamp: desc}
  ) {
    ...ConversationListFields
  }
}
    ${ConversationListFieldsFragmentDoc}`;

/**
 * __useAllConversationsWithReviewQuery__
 *
 * To run a query within a React component, call `useAllConversationsWithReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllConversationsWithReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversationsWithReviewQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllConversationsWithReviewQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllConversationsWithReviewQuery, Types.AllConversationsWithReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllConversationsWithReviewQuery, Types.AllConversationsWithReviewQueryVariables>(AllConversationsWithReviewDocument, options);
      }
export function useAllConversationsWithReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllConversationsWithReviewQuery, Types.AllConversationsWithReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllConversationsWithReviewQuery, Types.AllConversationsWithReviewQueryVariables>(AllConversationsWithReviewDocument, options);
        }
export type AllConversationsWithReviewQueryHookResult = ReturnType<typeof useAllConversationsWithReviewQuery>;
export type AllConversationsWithReviewLazyQueryHookResult = ReturnType<typeof useAllConversationsWithReviewLazyQuery>;
export type AllConversationsWithReviewQueryResult = Apollo.QueryResult<Types.AllConversationsWithReviewQuery, Types.AllConversationsWithReviewQueryVariables>;
export const AllLiveChatConversationsDocument = gql`
    query AllLiveChatConversations($assignedToRoleUserId: Int, $newerThanDate: timestamptz, $belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int) {
  linkSent: tracking_aggregate(
    where: {conversation: {contact: {blocked: {_eq: false}}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, last_message_received_timestamp: {_gte: $newerThanDate}, platform_id: {_nin: [11, 12]}, live_status: {_eq: true}, is_archived: {_eq: false}, _or: [{assigned_to_role_user_id: {_is_null: true}}, {assigned_to_role_user_id: {_eq: $assignedToRoleUserId}}]}}
  ) {
    aggregate {
      linkSentCount: count
    }
  }
  conversations: conversation(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, last_message_received_timestamp: {_gte: $newerThanDate}, platform_id: {_nin: [11, 12]}, contact: {blocked: {_eq: false}}, live_status: {_eq: true}, is_archived: {_eq: false}, _or: [{assigned_to_role_user_id: {_is_null: true}}, {assigned_to_role_user_id: {_eq: $assignedToRoleUserId}}]}
    order_by: [{read_status: asc}, {last_message_received_timestamp: desc}]
  ) {
    ...ConversationLiveChatFields
  }
  unassignedAndNewConversations: conversation(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_chat_company_id: {_eq: $belongsToChatCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, last_message_received_timestamp: {_gte: $newerThanDate}, platform_id: {_nin: [11, 12]}, contact: {blocked: {_eq: false}}, live_status: {_eq: true}, is_archived: {_eq: false}, _or: [{assigned_to_role_user_id: {_is_null: true}}, {assigned_to_role_user_id: {_eq: $assignedToRoleUserId}}], _not: {conversation_messages: {message_type: {_eq: "manual"}}}, read_status: {_eq: false}}
    order_by: [{last_message_received_timestamp: desc}]
  ) {
    ...ConversationLiveChatFields
  }
}
    ${ConversationLiveChatFieldsFragmentDoc}`;

/**
 * __useAllLiveChatConversationsQuery__
 *
 * To run a query within a React component, call `useAllLiveChatConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLiveChatConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLiveChatConversationsQuery({
 *   variables: {
 *      assignedToRoleUserId: // value for 'assignedToRoleUserId'
 *      newerThanDate: // value for 'newerThanDate'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useAllLiveChatConversationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllLiveChatConversationsQuery, Types.AllLiveChatConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllLiveChatConversationsQuery, Types.AllLiveChatConversationsQueryVariables>(AllLiveChatConversationsDocument, options);
      }
export function useAllLiveChatConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllLiveChatConversationsQuery, Types.AllLiveChatConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllLiveChatConversationsQuery, Types.AllLiveChatConversationsQueryVariables>(AllLiveChatConversationsDocument, options);
        }
export type AllLiveChatConversationsQueryHookResult = ReturnType<typeof useAllLiveChatConversationsQuery>;
export type AllLiveChatConversationsLazyQueryHookResult = ReturnType<typeof useAllLiveChatConversationsLazyQuery>;
export type AllLiveChatConversationsQueryResult = Apollo.QueryResult<Types.AllLiveChatConversationsQuery, Types.AllLiveChatConversationsQueryVariables>;
export const ConversationByIdDocument = gql`
    query ConversationById($id: Int!) {
  conversation: conversation_by_pk(id: $id) {
    ...ConversationFields
  }
}
    ${ConversationFieldsFragmentDoc}`;

/**
 * __useConversationByIdQuery__
 *
 * To run a query within a React component, call `useConversationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversationByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationByIdQuery, Types.ConversationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationByIdQuery, Types.ConversationByIdQueryVariables>(ConversationByIdDocument, options);
      }
export function useConversationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationByIdQuery, Types.ConversationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationByIdQuery, Types.ConversationByIdQueryVariables>(ConversationByIdDocument, options);
        }
export type ConversationByIdQueryHookResult = ReturnType<typeof useConversationByIdQuery>;
export type ConversationByIdLazyQueryHookResult = ReturnType<typeof useConversationByIdLazyQuery>;
export type ConversationByIdQueryResult = Apollo.QueryResult<Types.ConversationByIdQuery, Types.ConversationByIdQueryVariables>;
export const ConversationsByIdAndAssignedDocument = gql`
    query ConversationsByIdAndAssigned($id: Int!, $roleUserId: Int) {
  conversations: conversation(
    where: {id: {_eq: $id}, assigned_to_role_user_id: {_eq: $roleUserId}}
  ) {
    ...ConversationListFields
  }
}
    ${ConversationListFieldsFragmentDoc}`;

/**
 * __useConversationsByIdAndAssignedQuery__
 *
 * To run a query within a React component, call `useConversationsByIdAndAssignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsByIdAndAssignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsByIdAndAssignedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      roleUserId: // value for 'roleUserId'
 *   },
 * });
 */
export function useConversationsByIdAndAssignedQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationsByIdAndAssignedQuery, Types.ConversationsByIdAndAssignedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationsByIdAndAssignedQuery, Types.ConversationsByIdAndAssignedQueryVariables>(ConversationsByIdAndAssignedDocument, options);
      }
export function useConversationsByIdAndAssignedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationsByIdAndAssignedQuery, Types.ConversationsByIdAndAssignedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationsByIdAndAssignedQuery, Types.ConversationsByIdAndAssignedQueryVariables>(ConversationsByIdAndAssignedDocument, options);
        }
export type ConversationsByIdAndAssignedQueryHookResult = ReturnType<typeof useConversationsByIdAndAssignedQuery>;
export type ConversationsByIdAndAssignedLazyQueryHookResult = ReturnType<typeof useConversationsByIdAndAssignedLazyQuery>;
export type ConversationsByIdAndAssignedQueryResult = Apollo.QueryResult<Types.ConversationsByIdAndAssignedQuery, Types.ConversationsByIdAndAssignedQueryVariables>;
export const ConversationsByContactIdDocument = gql`
    query ConversationsByContactId($contactId: Int!) {
  conversations: conversation(where: {contact_id: {_eq: $contactId}}) {
    ...ConversationListFields
  }
}
    ${ConversationListFieldsFragmentDoc}`;

/**
 * __useConversationsByContactIdQuery__
 *
 * To run a query within a React component, call `useConversationsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsByContactIdQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useConversationsByContactIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationsByContactIdQuery, Types.ConversationsByContactIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationsByContactIdQuery, Types.ConversationsByContactIdQueryVariables>(ConversationsByContactIdDocument, options);
      }
export function useConversationsByContactIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationsByContactIdQuery, Types.ConversationsByContactIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationsByContactIdQuery, Types.ConversationsByContactIdQueryVariables>(ConversationsByContactIdDocument, options);
        }
export type ConversationsByContactIdQueryHookResult = ReturnType<typeof useConversationsByContactIdQuery>;
export type ConversationsByContactIdLazyQueryHookResult = ReturnType<typeof useConversationsByContactIdLazyQuery>;
export type ConversationsByContactIdQueryResult = Apollo.QueryResult<Types.ConversationsByContactIdQuery, Types.ConversationsByContactIdQueryVariables>;
export const ConversationsByContactEmailAndAssignedDocument = gql`
    query ConversationsByContactEmailAndAssigned($email: String!, $roleUserId: Int, $belongsToCompanyId: Int, $belongsToChatCompanyId: Int) {
  conversations: conversation(
    where: {contact: {email_address: {_ilike: $email}}, assigned_to_role_user_id: {_eq: $roleUserId}}
  ) {
    ...ConversationListFields
  }
}
    ${ConversationListFieldsFragmentDoc}`;

/**
 * __useConversationsByContactEmailAndAssignedQuery__
 *
 * To run a query within a React component, call `useConversationsByContactEmailAndAssignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsByContactEmailAndAssignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsByContactEmailAndAssignedQuery({
 *   variables: {
 *      email: // value for 'email'
 *      roleUserId: // value for 'roleUserId'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *   },
 * });
 */
export function useConversationsByContactEmailAndAssignedQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationsByContactEmailAndAssignedQuery, Types.ConversationsByContactEmailAndAssignedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationsByContactEmailAndAssignedQuery, Types.ConversationsByContactEmailAndAssignedQueryVariables>(ConversationsByContactEmailAndAssignedDocument, options);
      }
export function useConversationsByContactEmailAndAssignedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationsByContactEmailAndAssignedQuery, Types.ConversationsByContactEmailAndAssignedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationsByContactEmailAndAssignedQuery, Types.ConversationsByContactEmailAndAssignedQueryVariables>(ConversationsByContactEmailAndAssignedDocument, options);
        }
export type ConversationsByContactEmailAndAssignedQueryHookResult = ReturnType<typeof useConversationsByContactEmailAndAssignedQuery>;
export type ConversationsByContactEmailAndAssignedLazyQueryHookResult = ReturnType<typeof useConversationsByContactEmailAndAssignedLazyQuery>;
export type ConversationsByContactEmailAndAssignedQueryResult = Apollo.QueryResult<Types.ConversationsByContactEmailAndAssignedQuery, Types.ConversationsByContactEmailAndAssignedQueryVariables>;
export const ConversationsByContactSkypeAndAssignedDocument = gql`
    query ConversationsByContactSkypeAndAssigned($skype_id: String!, $roleUserId: Int, $belongsToCompanyId: Int, $belongsToChatCompanyId: Int) {
  conversations: conversation(
    where: {contact: {skype_id: {_ilike: $skype_id}}, assigned_to_role_user_id: {_eq: $roleUserId}}
  ) {
    ...ConversationListFields
  }
}
    ${ConversationListFieldsFragmentDoc}`;

/**
 * __useConversationsByContactSkypeAndAssignedQuery__
 *
 * To run a query within a React component, call `useConversationsByContactSkypeAndAssignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsByContactSkypeAndAssignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsByContactSkypeAndAssignedQuery({
 *   variables: {
 *      skype_id: // value for 'skype_id'
 *      roleUserId: // value for 'roleUserId'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *   },
 * });
 */
export function useConversationsByContactSkypeAndAssignedQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationsByContactSkypeAndAssignedQuery, Types.ConversationsByContactSkypeAndAssignedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationsByContactSkypeAndAssignedQuery, Types.ConversationsByContactSkypeAndAssignedQueryVariables>(ConversationsByContactSkypeAndAssignedDocument, options);
      }
export function useConversationsByContactSkypeAndAssignedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationsByContactSkypeAndAssignedQuery, Types.ConversationsByContactSkypeAndAssignedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationsByContactSkypeAndAssignedQuery, Types.ConversationsByContactSkypeAndAssignedQueryVariables>(ConversationsByContactSkypeAndAssignedDocument, options);
        }
export type ConversationsByContactSkypeAndAssignedQueryHookResult = ReturnType<typeof useConversationsByContactSkypeAndAssignedQuery>;
export type ConversationsByContactSkypeAndAssignedLazyQueryHookResult = ReturnType<typeof useConversationsByContactSkypeAndAssignedLazyQuery>;
export type ConversationsByContactSkypeAndAssignedQueryResult = Apollo.QueryResult<Types.ConversationsByContactSkypeAndAssignedQuery, Types.ConversationsByContactSkypeAndAssignedQueryVariables>;
export const ConversationByIdSubscriptionDocument = gql`
    subscription ConversationByIdSubscription($id: Int!) {
  conversation: conversation_by_pk(id: $id) {
    ...ConversationFields
  }
}
    ${ConversationFieldsFragmentDoc}`;

/**
 * __useConversationByIdSubscription__
 *
 * To run a query within a React component, call `useConversationByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversationByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ConversationByIdSubscription, Types.ConversationByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ConversationByIdSubscription, Types.ConversationByIdSubscriptionVariables>(ConversationByIdSubscriptionDocument, options);
      }
export type ConversationByIdSubscriptionHookResult = ReturnType<typeof useConversationByIdSubscription>;
export type ConversationByIdSubscriptionSubscriptionResult = Apollo.SubscriptionResult<Types.ConversationByIdSubscription>;
export const ConversationCardByIdDocument = gql`
    query ConversationCardById($converstion_id: Int!) {
  conversation: conversation_by_pk(id: $converstion_id) {
    id
    lastMessageReceivedTimestamp: last_message_received_timestamp
    liveStatus: live_status
    isArchived: is_archived
    platformId: platform_id
    convPlatform: conv_platform {
      ...PlatformFields
    }
    readStatus: read_status
    contact {
      id
      firstName: first_name
      blockedReason: blocked_reason
      blockedByRoleUserId: blocked_by_role_user_id
      blockedByRoleUser: blocked_by_role_user {
        user {
          id
          userName: username
        }
      }
    }
    conversions_aggregate {
      aggregate {
        sum {
          payoutChatOperator: payout_chat_operator
        }
      }
    }
    manualMessages: conversation_messages_aggregate(
      where: {message_type: {_eq: "manual"}}
    ) {
      aggregate {
        messageCount: count
      }
    }
    trackings(limit: 1) {
      id
      offerLinkSent: offer_link_sent
    }
    trackings_aggregate {
      aggregate {
        sum {
          clickCount: click_count
        }
      }
    }
    unreadMessages: conversation_messages_aggregate(
      where: {read_status: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${PlatformFieldsFragmentDoc}`;

/**
 * __useConversationCardByIdQuery__
 *
 * To run a query within a React component, call `useConversationCardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationCardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationCardByIdQuery({
 *   variables: {
 *      converstion_id: // value for 'converstion_id'
 *   },
 * });
 */
export function useConversationCardByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversationCardByIdQuery, Types.ConversationCardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversationCardByIdQuery, Types.ConversationCardByIdQueryVariables>(ConversationCardByIdDocument, options);
      }
export function useConversationCardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversationCardByIdQuery, Types.ConversationCardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversationCardByIdQuery, Types.ConversationCardByIdQueryVariables>(ConversationCardByIdDocument, options);
        }
export type ConversationCardByIdQueryHookResult = ReturnType<typeof useConversationCardByIdQuery>;
export type ConversationCardByIdLazyQueryHookResult = ReturnType<typeof useConversationCardByIdLazyQuery>;
export type ConversationCardByIdQueryResult = Apollo.QueryResult<Types.ConversationCardByIdQuery, Types.ConversationCardByIdQueryVariables>;
export const UpdateConversationDocument = gql`
    mutation UpdateConversation($id: Int!, $changes: conversation_set_input) {
  updateConversation: update_conversation(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateConversationMutationFn = Apollo.MutationFunction<Types.UpdateConversationMutation, Types.UpdateConversationMutationVariables>;

/**
 * __useUpdateConversationMutation__
 *
 * To run a mutation, you first call `useUpdateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationMutation, { data, loading, error }] = useUpdateConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateConversationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateConversationMutation, Types.UpdateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateConversationMutation, Types.UpdateConversationMutationVariables>(UpdateConversationDocument, options);
      }
export type UpdateConversationMutationHookResult = ReturnType<typeof useUpdateConversationMutation>;
export type UpdateConversationMutationResult = Apollo.MutationResult<Types.UpdateConversationMutation>;
export type UpdateConversationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateConversationMutation, Types.UpdateConversationMutationVariables>;
export const AllConversionsDocument = gql`
    query AllConversions($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $isEnabled: Boolean, $isBlacklisted: Boolean, $limit: Int, $offset: Int) {
  conversions: conversion(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {created_at: desc}
  ) {
    ...Conversions
  }
  conversion_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {id: desc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ConversionsFragmentDoc}`;

/**
 * __useAllConversionsQuery__
 *
 * To run a query within a React component, call `useAllConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConversionsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      isEnabled: // value for 'isEnabled'
 *      isBlacklisted: // value for 'isBlacklisted'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllConversionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllConversionsQuery, Types.AllConversionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllConversionsQuery, Types.AllConversionsQueryVariables>(AllConversionsDocument, options);
      }
export function useAllConversionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllConversionsQuery, Types.AllConversionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllConversionsQuery, Types.AllConversionsQueryVariables>(AllConversionsDocument, options);
        }
export type AllConversionsQueryHookResult = ReturnType<typeof useAllConversionsQuery>;
export type AllConversionsLazyQueryHookResult = ReturnType<typeof useAllConversionsLazyQuery>;
export type AllConversionsQueryResult = Apollo.QueryResult<Types.AllConversionsQuery, Types.AllConversionsQueryVariables>;
export const ConversionAggregateByConversionIdDocument = gql`
    query ConversionAggregateByConversionId($conversationId: Int!) {
  conversationAggregate: conversion_aggregate(
    where: {conversation_id: {_eq: $conversationId}}
  ) {
    aggregate {
      sum {
        payinAdvertiser: payin_advertiser
        payoutCompany: payout_company
        payoutAffiliate: payout_affiliate
        payoutChatCompany: payout_chat_company
        payoutChatOperator: payout_chat_operator
      }
    }
  }
}
    `;

/**
 * __useConversionAggregateByConversionIdQuery__
 *
 * To run a query within a React component, call `useConversionAggregateByConversionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionAggregateByConversionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionAggregateByConversionIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useConversionAggregateByConversionIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversionAggregateByConversionIdQuery, Types.ConversionAggregateByConversionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversionAggregateByConversionIdQuery, Types.ConversionAggregateByConversionIdQueryVariables>(ConversionAggregateByConversionIdDocument, options);
      }
export function useConversionAggregateByConversionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversionAggregateByConversionIdQuery, Types.ConversionAggregateByConversionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversionAggregateByConversionIdQuery, Types.ConversionAggregateByConversionIdQueryVariables>(ConversionAggregateByConversionIdDocument, options);
        }
export type ConversionAggregateByConversionIdQueryHookResult = ReturnType<typeof useConversionAggregateByConversionIdQuery>;
export type ConversionAggregateByConversionIdLazyQueryHookResult = ReturnType<typeof useConversionAggregateByConversionIdLazyQuery>;
export type ConversionAggregateByConversionIdQueryResult = Apollo.QueryResult<Types.ConversionAggregateByConversionIdQuery, Types.ConversionAggregateByConversionIdQueryVariables>;
export const ConversionByIdDocument = gql`
    query ConversionById($id: Int!) {
  conversions: conversion(where: {id: {_eq: $id}}, order_by: {id: desc}) {
    ...Conversions
  }
}
    ${ConversionsFragmentDoc}`;

/**
 * __useConversionByIdQuery__
 *
 * To run a query within a React component, call `useConversionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversionByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ConversionByIdQuery, Types.ConversionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConversionByIdQuery, Types.ConversionByIdQueryVariables>(ConversionByIdDocument, options);
      }
export function useConversionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConversionByIdQuery, Types.ConversionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConversionByIdQuery, Types.ConversionByIdQueryVariables>(ConversionByIdDocument, options);
        }
export type ConversionByIdQueryHookResult = ReturnType<typeof useConversionByIdQuery>;
export type ConversionByIdLazyQueryHookResult = ReturnType<typeof useConversionByIdLazyQuery>;
export type ConversionByIdQueryResult = Apollo.QueryResult<Types.ConversionByIdQuery, Types.ConversionByIdQueryVariables>;
export const UpdateConversionDocument = gql`
    mutation UpdateConversion($id: Int!, $changes: conversion_set_input) {
  updateConversion: update_conversion(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateConversionMutationFn = Apollo.MutationFunction<Types.UpdateConversionMutation, Types.UpdateConversionMutationVariables>;

/**
 * __useUpdateConversionMutation__
 *
 * To run a mutation, you first call `useUpdateConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversionMutation, { data, loading, error }] = useUpdateConversionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateConversionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateConversionMutation, Types.UpdateConversionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateConversionMutation, Types.UpdateConversionMutationVariables>(UpdateConversionDocument, options);
      }
export type UpdateConversionMutationHookResult = ReturnType<typeof useUpdateConversionMutation>;
export type UpdateConversionMutationResult = Apollo.MutationResult<Types.UpdateConversionMutation>;
export type UpdateConversionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateConversionMutation, Types.UpdateConversionMutationVariables>;
export const InsertConversionDocument = gql`
    mutation InsertConversion($objectArray: [conversion_insert_input!]!) {
  insert_conversion(objects: $objectArray) {
    returning {
      id
    }
  }
}
    `;
export type InsertConversionMutationFn = Apollo.MutationFunction<Types.InsertConversionMutation, Types.InsertConversionMutationVariables>;

/**
 * __useInsertConversionMutation__
 *
 * To run a mutation, you first call `useInsertConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConversionMutation, { data, loading, error }] = useInsertConversionMutation({
 *   variables: {
 *      objectArray: // value for 'objectArray'
 *   },
 * });
 */
export function useInsertConversionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertConversionMutation, Types.InsertConversionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertConversionMutation, Types.InsertConversionMutationVariables>(InsertConversionDocument, options);
      }
export type InsertConversionMutationHookResult = ReturnType<typeof useInsertConversionMutation>;
export type InsertConversionMutationResult = Apollo.MutationResult<Types.InsertConversionMutation>;
export type InsertConversionMutationOptions = Apollo.BaseMutationOptions<Types.InsertConversionMutation, Types.InsertConversionMutationVariables>;
export const DeleteConversionDocument = gql`
    mutation DeleteConversion($id: Int!) {
  delete_conversion(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteConversionMutationFn = Apollo.MutationFunction<Types.DeleteConversionMutation, Types.DeleteConversionMutationVariables>;

/**
 * __useDeleteConversionMutation__
 *
 * To run a mutation, you first call `useDeleteConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversionMutation, { data, loading, error }] = useDeleteConversionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConversionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConversionMutation, Types.DeleteConversionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConversionMutation, Types.DeleteConversionMutationVariables>(DeleteConversionDocument, options);
      }
export type DeleteConversionMutationHookResult = ReturnType<typeof useDeleteConversionMutation>;
export type DeleteConversionMutationResult = Apollo.MutationResult<Types.DeleteConversionMutation>;
export type DeleteConversionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConversionMutation, Types.DeleteConversionMutationVariables>;
export const CountriesDocument = gql`
    query Countries($id: Int) {
  countries: country(where: {id: {_eq: $id}}, order_by: {name: asc}) {
    ...CountryFields
  }
}
    ${CountryFieldsFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<Types.CountriesQuery, Types.CountriesQueryVariables>;
export const CountriesBySmsReadyDocument = gql`
    query CountriesBySmsReady($id: Int) {
  countries: country(
    where: {id: {_eq: $id}, is_sms_ready: {_eq: true}}
    order_by: {name: asc}
  ) {
    ...CountryFields
  }
}
    ${CountryFieldsFragmentDoc}`;

/**
 * __useCountriesBySmsReadyQuery__
 *
 * To run a query within a React component, call `useCountriesBySmsReadyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesBySmsReadyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesBySmsReadyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCountriesBySmsReadyQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountriesBySmsReadyQuery, Types.CountriesBySmsReadyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesBySmsReadyQuery, Types.CountriesBySmsReadyQueryVariables>(CountriesBySmsReadyDocument, options);
      }
export function useCountriesBySmsReadyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesBySmsReadyQuery, Types.CountriesBySmsReadyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesBySmsReadyQuery, Types.CountriesBySmsReadyQueryVariables>(CountriesBySmsReadyDocument, options);
        }
export type CountriesBySmsReadyQueryHookResult = ReturnType<typeof useCountriesBySmsReadyQuery>;
export type CountriesBySmsReadyLazyQueryHookResult = ReturnType<typeof useCountriesBySmsReadyLazyQuery>;
export type CountriesBySmsReadyQueryResult = Apollo.QueryResult<Types.CountriesBySmsReadyQuery, Types.CountriesBySmsReadyQueryVariables>;
export const StatesDocument = gql`
    query States($countryId: Int) {
  states: state(where: {country_id: {_eq: $countryId}}, order_by: {name: asc}) {
    ...StateFields
  }
}
    ${StateFieldsFragmentDoc}`;

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<Types.StatesQuery, Types.StatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StatesQuery, Types.StatesQueryVariables>(StatesDocument, options);
      }
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StatesQuery, Types.StatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StatesQuery, Types.StatesQueryVariables>(StatesDocument, options);
        }
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesQueryResult = Apollo.QueryResult<Types.StatesQuery, Types.StatesQueryVariables>;
export const CitiesDocument = gql`
    query Cities($stateId: Int) {
  cities: city(where: {state_id: {_eq: $stateId}}, order_by: {name: asc}) {
    ...CityFields
  }
}
    ${CityFieldsFragmentDoc}`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CitiesQuery, Types.CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CitiesQuery, Types.CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CitiesQuery, Types.CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CitiesQuery, Types.CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<Types.CitiesQuery, Types.CitiesQueryVariables>;
export const AllDomainsDocument = gql`
    query AllDomains($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $isEnabled: Boolean, $isBlacklisted: Boolean, $limit: Int, $offset: Int) {
  domains: domain(
    limit: $limit
    offset: $offset
    where: {is_enabled: {_eq: $isEnabled}, is_blacklisted: {_eq: $isBlacklisted}, belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {domain_name: asc}
  ) {
    ...DomainFields
  }
}
    ${DomainFieldsFragmentDoc}`;

/**
 * __useAllDomainsQuery__
 *
 * To run a query within a React component, call `useAllDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDomainsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      isEnabled: // value for 'isEnabled'
 *      isBlacklisted: // value for 'isBlacklisted'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllDomainsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllDomainsQuery, Types.AllDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllDomainsQuery, Types.AllDomainsQueryVariables>(AllDomainsDocument, options);
      }
export function useAllDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllDomainsQuery, Types.AllDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllDomainsQuery, Types.AllDomainsQueryVariables>(AllDomainsDocument, options);
        }
export type AllDomainsQueryHookResult = ReturnType<typeof useAllDomainsQuery>;
export type AllDomainsLazyQueryHookResult = ReturnType<typeof useAllDomainsLazyQuery>;
export type AllDomainsQueryResult = Apollo.QueryResult<Types.AllDomainsQuery, Types.AllDomainsQueryVariables>;
export const InsertDomainDocument = gql`
    mutation InsertDomain($domain: domain_insert_input!) {
  insert_domain(objects: [$domain]) {
    returning {
      id
    }
  }
}
    `;
export type InsertDomainMutationFn = Apollo.MutationFunction<Types.InsertDomainMutation, Types.InsertDomainMutationVariables>;

/**
 * __useInsertDomainMutation__
 *
 * To run a mutation, you first call `useInsertDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDomainMutation, { data, loading, error }] = useInsertDomainMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useInsertDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDomainMutation, Types.InsertDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDomainMutation, Types.InsertDomainMutationVariables>(InsertDomainDocument, options);
      }
export type InsertDomainMutationHookResult = ReturnType<typeof useInsertDomainMutation>;
export type InsertDomainMutationResult = Apollo.MutationResult<Types.InsertDomainMutation>;
export type InsertDomainMutationOptions = Apollo.BaseMutationOptions<Types.InsertDomainMutation, Types.InsertDomainMutationVariables>;
export const UpdateDomainDocument = gql`
    mutation UpdateDomain($id: Int!, $changes: domain_set_input) {
  updateDomain: update_domain(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateDomainMutationFn = Apollo.MutationFunction<Types.UpdateDomainMutation, Types.UpdateDomainMutationVariables>;

/**
 * __useUpdateDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainMutation, { data, loading, error }] = useUpdateDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDomainMutation, Types.UpdateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDomainMutation, Types.UpdateDomainMutationVariables>(UpdateDomainDocument, options);
      }
export type UpdateDomainMutationHookResult = ReturnType<typeof useUpdateDomainMutation>;
export type UpdateDomainMutationResult = Apollo.MutationResult<Types.UpdateDomainMutation>;
export type UpdateDomainMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDomainMutation, Types.UpdateDomainMutationVariables>;
export const DeleteDomainDocument = gql`
    mutation DeleteDomain($id: Int!) {
  deleteDomain: delete_domain(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteDomainMutationFn = Apollo.MutationFunction<Types.DeleteDomainMutation, Types.DeleteDomainMutationVariables>;

/**
 * __useDeleteDomainMutation__
 *
 * To run a mutation, you first call `useDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainMutation, { data, loading, error }] = useDeleteDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDomainMutation, Types.DeleteDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDomainMutation, Types.DeleteDomainMutationVariables>(DeleteDomainDocument, options);
      }
export type DeleteDomainMutationHookResult = ReturnType<typeof useDeleteDomainMutation>;
export type DeleteDomainMutationResult = Apollo.MutationResult<Types.DeleteDomainMutation>;
export type DeleteDomainMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDomainMutation, Types.DeleteDomainMutationVariables>;
export const AllEmailsDocument = gql`
    query AllEmails($belongsToCompanyId: Int!, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  emails: email_account(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {id: desc}
  ) {
    ...EmailFields
  }
  email_account_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {id: desc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${EmailFieldsFragmentDoc}`;

/**
 * __useAllEmailsQuery__
 *
 * To run a query within a React component, call `useAllEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEmailsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllEmailsQuery(baseOptions: Apollo.QueryHookOptions<Types.AllEmailsQuery, Types.AllEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllEmailsQuery, Types.AllEmailsQueryVariables>(AllEmailsDocument, options);
      }
export function useAllEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllEmailsQuery, Types.AllEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllEmailsQuery, Types.AllEmailsQueryVariables>(AllEmailsDocument, options);
        }
export type AllEmailsQueryHookResult = ReturnType<typeof useAllEmailsQuery>;
export type AllEmailsLazyQueryHookResult = ReturnType<typeof useAllEmailsLazyQuery>;
export type AllEmailsQueryResult = Apollo.QueryResult<Types.AllEmailsQuery, Types.AllEmailsQueryVariables>;
export const EmailByIdDocument = gql`
    query EmailById($id: Int!) {
  email: email_account(where: {id: {_eq: $id}}, order_by: {id: desc}) {
    ...EmailFields
  }
}
    ${EmailFieldsFragmentDoc}`;

/**
 * __useEmailByIdQuery__
 *
 * To run a query within a React component, call `useEmailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.EmailByIdQuery, Types.EmailByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmailByIdQuery, Types.EmailByIdQueryVariables>(EmailByIdDocument, options);
      }
export function useEmailByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmailByIdQuery, Types.EmailByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmailByIdQuery, Types.EmailByIdQueryVariables>(EmailByIdDocument, options);
        }
export type EmailByIdQueryHookResult = ReturnType<typeof useEmailByIdQuery>;
export type EmailByIdLazyQueryHookResult = ReturnType<typeof useEmailByIdLazyQuery>;
export type EmailByIdQueryResult = Apollo.QueryResult<Types.EmailByIdQuery, Types.EmailByIdQueryVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($id: Int!, $changes: email_account_set_input) {
  updateEmail: update_email_account(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<Types.UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>;
export const InsertEmailDocument = gql`
    mutation InsertEmail($objectArray: [email_account_insert_input!]!) {
  insert_email_account(objects: $objectArray) {
    returning {
      id
    }
  }
}
    `;
export type InsertEmailMutationFn = Apollo.MutationFunction<Types.InsertEmailMutation, Types.InsertEmailMutationVariables>;

/**
 * __useInsertEmailMutation__
 *
 * To run a mutation, you first call `useInsertEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailMutation, { data, loading, error }] = useInsertEmailMutation({
 *   variables: {
 *      objectArray: // value for 'objectArray'
 *   },
 * });
 */
export function useInsertEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertEmailMutation, Types.InsertEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertEmailMutation, Types.InsertEmailMutationVariables>(InsertEmailDocument, options);
      }
export type InsertEmailMutationHookResult = ReturnType<typeof useInsertEmailMutation>;
export type InsertEmailMutationResult = Apollo.MutationResult<Types.InsertEmailMutation>;
export type InsertEmailMutationOptions = Apollo.BaseMutationOptions<Types.InsertEmailMutation, Types.InsertEmailMutationVariables>;
export const DeleteEmailDocument = gql`
    mutation DeleteEmail($id: Int!) {
  delete_email_account(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteEmailMutationFn = Apollo.MutationFunction<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>;

/**
 * __useDeleteEmailMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMutation, { data, loading, error }] = useDeleteEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>(DeleteEmailDocument, options);
      }
export type DeleteEmailMutationHookResult = ReturnType<typeof useDeleteEmailMutation>;
export type DeleteEmailMutationResult = Apollo.MutationResult<Types.DeleteEmailMutation>;
export type DeleteEmailMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>;
export const AllFeaturesDocument = gql`
    query AllFeatures {
  features: feature(order_by: {feature_key: asc}) {
    ...FeatureFields
  }
}
    ${FeatureFieldsFragmentDoc}`;

/**
 * __useAllFeaturesQuery__
 *
 * To run a query within a React component, call `useAllFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllFeaturesQuery, Types.AllFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllFeaturesQuery, Types.AllFeaturesQueryVariables>(AllFeaturesDocument, options);
      }
export function useAllFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllFeaturesQuery, Types.AllFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllFeaturesQuery, Types.AllFeaturesQueryVariables>(AllFeaturesDocument, options);
        }
export type AllFeaturesQueryHookResult = ReturnType<typeof useAllFeaturesQuery>;
export type AllFeaturesLazyQueryHookResult = ReturnType<typeof useAllFeaturesLazyQuery>;
export type AllFeaturesQueryResult = Apollo.QueryResult<Types.AllFeaturesQuery, Types.AllFeaturesQueryVariables>;
export const FeatureDocument = gql`
    query Feature($id: Int!) {
  feature: feature(where: {id: {_eq: $id}}) {
    ...FeatureFields
  }
}
    ${FeatureFieldsFragmentDoc}`;

/**
 * __useFeatureQuery__
 *
 * To run a query within a React component, call `useFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureQuery(baseOptions: Apollo.QueryHookOptions<Types.FeatureQuery, Types.FeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FeatureQuery, Types.FeatureQueryVariables>(FeatureDocument, options);
      }
export function useFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FeatureQuery, Types.FeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FeatureQuery, Types.FeatureQueryVariables>(FeatureDocument, options);
        }
export type FeatureQueryHookResult = ReturnType<typeof useFeatureQuery>;
export type FeatureLazyQueryHookResult = ReturnType<typeof useFeatureLazyQuery>;
export type FeatureQueryResult = Apollo.QueryResult<Types.FeatureQuery, Types.FeatureQueryVariables>;
export const UpdateFeatureDocument = gql`
    mutation UpdateFeature($id: Int!, $changes: feature_set_input) {
  UpdateFeature: update_feature(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<Types.UpdateFeatureMutation, Types.UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFeatureMutation, Types.UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFeatureMutation, Types.UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<Types.UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFeatureMutation, Types.UpdateFeatureMutationVariables>;
export const InsertFeatureDocument = gql`
    mutation InsertFeature($object: feature_insert_input!) {
  insert_feature(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertFeatureMutationFn = Apollo.MutationFunction<Types.InsertFeatureMutation, Types.InsertFeatureMutationVariables>;

/**
 * __useInsertFeatureMutation__
 *
 * To run a mutation, you first call `useInsertFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeatureMutation, { data, loading, error }] = useInsertFeatureMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertFeatureMutation, Types.InsertFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertFeatureMutation, Types.InsertFeatureMutationVariables>(InsertFeatureDocument, options);
      }
export type InsertFeatureMutationHookResult = ReturnType<typeof useInsertFeatureMutation>;
export type InsertFeatureMutationResult = Apollo.MutationResult<Types.InsertFeatureMutation>;
export type InsertFeatureMutationOptions = Apollo.BaseMutationOptions<Types.InsertFeatureMutation, Types.InsertFeatureMutationVariables>;
export const DeleteFeatureDocument = gql`
    mutation DeleteFeature($id: Int!) {
  delete_feature(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteFeatureMutationFn = Apollo.MutationFunction<Types.DeleteFeatureMutation, Types.DeleteFeatureMutationVariables>;

/**
 * __useDeleteFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeatureMutation, { data, loading, error }] = useDeleteFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteFeatureMutation, Types.DeleteFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteFeatureMutation, Types.DeleteFeatureMutationVariables>(DeleteFeatureDocument, options);
      }
export type DeleteFeatureMutationHookResult = ReturnType<typeof useDeleteFeatureMutation>;
export type DeleteFeatureMutationResult = Apollo.MutationResult<Types.DeleteFeatureMutation>;
export type DeleteFeatureMutationOptions = Apollo.BaseMutationOptions<Types.DeleteFeatureMutation, Types.DeleteFeatureMutationVariables>;
export const AllFlowsDocument = gql`
    query AllFlows {
  flows: flow {
    ...FlowFields
  }
}
    ${FlowFieldsFragmentDoc}`;

/**
 * __useAllFlowsQuery__
 *
 * To run a query within a React component, call `useAllFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFlowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFlowsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllFlowsQuery, Types.AllFlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllFlowsQuery, Types.AllFlowsQueryVariables>(AllFlowsDocument, options);
      }
export function useAllFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllFlowsQuery, Types.AllFlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllFlowsQuery, Types.AllFlowsQueryVariables>(AllFlowsDocument, options);
        }
export type AllFlowsQueryHookResult = ReturnType<typeof useAllFlowsQuery>;
export type AllFlowsLazyQueryHookResult = ReturnType<typeof useAllFlowsLazyQuery>;
export type AllFlowsQueryResult = Apollo.QueryResult<Types.AllFlowsQuery, Types.AllFlowsQueryVariables>;
export const SmsFlowsByCompanyIdDocument = gql`
    query SMSFlowsByCompanyId($belongsToCompanyId: Int!, $belongsToRoleAffiliateId: Int) {
  flows: flow(
    order_by: {campaign: {name: asc}}
    where: {campaign: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, is_enabled: {_eq: true}}, name: {_eq: "SMS Flow"}}
  ) {
    ...FlowFields
  }
}
    ${FlowFieldsFragmentDoc}`;

/**
 * __useSmsFlowsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useSmsFlowsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmsFlowsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmsFlowsByCompanyIdQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useSmsFlowsByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<Types.SmsFlowsByCompanyIdQuery, Types.SmsFlowsByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmsFlowsByCompanyIdQuery, Types.SmsFlowsByCompanyIdQueryVariables>(SmsFlowsByCompanyIdDocument, options);
      }
export function useSmsFlowsByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmsFlowsByCompanyIdQuery, Types.SmsFlowsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmsFlowsByCompanyIdQuery, Types.SmsFlowsByCompanyIdQueryVariables>(SmsFlowsByCompanyIdDocument, options);
        }
export type SmsFlowsByCompanyIdQueryHookResult = ReturnType<typeof useSmsFlowsByCompanyIdQuery>;
export type SmsFlowsByCompanyIdLazyQueryHookResult = ReturnType<typeof useSmsFlowsByCompanyIdLazyQuery>;
export type SmsFlowsByCompanyIdQueryResult = Apollo.QueryResult<Types.SmsFlowsByCompanyIdQuery, Types.SmsFlowsByCompanyIdQueryVariables>;
export const FlowDocument = gql`
    query Flow($id: Int!) {
  flow: flow_by_pk(id: $id) {
    ...FlowFields
  }
}
    ${FlowFieldsFragmentDoc}`;

/**
 * __useFlowQuery__
 *
 * To run a query within a React component, call `useFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlowQuery(baseOptions: Apollo.QueryHookOptions<Types.FlowQuery, Types.FlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FlowQuery, Types.FlowQueryVariables>(FlowDocument, options);
      }
export function useFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FlowQuery, Types.FlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FlowQuery, Types.FlowQueryVariables>(FlowDocument, options);
        }
export type FlowQueryHookResult = ReturnType<typeof useFlowQuery>;
export type FlowLazyQueryHookResult = ReturnType<typeof useFlowLazyQuery>;
export type FlowQueryResult = Apollo.QueryResult<Types.FlowQuery, Types.FlowQueryVariables>;
export const InsertFlowDocument = gql`
    mutation insertFlow($flow: flow_insert_input!) {
  insertFlow: insert_flow(objects: [$flow]) {
    returning {
      id
    }
  }
}
    `;
export type InsertFlowMutationFn = Apollo.MutationFunction<Types.InsertFlowMutation, Types.InsertFlowMutationVariables>;

/**
 * __useInsertFlowMutation__
 *
 * To run a mutation, you first call `useInsertFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFlowMutation, { data, loading, error }] = useInsertFlowMutation({
 *   variables: {
 *      flow: // value for 'flow'
 *   },
 * });
 */
export function useInsertFlowMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertFlowMutation, Types.InsertFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertFlowMutation, Types.InsertFlowMutationVariables>(InsertFlowDocument, options);
      }
export type InsertFlowMutationHookResult = ReturnType<typeof useInsertFlowMutation>;
export type InsertFlowMutationResult = Apollo.MutationResult<Types.InsertFlowMutation>;
export type InsertFlowMutationOptions = Apollo.BaseMutationOptions<Types.InsertFlowMutation, Types.InsertFlowMutationVariables>;
export const UpdateFlowDocument = gql`
    mutation updateFlow($id: Int!, $changes: flow_set_input) {
  updateFlow: update_flow(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateFlowMutationFn = Apollo.MutationFunction<Types.UpdateFlowMutation, Types.UpdateFlowMutationVariables>;

/**
 * __useUpdateFlowMutation__
 *
 * To run a mutation, you first call `useUpdateFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowMutation, { data, loading, error }] = useUpdateFlowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateFlowMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFlowMutation, Types.UpdateFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFlowMutation, Types.UpdateFlowMutationVariables>(UpdateFlowDocument, options);
      }
export type UpdateFlowMutationHookResult = ReturnType<typeof useUpdateFlowMutation>;
export type UpdateFlowMutationResult = Apollo.MutationResult<Types.UpdateFlowMutation>;
export type UpdateFlowMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFlowMutation, Types.UpdateFlowMutationVariables>;
export const DeleteFlowDocument = gql`
    mutation deleteFlow($id: Int!) {
  deleteFlow: delete_flow(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteFlowMutationFn = Apollo.MutationFunction<Types.DeleteFlowMutation, Types.DeleteFlowMutationVariables>;

/**
 * __useDeleteFlowMutation__
 *
 * To run a mutation, you first call `useDeleteFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFlowMutation, { data, loading, error }] = useDeleteFlowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFlowMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteFlowMutation, Types.DeleteFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteFlowMutation, Types.DeleteFlowMutationVariables>(DeleteFlowDocument, options);
      }
export type DeleteFlowMutationHookResult = ReturnType<typeof useDeleteFlowMutation>;
export type DeleteFlowMutationResult = Apollo.MutationResult<Types.DeleteFlowMutation>;
export type DeleteFlowMutationOptions = Apollo.BaseMutationOptions<Types.DeleteFlowMutation, Types.DeleteFlowMutationVariables>;
export const AllIframesDocument = gql`
    query AllIframes($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToAdvertiserId: Int, $limit: Int, $offset: Int) {
  iframes: iframe(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_advertiser_id: {_eq: $belongsToAdvertiserId}}
    order_by: {advertiser: {name: asc}, name: asc}
  ) {
    ...IframeFields
  }
}
    ${IframeFieldsFragmentDoc}`;

/**
 * __useAllIframesQuery__
 *
 * To run a query within a React component, call `useAllIframesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIframesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIframesQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToAdvertiserId: // value for 'belongsToAdvertiserId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllIframesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllIframesQuery, Types.AllIframesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllIframesQuery, Types.AllIframesQueryVariables>(AllIframesDocument, options);
      }
export function useAllIframesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllIframesQuery, Types.AllIframesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllIframesQuery, Types.AllIframesQueryVariables>(AllIframesDocument, options);
        }
export type AllIframesQueryHookResult = ReturnType<typeof useAllIframesQuery>;
export type AllIframesLazyQueryHookResult = ReturnType<typeof useAllIframesLazyQuery>;
export type AllIframesQueryResult = Apollo.QueryResult<Types.AllIframesQuery, Types.AllIframesQueryVariables>;
export const AllIframesByAdvertiserIdQueryDocument = gql`
    query AllIframesByAdvertiserIdQuery($belongsToAdvertiserId: Int!) {
  iframes: iframe(
    where: {belongs_to_advertiser_id: {_eq: $belongsToAdvertiserId}}
    order_by: {name: asc}
  ) {
    ...IframeFields
  }
}
    ${IframeFieldsFragmentDoc}`;

/**
 * __useAllIframesByAdvertiserIdQuery__
 *
 * To run a query within a React component, call `useAllIframesByAdvertiserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIframesByAdvertiserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIframesByAdvertiserIdQuery({
 *   variables: {
 *      belongsToAdvertiserId: // value for 'belongsToAdvertiserId'
 *   },
 * });
 */
export function useAllIframesByAdvertiserIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllIframesByAdvertiserIdQuery, Types.AllIframesByAdvertiserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllIframesByAdvertiserIdQuery, Types.AllIframesByAdvertiserIdQueryVariables>(AllIframesByAdvertiserIdQueryDocument, options);
      }
export function useAllIframesByAdvertiserIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllIframesByAdvertiserIdQuery, Types.AllIframesByAdvertiserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllIframesByAdvertiserIdQuery, Types.AllIframesByAdvertiserIdQueryVariables>(AllIframesByAdvertiserIdQueryDocument, options);
        }
export type AllIframesByAdvertiserIdQueryHookResult = ReturnType<typeof useAllIframesByAdvertiserIdQuery>;
export type AllIframesByAdvertiserIdQueryLazyQueryHookResult = ReturnType<typeof useAllIframesByAdvertiserIdQueryLazyQuery>;
export type AllIframesByAdvertiserIdQueryQueryResult = Apollo.QueryResult<Types.AllIframesByAdvertiserIdQuery, Types.AllIframesByAdvertiserIdQueryVariables>;
export const IframeByIdDocument = gql`
    query IframeById($id: Int!) {
  iframe: iframe_by_pk(id: $id) {
    ...IframeFields
  }
}
    ${IframeFieldsFragmentDoc}`;

/**
 * __useIframeByIdQuery__
 *
 * To run a query within a React component, call `useIframeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useIframeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIframeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIframeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.IframeByIdQuery, Types.IframeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IframeByIdQuery, Types.IframeByIdQueryVariables>(IframeByIdDocument, options);
      }
export function useIframeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IframeByIdQuery, Types.IframeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IframeByIdQuery, Types.IframeByIdQueryVariables>(IframeByIdDocument, options);
        }
export type IframeByIdQueryHookResult = ReturnType<typeof useIframeByIdQuery>;
export type IframeByIdLazyQueryHookResult = ReturnType<typeof useIframeByIdLazyQuery>;
export type IframeByIdQueryResult = Apollo.QueryResult<Types.IframeByIdQuery, Types.IframeByIdQueryVariables>;
export const UpdateIframeDocument = gql`
    mutation UpdateIframe($id: Int!, $changes: iframe_set_input) {
  UpdateIframe: update_iframe(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateIframeMutationFn = Apollo.MutationFunction<Types.UpdateIframeMutation, Types.UpdateIframeMutationVariables>;

/**
 * __useUpdateIframeMutation__
 *
 * To run a mutation, you first call `useUpdateIframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIframeMutation, { data, loading, error }] = useUpdateIframeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateIframeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateIframeMutation, Types.UpdateIframeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateIframeMutation, Types.UpdateIframeMutationVariables>(UpdateIframeDocument, options);
      }
export type UpdateIframeMutationHookResult = ReturnType<typeof useUpdateIframeMutation>;
export type UpdateIframeMutationResult = Apollo.MutationResult<Types.UpdateIframeMutation>;
export type UpdateIframeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIframeMutation, Types.UpdateIframeMutationVariables>;
export const InsertIframeDocument = gql`
    mutation InsertIframe($object: iframe_insert_input!) {
  insert_iframe(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertIframeMutationFn = Apollo.MutationFunction<Types.InsertIframeMutation, Types.InsertIframeMutationVariables>;

/**
 * __useInsertIframeMutation__
 *
 * To run a mutation, you first call `useInsertIframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIframeMutation, { data, loading, error }] = useInsertIframeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertIframeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertIframeMutation, Types.InsertIframeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertIframeMutation, Types.InsertIframeMutationVariables>(InsertIframeDocument, options);
      }
export type InsertIframeMutationHookResult = ReturnType<typeof useInsertIframeMutation>;
export type InsertIframeMutationResult = Apollo.MutationResult<Types.InsertIframeMutation>;
export type InsertIframeMutationOptions = Apollo.BaseMutationOptions<Types.InsertIframeMutation, Types.InsertIframeMutationVariables>;
export const DeleteIframeDocument = gql`
    mutation DeleteIframe($id: Int!) {
  delete_iframe(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteIframeMutationFn = Apollo.MutationFunction<Types.DeleteIframeMutation, Types.DeleteIframeMutationVariables>;

/**
 * __useDeleteIframeMutation__
 *
 * To run a mutation, you first call `useDeleteIframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIframeMutation, { data, loading, error }] = useDeleteIframeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIframeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteIframeMutation, Types.DeleteIframeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteIframeMutation, Types.DeleteIframeMutationVariables>(DeleteIframeDocument, options);
      }
export type DeleteIframeMutationHookResult = ReturnType<typeof useDeleteIframeMutation>;
export type DeleteIframeMutationResult = Apollo.MutationResult<Types.DeleteIframeMutation>;
export type DeleteIframeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteIframeMutation, Types.DeleteIframeMutationVariables>;
export const AllIntentsDocument = gql`
    query AllIntents($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int) {
  intents: intent(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {name: asc}
  ) {
    ...IntentFields
  }
}
    ${IntentFieldsFragmentDoc}`;

/**
 * __useAllIntentsQuery__
 *
 * To run a query within a React component, call `useAllIntentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIntentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIntentsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useAllIntentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllIntentsQuery, Types.AllIntentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllIntentsQuery, Types.AllIntentsQueryVariables>(AllIntentsDocument, options);
      }
export function useAllIntentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllIntentsQuery, Types.AllIntentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllIntentsQuery, Types.AllIntentsQueryVariables>(AllIntentsDocument, options);
        }
export type AllIntentsQueryHookResult = ReturnType<typeof useAllIntentsQuery>;
export type AllIntentsLazyQueryHookResult = ReturnType<typeof useAllIntentsLazyQuery>;
export type AllIntentsQueryResult = Apollo.QueryResult<Types.AllIntentsQuery, Types.AllIntentsQueryVariables>;
export const AllIntentSlashCommandsDocument = gql`
    query AllIntentSlashCommands($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int) {
  intents: intent(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, slash_command: {_is_null: false}}
    order_by: {name: asc, slash_command: asc}
  ) {
    id
    name
    isGlobal: is_global
    slashCommand: slash_command
    chatbotMessages: chatbot_messages(
      limit: 1
      where: {message_type: {_eq: "reply"}}
    ) {
      text
    }
  }
}
    `;

/**
 * __useAllIntentSlashCommandsQuery__
 *
 * To run a query within a React component, call `useAllIntentSlashCommandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIntentSlashCommandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIntentSlashCommandsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useAllIntentSlashCommandsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllIntentSlashCommandsQuery, Types.AllIntentSlashCommandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllIntentSlashCommandsQuery, Types.AllIntentSlashCommandsQueryVariables>(AllIntentSlashCommandsDocument, options);
      }
export function useAllIntentSlashCommandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllIntentSlashCommandsQuery, Types.AllIntentSlashCommandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllIntentSlashCommandsQuery, Types.AllIntentSlashCommandsQueryVariables>(AllIntentSlashCommandsDocument, options);
        }
export type AllIntentSlashCommandsQueryHookResult = ReturnType<typeof useAllIntentSlashCommandsQuery>;
export type AllIntentSlashCommandsLazyQueryHookResult = ReturnType<typeof useAllIntentSlashCommandsLazyQuery>;
export type AllIntentSlashCommandsQueryResult = Apollo.QueryResult<Types.AllIntentSlashCommandsQuery, Types.AllIntentSlashCommandsQueryVariables>;
export const IntentDocument = gql`
    query Intent($id: Int!) {
  intent: intent_by_pk(id: $id) {
    ...IntentFields
  }
}
    ${IntentFieldsFragmentDoc}`;

/**
 * __useIntentQuery__
 *
 * To run a query within a React component, call `useIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntentQuery(baseOptions: Apollo.QueryHookOptions<Types.IntentQuery, Types.IntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IntentQuery, Types.IntentQueryVariables>(IntentDocument, options);
      }
export function useIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IntentQuery, Types.IntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IntentQuery, Types.IntentQueryVariables>(IntentDocument, options);
        }
export type IntentQueryHookResult = ReturnType<typeof useIntentQuery>;
export type IntentLazyQueryHookResult = ReturnType<typeof useIntentLazyQuery>;
export type IntentQueryResult = Apollo.QueryResult<Types.IntentQuery, Types.IntentQueryVariables>;
export const InsertIntentDocument = gql`
    mutation insertIntent($intent: intent_insert_input!) {
  insertIntent: insert_intent(objects: [$intent]) {
    returning {
      id
    }
  }
}
    `;
export type InsertIntentMutationFn = Apollo.MutationFunction<Types.InsertIntentMutation, Types.InsertIntentMutationVariables>;

/**
 * __useInsertIntentMutation__
 *
 * To run a mutation, you first call `useInsertIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIntentMutation, { data, loading, error }] = useInsertIntentMutation({
 *   variables: {
 *      intent: // value for 'intent'
 *   },
 * });
 */
export function useInsertIntentMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertIntentMutation, Types.InsertIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertIntentMutation, Types.InsertIntentMutationVariables>(InsertIntentDocument, options);
      }
export type InsertIntentMutationHookResult = ReturnType<typeof useInsertIntentMutation>;
export type InsertIntentMutationResult = Apollo.MutationResult<Types.InsertIntentMutation>;
export type InsertIntentMutationOptions = Apollo.BaseMutationOptions<Types.InsertIntentMutation, Types.InsertIntentMutationVariables>;
export const UpdateIntentDocument = gql`
    mutation updateIntent($id: Int!, $changes: intent_set_input) {
  updateIntent: update_intent(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateIntentMutationFn = Apollo.MutationFunction<Types.UpdateIntentMutation, Types.UpdateIntentMutationVariables>;

/**
 * __useUpdateIntentMutation__
 *
 * To run a mutation, you first call `useUpdateIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntentMutation, { data, loading, error }] = useUpdateIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateIntentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateIntentMutation, Types.UpdateIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateIntentMutation, Types.UpdateIntentMutationVariables>(UpdateIntentDocument, options);
      }
export type UpdateIntentMutationHookResult = ReturnType<typeof useUpdateIntentMutation>;
export type UpdateIntentMutationResult = Apollo.MutationResult<Types.UpdateIntentMutation>;
export type UpdateIntentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIntentMutation, Types.UpdateIntentMutationVariables>;
export const DeleteIntentDocument = gql`
    mutation deleteIntent($id: Int!) {
  deleteIntent: delete_intent(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteIntentMutationFn = Apollo.MutationFunction<Types.DeleteIntentMutation, Types.DeleteIntentMutationVariables>;

/**
 * __useDeleteIntentMutation__
 *
 * To run a mutation, you first call `useDeleteIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntentMutation, { data, loading, error }] = useDeleteIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteIntentMutation, Types.DeleteIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteIntentMutation, Types.DeleteIntentMutationVariables>(DeleteIntentDocument, options);
      }
export type DeleteIntentMutationHookResult = ReturnType<typeof useDeleteIntentMutation>;
export type DeleteIntentMutationResult = Apollo.MutationResult<Types.DeleteIntentMutation>;
export type DeleteIntentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteIntentMutation, Types.DeleteIntentMutationVariables>;
export const GetIntentByUniqueSlashCommandDocument = gql`
    query getIntentByUniqueSlashCommand($slashCommand: String!, $companyId: Int) {
  intent(
    where: {slash_command: {_eq: $slashCommand}, _or: {is_global: {_eq: true}, belongs_to_company_id: {_eq: $companyId}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetIntentByUniqueSlashCommandQuery__
 *
 * To run a query within a React component, call `useGetIntentByUniqueSlashCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntentByUniqueSlashCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntentByUniqueSlashCommandQuery({
 *   variables: {
 *      slashCommand: // value for 'slashCommand'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetIntentByUniqueSlashCommandQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIntentByUniqueSlashCommandQuery, Types.GetIntentByUniqueSlashCommandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIntentByUniqueSlashCommandQuery, Types.GetIntentByUniqueSlashCommandQueryVariables>(GetIntentByUniqueSlashCommandDocument, options);
      }
export function useGetIntentByUniqueSlashCommandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIntentByUniqueSlashCommandQuery, Types.GetIntentByUniqueSlashCommandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIntentByUniqueSlashCommandQuery, Types.GetIntentByUniqueSlashCommandQueryVariables>(GetIntentByUniqueSlashCommandDocument, options);
        }
export type GetIntentByUniqueSlashCommandQueryHookResult = ReturnType<typeof useGetIntentByUniqueSlashCommandQuery>;
export type GetIntentByUniqueSlashCommandLazyQueryHookResult = ReturnType<typeof useGetIntentByUniqueSlashCommandLazyQuery>;
export type GetIntentByUniqueSlashCommandQueryResult = Apollo.QueryResult<Types.GetIntentByUniqueSlashCommandQuery, Types.GetIntentByUniqueSlashCommandQueryVariables>;
export const AllLedgersDocument = gql`
    query AllLedgers($limit: Int, $offset: Int) {
  ledgers: ledger(
    limit: $limit
    offset: $offset
    order_by: {transaction_date: desc}
  ) {
    ...LedgerFields
  }
  ledger_aggregate(order_by: {transaction_date: asc}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${LedgerFieldsFragmentDoc}`;

/**
 * __useAllLedgersQuery__
 *
 * To run a query within a React component, call `useAllLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLedgersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllLedgersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllLedgersQuery, Types.AllLedgersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllLedgersQuery, Types.AllLedgersQueryVariables>(AllLedgersDocument, options);
      }
export function useAllLedgersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllLedgersQuery, Types.AllLedgersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllLedgersQuery, Types.AllLedgersQueryVariables>(AllLedgersDocument, options);
        }
export type AllLedgersQueryHookResult = ReturnType<typeof useAllLedgersQuery>;
export type AllLedgersLazyQueryHookResult = ReturnType<typeof useAllLedgersLazyQuery>;
export type AllLedgersQueryResult = Apollo.QueryResult<Types.AllLedgersQuery, Types.AllLedgersQueryVariables>;
export const LedgerByIdDocument = gql`
    query LedgerById($id: Int!) {
  ledgers: ledger(where: {id: {_eq: $id}}, order_by: {id: desc}) {
    ...LedgerFields
  }
}
    ${LedgerFieldsFragmentDoc}`;

/**
 * __useLedgerByIdQuery__
 *
 * To run a query within a React component, call `useLedgerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLedgerByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.LedgerByIdQuery, Types.LedgerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LedgerByIdQuery, Types.LedgerByIdQueryVariables>(LedgerByIdDocument, options);
      }
export function useLedgerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LedgerByIdQuery, Types.LedgerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LedgerByIdQuery, Types.LedgerByIdQueryVariables>(LedgerByIdDocument, options);
        }
export type LedgerByIdQueryHookResult = ReturnType<typeof useLedgerByIdQuery>;
export type LedgerByIdLazyQueryHookResult = ReturnType<typeof useLedgerByIdLazyQuery>;
export type LedgerByIdQueryResult = Apollo.QueryResult<Types.LedgerByIdQuery, Types.LedgerByIdQueryVariables>;
export const UpdateLedgerDocument = gql`
    mutation UpdateLedger($id: Int!, $changes: ledger_set_input) {
  updateLedger: update_ledger(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateLedgerMutationFn = Apollo.MutationFunction<Types.UpdateLedgerMutation, Types.UpdateLedgerMutationVariables>;

/**
 * __useUpdateLedgerMutation__
 *
 * To run a mutation, you first call `useUpdateLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLedgerMutation, { data, loading, error }] = useUpdateLedgerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateLedgerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLedgerMutation, Types.UpdateLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLedgerMutation, Types.UpdateLedgerMutationVariables>(UpdateLedgerDocument, options);
      }
export type UpdateLedgerMutationHookResult = ReturnType<typeof useUpdateLedgerMutation>;
export type UpdateLedgerMutationResult = Apollo.MutationResult<Types.UpdateLedgerMutation>;
export type UpdateLedgerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLedgerMutation, Types.UpdateLedgerMutationVariables>;
export const InsertLedgerDocument = gql`
    mutation InsertLedger($ledger: ledger_insert_input!) {
  insert_ledger(objects: [$ledger]) {
    returning {
      id
    }
  }
}
    `;
export type InsertLedgerMutationFn = Apollo.MutationFunction<Types.InsertLedgerMutation, Types.InsertLedgerMutationVariables>;

/**
 * __useInsertLedgerMutation__
 *
 * To run a mutation, you first call `useInsertLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLedgerMutation, { data, loading, error }] = useInsertLedgerMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useInsertLedgerMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLedgerMutation, Types.InsertLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLedgerMutation, Types.InsertLedgerMutationVariables>(InsertLedgerDocument, options);
      }
export type InsertLedgerMutationHookResult = ReturnType<typeof useInsertLedgerMutation>;
export type InsertLedgerMutationResult = Apollo.MutationResult<Types.InsertLedgerMutation>;
export type InsertLedgerMutationOptions = Apollo.BaseMutationOptions<Types.InsertLedgerMutation, Types.InsertLedgerMutationVariables>;
export const DeleteLedgerDocument = gql`
    mutation DeleteLedger($id: Int!) {
  delete_ledger(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteLedgerMutationFn = Apollo.MutationFunction<Types.DeleteLedgerMutation, Types.DeleteLedgerMutationVariables>;

/**
 * __useDeleteLedgerMutation__
 *
 * To run a mutation, you first call `useDeleteLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLedgerMutation, { data, loading, error }] = useDeleteLedgerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLedgerMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLedgerMutation, Types.DeleteLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLedgerMutation, Types.DeleteLedgerMutationVariables>(DeleteLedgerDocument, options);
      }
export type DeleteLedgerMutationHookResult = ReturnType<typeof useDeleteLedgerMutation>;
export type DeleteLedgerMutationResult = Apollo.MutationResult<Types.DeleteLedgerMutation>;
export type DeleteLedgerMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLedgerMutation, Types.DeleteLedgerMutationVariables>;
export const MeDocument = gql`
    query Me($userId: Int!, $companyId: Int!) {
  companyRoleUser: company_role_user(
    where: {user_id: {_eq: $userId}, company_id: {_eq: $companyId}}
  ) {
    id
    companyId: company_id
    isEnabled: is_enabled
    apiKey: api_key
    role {
      id
      name
      shortcode
    }
    companyRoleUserPermissions: company_role_user_permissions {
      id
      permission {
        id
        permissionKey: permission_key
      }
    }
    companyFeatures: company {
      id
      companyFeatures: features {
        feature {
          id
          featureKey: feature_key
        }
      }
    }
    user {
      id
      currentCompanyId: current_company_id
      firstName: first_name
      lastName: last_name
      isChatting: is_chatting
      metabaseDashboardId: metabase_dashboard_id
      currentCompany: current_company {
        ...CompanySafeFields
        companyApiKey: company_api_key
      }
    }
  }
  user(where: {id: {_eq: $userId}}) {
    id
    currentCompanyId: current_company_id
    firstName: first_name
    lastName: last_name
    isChatting: is_chatting
    metabaseDashboardId: metabase_dashboard_id
    currentCompany: current_company {
      ...CompanySafeFields
    }
  }
}
    ${CompanySafeFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMeQuery(baseOptions: Apollo.QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<Types.MeQuery, Types.MeQueryVariables>;
export const MyPlanRolePermissionsQueryDocument = gql`
    query MyPlanRolePermissionsQuery($planId: Int, $roleId: Int!) {
  planRole: plan_role(where: {plan_id: {_eq: $planId}, role_id: {_eq: $roleId}}) {
    ...PlanRoleFields
  }
}
    ${PlanRoleFieldsFragmentDoc}`;

/**
 * __useMyPlanRolePermissionsQuery__
 *
 * To run a query within a React component, call `useMyPlanRolePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPlanRolePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPlanRolePermissionsQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useMyPlanRolePermissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.MyPlanRolePermissionsQuery, Types.MyPlanRolePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyPlanRolePermissionsQuery, Types.MyPlanRolePermissionsQueryVariables>(MyPlanRolePermissionsQueryDocument, options);
      }
export function useMyPlanRolePermissionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyPlanRolePermissionsQuery, Types.MyPlanRolePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyPlanRolePermissionsQuery, Types.MyPlanRolePermissionsQueryVariables>(MyPlanRolePermissionsQueryDocument, options);
        }
export type MyPlanRolePermissionsQueryHookResult = ReturnType<typeof useMyPlanRolePermissionsQuery>;
export type MyPlanRolePermissionsQueryLazyQueryHookResult = ReturnType<typeof useMyPlanRolePermissionsQueryLazyQuery>;
export type MyPlanRolePermissionsQueryQueryResult = Apollo.QueryResult<Types.MyPlanRolePermissionsQuery, Types.MyPlanRolePermissionsQueryVariables>;
export const MediasByFilterDocument = gql`
    query MediasByFilter($filter: media_bool_exp) {
  medias: media(where: $filter) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;

/**
 * __useMediasByFilterQuery__
 *
 * To run a query within a React component, call `useMediasByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediasByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediasByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMediasByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.MediasByFilterQuery, Types.MediasByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediasByFilterQuery, Types.MediasByFilterQueryVariables>(MediasByFilterDocument, options);
      }
export function useMediasByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediasByFilterQuery, Types.MediasByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediasByFilterQuery, Types.MediasByFilterQueryVariables>(MediasByFilterDocument, options);
        }
export type MediasByFilterQueryHookResult = ReturnType<typeof useMediasByFilterQuery>;
export type MediasByFilterLazyQueryHookResult = ReturnType<typeof useMediasByFilterLazyQuery>;
export type MediasByFilterQueryResult = Apollo.QueryResult<Types.MediasByFilterQuery, Types.MediasByFilterQueryVariables>;
export const InsertPersonaMediaDocument = gql`
    mutation InsertPersonaMedia($object: persona_media_insert_input!) {
  insert_persona_media(objects: [$object]) {
    affected_rows
  }
}
    `;
export type InsertPersonaMediaMutationFn = Apollo.MutationFunction<Types.InsertPersonaMediaMutation, Types.InsertPersonaMediaMutationVariables>;

/**
 * __useInsertPersonaMediaMutation__
 *
 * To run a mutation, you first call `useInsertPersonaMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPersonaMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPersonaMediaMutation, { data, loading, error }] = useInsertPersonaMediaMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPersonaMediaMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPersonaMediaMutation, Types.InsertPersonaMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPersonaMediaMutation, Types.InsertPersonaMediaMutationVariables>(InsertPersonaMediaDocument, options);
      }
export type InsertPersonaMediaMutationHookResult = ReturnType<typeof useInsertPersonaMediaMutation>;
export type InsertPersonaMediaMutationResult = Apollo.MutationResult<Types.InsertPersonaMediaMutation>;
export type InsertPersonaMediaMutationOptions = Apollo.BaseMutationOptions<Types.InsertPersonaMediaMutation, Types.InsertPersonaMediaMutationVariables>;
export const UpdateMediaDocument = gql`
    mutation UpdateMedia($id: Int!, $changes: media_set_input) {
  update_media(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateMediaMutationFn = Apollo.MutationFunction<Types.UpdateMediaMutation, Types.UpdateMediaMutationVariables>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateMediaMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMediaMutation, Types.UpdateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMediaMutation, Types.UpdateMediaMutationVariables>(UpdateMediaDocument, options);
      }
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = Apollo.MutationResult<Types.UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMediaMutation, Types.UpdateMediaMutationVariables>;
export const DeleteMediaDocument = gql`
    mutation DeleteMedia($id: Int!) {
  delete_media(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteMediaMutationFn = Apollo.MutationFunction<Types.DeleteMediaMutation, Types.DeleteMediaMutationVariables>;

/**
 * __useDeleteMediaMutation__
 *
 * To run a mutation, you first call `useDeleteMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaMutation, { data, loading, error }] = useDeleteMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMediaMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMediaMutation, Types.DeleteMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMediaMutation, Types.DeleteMediaMutationVariables>(DeleteMediaDocument, options);
      }
export type DeleteMediaMutationHookResult = ReturnType<typeof useDeleteMediaMutation>;
export type DeleteMediaMutationResult = Apollo.MutationResult<Types.DeleteMediaMutation>;
export type DeleteMediaMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMediaMutation, Types.DeleteMediaMutationVariables>;
export const AllOfferDomainsDocument = gql`
    query AllOfferDomains {
  offerDomains: offer_domain(order_by: {domain: {domain_name: asc}}) {
    ...OfferDomainFields
  }
}
    ${OfferDomainFieldsFragmentDoc}`;

/**
 * __useAllOfferDomainsQuery__
 *
 * To run a query within a React component, call `useAllOfferDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOfferDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOfferDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOfferDomainsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllOfferDomainsQuery, Types.AllOfferDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOfferDomainsQuery, Types.AllOfferDomainsQueryVariables>(AllOfferDomainsDocument, options);
      }
export function useAllOfferDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOfferDomainsQuery, Types.AllOfferDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOfferDomainsQuery, Types.AllOfferDomainsQueryVariables>(AllOfferDomainsDocument, options);
        }
export type AllOfferDomainsQueryHookResult = ReturnType<typeof useAllOfferDomainsQuery>;
export type AllOfferDomainsLazyQueryHookResult = ReturnType<typeof useAllOfferDomainsLazyQuery>;
export type AllOfferDomainsQueryResult = Apollo.QueryResult<Types.AllOfferDomainsQuery, Types.AllOfferDomainsQueryVariables>;
export const AllOfferDomainsByOfferIdDocument = gql`
    query AllOfferDomainsByOfferId($offerId: Int!) {
  offerDomains: offer_domain(
    order_by: {domain: {domain_name: asc}}
    where: {offer_id: {_eq: $offerId}}
  ) {
    ...OfferDomainFields
  }
}
    ${OfferDomainFieldsFragmentDoc}`;

/**
 * __useAllOfferDomainsByOfferIdQuery__
 *
 * To run a query within a React component, call `useAllOfferDomainsByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOfferDomainsByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOfferDomainsByOfferIdQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAllOfferDomainsByOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllOfferDomainsByOfferIdQuery, Types.AllOfferDomainsByOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOfferDomainsByOfferIdQuery, Types.AllOfferDomainsByOfferIdQueryVariables>(AllOfferDomainsByOfferIdDocument, options);
      }
export function useAllOfferDomainsByOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOfferDomainsByOfferIdQuery, Types.AllOfferDomainsByOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOfferDomainsByOfferIdQuery, Types.AllOfferDomainsByOfferIdQueryVariables>(AllOfferDomainsByOfferIdDocument, options);
        }
export type AllOfferDomainsByOfferIdQueryHookResult = ReturnType<typeof useAllOfferDomainsByOfferIdQuery>;
export type AllOfferDomainsByOfferIdLazyQueryHookResult = ReturnType<typeof useAllOfferDomainsByOfferIdLazyQuery>;
export type AllOfferDomainsByOfferIdQueryResult = Apollo.QueryResult<Types.AllOfferDomainsByOfferIdQuery, Types.AllOfferDomainsByOfferIdQueryVariables>;
export const UpdateOfferDomainDocument = gql`
    mutation UpdateOfferDomain($domainId: Int, $offerId: Int!, $changes: offer_domain_set_input) {
  UpdateOfferDomain: update_offer_domain(
    where: {domain_id: {_eq: $domainId}, offer_id: {_eq: $offerId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateOfferDomainMutationFn = Apollo.MutationFunction<Types.UpdateOfferDomainMutation, Types.UpdateOfferDomainMutationVariables>;

/**
 * __useUpdateOfferDomainMutation__
 *
 * To run a mutation, you first call `useUpdateOfferDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferDomainMutation, { data, loading, error }] = useUpdateOfferDomainMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      offerId: // value for 'offerId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateOfferDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOfferDomainMutation, Types.UpdateOfferDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOfferDomainMutation, Types.UpdateOfferDomainMutationVariables>(UpdateOfferDomainDocument, options);
      }
export type UpdateOfferDomainMutationHookResult = ReturnType<typeof useUpdateOfferDomainMutation>;
export type UpdateOfferDomainMutationResult = Apollo.MutationResult<Types.UpdateOfferDomainMutation>;
export type UpdateOfferDomainMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOfferDomainMutation, Types.UpdateOfferDomainMutationVariables>;
export const InsertOfferDomainDocument = gql`
    mutation InsertOfferDomain($object: offer_domain_insert_input!) {
  insert_offer_domain(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertOfferDomainMutationFn = Apollo.MutationFunction<Types.InsertOfferDomainMutation, Types.InsertOfferDomainMutationVariables>;

/**
 * __useInsertOfferDomainMutation__
 *
 * To run a mutation, you first call `useInsertOfferDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOfferDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOfferDomainMutation, { data, loading, error }] = useInsertOfferDomainMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOfferDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertOfferDomainMutation, Types.InsertOfferDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertOfferDomainMutation, Types.InsertOfferDomainMutationVariables>(InsertOfferDomainDocument, options);
      }
export type InsertOfferDomainMutationHookResult = ReturnType<typeof useInsertOfferDomainMutation>;
export type InsertOfferDomainMutationResult = Apollo.MutationResult<Types.InsertOfferDomainMutation>;
export type InsertOfferDomainMutationOptions = Apollo.BaseMutationOptions<Types.InsertOfferDomainMutation, Types.InsertOfferDomainMutationVariables>;
export const DeleteOfferDomainDocument = gql`
    mutation DeleteOfferDomain($offerId: Int!, $domainId: Int!) {
  delete_offer_domain(
    where: {offer_id: {_eq: $offerId}, domain_id: {_eq: $domainId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteOfferDomainMutationFn = Apollo.MutationFunction<Types.DeleteOfferDomainMutation, Types.DeleteOfferDomainMutationVariables>;

/**
 * __useDeleteOfferDomainMutation__
 *
 * To run a mutation, you first call `useDeleteOfferDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferDomainMutation, { data, loading, error }] = useDeleteOfferDomainMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDeleteOfferDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOfferDomainMutation, Types.DeleteOfferDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOfferDomainMutation, Types.DeleteOfferDomainMutationVariables>(DeleteOfferDomainDocument, options);
      }
export type DeleteOfferDomainMutationHookResult = ReturnType<typeof useDeleteOfferDomainMutation>;
export type DeleteOfferDomainMutationResult = Apollo.MutationResult<Types.DeleteOfferDomainMutation>;
export type DeleteOfferDomainMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOfferDomainMutation, Types.DeleteOfferDomainMutationVariables>;
export const AllOfferIframesDocument = gql`
    query AllOfferIframes {
  offerIframes: offer_iframe(order_by: {iframe: {name: asc}}) {
    ...OfferIframeFields
  }
}
    ${OfferIframeFieldsFragmentDoc}`;

/**
 * __useAllOfferIframesQuery__
 *
 * To run a query within a React component, call `useAllOfferIframesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOfferIframesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOfferIframesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOfferIframesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllOfferIframesQuery, Types.AllOfferIframesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOfferIframesQuery, Types.AllOfferIframesQueryVariables>(AllOfferIframesDocument, options);
      }
export function useAllOfferIframesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOfferIframesQuery, Types.AllOfferIframesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOfferIframesQuery, Types.AllOfferIframesQueryVariables>(AllOfferIframesDocument, options);
        }
export type AllOfferIframesQueryHookResult = ReturnType<typeof useAllOfferIframesQuery>;
export type AllOfferIframesLazyQueryHookResult = ReturnType<typeof useAllOfferIframesLazyQuery>;
export type AllOfferIframesQueryResult = Apollo.QueryResult<Types.AllOfferIframesQuery, Types.AllOfferIframesQueryVariables>;
export const AllOfferIframesByOfferIdDocument = gql`
    query AllOfferIframesByOfferId($offerId: Int!) {
  offerIframes: offer_iframe(
    order_by: {iframe: {name: asc}}
    where: {offer_id: {_eq: $offerId}}
  ) {
    ...OfferIframeFields
  }
}
    ${OfferIframeFieldsFragmentDoc}`;

/**
 * __useAllOfferIframesByOfferIdQuery__
 *
 * To run a query within a React component, call `useAllOfferIframesByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOfferIframesByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOfferIframesByOfferIdQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAllOfferIframesByOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllOfferIframesByOfferIdQuery, Types.AllOfferIframesByOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOfferIframesByOfferIdQuery, Types.AllOfferIframesByOfferIdQueryVariables>(AllOfferIframesByOfferIdDocument, options);
      }
export function useAllOfferIframesByOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOfferIframesByOfferIdQuery, Types.AllOfferIframesByOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOfferIframesByOfferIdQuery, Types.AllOfferIframesByOfferIdQueryVariables>(AllOfferIframesByOfferIdDocument, options);
        }
export type AllOfferIframesByOfferIdQueryHookResult = ReturnType<typeof useAllOfferIframesByOfferIdQuery>;
export type AllOfferIframesByOfferIdLazyQueryHookResult = ReturnType<typeof useAllOfferIframesByOfferIdLazyQuery>;
export type AllOfferIframesByOfferIdQueryResult = Apollo.QueryResult<Types.AllOfferIframesByOfferIdQuery, Types.AllOfferIframesByOfferIdQueryVariables>;
export const OfferIframeByIdDocument = gql`
    query OfferIframeById($id: Int!) {
  offerIframe: offer_iframe(where: {id: {_eq: $id}}) {
    ...OfferIframeFields
  }
}
    ${OfferIframeFieldsFragmentDoc}`;

/**
 * __useOfferIframeByIdQuery__
 *
 * To run a query within a React component, call `useOfferIframeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferIframeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferIframeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferIframeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferIframeByIdQuery, Types.OfferIframeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferIframeByIdQuery, Types.OfferIframeByIdQueryVariables>(OfferIframeByIdDocument, options);
      }
export function useOfferIframeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferIframeByIdQuery, Types.OfferIframeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferIframeByIdQuery, Types.OfferIframeByIdQueryVariables>(OfferIframeByIdDocument, options);
        }
export type OfferIframeByIdQueryHookResult = ReturnType<typeof useOfferIframeByIdQuery>;
export type OfferIframeByIdLazyQueryHookResult = ReturnType<typeof useOfferIframeByIdLazyQuery>;
export type OfferIframeByIdQueryResult = Apollo.QueryResult<Types.OfferIframeByIdQuery, Types.OfferIframeByIdQueryVariables>;
export const UpdateOfferIframeDocument = gql`
    mutation UpdateOfferIframe($id: Int!, $changes: offer_iframe_set_input) {
  UpdateOfferIframe: update_offer_iframe(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateOfferIframeMutationFn = Apollo.MutationFunction<Types.UpdateOfferIframeMutation, Types.UpdateOfferIframeMutationVariables>;

/**
 * __useUpdateOfferIframeMutation__
 *
 * To run a mutation, you first call `useUpdateOfferIframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferIframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferIframeMutation, { data, loading, error }] = useUpdateOfferIframeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateOfferIframeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOfferIframeMutation, Types.UpdateOfferIframeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOfferIframeMutation, Types.UpdateOfferIframeMutationVariables>(UpdateOfferIframeDocument, options);
      }
export type UpdateOfferIframeMutationHookResult = ReturnType<typeof useUpdateOfferIframeMutation>;
export type UpdateOfferIframeMutationResult = Apollo.MutationResult<Types.UpdateOfferIframeMutation>;
export type UpdateOfferIframeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOfferIframeMutation, Types.UpdateOfferIframeMutationVariables>;
export const InsertOfferIframeDocument = gql`
    mutation InsertOfferIframe($object: offer_iframe_insert_input!) {
  insert_offer_iframe(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertOfferIframeMutationFn = Apollo.MutationFunction<Types.InsertOfferIframeMutation, Types.InsertOfferIframeMutationVariables>;

/**
 * __useInsertOfferIframeMutation__
 *
 * To run a mutation, you first call `useInsertOfferIframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOfferIframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOfferIframeMutation, { data, loading, error }] = useInsertOfferIframeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOfferIframeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertOfferIframeMutation, Types.InsertOfferIframeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertOfferIframeMutation, Types.InsertOfferIframeMutationVariables>(InsertOfferIframeDocument, options);
      }
export type InsertOfferIframeMutationHookResult = ReturnType<typeof useInsertOfferIframeMutation>;
export type InsertOfferIframeMutationResult = Apollo.MutationResult<Types.InsertOfferIframeMutation>;
export type InsertOfferIframeMutationOptions = Apollo.BaseMutationOptions<Types.InsertOfferIframeMutation, Types.InsertOfferIframeMutationVariables>;
export const DeleteOfferIframeDocument = gql`
    mutation DeleteOfferIframe($id: Int!) {
  delete_offer_iframe(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteOfferIframeMutationFn = Apollo.MutationFunction<Types.DeleteOfferIframeMutation, Types.DeleteOfferIframeMutationVariables>;

/**
 * __useDeleteOfferIframeMutation__
 *
 * To run a mutation, you first call `useDeleteOfferIframeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferIframeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferIframeMutation, { data, loading, error }] = useDeleteOfferIframeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferIframeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOfferIframeMutation, Types.DeleteOfferIframeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOfferIframeMutation, Types.DeleteOfferIframeMutationVariables>(DeleteOfferIframeDocument, options);
      }
export type DeleteOfferIframeMutationHookResult = ReturnType<typeof useDeleteOfferIframeMutation>;
export type DeleteOfferIframeMutationResult = Apollo.MutationResult<Types.DeleteOfferIframeMutation>;
export type DeleteOfferIframeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOfferIframeMutation, Types.DeleteOfferIframeMutationVariables>;
export const AllOfferPayoutTypesDocument = gql`
    query AllOfferPayoutTypes {
  offerPayoutTypes: offer_payout_type(order_by: {payout_type: {name: asc}}) {
    ...OfferPayoutTypeFields
  }
}
    ${OfferPayoutTypeFieldsFragmentDoc}`;

/**
 * __useAllOfferPayoutTypesQuery__
 *
 * To run a query within a React component, call `useAllOfferPayoutTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOfferPayoutTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOfferPayoutTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOfferPayoutTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllOfferPayoutTypesQuery, Types.AllOfferPayoutTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOfferPayoutTypesQuery, Types.AllOfferPayoutTypesQueryVariables>(AllOfferPayoutTypesDocument, options);
      }
export function useAllOfferPayoutTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOfferPayoutTypesQuery, Types.AllOfferPayoutTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOfferPayoutTypesQuery, Types.AllOfferPayoutTypesQueryVariables>(AllOfferPayoutTypesDocument, options);
        }
export type AllOfferPayoutTypesQueryHookResult = ReturnType<typeof useAllOfferPayoutTypesQuery>;
export type AllOfferPayoutTypesLazyQueryHookResult = ReturnType<typeof useAllOfferPayoutTypesLazyQuery>;
export type AllOfferPayoutTypesQueryResult = Apollo.QueryResult<Types.AllOfferPayoutTypesQuery, Types.AllOfferPayoutTypesQueryVariables>;
export const AllOfferPayoutTypesByOfferIdDocument = gql`
    query AllOfferPayoutTypesByOfferId($offerId: Int!) {
  offerPayoutTypes: offer_payout_type(
    order_by: {payout_type: {name: asc}}
    where: {offer_id: {_eq: $offerId}}
  ) {
    ...OfferPayoutTypeFields
  }
}
    ${OfferPayoutTypeFieldsFragmentDoc}`;

/**
 * __useAllOfferPayoutTypesByOfferIdQuery__
 *
 * To run a query within a React component, call `useAllOfferPayoutTypesByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOfferPayoutTypesByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOfferPayoutTypesByOfferIdQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAllOfferPayoutTypesByOfferIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllOfferPayoutTypesByOfferIdQuery, Types.AllOfferPayoutTypesByOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOfferPayoutTypesByOfferIdQuery, Types.AllOfferPayoutTypesByOfferIdQueryVariables>(AllOfferPayoutTypesByOfferIdDocument, options);
      }
export function useAllOfferPayoutTypesByOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOfferPayoutTypesByOfferIdQuery, Types.AllOfferPayoutTypesByOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOfferPayoutTypesByOfferIdQuery, Types.AllOfferPayoutTypesByOfferIdQueryVariables>(AllOfferPayoutTypesByOfferIdDocument, options);
        }
export type AllOfferPayoutTypesByOfferIdQueryHookResult = ReturnType<typeof useAllOfferPayoutTypesByOfferIdQuery>;
export type AllOfferPayoutTypesByOfferIdLazyQueryHookResult = ReturnType<typeof useAllOfferPayoutTypesByOfferIdLazyQuery>;
export type AllOfferPayoutTypesByOfferIdQueryResult = Apollo.QueryResult<Types.AllOfferPayoutTypesByOfferIdQuery, Types.AllOfferPayoutTypesByOfferIdQueryVariables>;
export const OfferPayoutTypeByIdDocument = gql`
    query OfferPayoutTypeById($id: Int!) {
  offerPayoutType: offer_payout_type(where: {id: {_eq: $id}}) {
    ...OfferPayoutTypeFields
  }
}
    ${OfferPayoutTypeFieldsFragmentDoc}`;

/**
 * __useOfferPayoutTypeByIdQuery__
 *
 * To run a query within a React component, call `useOfferPayoutTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferPayoutTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferPayoutTypeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferPayoutTypeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferPayoutTypeByIdQuery, Types.OfferPayoutTypeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferPayoutTypeByIdQuery, Types.OfferPayoutTypeByIdQueryVariables>(OfferPayoutTypeByIdDocument, options);
      }
export function useOfferPayoutTypeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferPayoutTypeByIdQuery, Types.OfferPayoutTypeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferPayoutTypeByIdQuery, Types.OfferPayoutTypeByIdQueryVariables>(OfferPayoutTypeByIdDocument, options);
        }
export type OfferPayoutTypeByIdQueryHookResult = ReturnType<typeof useOfferPayoutTypeByIdQuery>;
export type OfferPayoutTypeByIdLazyQueryHookResult = ReturnType<typeof useOfferPayoutTypeByIdLazyQuery>;
export type OfferPayoutTypeByIdQueryResult = Apollo.QueryResult<Types.OfferPayoutTypeByIdQuery, Types.OfferPayoutTypeByIdQueryVariables>;
export const UpdateOfferPayoutTypeDocument = gql`
    mutation UpdateOfferPayoutType($id: Int!, $changes: offer_payout_type_set_input) {
  UpdateOfferPayoutType: update_offer_payout_type(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateOfferPayoutTypeMutationFn = Apollo.MutationFunction<Types.UpdateOfferPayoutTypeMutation, Types.UpdateOfferPayoutTypeMutationVariables>;

/**
 * __useUpdateOfferPayoutTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOfferPayoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferPayoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferPayoutTypeMutation, { data, loading, error }] = useUpdateOfferPayoutTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateOfferPayoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOfferPayoutTypeMutation, Types.UpdateOfferPayoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOfferPayoutTypeMutation, Types.UpdateOfferPayoutTypeMutationVariables>(UpdateOfferPayoutTypeDocument, options);
      }
export type UpdateOfferPayoutTypeMutationHookResult = ReturnType<typeof useUpdateOfferPayoutTypeMutation>;
export type UpdateOfferPayoutTypeMutationResult = Apollo.MutationResult<Types.UpdateOfferPayoutTypeMutation>;
export type UpdateOfferPayoutTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOfferPayoutTypeMutation, Types.UpdateOfferPayoutTypeMutationVariables>;
export const InsertOfferPayoutTypeDocument = gql`
    mutation InsertOfferPayoutType($object: offer_payout_type_insert_input!) {
  insert_offer_payout_type(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertOfferPayoutTypeMutationFn = Apollo.MutationFunction<Types.InsertOfferPayoutTypeMutation, Types.InsertOfferPayoutTypeMutationVariables>;

/**
 * __useInsertOfferPayoutTypeMutation__
 *
 * To run a mutation, you first call `useInsertOfferPayoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOfferPayoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOfferPayoutTypeMutation, { data, loading, error }] = useInsertOfferPayoutTypeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOfferPayoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertOfferPayoutTypeMutation, Types.InsertOfferPayoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertOfferPayoutTypeMutation, Types.InsertOfferPayoutTypeMutationVariables>(InsertOfferPayoutTypeDocument, options);
      }
export type InsertOfferPayoutTypeMutationHookResult = ReturnType<typeof useInsertOfferPayoutTypeMutation>;
export type InsertOfferPayoutTypeMutationResult = Apollo.MutationResult<Types.InsertOfferPayoutTypeMutation>;
export type InsertOfferPayoutTypeMutationOptions = Apollo.BaseMutationOptions<Types.InsertOfferPayoutTypeMutation, Types.InsertOfferPayoutTypeMutationVariables>;
export const DeleteOfferPayoutTypeDocument = gql`
    mutation DeleteOfferPayoutType($id: Int!) {
  delete_offer_payout_type(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteOfferPayoutTypeMutationFn = Apollo.MutationFunction<Types.DeleteOfferPayoutTypeMutation, Types.DeleteOfferPayoutTypeMutationVariables>;

/**
 * __useDeleteOfferPayoutTypeMutation__
 *
 * To run a mutation, you first call `useDeleteOfferPayoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferPayoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferPayoutTypeMutation, { data, loading, error }] = useDeleteOfferPayoutTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferPayoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOfferPayoutTypeMutation, Types.DeleteOfferPayoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOfferPayoutTypeMutation, Types.DeleteOfferPayoutTypeMutationVariables>(DeleteOfferPayoutTypeDocument, options);
      }
export type DeleteOfferPayoutTypeMutationHookResult = ReturnType<typeof useDeleteOfferPayoutTypeMutation>;
export type DeleteOfferPayoutTypeMutationResult = Apollo.MutationResult<Types.DeleteOfferPayoutTypeMutation>;
export type DeleteOfferPayoutTypeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOfferPayoutTypeMutation, Types.DeleteOfferPayoutTypeMutationVariables>;
export const AllOffersDocument = gql`
    query AllOffers($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToAdvertiserId: Int, $limit: Int, $offset: Int) {
  offers: offer(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_advertiser_id: {_eq: $belongsToAdvertiserId}}
    order_by: {advertiser: {name: asc}, name: asc}
  ) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useAllOffersQuery__
 *
 * To run a query within a React component, call `useAllOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOffersQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToAdvertiserId: // value for 'belongsToAdvertiserId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllOffersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllOffersQuery, Types.AllOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOffersQuery, Types.AllOffersQueryVariables>(AllOffersDocument, options);
      }
export function useAllOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOffersQuery, Types.AllOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOffersQuery, Types.AllOffersQueryVariables>(AllOffersDocument, options);
        }
export type AllOffersQueryHookResult = ReturnType<typeof useAllOffersQuery>;
export type AllOffersLazyQueryHookResult = ReturnType<typeof useAllOffersLazyQuery>;
export type AllOffersQueryResult = Apollo.QueryResult<Types.AllOffersQuery, Types.AllOffersQueryVariables>;
export const AllOffersByAdvertiserIdQueryDocument = gql`
    query AllOffersByAdvertiserIdQuery($belongsToAdvertiserId: Int!) {
  offers: offer(
    where: {belongs_to_advertiser_id: {_eq: $belongsToAdvertiserId}}
    order_by: {name: asc}
  ) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useAllOffersByAdvertiserIdQuery__
 *
 * To run a query within a React component, call `useAllOffersByAdvertiserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOffersByAdvertiserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOffersByAdvertiserIdQuery({
 *   variables: {
 *      belongsToAdvertiserId: // value for 'belongsToAdvertiserId'
 *   },
 * });
 */
export function useAllOffersByAdvertiserIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllOffersByAdvertiserIdQuery, Types.AllOffersByAdvertiserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllOffersByAdvertiserIdQuery, Types.AllOffersByAdvertiserIdQueryVariables>(AllOffersByAdvertiserIdQueryDocument, options);
      }
export function useAllOffersByAdvertiserIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllOffersByAdvertiserIdQuery, Types.AllOffersByAdvertiserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllOffersByAdvertiserIdQuery, Types.AllOffersByAdvertiserIdQueryVariables>(AllOffersByAdvertiserIdQueryDocument, options);
        }
export type AllOffersByAdvertiserIdQueryHookResult = ReturnType<typeof useAllOffersByAdvertiserIdQuery>;
export type AllOffersByAdvertiserIdQueryLazyQueryHookResult = ReturnType<typeof useAllOffersByAdvertiserIdQueryLazyQuery>;
export type AllOffersByAdvertiserIdQueryQueryResult = Apollo.QueryResult<Types.AllOffersByAdvertiserIdQuery, Types.AllOffersByAdvertiserIdQueryVariables>;
export const OfferDocument = gql`
    query Offer($id: Int!) {
  offer: offer_by_pk(id: $id) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useOfferQuery__
 *
 * To run a query within a React component, call `useOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferQuery, Types.OfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferQuery, Types.OfferQueryVariables>(OfferDocument, options);
      }
export function useOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferQuery, Types.OfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferQuery, Types.OfferQueryVariables>(OfferDocument, options);
        }
export type OfferQueryHookResult = ReturnType<typeof useOfferQuery>;
export type OfferLazyQueryHookResult = ReturnType<typeof useOfferLazyQuery>;
export type OfferQueryResult = Apollo.QueryResult<Types.OfferQuery, Types.OfferQueryVariables>;
export const OffersDocument = gql`
    query Offers {
  offers: offer(order_by: {name: asc}) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useOffersQuery__
 *
 * To run a query within a React component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOffersQuery(baseOptions?: Apollo.QueryHookOptions<Types.OffersQuery, Types.OffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OffersQuery, Types.OffersQueryVariables>(OffersDocument, options);
      }
export function useOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OffersQuery, Types.OffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OffersQuery, Types.OffersQueryVariables>(OffersDocument, options);
        }
export type OffersQueryHookResult = ReturnType<typeof useOffersQuery>;
export type OffersLazyQueryHookResult = ReturnType<typeof useOffersLazyQuery>;
export type OffersQueryResult = Apollo.QueryResult<Types.OffersQuery, Types.OffersQueryVariables>;
export const InsertOfferDocument = gql`
    mutation insertOffer($offer: offer_insert_input!) {
  insertOffer: insert_offer(objects: [$offer]) {
    returning {
      id
    }
  }
}
    `;
export type InsertOfferMutationFn = Apollo.MutationFunction<Types.InsertOfferMutation, Types.InsertOfferMutationVariables>;

/**
 * __useInsertOfferMutation__
 *
 * To run a mutation, you first call `useInsertOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOfferMutation, { data, loading, error }] = useInsertOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *   },
 * });
 */
export function useInsertOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertOfferMutation, Types.InsertOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertOfferMutation, Types.InsertOfferMutationVariables>(InsertOfferDocument, options);
      }
export type InsertOfferMutationHookResult = ReturnType<typeof useInsertOfferMutation>;
export type InsertOfferMutationResult = Apollo.MutationResult<Types.InsertOfferMutation>;
export type InsertOfferMutationOptions = Apollo.BaseMutationOptions<Types.InsertOfferMutation, Types.InsertOfferMutationVariables>;
export const UpdateOfferDocument = gql`
    mutation UpdateOffer($id: Int!, $changes: offer_set_input) {
  updateOffer: update_offer(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateOfferMutationFn = Apollo.MutationFunction<Types.UpdateOfferMutation, Types.UpdateOfferMutationVariables>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOfferMutation, Types.UpdateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOfferMutation, Types.UpdateOfferMutationVariables>(UpdateOfferDocument, options);
      }
export type UpdateOfferMutationHookResult = ReturnType<typeof useUpdateOfferMutation>;
export type UpdateOfferMutationResult = Apollo.MutationResult<Types.UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOfferMutation, Types.UpdateOfferMutationVariables>;
export const DeleteOfferDocument = gql`
    mutation DeleteOffer($id: Int!) {
  deleteOffer: delete_offer(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteOfferMutationFn = Apollo.MutationFunction<Types.DeleteOfferMutation, Types.DeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOfferMutation, Types.DeleteOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOfferMutation, Types.DeleteOfferMutationVariables>(DeleteOfferDocument, options);
      }
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<Types.DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOfferMutation, Types.DeleteOfferMutationVariables>;
export const LiveChatOlderNewerConversationsDocument = gql`
    query liveChatOlderNewerConversations($timestamp: timestamptz!, $liveStatus: Boolean, $belongsToCompanyId: Int, $assignedToRoleUserId: Int) {
  newerConversation: conversation(
    where: {last_message_timestamp: {_gt: $timestamp}, live_status: {_eq: $liveStatus}, belongs_to_company_id: {_eq: $belongsToCompanyId}, assigned_to_role_user_id: {_eq: $assignedToRoleUserId}}
    order_by: {last_message_timestamp: asc}
    limit: 1
  ) {
    id
    belongsToCompanyId: belongs_to_company_id
  }
  olderConversation: conversation(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, last_message_timestamp: {_lt: $timestamp}, live_status: {_eq: $liveStatus}, assigned_to_role_user_id: {_eq: $assignedToRoleUserId}}
    order_by: {last_message_timestamp: desc}
    limit: 1
  ) {
    id
    belongsToCompanyId: belongs_to_company_id
  }
}
    `;

/**
 * __useLiveChatOlderNewerConversationsQuery__
 *
 * To run a query within a React component, call `useLiveChatOlderNewerConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatOlderNewerConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatOlderNewerConversationsQuery({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      liveStatus: // value for 'liveStatus'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      assignedToRoleUserId: // value for 'assignedToRoleUserId'
 *   },
 * });
 */
export function useLiveChatOlderNewerConversationsQuery(baseOptions: Apollo.QueryHookOptions<Types.LiveChatOlderNewerConversationsQuery, Types.LiveChatOlderNewerConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LiveChatOlderNewerConversationsQuery, Types.LiveChatOlderNewerConversationsQueryVariables>(LiveChatOlderNewerConversationsDocument, options);
      }
export function useLiveChatOlderNewerConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LiveChatOlderNewerConversationsQuery, Types.LiveChatOlderNewerConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LiveChatOlderNewerConversationsQuery, Types.LiveChatOlderNewerConversationsQueryVariables>(LiveChatOlderNewerConversationsDocument, options);
        }
export type LiveChatOlderNewerConversationsQueryHookResult = ReturnType<typeof useLiveChatOlderNewerConversationsQuery>;
export type LiveChatOlderNewerConversationsLazyQueryHookResult = ReturnType<typeof useLiveChatOlderNewerConversationsLazyQuery>;
export type LiveChatOlderNewerConversationsQueryResult = Apollo.QueryResult<Types.LiveChatOlderNewerConversationsQuery, Types.LiveChatOlderNewerConversationsQueryVariables>;
export const OlderNewerConversationsDocument = gql`
    query OlderNewerConversations($timestamp: timestamptz!, $liveStatus: Boolean, $belongsToCompanyId: Int) {
  newerConversation: conversation(
    where: {last_message_timestamp: {_gt: $timestamp}, live_status: {_eq: $liveStatus}, belongs_to_company_id: {_eq: $belongsToCompanyId}}
    order_by: {last_message_timestamp: asc}
    limit: 1
  ) {
    id
    belongsToCompanyId: belongs_to_company_id
  }
  olderConversation: conversation(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, last_message_timestamp: {_lt: $timestamp}, live_status: {_eq: $liveStatus}}
    order_by: {last_message_timestamp: desc}
    limit: 1
  ) {
    id
    belongsToCompanyId: belongs_to_company_id
  }
}
    `;

/**
 * __useOlderNewerConversationsQuery__
 *
 * To run a query within a React component, call `useOlderNewerConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOlderNewerConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOlderNewerConversationsQuery({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      liveStatus: // value for 'liveStatus'
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *   },
 * });
 */
export function useOlderNewerConversationsQuery(baseOptions: Apollo.QueryHookOptions<Types.OlderNewerConversationsQuery, Types.OlderNewerConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OlderNewerConversationsQuery, Types.OlderNewerConversationsQueryVariables>(OlderNewerConversationsDocument, options);
      }
export function useOlderNewerConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OlderNewerConversationsQuery, Types.OlderNewerConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OlderNewerConversationsQuery, Types.OlderNewerConversationsQueryVariables>(OlderNewerConversationsDocument, options);
        }
export type OlderNewerConversationsQueryHookResult = ReturnType<typeof useOlderNewerConversationsQuery>;
export type OlderNewerConversationsLazyQueryHookResult = ReturnType<typeof useOlderNewerConversationsLazyQuery>;
export type OlderNewerConversationsQueryResult = Apollo.QueryResult<Types.OlderNewerConversationsQuery, Types.OlderNewerConversationsQueryVariables>;
export const AllPayoutTypesDocument = gql`
    query AllPayoutTypes($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToAdvertiserId: Int, $limit: Int, $offset: Int) {
  payoutTypes: payout_type(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_advertiser_id: {_eq: $belongsToAdvertiserId}}
    order_by: {advertiser: {name: asc}, name: asc}
  ) {
    ...PayoutTypeFields
  }
}
    ${PayoutTypeFieldsFragmentDoc}`;

/**
 * __useAllPayoutTypesQuery__
 *
 * To run a query within a React component, call `useAllPayoutTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPayoutTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPayoutTypesQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToAdvertiserId: // value for 'belongsToAdvertiserId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllPayoutTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPayoutTypesQuery, Types.AllPayoutTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPayoutTypesQuery, Types.AllPayoutTypesQueryVariables>(AllPayoutTypesDocument, options);
      }
export function useAllPayoutTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPayoutTypesQuery, Types.AllPayoutTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPayoutTypesQuery, Types.AllPayoutTypesQueryVariables>(AllPayoutTypesDocument, options);
        }
export type AllPayoutTypesQueryHookResult = ReturnType<typeof useAllPayoutTypesQuery>;
export type AllPayoutTypesLazyQueryHookResult = ReturnType<typeof useAllPayoutTypesLazyQuery>;
export type AllPayoutTypesQueryResult = Apollo.QueryResult<Types.AllPayoutTypesQuery, Types.AllPayoutTypesQueryVariables>;
export const AllPayoutTypesByAdvertiserIdQueryDocument = gql`
    query AllPayoutTypesByAdvertiserIdQuery($belongsToAdvertiserId: Int!) {
  payoutTypes: payout_type(
    where: {belongs_to_advertiser_id: {_eq: $belongsToAdvertiserId}}
    order_by: {name: asc}
  ) {
    ...PayoutTypeFields
  }
}
    ${PayoutTypeFieldsFragmentDoc}`;

/**
 * __useAllPayoutTypesByAdvertiserIdQuery__
 *
 * To run a query within a React component, call `useAllPayoutTypesByAdvertiserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPayoutTypesByAdvertiserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPayoutTypesByAdvertiserIdQuery({
 *   variables: {
 *      belongsToAdvertiserId: // value for 'belongsToAdvertiserId'
 *   },
 * });
 */
export function useAllPayoutTypesByAdvertiserIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllPayoutTypesByAdvertiserIdQuery, Types.AllPayoutTypesByAdvertiserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPayoutTypesByAdvertiserIdQuery, Types.AllPayoutTypesByAdvertiserIdQueryVariables>(AllPayoutTypesByAdvertiserIdQueryDocument, options);
      }
export function useAllPayoutTypesByAdvertiserIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPayoutTypesByAdvertiserIdQuery, Types.AllPayoutTypesByAdvertiserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPayoutTypesByAdvertiserIdQuery, Types.AllPayoutTypesByAdvertiserIdQueryVariables>(AllPayoutTypesByAdvertiserIdQueryDocument, options);
        }
export type AllPayoutTypesByAdvertiserIdQueryHookResult = ReturnType<typeof useAllPayoutTypesByAdvertiserIdQuery>;
export type AllPayoutTypesByAdvertiserIdQueryLazyQueryHookResult = ReturnType<typeof useAllPayoutTypesByAdvertiserIdQueryLazyQuery>;
export type AllPayoutTypesByAdvertiserIdQueryQueryResult = Apollo.QueryResult<Types.AllPayoutTypesByAdvertiserIdQuery, Types.AllPayoutTypesByAdvertiserIdQueryVariables>;
export const PayoutTypeByIdDocument = gql`
    query PayoutTypeById($id: Int!) {
  payoutType: payout_type_by_pk(id: $id) {
    ...PayoutTypeFields
  }
}
    ${PayoutTypeFieldsFragmentDoc}`;

/**
 * __usePayoutTypeByIdQuery__
 *
 * To run a query within a React component, call `usePayoutTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutTypeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayoutTypeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PayoutTypeByIdQuery, Types.PayoutTypeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PayoutTypeByIdQuery, Types.PayoutTypeByIdQueryVariables>(PayoutTypeByIdDocument, options);
      }
export function usePayoutTypeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PayoutTypeByIdQuery, Types.PayoutTypeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PayoutTypeByIdQuery, Types.PayoutTypeByIdQueryVariables>(PayoutTypeByIdDocument, options);
        }
export type PayoutTypeByIdQueryHookResult = ReturnType<typeof usePayoutTypeByIdQuery>;
export type PayoutTypeByIdLazyQueryHookResult = ReturnType<typeof usePayoutTypeByIdLazyQuery>;
export type PayoutTypeByIdQueryResult = Apollo.QueryResult<Types.PayoutTypeByIdQuery, Types.PayoutTypeByIdQueryVariables>;
export const UpdatePayoutTypeDocument = gql`
    mutation UpdatePayoutType($id: Int!, $changes: payout_type_set_input) {
  UpdatePayoutType: update_payout_type(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePayoutTypeMutationFn = Apollo.MutationFunction<Types.UpdatePayoutTypeMutation, Types.UpdatePayoutTypeMutationVariables>;

/**
 * __useUpdatePayoutTypeMutation__
 *
 * To run a mutation, you first call `useUpdatePayoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayoutTypeMutation, { data, loading, error }] = useUpdatePayoutTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePayoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePayoutTypeMutation, Types.UpdatePayoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePayoutTypeMutation, Types.UpdatePayoutTypeMutationVariables>(UpdatePayoutTypeDocument, options);
      }
export type UpdatePayoutTypeMutationHookResult = ReturnType<typeof useUpdatePayoutTypeMutation>;
export type UpdatePayoutTypeMutationResult = Apollo.MutationResult<Types.UpdatePayoutTypeMutation>;
export type UpdatePayoutTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePayoutTypeMutation, Types.UpdatePayoutTypeMutationVariables>;
export const InsertPayoutTypeDocument = gql`
    mutation InsertPayoutType($object: payout_type_insert_input!) {
  insert_payout_type(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPayoutTypeMutationFn = Apollo.MutationFunction<Types.InsertPayoutTypeMutation, Types.InsertPayoutTypeMutationVariables>;

/**
 * __useInsertPayoutTypeMutation__
 *
 * To run a mutation, you first call `useInsertPayoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPayoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPayoutTypeMutation, { data, loading, error }] = useInsertPayoutTypeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPayoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPayoutTypeMutation, Types.InsertPayoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPayoutTypeMutation, Types.InsertPayoutTypeMutationVariables>(InsertPayoutTypeDocument, options);
      }
export type InsertPayoutTypeMutationHookResult = ReturnType<typeof useInsertPayoutTypeMutation>;
export type InsertPayoutTypeMutationResult = Apollo.MutationResult<Types.InsertPayoutTypeMutation>;
export type InsertPayoutTypeMutationOptions = Apollo.BaseMutationOptions<Types.InsertPayoutTypeMutation, Types.InsertPayoutTypeMutationVariables>;
export const DeletePayoutTypeDocument = gql`
    mutation DeletePayoutType($id: Int!) {
  delete_payout_type(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePayoutTypeMutationFn = Apollo.MutationFunction<Types.DeletePayoutTypeMutation, Types.DeletePayoutTypeMutationVariables>;

/**
 * __useDeletePayoutTypeMutation__
 *
 * To run a mutation, you first call `useDeletePayoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayoutTypeMutation, { data, loading, error }] = useDeletePayoutTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePayoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePayoutTypeMutation, Types.DeletePayoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePayoutTypeMutation, Types.DeletePayoutTypeMutationVariables>(DeletePayoutTypeDocument, options);
      }
export type DeletePayoutTypeMutationHookResult = ReturnType<typeof useDeletePayoutTypeMutation>;
export type DeletePayoutTypeMutationResult = Apollo.MutationResult<Types.DeletePayoutTypeMutation>;
export type DeletePayoutTypeMutationOptions = Apollo.BaseMutationOptions<Types.DeletePayoutTypeMutation, Types.DeletePayoutTypeMutationVariables>;
export const AllPermissionsDocument = gql`
    query AllPermissions {
  permissions: permission(order_by: {permission_key: asc}) {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;

/**
 * __useAllPermissionsQuery__
 *
 * To run a query within a React component, call `useAllPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPermissionsQuery, Types.AllPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPermissionsQuery, Types.AllPermissionsQueryVariables>(AllPermissionsDocument, options);
      }
export function useAllPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPermissionsQuery, Types.AllPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPermissionsQuery, Types.AllPermissionsQueryVariables>(AllPermissionsDocument, options);
        }
export type AllPermissionsQueryHookResult = ReturnType<typeof useAllPermissionsQuery>;
export type AllPermissionsLazyQueryHookResult = ReturnType<typeof useAllPermissionsLazyQuery>;
export type AllPermissionsQueryResult = Apollo.QueryResult<Types.AllPermissionsQuery, Types.AllPermissionsQueryVariables>;
export const PermissionDocument = gql`
    query Permission($id: Int!) {
  permission: permission(where: {id: {_eq: $id}}) {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;

/**
 * __usePermissionQuery__
 *
 * To run a query within a React component, call `usePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionQuery(baseOptions: Apollo.QueryHookOptions<Types.PermissionQuery, Types.PermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PermissionQuery, Types.PermissionQueryVariables>(PermissionDocument, options);
      }
export function usePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PermissionQuery, Types.PermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PermissionQuery, Types.PermissionQueryVariables>(PermissionDocument, options);
        }
export type PermissionQueryHookResult = ReturnType<typeof usePermissionQuery>;
export type PermissionLazyQueryHookResult = ReturnType<typeof usePermissionLazyQuery>;
export type PermissionQueryResult = Apollo.QueryResult<Types.PermissionQuery, Types.PermissionQueryVariables>;
export const UpdatePermissionDocument = gql`
    mutation UpdatePermission($id: Int!, $changes: permission_set_input) {
  UpdatePermission: update_permission(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>(UpdatePermissionDocument, options);
      }
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<Types.UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>;
export const InsertPermissionDocument = gql`
    mutation InsertPermission($object: permission_insert_input!) {
  insert_permission(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPermissionMutationFn = Apollo.MutationFunction<Types.InsertPermissionMutation, Types.InsertPermissionMutationVariables>;

/**
 * __useInsertPermissionMutation__
 *
 * To run a mutation, you first call `useInsertPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPermissionMutation, { data, loading, error }] = useInsertPermissionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPermissionMutation, Types.InsertPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPermissionMutation, Types.InsertPermissionMutationVariables>(InsertPermissionDocument, options);
      }
export type InsertPermissionMutationHookResult = ReturnType<typeof useInsertPermissionMutation>;
export type InsertPermissionMutationResult = Apollo.MutationResult<Types.InsertPermissionMutation>;
export type InsertPermissionMutationOptions = Apollo.BaseMutationOptions<Types.InsertPermissionMutation, Types.InsertPermissionMutationVariables>;
export const DeletePermissionDocument = gql`
    mutation DeletePermission($id: Int!) {
  delete_permission(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePermissionMutationFn = Apollo.MutationFunction<Types.DeletePermissionMutation, Types.DeletePermissionMutationVariables>;

/**
 * __useDeletePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionMutation, { data, loading, error }] = useDeletePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePermissionMutation, Types.DeletePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePermissionMutation, Types.DeletePermissionMutationVariables>(DeletePermissionDocument, options);
      }
export type DeletePermissionMutationHookResult = ReturnType<typeof useDeletePermissionMutation>;
export type DeletePermissionMutationResult = Apollo.MutationResult<Types.DeletePermissionMutation>;
export type DeletePermissionMutationOptions = Apollo.BaseMutationOptions<Types.DeletePermissionMutation, Types.DeletePermissionMutationVariables>;
export const PermissionRoleDocument = gql`
    query PermissionRole($roleId: Int) {
  permissionRole: permission_role(where: {role_id: {_eq: $roleId}}) {
    ...PermissionRoleFields
  }
}
    ${PermissionRoleFieldsFragmentDoc}`;

/**
 * __usePermissionRoleQuery__
 *
 * To run a query within a React component, call `usePermissionRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function usePermissionRoleQuery(baseOptions?: Apollo.QueryHookOptions<Types.PermissionRoleQuery, Types.PermissionRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PermissionRoleQuery, Types.PermissionRoleQueryVariables>(PermissionRoleDocument, options);
      }
export function usePermissionRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PermissionRoleQuery, Types.PermissionRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PermissionRoleQuery, Types.PermissionRoleQueryVariables>(PermissionRoleDocument, options);
        }
export type PermissionRoleQueryHookResult = ReturnType<typeof usePermissionRoleQuery>;
export type PermissionRoleLazyQueryHookResult = ReturnType<typeof usePermissionRoleLazyQuery>;
export type PermissionRoleQueryResult = Apollo.QueryResult<Types.PermissionRoleQuery, Types.PermissionRoleQueryVariables>;
export const UpdatePermissionRoleDocument = gql`
    mutation UpdatePermissionRole($roleId: Int!, $changes: permission_role_set_input) {
  UpdatePermissionRole: update_permission_role(
    where: {role_id: {_eq: $roleId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePermissionRoleMutationFn = Apollo.MutationFunction<Types.UpdatePermissionRoleMutation, Types.UpdatePermissionRoleMutationVariables>;

/**
 * __useUpdatePermissionRoleMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionRoleMutation, { data, loading, error }] = useUpdatePermissionRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePermissionRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePermissionRoleMutation, Types.UpdatePermissionRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePermissionRoleMutation, Types.UpdatePermissionRoleMutationVariables>(UpdatePermissionRoleDocument, options);
      }
export type UpdatePermissionRoleMutationHookResult = ReturnType<typeof useUpdatePermissionRoleMutation>;
export type UpdatePermissionRoleMutationResult = Apollo.MutationResult<Types.UpdatePermissionRoleMutation>;
export type UpdatePermissionRoleMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePermissionRoleMutation, Types.UpdatePermissionRoleMutationVariables>;
export const InsertPermissionRoleDocument = gql`
    mutation InsertPermissionRole($object: [permission_role_insert_input!]!) {
  insert_permission_role(objects: $object) {
    returning {
      roleId: role_id
      permissionId: permission_id
    }
  }
}
    `;
export type InsertPermissionRoleMutationFn = Apollo.MutationFunction<Types.InsertPermissionRoleMutation, Types.InsertPermissionRoleMutationVariables>;

/**
 * __useInsertPermissionRoleMutation__
 *
 * To run a mutation, you first call `useInsertPermissionRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPermissionRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPermissionRoleMutation, { data, loading, error }] = useInsertPermissionRoleMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPermissionRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPermissionRoleMutation, Types.InsertPermissionRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPermissionRoleMutation, Types.InsertPermissionRoleMutationVariables>(InsertPermissionRoleDocument, options);
      }
export type InsertPermissionRoleMutationHookResult = ReturnType<typeof useInsertPermissionRoleMutation>;
export type InsertPermissionRoleMutationResult = Apollo.MutationResult<Types.InsertPermissionRoleMutation>;
export type InsertPermissionRoleMutationOptions = Apollo.BaseMutationOptions<Types.InsertPermissionRoleMutation, Types.InsertPermissionRoleMutationVariables>;
export const DeletePermissionRoleDocument = gql`
    mutation DeletePermissionRole($roleId: Int!, $permissionId: Int) {
  delete_permission_role(
    where: {role_id: {_eq: $roleId}, permission_id: {_eq: $permissionId}}
  ) {
    affected_rows
  }
}
    `;
export type DeletePermissionRoleMutationFn = Apollo.MutationFunction<Types.DeletePermissionRoleMutation, Types.DeletePermissionRoleMutationVariables>;

/**
 * __useDeletePermissionRoleMutation__
 *
 * To run a mutation, you first call `useDeletePermissionRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionRoleMutation, { data, loading, error }] = useDeletePermissionRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      permissionId: // value for 'permissionId'
 *   },
 * });
 */
export function useDeletePermissionRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePermissionRoleMutation, Types.DeletePermissionRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePermissionRoleMutation, Types.DeletePermissionRoleMutationVariables>(DeletePermissionRoleDocument, options);
      }
export type DeletePermissionRoleMutationHookResult = ReturnType<typeof useDeletePermissionRoleMutation>;
export type DeletePermissionRoleMutationResult = Apollo.MutationResult<Types.DeletePermissionRoleMutation>;
export type DeletePermissionRoleMutationOptions = Apollo.BaseMutationOptions<Types.DeletePermissionRoleMutation, Types.DeletePermissionRoleMutationVariables>;
export const PersonaAccountsByPersonaIdDocument = gql`
    query PersonaAccountsByPersonaId($personaId: Int, $assignedToRoleUserId: Int) {
  accounts: persona_account(
    where: {persona_id: {_eq: $personaId}, assigned_to_role_user_id: {_eq: $assignedToRoleUserId}}
  ) {
    ...PersonaAccountFields
  }
}
    ${PersonaAccountFieldsFragmentDoc}`;

/**
 * __usePersonaAccountsByPersonaIdQuery__
 *
 * To run a query within a React component, call `usePersonaAccountsByPersonaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonaAccountsByPersonaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonaAccountsByPersonaIdQuery({
 *   variables: {
 *      personaId: // value for 'personaId'
 *      assignedToRoleUserId: // value for 'assignedToRoleUserId'
 *   },
 * });
 */
export function usePersonaAccountsByPersonaIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonaAccountsByPersonaIdQuery, Types.PersonaAccountsByPersonaIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonaAccountsByPersonaIdQuery, Types.PersonaAccountsByPersonaIdQueryVariables>(PersonaAccountsByPersonaIdDocument, options);
      }
export function usePersonaAccountsByPersonaIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonaAccountsByPersonaIdQuery, Types.PersonaAccountsByPersonaIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonaAccountsByPersonaIdQuery, Types.PersonaAccountsByPersonaIdQueryVariables>(PersonaAccountsByPersonaIdDocument, options);
        }
export type PersonaAccountsByPersonaIdQueryHookResult = ReturnType<typeof usePersonaAccountsByPersonaIdQuery>;
export type PersonaAccountsByPersonaIdLazyQueryHookResult = ReturnType<typeof usePersonaAccountsByPersonaIdLazyQuery>;
export type PersonaAccountsByPersonaIdQueryResult = Apollo.QueryResult<Types.PersonaAccountsByPersonaIdQuery, Types.PersonaAccountsByPersonaIdQueryVariables>;
export const UpdatePersonaAccountDocument = gql`
    mutation UpdatePersonaAccount($id: Int!, $changes: persona_account_set_input) {
  updatePersonaAccount: update_persona_account(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePersonaAccountMutationFn = Apollo.MutationFunction<Types.UpdatePersonaAccountMutation, Types.UpdatePersonaAccountMutationVariables>;

/**
 * __useUpdatePersonaAccountMutation__
 *
 * To run a mutation, you first call `useUpdatePersonaAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonaAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonaAccountMutation, { data, loading, error }] = useUpdatePersonaAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePersonaAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePersonaAccountMutation, Types.UpdatePersonaAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePersonaAccountMutation, Types.UpdatePersonaAccountMutationVariables>(UpdatePersonaAccountDocument, options);
      }
export type UpdatePersonaAccountMutationHookResult = ReturnType<typeof useUpdatePersonaAccountMutation>;
export type UpdatePersonaAccountMutationResult = Apollo.MutationResult<Types.UpdatePersonaAccountMutation>;
export type UpdatePersonaAccountMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePersonaAccountMutation, Types.UpdatePersonaAccountMutationVariables>;
export const InsertPersonaAccountDocument = gql`
    mutation InsertPersonaAccount($object: persona_account_insert_input!) {
  insert_persona_account(objects: [$object]) {
    returning {
      ...PersonaAccountFields
    }
  }
}
    ${PersonaAccountFieldsFragmentDoc}`;
export type InsertPersonaAccountMutationFn = Apollo.MutationFunction<Types.InsertPersonaAccountMutation, Types.InsertPersonaAccountMutationVariables>;

/**
 * __useInsertPersonaAccountMutation__
 *
 * To run a mutation, you first call `useInsertPersonaAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPersonaAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPersonaAccountMutation, { data, loading, error }] = useInsertPersonaAccountMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPersonaAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPersonaAccountMutation, Types.InsertPersonaAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPersonaAccountMutation, Types.InsertPersonaAccountMutationVariables>(InsertPersonaAccountDocument, options);
      }
export type InsertPersonaAccountMutationHookResult = ReturnType<typeof useInsertPersonaAccountMutation>;
export type InsertPersonaAccountMutationResult = Apollo.MutationResult<Types.InsertPersonaAccountMutation>;
export type InsertPersonaAccountMutationOptions = Apollo.BaseMutationOptions<Types.InsertPersonaAccountMutation, Types.InsertPersonaAccountMutationVariables>;
export const DeletePersonaAccountDocument = gql`
    mutation DeletePersonaAccount($id: Int!) {
  delete_persona_account(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePersonaAccountMutationFn = Apollo.MutationFunction<Types.DeletePersonaAccountMutation, Types.DeletePersonaAccountMutationVariables>;

/**
 * __useDeletePersonaAccountMutation__
 *
 * To run a mutation, you first call `useDeletePersonaAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonaAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonaAccountMutation, { data, loading, error }] = useDeletePersonaAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonaAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePersonaAccountMutation, Types.DeletePersonaAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePersonaAccountMutation, Types.DeletePersonaAccountMutationVariables>(DeletePersonaAccountDocument, options);
      }
export type DeletePersonaAccountMutationHookResult = ReturnType<typeof useDeletePersonaAccountMutation>;
export type DeletePersonaAccountMutationResult = Apollo.MutationResult<Types.DeletePersonaAccountMutation>;
export type DeletePersonaAccountMutationOptions = Apollo.BaseMutationOptions<Types.DeletePersonaAccountMutation, Types.DeletePersonaAccountMutationVariables>;
export const AllPersonaDatasetsDocument = gql`
    query AllPersonaDatasets($limit: Int, $offset: Int, $workflowStatus: String) {
  personaDatasets: persona_dataset(
    limit: $limit
    offset: $offset
    where: {is_approved: {_eq: false}, has_been_used: {_eq: false}, workflow_status: {_eq: $workflowStatus}}
    order_by: {username: asc, id: asc}
  ) {
    ...PersonaDataSetFields
  }
  persona_dataset_aggregate(
    where: {is_approved: {_eq: false}, has_been_used: {_eq: false}, workflow_status: {_eq: $workflowStatus}}
  ) {
    aggregate {
      totalCount: count
    }
  }
  isApproved: persona_dataset_aggregate(where: {is_approved: {_eq: true}}) {
    aggregate {
      totalCount: count
    }
  }
  toApprove: persona_dataset_aggregate(
    where: {is_approved: {_eq: false}, has_been_used: {_eq: false}, workflow_status: {_eq: "to_review"}}
  ) {
    aggregate {
      totalCount: count
    }
  }
  hasBeenUsed: persona_dataset_aggregate(where: {has_been_used: {_eq: true}}) {
    aggregate {
      totalCount: count
    }
  }
  unUsed: persona_dataset_aggregate(
    where: {has_been_used: {_eq: false}, is_approved: {_eq: true}}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${PersonaDataSetFieldsFragmentDoc}`;

/**
 * __useAllPersonaDatasetsQuery__
 *
 * To run a query within a React component, call `useAllPersonaDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPersonaDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPersonaDatasetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      workflowStatus: // value for 'workflowStatus'
 *   },
 * });
 */
export function useAllPersonaDatasetsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPersonaDatasetsQuery, Types.AllPersonaDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPersonaDatasetsQuery, Types.AllPersonaDatasetsQueryVariables>(AllPersonaDatasetsDocument, options);
      }
export function useAllPersonaDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPersonaDatasetsQuery, Types.AllPersonaDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPersonaDatasetsQuery, Types.AllPersonaDatasetsQueryVariables>(AllPersonaDatasetsDocument, options);
        }
export type AllPersonaDatasetsQueryHookResult = ReturnType<typeof useAllPersonaDatasetsQuery>;
export type AllPersonaDatasetsLazyQueryHookResult = ReturnType<typeof useAllPersonaDatasetsLazyQuery>;
export type AllPersonaDatasetsQueryResult = Apollo.QueryResult<Types.AllPersonaDatasetsQuery, Types.AllPersonaDatasetsQueryVariables>;
export const AllApprovedPersonaDatasetsDocument = gql`
    query AllApprovedPersonaDatasets($limit: Int, $offset: Int) {
  personaDatasets: persona_dataset(
    limit: $limit
    offset: $offset
    where: {is_approved: {_eq: true}, has_been_used: {_eq: false}}
    order_by: {username: asc, id: asc}
  ) {
    ...PersonaDataSetFields
  }
}
    ${PersonaDataSetFieldsFragmentDoc}`;

/**
 * __useAllApprovedPersonaDatasetsQuery__
 *
 * To run a query within a React component, call `useAllApprovedPersonaDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApprovedPersonaDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApprovedPersonaDatasetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllApprovedPersonaDatasetsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllApprovedPersonaDatasetsQuery, Types.AllApprovedPersonaDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllApprovedPersonaDatasetsQuery, Types.AllApprovedPersonaDatasetsQueryVariables>(AllApprovedPersonaDatasetsDocument, options);
      }
export function useAllApprovedPersonaDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllApprovedPersonaDatasetsQuery, Types.AllApprovedPersonaDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllApprovedPersonaDatasetsQuery, Types.AllApprovedPersonaDatasetsQueryVariables>(AllApprovedPersonaDatasetsDocument, options);
        }
export type AllApprovedPersonaDatasetsQueryHookResult = ReturnType<typeof useAllApprovedPersonaDatasetsQuery>;
export type AllApprovedPersonaDatasetsLazyQueryHookResult = ReturnType<typeof useAllApprovedPersonaDatasetsLazyQuery>;
export type AllApprovedPersonaDatasetsQueryResult = Apollo.QueryResult<Types.AllApprovedPersonaDatasetsQuery, Types.AllApprovedPersonaDatasetsQueryVariables>;
export const PersonaDatasetByIdDocument = gql`
    query PersonaDatasetById($id: Int!) {
  personaDataset: persona_dataset_by_pk(id: $id) {
    ...PersonaDataSetFields
  }
}
    ${PersonaDataSetFieldsFragmentDoc}`;

/**
 * __usePersonaDatasetByIdQuery__
 *
 * To run a query within a React component, call `usePersonaDatasetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonaDatasetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonaDatasetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonaDatasetByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonaDatasetByIdQuery, Types.PersonaDatasetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonaDatasetByIdQuery, Types.PersonaDatasetByIdQueryVariables>(PersonaDatasetByIdDocument, options);
      }
export function usePersonaDatasetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonaDatasetByIdQuery, Types.PersonaDatasetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonaDatasetByIdQuery, Types.PersonaDatasetByIdQueryVariables>(PersonaDatasetByIdDocument, options);
        }
export type PersonaDatasetByIdQueryHookResult = ReturnType<typeof usePersonaDatasetByIdQuery>;
export type PersonaDatasetByIdLazyQueryHookResult = ReturnType<typeof usePersonaDatasetByIdLazyQuery>;
export type PersonaDatasetByIdQueryResult = Apollo.QueryResult<Types.PersonaDatasetByIdQuery, Types.PersonaDatasetByIdQueryVariables>;
export const InsertPersonaDatasetDocument = gql`
    mutation InsertPersonaDataset($object: persona_dataset_insert_input!) {
  insert_persona_dataset(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPersonaDatasetMutationFn = Apollo.MutationFunction<Types.InsertPersonaDatasetMutation, Types.InsertPersonaDatasetMutationVariables>;

/**
 * __useInsertPersonaDatasetMutation__
 *
 * To run a mutation, you first call `useInsertPersonaDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPersonaDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPersonaDatasetMutation, { data, loading, error }] = useInsertPersonaDatasetMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPersonaDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPersonaDatasetMutation, Types.InsertPersonaDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPersonaDatasetMutation, Types.InsertPersonaDatasetMutationVariables>(InsertPersonaDatasetDocument, options);
      }
export type InsertPersonaDatasetMutationHookResult = ReturnType<typeof useInsertPersonaDatasetMutation>;
export type InsertPersonaDatasetMutationResult = Apollo.MutationResult<Types.InsertPersonaDatasetMutation>;
export type InsertPersonaDatasetMutationOptions = Apollo.BaseMutationOptions<Types.InsertPersonaDatasetMutation, Types.InsertPersonaDatasetMutationVariables>;
export const UpdatePersonaDatasetDocument = gql`
    mutation UpdatePersonaDataset($id: Int!, $changes: persona_dataset_set_input) {
  update_persona_dataset(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePersonaDatasetMutationFn = Apollo.MutationFunction<Types.UpdatePersonaDatasetMutation, Types.UpdatePersonaDatasetMutationVariables>;

/**
 * __useUpdatePersonaDatasetMutation__
 *
 * To run a mutation, you first call `useUpdatePersonaDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonaDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonaDatasetMutation, { data, loading, error }] = useUpdatePersonaDatasetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePersonaDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePersonaDatasetMutation, Types.UpdatePersonaDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePersonaDatasetMutation, Types.UpdatePersonaDatasetMutationVariables>(UpdatePersonaDatasetDocument, options);
      }
export type UpdatePersonaDatasetMutationHookResult = ReturnType<typeof useUpdatePersonaDatasetMutation>;
export type UpdatePersonaDatasetMutationResult = Apollo.MutationResult<Types.UpdatePersonaDatasetMutation>;
export type UpdatePersonaDatasetMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePersonaDatasetMutation, Types.UpdatePersonaDatasetMutationVariables>;
export const DeletePersonaDatasetDocument = gql`
    mutation DeletePersonaDataset($id: Int!) {
  delete_persona_dataset(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePersonaDatasetMutationFn = Apollo.MutationFunction<Types.DeletePersonaDatasetMutation, Types.DeletePersonaDatasetMutationVariables>;

/**
 * __useDeletePersonaDatasetMutation__
 *
 * To run a mutation, you first call `useDeletePersonaDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonaDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonaDatasetMutation, { data, loading, error }] = useDeletePersonaDatasetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonaDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePersonaDatasetMutation, Types.DeletePersonaDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePersonaDatasetMutation, Types.DeletePersonaDatasetMutationVariables>(DeletePersonaDatasetDocument, options);
      }
export type DeletePersonaDatasetMutationHookResult = ReturnType<typeof useDeletePersonaDatasetMutation>;
export type DeletePersonaDatasetMutationResult = Apollo.MutationResult<Types.DeletePersonaDatasetMutation>;
export type DeletePersonaDatasetMutationOptions = Apollo.BaseMutationOptions<Types.DeletePersonaDatasetMutation, Types.DeletePersonaDatasetMutationVariables>;
export const AllPersonasDocument = gql`
    query AllPersonas($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $createdByRoleUserId: Int, $username: String, $limit: Int, $offset: Int, $needsBio: Boolean) {
  personas: persona(
    where: {_or: [{belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, created_by_role_user_id: {_eq: $createdByRoleUserId}, username: {_ilike: $username}, bio: {_is_null: $needsBio}}, {belongs_to_company_id: {_eq: $belongsToCompanyId}, phone_numbers: {belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}, username: {_ilike: $username}, bio: {_is_null: $needsBio}}]}
    order_by: {username: asc}
    limit: $limit
    offset: $offset
  ) {
    id
    firstName: first_name
    lastName: last_name
    headline
    bio
    username
    belongsToCompanyId: belongs_to_company_id
    medias: persona_media(
      order_by: {media: {rank: asc}}
      where: {media: {media_type: {_eq: "avatar"}}}
    ) {
      media {
        ...MediaFields
      }
    }
    conversations_aggregate {
      aggregate {
        count
      }
    }
    persona_accounts_aggregate {
      aggregate {
        count
      }
    }
  }
  persona_aggregate(
    where: {_or: [{belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, created_by_role_user_id: {_eq: $createdByRoleUserId}, username: {_ilike: $username}, bio: {_is_null: $needsBio}}, {belongs_to_company_id: {_eq: $belongsToCompanyId}, phone_numbers: {belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}, username: {_ilike: $username}, bio: {_is_null: $needsBio}}]}
    order_by: {username: asc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${MediaFieldsFragmentDoc}`;

/**
 * __useAllPersonasQuery__
 *
 * To run a query within a React component, call `useAllPersonasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPersonasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPersonasQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      createdByRoleUserId: // value for 'createdByRoleUserId'
 *      username: // value for 'username'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      needsBio: // value for 'needsBio'
 *   },
 * });
 */
export function useAllPersonasQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPersonasQuery, Types.AllPersonasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPersonasQuery, Types.AllPersonasQueryVariables>(AllPersonasDocument, options);
      }
export function useAllPersonasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPersonasQuery, Types.AllPersonasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPersonasQuery, Types.AllPersonasQueryVariables>(AllPersonasDocument, options);
        }
export type AllPersonasQueryHookResult = ReturnType<typeof useAllPersonasQuery>;
export type AllPersonasLazyQueryHookResult = ReturnType<typeof useAllPersonasLazyQuery>;
export type AllPersonasQueryResult = Apollo.QueryResult<Types.AllPersonasQuery, Types.AllPersonasQueryVariables>;
export const AllPersonasByPhoneNumberDocument = gql`
    query AllPersonasByPhoneNumber($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $username: String, $limit: Int, $offset: Int, $needsBio: Boolean) {
  personas: persona(
    where: {phone_numbers: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}, username: {_ilike: $username}}
    order_by: {username: asc}
    limit: $limit
    offset: $offset
  ) {
    id
    firstName: first_name
    lastName: last_name
    headline
    bio
    username
    belongsToCompanyId: belongs_to_company_id
    medias: persona_media(
      order_by: {media: {rank: asc}}
      where: {media: {media_type: {_eq: "avatar"}}}
    ) {
      media {
        ...MediaFields
      }
    }
    conversations_aggregate {
      aggregate {
        count
      }
    }
    persona_accounts_aggregate {
      aggregate {
        count
      }
    }
  }
  persona_aggregate(
    where: {phone_numbers: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}, username: {_ilike: $username}}
    order_by: {username: asc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${MediaFieldsFragmentDoc}`;

/**
 * __useAllPersonasByPhoneNumberQuery__
 *
 * To run a query within a React component, call `useAllPersonasByPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPersonasByPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPersonasByPhoneNumberQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      username: // value for 'username'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      needsBio: // value for 'needsBio'
 *   },
 * });
 */
export function useAllPersonasByPhoneNumberQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPersonasByPhoneNumberQuery, Types.AllPersonasByPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPersonasByPhoneNumberQuery, Types.AllPersonasByPhoneNumberQueryVariables>(AllPersonasByPhoneNumberDocument, options);
      }
export function useAllPersonasByPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPersonasByPhoneNumberQuery, Types.AllPersonasByPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPersonasByPhoneNumberQuery, Types.AllPersonasByPhoneNumberQueryVariables>(AllPersonasByPhoneNumberDocument, options);
        }
export type AllPersonasByPhoneNumberQueryHookResult = ReturnType<typeof useAllPersonasByPhoneNumberQuery>;
export type AllPersonasByPhoneNumberLazyQueryHookResult = ReturnType<typeof useAllPersonasByPhoneNumberLazyQuery>;
export type AllPersonasByPhoneNumberQueryResult = Apollo.QueryResult<Types.AllPersonasByPhoneNumberQuery, Types.AllPersonasByPhoneNumberQueryVariables>;
export const AllPersonasDropdownDocument = gql`
    query AllPersonasDropdown($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $createdByRoleUserId: Int, $username: String, $limit: Int) {
  personas: persona(
    where: {_or: [{belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, created_by_role_user_id: {_eq: $createdByRoleUserId}, username: {_ilike: $username}}, {belongs_to_company_id: {_eq: $belongsToCompanyId}, phone_numbers: {belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}, username: {_ilike: $username}}]}
    order_by: {username: asc}
    limit: $limit
  ) {
    id
    firstName: first_name
    lastName: last_name
    username
    belongsToCompanyId: belongs_to_company_id
  }
}
    `;

/**
 * __useAllPersonasDropdownQuery__
 *
 * To run a query within a React component, call `useAllPersonasDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPersonasDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPersonasDropdownQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      createdByRoleUserId: // value for 'createdByRoleUserId'
 *      username: // value for 'username'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllPersonasDropdownQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPersonasDropdownQuery, Types.AllPersonasDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPersonasDropdownQuery, Types.AllPersonasDropdownQueryVariables>(AllPersonasDropdownDocument, options);
      }
export function useAllPersonasDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPersonasDropdownQuery, Types.AllPersonasDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPersonasDropdownQuery, Types.AllPersonasDropdownQueryVariables>(AllPersonasDropdownDocument, options);
        }
export type AllPersonasDropdownQueryHookResult = ReturnType<typeof useAllPersonasDropdownQuery>;
export type AllPersonasDropdownLazyQueryHookResult = ReturnType<typeof useAllPersonasDropdownLazyQuery>;
export type AllPersonasDropdownQueryResult = Apollo.QueryResult<Types.AllPersonasDropdownQuery, Types.AllPersonasDropdownQueryVariables>;
export const PersonasByFilterDocument = gql`
    query PersonasByFilter($filter: persona_bool_exp) {
  personas: persona(where: $filter) {
    ...PersonaFields
  }
}
    ${PersonaFieldsFragmentDoc}`;

/**
 * __usePersonasByFilterQuery__
 *
 * To run a query within a React component, call `usePersonasByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonasByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonasByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonasByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonasByFilterQuery, Types.PersonasByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonasByFilterQuery, Types.PersonasByFilterQueryVariables>(PersonasByFilterDocument, options);
      }
export function usePersonasByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonasByFilterQuery, Types.PersonasByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonasByFilterQuery, Types.PersonasByFilterQueryVariables>(PersonasByFilterDocument, options);
        }
export type PersonasByFilterQueryHookResult = ReturnType<typeof usePersonasByFilterQuery>;
export type PersonasByFilterLazyQueryHookResult = ReturnType<typeof usePersonasByFilterLazyQuery>;
export type PersonasByFilterQueryResult = Apollo.QueryResult<Types.PersonasByFilterQuery, Types.PersonasByFilterQueryVariables>;
export const GetAllPersonasSimpleDocument = gql`
    query getAllPersonasSimple($belongsToCompanyId: [Int!], $belongsToRoleAffiliateId: Int, $createdByRoleUserId: Int, $limit: Int) {
  personas: persona(
    where: {belongs_to_company_id: {_in: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, created_by_role_user_id: {_eq: $createdByRoleUserId}}
    order_by: {username: asc}
    limit: $limit
  ) {
    id
    username
    belongsToCompanyId: belongs_to_company_id
  }
}
    `;

/**
 * __useGetAllPersonasSimpleQuery__
 *
 * To run a query within a React component, call `useGetAllPersonasSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPersonasSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPersonasSimpleQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      createdByRoleUserId: // value for 'createdByRoleUserId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllPersonasSimpleQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllPersonasSimpleQuery, Types.GetAllPersonasSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllPersonasSimpleQuery, Types.GetAllPersonasSimpleQueryVariables>(GetAllPersonasSimpleDocument, options);
      }
export function useGetAllPersonasSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllPersonasSimpleQuery, Types.GetAllPersonasSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllPersonasSimpleQuery, Types.GetAllPersonasSimpleQueryVariables>(GetAllPersonasSimpleDocument, options);
        }
export type GetAllPersonasSimpleQueryHookResult = ReturnType<typeof useGetAllPersonasSimpleQuery>;
export type GetAllPersonasSimpleLazyQueryHookResult = ReturnType<typeof useGetAllPersonasSimpleLazyQuery>;
export type GetAllPersonasSimpleQueryResult = Apollo.QueryResult<Types.GetAllPersonasSimpleQuery, Types.GetAllPersonasSimpleQueryVariables>;
export const GetPersonaByUsernameDocument = gql`
    query getPersonaByUsername($username: String!) {
  persona(where: {username: {_eq: $username}}) {
    id
  }
}
    `;

/**
 * __useGetPersonaByUsernameQuery__
 *
 * To run a query within a React component, call `useGetPersonaByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetPersonaByUsernameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPersonaByUsernameQuery, Types.GetPersonaByUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPersonaByUsernameQuery, Types.GetPersonaByUsernameQueryVariables>(GetPersonaByUsernameDocument, options);
      }
export function useGetPersonaByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPersonaByUsernameQuery, Types.GetPersonaByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPersonaByUsernameQuery, Types.GetPersonaByUsernameQueryVariables>(GetPersonaByUsernameDocument, options);
        }
export type GetPersonaByUsernameQueryHookResult = ReturnType<typeof useGetPersonaByUsernameQuery>;
export type GetPersonaByUsernameLazyQueryHookResult = ReturnType<typeof useGetPersonaByUsernameLazyQuery>;
export type GetPersonaByUsernameQueryResult = Apollo.QueryResult<Types.GetPersonaByUsernameQuery, Types.GetPersonaByUsernameQueryVariables>;
export const GetPersonaByFromUrlDocument = gql`
    query getPersonaByFromUrl($fromUrl: String!) {
  persona(where: {from_url: {_eq: $fromUrl}}) {
    id
  }
}
    `;

/**
 * __useGetPersonaByFromUrlQuery__
 *
 * To run a query within a React component, call `useGetPersonaByFromUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaByFromUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaByFromUrlQuery({
 *   variables: {
 *      fromUrl: // value for 'fromUrl'
 *   },
 * });
 */
export function useGetPersonaByFromUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPersonaByFromUrlQuery, Types.GetPersonaByFromUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPersonaByFromUrlQuery, Types.GetPersonaByFromUrlQueryVariables>(GetPersonaByFromUrlDocument, options);
      }
export function useGetPersonaByFromUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPersonaByFromUrlQuery, Types.GetPersonaByFromUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPersonaByFromUrlQuery, Types.GetPersonaByFromUrlQueryVariables>(GetPersonaByFromUrlDocument, options);
        }
export type GetPersonaByFromUrlQueryHookResult = ReturnType<typeof useGetPersonaByFromUrlQuery>;
export type GetPersonaByFromUrlLazyQueryHookResult = ReturnType<typeof useGetPersonaByFromUrlLazyQuery>;
export type GetPersonaByFromUrlQueryResult = Apollo.QueryResult<Types.GetPersonaByFromUrlQuery, Types.GetPersonaByFromUrlQueryVariables>;
export const PersonaDocument = gql`
    query Persona($id: Int!) {
  persona: persona_by_pk(id: $id) {
    ...PersonaFields
  }
}
    ${PersonaFieldsFragmentDoc}`;

/**
 * __usePersonaQuery__
 *
 * To run a query within a React component, call `usePersonaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonaQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonaQuery, Types.PersonaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonaQuery, Types.PersonaQueryVariables>(PersonaDocument, options);
      }
export function usePersonaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonaQuery, Types.PersonaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonaQuery, Types.PersonaQueryVariables>(PersonaDocument, options);
        }
export type PersonaQueryHookResult = ReturnType<typeof usePersonaQuery>;
export type PersonaLazyQueryHookResult = ReturnType<typeof usePersonaLazyQuery>;
export type PersonaQueryResult = Apollo.QueryResult<Types.PersonaQuery, Types.PersonaQueryVariables>;
export const InsertPersonaDocument = gql`
    mutation InsertPersona($object: persona_insert_input!) {
  insert_persona(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPersonaMutationFn = Apollo.MutationFunction<Types.InsertPersonaMutation, Types.InsertPersonaMutationVariables>;

/**
 * __useInsertPersonaMutation__
 *
 * To run a mutation, you first call `useInsertPersonaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPersonaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPersonaMutation, { data, loading, error }] = useInsertPersonaMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPersonaMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPersonaMutation, Types.InsertPersonaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPersonaMutation, Types.InsertPersonaMutationVariables>(InsertPersonaDocument, options);
      }
export type InsertPersonaMutationHookResult = ReturnType<typeof useInsertPersonaMutation>;
export type InsertPersonaMutationResult = Apollo.MutationResult<Types.InsertPersonaMutation>;
export type InsertPersonaMutationOptions = Apollo.BaseMutationOptions<Types.InsertPersonaMutation, Types.InsertPersonaMutationVariables>;
export const UpdatePersonaDocument = gql`
    mutation UpdatePersona($id: Int!, $changes: persona_set_input) {
  update_persona(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePersonaMutationFn = Apollo.MutationFunction<Types.UpdatePersonaMutation, Types.UpdatePersonaMutationVariables>;

/**
 * __useUpdatePersonaMutation__
 *
 * To run a mutation, you first call `useUpdatePersonaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonaMutation, { data, loading, error }] = useUpdatePersonaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePersonaMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePersonaMutation, Types.UpdatePersonaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePersonaMutation, Types.UpdatePersonaMutationVariables>(UpdatePersonaDocument, options);
      }
export type UpdatePersonaMutationHookResult = ReturnType<typeof useUpdatePersonaMutation>;
export type UpdatePersonaMutationResult = Apollo.MutationResult<Types.UpdatePersonaMutation>;
export type UpdatePersonaMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePersonaMutation, Types.UpdatePersonaMutationVariables>;
export const DeletePersonaDocument = gql`
    mutation DeletePersona($id: Int!) {
  delete_persona(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePersonaMutationFn = Apollo.MutationFunction<Types.DeletePersonaMutation, Types.DeletePersonaMutationVariables>;

/**
 * __useDeletePersonaMutation__
 *
 * To run a mutation, you first call `useDeletePersonaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonaMutation, { data, loading, error }] = useDeletePersonaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonaMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePersonaMutation, Types.DeletePersonaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePersonaMutation, Types.DeletePersonaMutationVariables>(DeletePersonaDocument, options);
      }
export type DeletePersonaMutationHookResult = ReturnType<typeof useDeletePersonaMutation>;
export type DeletePersonaMutationResult = Apollo.MutationResult<Types.DeletePersonaMutation>;
export type DeletePersonaMutationOptions = Apollo.BaseMutationOptions<Types.DeletePersonaMutation, Types.DeletePersonaMutationVariables>;
export const PhoneAreaCodeByIdDocument = gql`
    query PhoneAreaCodeById($id: Int!) {
  phoneAreaCodes: phone_area_code(where: {id: {_eq: $id}}, order_by: {id: desc}) {
    ...PhoneAreaCodeFields
  }
}
    ${PhoneAreaCodeFieldsFragmentDoc}`;

/**
 * __usePhoneAreaCodeByIdQuery__
 *
 * To run a query within a React component, call `usePhoneAreaCodeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneAreaCodeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneAreaCodeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhoneAreaCodeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PhoneAreaCodeByIdQuery, Types.PhoneAreaCodeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneAreaCodeByIdQuery, Types.PhoneAreaCodeByIdQueryVariables>(PhoneAreaCodeByIdDocument, options);
      }
export function usePhoneAreaCodeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneAreaCodeByIdQuery, Types.PhoneAreaCodeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneAreaCodeByIdQuery, Types.PhoneAreaCodeByIdQueryVariables>(PhoneAreaCodeByIdDocument, options);
        }
export type PhoneAreaCodeByIdQueryHookResult = ReturnType<typeof usePhoneAreaCodeByIdQuery>;
export type PhoneAreaCodeByIdLazyQueryHookResult = ReturnType<typeof usePhoneAreaCodeByIdLazyQuery>;
export type PhoneAreaCodeByIdQueryResult = Apollo.QueryResult<Types.PhoneAreaCodeByIdQuery, Types.PhoneAreaCodeByIdQueryVariables>;
export const PhoneAreaCodeByAreaCodeDocument = gql`
    query PhoneAreaCodeByAreaCode($areaCode: String!) {
  phoneAreaCodes: phone_area_code(
    where: {area_code: {_eq: $areaCode}}
    order_by: {id: desc}
    limit: 1
  ) {
    ...PhoneAreaCodeFields
  }
}
    ${PhoneAreaCodeFieldsFragmentDoc}`;

/**
 * __usePhoneAreaCodeByAreaCodeQuery__
 *
 * To run a query within a React component, call `usePhoneAreaCodeByAreaCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneAreaCodeByAreaCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneAreaCodeByAreaCodeQuery({
 *   variables: {
 *      areaCode: // value for 'areaCode'
 *   },
 * });
 */
export function usePhoneAreaCodeByAreaCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PhoneAreaCodeByAreaCodeQuery, Types.PhoneAreaCodeByAreaCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneAreaCodeByAreaCodeQuery, Types.PhoneAreaCodeByAreaCodeQueryVariables>(PhoneAreaCodeByAreaCodeDocument, options);
      }
export function usePhoneAreaCodeByAreaCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneAreaCodeByAreaCodeQuery, Types.PhoneAreaCodeByAreaCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneAreaCodeByAreaCodeQuery, Types.PhoneAreaCodeByAreaCodeQueryVariables>(PhoneAreaCodeByAreaCodeDocument, options);
        }
export type PhoneAreaCodeByAreaCodeQueryHookResult = ReturnType<typeof usePhoneAreaCodeByAreaCodeQuery>;
export type PhoneAreaCodeByAreaCodeLazyQueryHookResult = ReturnType<typeof usePhoneAreaCodeByAreaCodeLazyQuery>;
export type PhoneAreaCodeByAreaCodeQueryResult = Apollo.QueryResult<Types.PhoneAreaCodeByAreaCodeQuery, Types.PhoneAreaCodeByAreaCodeQueryVariables>;
export const UpdatePhoneAreaCodeDocument = gql`
    mutation UpdatePhoneAreaCode($id: Int!, $changes: phone_area_code_set_input) {
  updatePhoneAreaCode: update_phone_area_code(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePhoneAreaCodeMutationFn = Apollo.MutationFunction<Types.UpdatePhoneAreaCodeMutation, Types.UpdatePhoneAreaCodeMutationVariables>;

/**
 * __useUpdatePhoneAreaCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneAreaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneAreaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneAreaCodeMutation, { data, loading, error }] = useUpdatePhoneAreaCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePhoneAreaCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePhoneAreaCodeMutation, Types.UpdatePhoneAreaCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePhoneAreaCodeMutation, Types.UpdatePhoneAreaCodeMutationVariables>(UpdatePhoneAreaCodeDocument, options);
      }
export type UpdatePhoneAreaCodeMutationHookResult = ReturnType<typeof useUpdatePhoneAreaCodeMutation>;
export type UpdatePhoneAreaCodeMutationResult = Apollo.MutationResult<Types.UpdatePhoneAreaCodeMutation>;
export type UpdatePhoneAreaCodeMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePhoneAreaCodeMutation, Types.UpdatePhoneAreaCodeMutationVariables>;
export const InsertPhoneAreaCodeDocument = gql`
    mutation InsertPhoneAreaCode($phone_area_code: phone_area_code_insert_input!) {
  insert_phone_area_code(objects: [$phone_area_code]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPhoneAreaCodeMutationFn = Apollo.MutationFunction<Types.InsertPhoneAreaCodeMutation, Types.InsertPhoneAreaCodeMutationVariables>;

/**
 * __useInsertPhoneAreaCodeMutation__
 *
 * To run a mutation, you first call `useInsertPhoneAreaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhoneAreaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhoneAreaCodeMutation, { data, loading, error }] = useInsertPhoneAreaCodeMutation({
 *   variables: {
 *      phone_area_code: // value for 'phone_area_code'
 *   },
 * });
 */
export function useInsertPhoneAreaCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPhoneAreaCodeMutation, Types.InsertPhoneAreaCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPhoneAreaCodeMutation, Types.InsertPhoneAreaCodeMutationVariables>(InsertPhoneAreaCodeDocument, options);
      }
export type InsertPhoneAreaCodeMutationHookResult = ReturnType<typeof useInsertPhoneAreaCodeMutation>;
export type InsertPhoneAreaCodeMutationResult = Apollo.MutationResult<Types.InsertPhoneAreaCodeMutation>;
export type InsertPhoneAreaCodeMutationOptions = Apollo.BaseMutationOptions<Types.InsertPhoneAreaCodeMutation, Types.InsertPhoneAreaCodeMutationVariables>;
export const DeletePhoneAreaCodeDocument = gql`
    mutation DeletePhoneAreaCode($id: Int!) {
  delete_phone_area_code(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePhoneAreaCodeMutationFn = Apollo.MutationFunction<Types.DeletePhoneAreaCodeMutation, Types.DeletePhoneAreaCodeMutationVariables>;

/**
 * __useDeletePhoneAreaCodeMutation__
 *
 * To run a mutation, you first call `useDeletePhoneAreaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneAreaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneAreaCodeMutation, { data, loading, error }] = useDeletePhoneAreaCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneAreaCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePhoneAreaCodeMutation, Types.DeletePhoneAreaCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePhoneAreaCodeMutation, Types.DeletePhoneAreaCodeMutationVariables>(DeletePhoneAreaCodeDocument, options);
      }
export type DeletePhoneAreaCodeMutationHookResult = ReturnType<typeof useDeletePhoneAreaCodeMutation>;
export type DeletePhoneAreaCodeMutationResult = Apollo.MutationResult<Types.DeletePhoneAreaCodeMutation>;
export type DeletePhoneAreaCodeMutationOptions = Apollo.BaseMutationOptions<Types.DeletePhoneAreaCodeMutation, Types.DeletePhoneAreaCodeMutationVariables>;
export const PhoneNumberBlocksByFilterDocument = gql`
    query PhoneNumberBlocksByFilter($filter: phone_number_block_bool_exp, $orderBy: [phone_number_block_order_by!], $limit: Int) {
  phoneNumbers: phone_number_block(
    where: $filter
    order_by: $orderBy
    limit: $limit
  ) {
    ...PhoneNumberBlockFields
  }
}
    ${PhoneNumberBlockFieldsFragmentDoc}`;

/**
 * __usePhoneNumberBlocksByFilterQuery__
 *
 * To run a query within a React component, call `usePhoneNumberBlocksByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberBlocksByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberBlocksByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePhoneNumberBlocksByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.PhoneNumberBlocksByFilterQuery, Types.PhoneNumberBlocksByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneNumberBlocksByFilterQuery, Types.PhoneNumberBlocksByFilterQueryVariables>(PhoneNumberBlocksByFilterDocument, options);
      }
export function usePhoneNumberBlocksByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneNumberBlocksByFilterQuery, Types.PhoneNumberBlocksByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneNumberBlocksByFilterQuery, Types.PhoneNumberBlocksByFilterQueryVariables>(PhoneNumberBlocksByFilterDocument, options);
        }
export type PhoneNumberBlocksByFilterQueryHookResult = ReturnType<typeof usePhoneNumberBlocksByFilterQuery>;
export type PhoneNumberBlocksByFilterLazyQueryHookResult = ReturnType<typeof usePhoneNumberBlocksByFilterLazyQuery>;
export type PhoneNumberBlocksByFilterQueryResult = Apollo.QueryResult<Types.PhoneNumberBlocksByFilterQuery, Types.PhoneNumberBlocksByFilterQueryVariables>;
export const InsertPhoneNumberBlockDocument = gql`
    mutation InsertPhoneNumberBlock($objectArray: [phone_number_block_insert_input!]!) {
  insert_phone_number_block(
    objects: $objectArray
    on_conflict: {constraint: phone_number_block_phone_number_key, update_columns: []}
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertPhoneNumberBlockMutationFn = Apollo.MutationFunction<Types.InsertPhoneNumberBlockMutation, Types.InsertPhoneNumberBlockMutationVariables>;

/**
 * __useInsertPhoneNumberBlockMutation__
 *
 * To run a mutation, you first call `useInsertPhoneNumberBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhoneNumberBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhoneNumberBlockMutation, { data, loading, error }] = useInsertPhoneNumberBlockMutation({
 *   variables: {
 *      objectArray: // value for 'objectArray'
 *   },
 * });
 */
export function useInsertPhoneNumberBlockMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPhoneNumberBlockMutation, Types.InsertPhoneNumberBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPhoneNumberBlockMutation, Types.InsertPhoneNumberBlockMutationVariables>(InsertPhoneNumberBlockDocument, options);
      }
export type InsertPhoneNumberBlockMutationHookResult = ReturnType<typeof useInsertPhoneNumberBlockMutation>;
export type InsertPhoneNumberBlockMutationResult = Apollo.MutationResult<Types.InsertPhoneNumberBlockMutation>;
export type InsertPhoneNumberBlockMutationOptions = Apollo.BaseMutationOptions<Types.InsertPhoneNumberBlockMutation, Types.InsertPhoneNumberBlockMutationVariables>;
export const UpdatePhoneNumberBlockDocument = gql`
    mutation UpdatePhoneNumberBlock($id: Int!, $changes: phone_number_block_set_input) {
  updatePhoneNumberBlock: update_phone_number_block(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePhoneNumberBlockMutationFn = Apollo.MutationFunction<Types.UpdatePhoneNumberBlockMutation, Types.UpdatePhoneNumberBlockMutationVariables>;

/**
 * __useUpdatePhoneNumberBlockMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberBlockMutation, { data, loading, error }] = useUpdatePhoneNumberBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePhoneNumberBlockMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePhoneNumberBlockMutation, Types.UpdatePhoneNumberBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePhoneNumberBlockMutation, Types.UpdatePhoneNumberBlockMutationVariables>(UpdatePhoneNumberBlockDocument, options);
      }
export type UpdatePhoneNumberBlockMutationHookResult = ReturnType<typeof useUpdatePhoneNumberBlockMutation>;
export type UpdatePhoneNumberBlockMutationResult = Apollo.MutationResult<Types.UpdatePhoneNumberBlockMutation>;
export type UpdatePhoneNumberBlockMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePhoneNumberBlockMutation, Types.UpdatePhoneNumberBlockMutationVariables>;
export const DeletePhoneNumberBlockDocument = gql`
    mutation DeletePhoneNumberBlock($id: Int!) {
  deletePhoneNumberBlock: delete_phone_number_block(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePhoneNumberBlockMutationFn = Apollo.MutationFunction<Types.DeletePhoneNumberBlockMutation, Types.DeletePhoneNumberBlockMutationVariables>;

/**
 * __useDeletePhoneNumberBlockMutation__
 *
 * To run a mutation, you first call `useDeletePhoneNumberBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneNumberBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneNumberBlockMutation, { data, loading, error }] = useDeletePhoneNumberBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneNumberBlockMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePhoneNumberBlockMutation, Types.DeletePhoneNumberBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePhoneNumberBlockMutation, Types.DeletePhoneNumberBlockMutationVariables>(DeletePhoneNumberBlockDocument, options);
      }
export type DeletePhoneNumberBlockMutationHookResult = ReturnType<typeof useDeletePhoneNumberBlockMutation>;
export type DeletePhoneNumberBlockMutationResult = Apollo.MutationResult<Types.DeletePhoneNumberBlockMutation>;
export type DeletePhoneNumberBlockMutationOptions = Apollo.BaseMutationOptions<Types.DeletePhoneNumberBlockMutation, Types.DeletePhoneNumberBlockMutationVariables>;
export const AllPhoneNumbersDocument = gql`
    query AllPhoneNumbers {
  phoneNumbers: phone_number(order_by: [{is_active: desc}, {phone_number: asc}]) {
    ...PhoneNumberFields
  }
  campaigns: campaign(order_by: {name: asc}) {
    id
    name
  }
  offers: offer(order_by: {name: asc}) {
    id
    name
  }
  personas: persona(order_by: {first_name: asc}) {
    id
    firstName: first_name
    username
  }
}
    ${PhoneNumberFieldsFragmentDoc}`;

/**
 * __useAllPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useAllPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPhoneNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPhoneNumbersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPhoneNumbersQuery, Types.AllPhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPhoneNumbersQuery, Types.AllPhoneNumbersQueryVariables>(AllPhoneNumbersDocument, options);
      }
export function useAllPhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPhoneNumbersQuery, Types.AllPhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPhoneNumbersQuery, Types.AllPhoneNumbersQueryVariables>(AllPhoneNumbersDocument, options);
        }
export type AllPhoneNumbersQueryHookResult = ReturnType<typeof useAllPhoneNumbersQuery>;
export type AllPhoneNumbersLazyQueryHookResult = ReturnType<typeof useAllPhoneNumbersLazyQuery>;
export type AllPhoneNumbersQueryResult = Apollo.QueryResult<Types.AllPhoneNumbersQuery, Types.AllPhoneNumbersQueryVariables>;
export const AllActivePhoneNumbersDocument = gql`
    query AllActivePhoneNumbers {
  phoneNumbers: phone_number(where: {is_active: {_eq: true}}) {
    id
    phoneNumber: phone_number
  }
}
    `;

/**
 * __useAllActivePhoneNumbersQuery__
 *
 * To run a query within a React component, call `useAllActivePhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivePhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivePhoneNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllActivePhoneNumbersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllActivePhoneNumbersQuery, Types.AllActivePhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllActivePhoneNumbersQuery, Types.AllActivePhoneNumbersQueryVariables>(AllActivePhoneNumbersDocument, options);
      }
export function useAllActivePhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllActivePhoneNumbersQuery, Types.AllActivePhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllActivePhoneNumbersQuery, Types.AllActivePhoneNumbersQueryVariables>(AllActivePhoneNumbersDocument, options);
        }
export type AllActivePhoneNumbersQueryHookResult = ReturnType<typeof useAllActivePhoneNumbersQuery>;
export type AllActivePhoneNumbersLazyQueryHookResult = ReturnType<typeof useAllActivePhoneNumbersLazyQuery>;
export type AllActivePhoneNumbersQueryResult = Apollo.QueryResult<Types.AllActivePhoneNumbersQuery, Types.AllActivePhoneNumbersQueryVariables>;
export const AdminPhoneNumbersDocument = gql`
    query AdminPhoneNumbers($limit: Int, $offset: Int) {
  phoneNumbers: phone_number(
    limit: $limit
    offset: $offset
    order_by: [{is_active: desc}, {phone_number: asc}]
  ) {
    ...PhoneNumberFields
  }
  campaigns: campaign(order_by: {name: asc}) {
    id
    name
    company {
      id
      name
    }
  }
  offers: offer(order_by: {name: asc}) {
    id
    name
    isPrivate: is_private
    company {
      id
      name
    }
  }
  personas: persona(order_by: {first_name: asc}) {
    id
    firstName: first_name
    lastName: last_name
    username
    company {
      id
      name
    }
  }
  targetSites: target_site(order_by: {site_name: asc}) {
    id
    siteName: site_name
    company {
      id
      name
    }
  }
  companies: company(order_by: {name: asc}) {
    id
    name
  }
  smsProviders: sms_provider(order_by: {name: asc}) {
    ...SmsProviderFields
  }
  phone_number_aggregate(order_by: {phone_number: asc}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${PhoneNumberFieldsFragmentDoc}
${SmsProviderFieldsFragmentDoc}`;

/**
 * __useAdminPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useAdminPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPhoneNumbersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdminPhoneNumbersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdminPhoneNumbersQuery, Types.AdminPhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminPhoneNumbersQuery, Types.AdminPhoneNumbersQueryVariables>(AdminPhoneNumbersDocument, options);
      }
export function useAdminPhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminPhoneNumbersQuery, Types.AdminPhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminPhoneNumbersQuery, Types.AdminPhoneNumbersQueryVariables>(AdminPhoneNumbersDocument, options);
        }
export type AdminPhoneNumbersQueryHookResult = ReturnType<typeof useAdminPhoneNumbersQuery>;
export type AdminPhoneNumbersLazyQueryHookResult = ReturnType<typeof useAdminPhoneNumbersLazyQuery>;
export type AdminPhoneNumbersQueryResult = Apollo.QueryResult<Types.AdminPhoneNumbersQuery, Types.AdminPhoneNumbersQueryVariables>;
export const PhoneNumbersByFilterDocument = gql`
    query PhoneNumbersByFilter($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $smsProviderId: Int, $phoneSearch: String, $limit: Int, $offset: Int) {
  activePhoneNumbers: phone_number(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, phone_number: {_ilike: $phoneSearch}, sms_provider_id: {_eq: $smsProviderId}, is_active: {_eq: true}}
    order_by: {phone_number: asc}
  ) {
    ...PhoneNumberFields
  }
  activePhoneNumberAggregate: phone_number_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, phone_number: {_ilike: $phoneSearch}, sms_provider_id: {_eq: $smsProviderId}, is_active: {_eq: true}}
    order_by: {phone_number: asc}
  ) {
    aggregate {
      totalCount: count
    }
  }
  inactivePhoneNumbers: phone_number(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, phone_number: {_ilike: $phoneSearch}, sms_provider_id: {_eq: $smsProviderId}, is_active: {_eq: false}}
    order_by: {phone_number: asc}
  ) {
    ...PhoneNumberFields
  }
  inactivePhoneNumberAggregate: phone_number_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}, phone_number: {_ilike: $phoneSearch}, sms_provider_id: {_eq: $smsProviderId}, is_active: {_eq: false}}
    order_by: {phone_number: asc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${PhoneNumberFieldsFragmentDoc}`;

/**
 * __usePhoneNumbersByFilterQuery__
 *
 * To run a query within a React component, call `usePhoneNumbersByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumbersByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumbersByFilterQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      smsProviderId: // value for 'smsProviderId'
 *      phoneSearch: // value for 'phoneSearch'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePhoneNumbersByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.PhoneNumbersByFilterQuery, Types.PhoneNumbersByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneNumbersByFilterQuery, Types.PhoneNumbersByFilterQueryVariables>(PhoneNumbersByFilterDocument, options);
      }
export function usePhoneNumbersByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneNumbersByFilterQuery, Types.PhoneNumbersByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneNumbersByFilterQuery, Types.PhoneNumbersByFilterQueryVariables>(PhoneNumbersByFilterDocument, options);
        }
export type PhoneNumbersByFilterQueryHookResult = ReturnType<typeof usePhoneNumbersByFilterQuery>;
export type PhoneNumbersByFilterLazyQueryHookResult = ReturnType<typeof usePhoneNumbersByFilterLazyQuery>;
export type PhoneNumbersByFilterQueryResult = Apollo.QueryResult<Types.PhoneNumbersByFilterQuery, Types.PhoneNumbersByFilterQueryVariables>;
export const UpdatePhoneNumberDocument = gql`
    mutation UpdatePhoneNumber($id: Int!, $changes: phone_number_set_input) {
  updatePhoneNumber: update_phone_number(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>(UpdatePhoneNumberDocument, options);
      }
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationResult = Apollo.MutationResult<Types.UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>;
export const InsertPhoneNumberDocument = gql`
    mutation InsertPhoneNumber($object: phone_number_insert_input!) {
  insert_phone_number(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPhoneNumberMutationFn = Apollo.MutationFunction<Types.InsertPhoneNumberMutation, Types.InsertPhoneNumberMutationVariables>;

/**
 * __useInsertPhoneNumberMutation__
 *
 * To run a mutation, you first call `useInsertPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhoneNumberMutation, { data, loading, error }] = useInsertPhoneNumberMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPhoneNumberMutation, Types.InsertPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPhoneNumberMutation, Types.InsertPhoneNumberMutationVariables>(InsertPhoneNumberDocument, options);
      }
export type InsertPhoneNumberMutationHookResult = ReturnType<typeof useInsertPhoneNumberMutation>;
export type InsertPhoneNumberMutationResult = Apollo.MutationResult<Types.InsertPhoneNumberMutation>;
export type InsertPhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.InsertPhoneNumberMutation, Types.InsertPhoneNumberMutationVariables>;
export const DeletePhoneNumberDocument = gql`
    mutation DeletePhoneNumber($id: Int!) {
  delete_phone_number(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePhoneNumberMutationFn = Apollo.MutationFunction<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>;

/**
 * __useDeletePhoneNumberMutation__
 *
 * To run a mutation, you first call `useDeletePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneNumberMutation, { data, loading, error }] = useDeletePhoneNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>(DeletePhoneNumberDocument, options);
      }
export type DeletePhoneNumberMutationHookResult = ReturnType<typeof useDeletePhoneNumberMutation>;
export type DeletePhoneNumberMutationResult = Apollo.MutationResult<Types.DeletePhoneNumberMutation>;
export type DeletePhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>;
export const AllPhoneNumberWhitelistsDocument = gql`
    query AllPhoneNumberWhitelists($belongsToCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  phone_number_whitelists: phone_number_whitelist(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {phone_number: asc}
  ) {
    ...PhoneNumberWhitelistFields
  }
}
    ${PhoneNumberWhitelistFieldsFragmentDoc}`;

/**
 * __useAllPhoneNumberWhitelistsQuery__
 *
 * To run a query within a React component, call `useAllPhoneNumberWhitelistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPhoneNumberWhitelistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPhoneNumberWhitelistsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllPhoneNumberWhitelistsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPhoneNumberWhitelistsQuery, Types.AllPhoneNumberWhitelistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPhoneNumberWhitelistsQuery, Types.AllPhoneNumberWhitelistsQueryVariables>(AllPhoneNumberWhitelistsDocument, options);
      }
export function useAllPhoneNumberWhitelistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPhoneNumberWhitelistsQuery, Types.AllPhoneNumberWhitelistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPhoneNumberWhitelistsQuery, Types.AllPhoneNumberWhitelistsQueryVariables>(AllPhoneNumberWhitelistsDocument, options);
        }
export type AllPhoneNumberWhitelistsQueryHookResult = ReturnType<typeof useAllPhoneNumberWhitelistsQuery>;
export type AllPhoneNumberWhitelistsLazyQueryHookResult = ReturnType<typeof useAllPhoneNumberWhitelistsLazyQuery>;
export type AllPhoneNumberWhitelistsQueryResult = Apollo.QueryResult<Types.AllPhoneNumberWhitelistsQuery, Types.AllPhoneNumberWhitelistsQueryVariables>;
export const InsertPhoneNumberWhitelistDocument = gql`
    mutation InsertPhoneNumberWhitelist($phone_number: phone_number_whitelist_insert_input!) {
  insert_phone_number_whitelist(objects: [$phone_number]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPhoneNumberWhitelistMutationFn = Apollo.MutationFunction<Types.InsertPhoneNumberWhitelistMutation, Types.InsertPhoneNumberWhitelistMutationVariables>;

/**
 * __useInsertPhoneNumberWhitelistMutation__
 *
 * To run a mutation, you first call `useInsertPhoneNumberWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhoneNumberWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhoneNumberWhitelistMutation, { data, loading, error }] = useInsertPhoneNumberWhitelistMutation({
 *   variables: {
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useInsertPhoneNumberWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPhoneNumberWhitelistMutation, Types.InsertPhoneNumberWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPhoneNumberWhitelistMutation, Types.InsertPhoneNumberWhitelistMutationVariables>(InsertPhoneNumberWhitelistDocument, options);
      }
export type InsertPhoneNumberWhitelistMutationHookResult = ReturnType<typeof useInsertPhoneNumberWhitelistMutation>;
export type InsertPhoneNumberWhitelistMutationResult = Apollo.MutationResult<Types.InsertPhoneNumberWhitelistMutation>;
export type InsertPhoneNumberWhitelistMutationOptions = Apollo.BaseMutationOptions<Types.InsertPhoneNumberWhitelistMutation, Types.InsertPhoneNumberWhitelistMutationVariables>;
export const UpdatePhoneNumberWhitelistDocument = gql`
    mutation UpdatePhoneNumberWhitelist($id: Int!, $changes: phone_number_whitelist_set_input) {
  updatePhoneNumberWhitelist: update_phone_number_whitelist(
    where: {id: {_eq: $id}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePhoneNumberWhitelistMutationFn = Apollo.MutationFunction<Types.UpdatePhoneNumberWhitelistMutation, Types.UpdatePhoneNumberWhitelistMutationVariables>;

/**
 * __useUpdatePhoneNumberWhitelistMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberWhitelistMutation, { data, loading, error }] = useUpdatePhoneNumberWhitelistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePhoneNumberWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePhoneNumberWhitelistMutation, Types.UpdatePhoneNumberWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePhoneNumberWhitelistMutation, Types.UpdatePhoneNumberWhitelistMutationVariables>(UpdatePhoneNumberWhitelistDocument, options);
      }
export type UpdatePhoneNumberWhitelistMutationHookResult = ReturnType<typeof useUpdatePhoneNumberWhitelistMutation>;
export type UpdatePhoneNumberWhitelistMutationResult = Apollo.MutationResult<Types.UpdatePhoneNumberWhitelistMutation>;
export type UpdatePhoneNumberWhitelistMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePhoneNumberWhitelistMutation, Types.UpdatePhoneNumberWhitelistMutationVariables>;
export const DeletePhoneNumberWhitelistDocument = gql`
    mutation DeletePhoneNumberWhitelist($id: Int!) {
  deletePhoneNumberWhitelist: delete_phone_number_whitelist(
    where: {id: {_eq: $id}}
  ) {
    affected_rows
  }
}
    `;
export type DeletePhoneNumberWhitelistMutationFn = Apollo.MutationFunction<Types.DeletePhoneNumberWhitelistMutation, Types.DeletePhoneNumberWhitelistMutationVariables>;

/**
 * __useDeletePhoneNumberWhitelistMutation__
 *
 * To run a mutation, you first call `useDeletePhoneNumberWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneNumberWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneNumberWhitelistMutation, { data, loading, error }] = useDeletePhoneNumberWhitelistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneNumberWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePhoneNumberWhitelistMutation, Types.DeletePhoneNumberWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePhoneNumberWhitelistMutation, Types.DeletePhoneNumberWhitelistMutationVariables>(DeletePhoneNumberWhitelistDocument, options);
      }
export type DeletePhoneNumberWhitelistMutationHookResult = ReturnType<typeof useDeletePhoneNumberWhitelistMutation>;
export type DeletePhoneNumberWhitelistMutationResult = Apollo.MutationResult<Types.DeletePhoneNumberWhitelistMutation>;
export type DeletePhoneNumberWhitelistMutationOptions = Apollo.BaseMutationOptions<Types.DeletePhoneNumberWhitelistMutation, Types.DeletePhoneNumberWhitelistMutationVariables>;
export const PlanFeatureDocument = gql`
    query PlanFeature($planId: Int) {
  planFeature: plan_feature(where: {plan_id: {_eq: $planId}}) {
    ...PlanFeatureFields
  }
}
    ${PlanFeatureFieldsFragmentDoc}`;

/**
 * __usePlanFeatureQuery__
 *
 * To run a query within a React component, call `usePlanFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanFeatureQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanFeatureQuery(baseOptions?: Apollo.QueryHookOptions<Types.PlanFeatureQuery, Types.PlanFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanFeatureQuery, Types.PlanFeatureQueryVariables>(PlanFeatureDocument, options);
      }
export function usePlanFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanFeatureQuery, Types.PlanFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanFeatureQuery, Types.PlanFeatureQueryVariables>(PlanFeatureDocument, options);
        }
export type PlanFeatureQueryHookResult = ReturnType<typeof usePlanFeatureQuery>;
export type PlanFeatureLazyQueryHookResult = ReturnType<typeof usePlanFeatureLazyQuery>;
export type PlanFeatureQueryResult = Apollo.QueryResult<Types.PlanFeatureQuery, Types.PlanFeatureQueryVariables>;
export const PlanFeatureLimitDocument = gql`
    query PlanFeatureLimit($planId: Int!, $featureKey: String!) {
  planFeature: plan_feature(
    where: {plan_id: {_eq: $planId}, feature: {feature_key: {_eq: $featureKey}}}
  ) {
    id
    isUpgradeable: is_upgradeable
    isUsageBased: is_usage_based
    isUnlimited: is_unlimited
    isVisibleOnPlanPage: is_visible_on_plan_page
    isVisibleOnUsagePage: is_visible_on_usage_page
    limit
    planId: plan_id
    feature {
      ...FeatureFields
    }
  }
}
    ${FeatureFieldsFragmentDoc}`;

/**
 * __usePlanFeatureLimitQuery__
 *
 * To run a query within a React component, call `usePlanFeatureLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanFeatureLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanFeatureLimitQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      featureKey: // value for 'featureKey'
 *   },
 * });
 */
export function usePlanFeatureLimitQuery(baseOptions: Apollo.QueryHookOptions<Types.PlanFeatureLimitQuery, Types.PlanFeatureLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanFeatureLimitQuery, Types.PlanFeatureLimitQueryVariables>(PlanFeatureLimitDocument, options);
      }
export function usePlanFeatureLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanFeatureLimitQuery, Types.PlanFeatureLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanFeatureLimitQuery, Types.PlanFeatureLimitQueryVariables>(PlanFeatureLimitDocument, options);
        }
export type PlanFeatureLimitQueryHookResult = ReturnType<typeof usePlanFeatureLimitQuery>;
export type PlanFeatureLimitLazyQueryHookResult = ReturnType<typeof usePlanFeatureLimitLazyQuery>;
export type PlanFeatureLimitQueryResult = Apollo.QueryResult<Types.PlanFeatureLimitQuery, Types.PlanFeatureLimitQueryVariables>;
export const UpdatePlanFeatureDocument = gql`
    mutation UpdatePlanFeature($id: Int!, $changes: plan_feature_set_input) {
  UpdatePlanFeature: update_plan_feature(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePlanFeatureMutationFn = Apollo.MutationFunction<Types.UpdatePlanFeatureMutation, Types.UpdatePlanFeatureMutationVariables>;

/**
 * __useUpdatePlanFeatureMutation__
 *
 * To run a mutation, you first call `useUpdatePlanFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanFeatureMutation, { data, loading, error }] = useUpdatePlanFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePlanFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlanFeatureMutation, Types.UpdatePlanFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePlanFeatureMutation, Types.UpdatePlanFeatureMutationVariables>(UpdatePlanFeatureDocument, options);
      }
export type UpdatePlanFeatureMutationHookResult = ReturnType<typeof useUpdatePlanFeatureMutation>;
export type UpdatePlanFeatureMutationResult = Apollo.MutationResult<Types.UpdatePlanFeatureMutation>;
export type UpdatePlanFeatureMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePlanFeatureMutation, Types.UpdatePlanFeatureMutationVariables>;
export const InsertPlanFeatureDocument = gql`
    mutation InsertPlanFeature($object: [plan_feature_insert_input!]!) {
  insert_plan_feature(objects: $object) {
    returning {
      planId: plan_id
      featureId: feature_id
    }
  }
}
    `;
export type InsertPlanFeatureMutationFn = Apollo.MutationFunction<Types.InsertPlanFeatureMutation, Types.InsertPlanFeatureMutationVariables>;

/**
 * __useInsertPlanFeatureMutation__
 *
 * To run a mutation, you first call `useInsertPlanFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPlanFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPlanFeatureMutation, { data, loading, error }] = useInsertPlanFeatureMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPlanFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPlanFeatureMutation, Types.InsertPlanFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPlanFeatureMutation, Types.InsertPlanFeatureMutationVariables>(InsertPlanFeatureDocument, options);
      }
export type InsertPlanFeatureMutationHookResult = ReturnType<typeof useInsertPlanFeatureMutation>;
export type InsertPlanFeatureMutationResult = Apollo.MutationResult<Types.InsertPlanFeatureMutation>;
export type InsertPlanFeatureMutationOptions = Apollo.BaseMutationOptions<Types.InsertPlanFeatureMutation, Types.InsertPlanFeatureMutationVariables>;
export const DeletePlanFeatureDocument = gql`
    mutation DeletePlanFeature($planFeatureId: Int!) {
  delete_plan_feature(where: {id: {_eq: $planFeatureId}}) {
    affected_rows
  }
}
    `;
export type DeletePlanFeatureMutationFn = Apollo.MutationFunction<Types.DeletePlanFeatureMutation, Types.DeletePlanFeatureMutationVariables>;

/**
 * __useDeletePlanFeatureMutation__
 *
 * To run a mutation, you first call `useDeletePlanFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanFeatureMutation, { data, loading, error }] = useDeletePlanFeatureMutation({
 *   variables: {
 *      planFeatureId: // value for 'planFeatureId'
 *   },
 * });
 */
export function useDeletePlanFeatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlanFeatureMutation, Types.DeletePlanFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlanFeatureMutation, Types.DeletePlanFeatureMutationVariables>(DeletePlanFeatureDocument, options);
      }
export type DeletePlanFeatureMutationHookResult = ReturnType<typeof useDeletePlanFeatureMutation>;
export type DeletePlanFeatureMutationResult = Apollo.MutationResult<Types.DeletePlanFeatureMutation>;
export type DeletePlanFeatureMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlanFeatureMutation, Types.DeletePlanFeatureMutationVariables>;
export const AllPlansDocument = gql`
    query AllPlans {
  plans: plan(order_by: {name: asc}) {
    ...PlanFields
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __useAllPlansQuery__
 *
 * To run a query within a React component, call `useAllPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPlansQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPlansQuery, Types.AllPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPlansQuery, Types.AllPlansQueryVariables>(AllPlansDocument, options);
      }
export function useAllPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPlansQuery, Types.AllPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPlansQuery, Types.AllPlansQueryVariables>(AllPlansDocument, options);
        }
export type AllPlansQueryHookResult = ReturnType<typeof useAllPlansQuery>;
export type AllPlansLazyQueryHookResult = ReturnType<typeof useAllPlansLazyQuery>;
export type AllPlansQueryResult = Apollo.QueryResult<Types.AllPlansQuery, Types.AllPlansQueryVariables>;
export const PlanDocument = gql`
    query Plan($id: Int!) {
  plan: plan(where: {id: {_eq: $id}}, limit: 1) {
    ...PlanFields
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanQuery(baseOptions: Apollo.QueryHookOptions<Types.PlanQuery, Types.PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanQuery, Types.PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanQuery, Types.PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanQuery, Types.PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<Types.PlanQuery, Types.PlanQueryVariables>;
export const PlanByIdDocument = gql`
    query PlanById($id: Int!) {
  plan: plan(where: {id: {_eq: $id}}) {
    ...PlanFields
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __usePlanByIdQuery__
 *
 * To run a query within a React component, call `usePlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PlanByIdQuery, Types.PlanByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanByIdQuery, Types.PlanByIdQueryVariables>(PlanByIdDocument, options);
      }
export function usePlanByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanByIdQuery, Types.PlanByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanByIdQuery, Types.PlanByIdQueryVariables>(PlanByIdDocument, options);
        }
export type PlanByIdQueryHookResult = ReturnType<typeof usePlanByIdQuery>;
export type PlanByIdLazyQueryHookResult = ReturnType<typeof usePlanByIdLazyQuery>;
export type PlanByIdQueryResult = Apollo.QueryResult<Types.PlanByIdQuery, Types.PlanByIdQueryVariables>;
export const PlanByFilterDocument = gql`
    query PlanByFilter($filter: plan_bool_exp) {
  plans: plan(where: $filter) {
    ...PlanFields
  }
}
    ${PlanFieldsFragmentDoc}`;

/**
 * __usePlanByFilterQuery__
 *
 * To run a query within a React component, call `usePlanByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePlanByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.PlanByFilterQuery, Types.PlanByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanByFilterQuery, Types.PlanByFilterQueryVariables>(PlanByFilterDocument, options);
      }
export function usePlanByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanByFilterQuery, Types.PlanByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanByFilterQuery, Types.PlanByFilterQueryVariables>(PlanByFilterDocument, options);
        }
export type PlanByFilterQueryHookResult = ReturnType<typeof usePlanByFilterQuery>;
export type PlanByFilterLazyQueryHookResult = ReturnType<typeof usePlanByFilterLazyQuery>;
export type PlanByFilterQueryResult = Apollo.QueryResult<Types.PlanByFilterQuery, Types.PlanByFilterQueryVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($id: Int!, $changes: plan_set_input) {
  UpdatePlan: update_plan(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<Types.UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePlanMutation, Types.UpdatePlanMutationVariables>;
export const InsertPlanDocument = gql`
    mutation InsertPlan($object: plan_insert_input!) {
  insert_plan(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPlanMutationFn = Apollo.MutationFunction<Types.InsertPlanMutation, Types.InsertPlanMutationVariables>;

/**
 * __useInsertPlanMutation__
 *
 * To run a mutation, you first call `useInsertPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPlanMutation, { data, loading, error }] = useInsertPlanMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPlanMutation, Types.InsertPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPlanMutation, Types.InsertPlanMutationVariables>(InsertPlanDocument, options);
      }
export type InsertPlanMutationHookResult = ReturnType<typeof useInsertPlanMutation>;
export type InsertPlanMutationResult = Apollo.MutationResult<Types.InsertPlanMutation>;
export type InsertPlanMutationOptions = Apollo.BaseMutationOptions<Types.InsertPlanMutation, Types.InsertPlanMutationVariables>;
export const DeletePlanDocument = gql`
    mutation DeletePlan($id: Int!) {
  delete_plan(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<Types.DeletePlanMutation, Types.DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlanMutation, Types.DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlanMutation, Types.DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<Types.DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlanMutation, Types.DeletePlanMutationVariables>;
export const PlanRolePermissionDocument = gql`
    query PlanRolePermission($planRoleId: Int) {
  planRolePermission: plan_role_permission(
    where: {plan_role_id: {_eq: $planRoleId}}
  ) {
    ...PlanRolePermissionFields
  }
}
    ${PlanRolePermissionFieldsFragmentDoc}`;

/**
 * __usePlanRolePermissionQuery__
 *
 * To run a query within a React component, call `usePlanRolePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanRolePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanRolePermissionQuery({
 *   variables: {
 *      planRoleId: // value for 'planRoleId'
 *   },
 * });
 */
export function usePlanRolePermissionQuery(baseOptions?: Apollo.QueryHookOptions<Types.PlanRolePermissionQuery, Types.PlanRolePermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanRolePermissionQuery, Types.PlanRolePermissionQueryVariables>(PlanRolePermissionDocument, options);
      }
export function usePlanRolePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanRolePermissionQuery, Types.PlanRolePermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanRolePermissionQuery, Types.PlanRolePermissionQueryVariables>(PlanRolePermissionDocument, options);
        }
export type PlanRolePermissionQueryHookResult = ReturnType<typeof usePlanRolePermissionQuery>;
export type PlanRolePermissionLazyQueryHookResult = ReturnType<typeof usePlanRolePermissionLazyQuery>;
export type PlanRolePermissionQueryResult = Apollo.QueryResult<Types.PlanRolePermissionQuery, Types.PlanRolePermissionQueryVariables>;
export const UpdatePlanRolePermissionDocument = gql`
    mutation UpdatePlanRolePermission($planRoleId: Int!, $changes: plan_role_permission_set_input) {
  UpdatePlanRolePermission: update_plan_role_permission(
    where: {plan_role_id: {_eq: $planRoleId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePlanRolePermissionMutationFn = Apollo.MutationFunction<Types.UpdatePlanRolePermissionMutation, Types.UpdatePlanRolePermissionMutationVariables>;

/**
 * __useUpdatePlanRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePlanRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanRolePermissionMutation, { data, loading, error }] = useUpdatePlanRolePermissionMutation({
 *   variables: {
 *      planRoleId: // value for 'planRoleId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePlanRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlanRolePermissionMutation, Types.UpdatePlanRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePlanRolePermissionMutation, Types.UpdatePlanRolePermissionMutationVariables>(UpdatePlanRolePermissionDocument, options);
      }
export type UpdatePlanRolePermissionMutationHookResult = ReturnType<typeof useUpdatePlanRolePermissionMutation>;
export type UpdatePlanRolePermissionMutationResult = Apollo.MutationResult<Types.UpdatePlanRolePermissionMutation>;
export type UpdatePlanRolePermissionMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePlanRolePermissionMutation, Types.UpdatePlanRolePermissionMutationVariables>;
export const InsertPlanRolePermissionDocument = gql`
    mutation InsertPlanRolePermission($object: [plan_role_permission_insert_input!]!) {
  insert_plan_role_permission(objects: $object) {
    returning {
      planRoleId: plan_role_id
      permissionId: permission_id
    }
  }
}
    `;
export type InsertPlanRolePermissionMutationFn = Apollo.MutationFunction<Types.InsertPlanRolePermissionMutation, Types.InsertPlanRolePermissionMutationVariables>;

/**
 * __useInsertPlanRolePermissionMutation__
 *
 * To run a mutation, you first call `useInsertPlanRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPlanRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPlanRolePermissionMutation, { data, loading, error }] = useInsertPlanRolePermissionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPlanRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPlanRolePermissionMutation, Types.InsertPlanRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPlanRolePermissionMutation, Types.InsertPlanRolePermissionMutationVariables>(InsertPlanRolePermissionDocument, options);
      }
export type InsertPlanRolePermissionMutationHookResult = ReturnType<typeof useInsertPlanRolePermissionMutation>;
export type InsertPlanRolePermissionMutationResult = Apollo.MutationResult<Types.InsertPlanRolePermissionMutation>;
export type InsertPlanRolePermissionMutationOptions = Apollo.BaseMutationOptions<Types.InsertPlanRolePermissionMutation, Types.InsertPlanRolePermissionMutationVariables>;
export const DeletePlanRolePermissionDocument = gql`
    mutation DeletePlanRolePermission($planRoleId: Int!) {
  delete_plan_role_permission(where: {plan_role_id: {_eq: $planRoleId}}) {
    affected_rows
  }
}
    `;
export type DeletePlanRolePermissionMutationFn = Apollo.MutationFunction<Types.DeletePlanRolePermissionMutation, Types.DeletePlanRolePermissionMutationVariables>;

/**
 * __useDeletePlanRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePlanRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanRolePermissionMutation, { data, loading, error }] = useDeletePlanRolePermissionMutation({
 *   variables: {
 *      planRoleId: // value for 'planRoleId'
 *   },
 * });
 */
export function useDeletePlanRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlanRolePermissionMutation, Types.DeletePlanRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlanRolePermissionMutation, Types.DeletePlanRolePermissionMutationVariables>(DeletePlanRolePermissionDocument, options);
      }
export type DeletePlanRolePermissionMutationHookResult = ReturnType<typeof useDeletePlanRolePermissionMutation>;
export type DeletePlanRolePermissionMutationResult = Apollo.MutationResult<Types.DeletePlanRolePermissionMutation>;
export type DeletePlanRolePermissionMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlanRolePermissionMutation, Types.DeletePlanRolePermissionMutationVariables>;
export const PlanRoleDocument = gql`
    query PlanRole($planId: Int) {
  planRole: plan_role(where: {plan_id: {_eq: $planId}}) {
    ...PlanRoleFields
  }
}
    ${PlanRoleFieldsFragmentDoc}`;

/**
 * __usePlanRoleQuery__
 *
 * To run a query within a React component, call `usePlanRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanRoleQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanRoleQuery(baseOptions?: Apollo.QueryHookOptions<Types.PlanRoleQuery, Types.PlanRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanRoleQuery, Types.PlanRoleQueryVariables>(PlanRoleDocument, options);
      }
export function usePlanRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanRoleQuery, Types.PlanRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanRoleQuery, Types.PlanRoleQueryVariables>(PlanRoleDocument, options);
        }
export type PlanRoleQueryHookResult = ReturnType<typeof usePlanRoleQuery>;
export type PlanRoleLazyQueryHookResult = ReturnType<typeof usePlanRoleLazyQuery>;
export type PlanRoleQueryResult = Apollo.QueryResult<Types.PlanRoleQuery, Types.PlanRoleQueryVariables>;
export const PlanRoleByPlanIdAndRoleIdDocument = gql`
    query PlanRoleByPlanIdAndRoleId($planId: Int, $roleId: Int!) {
  planRole: plan_role(where: {plan_id: {_eq: $planId}, role_id: {_eq: $roleId}}) {
    ...PlanRoleFields
  }
}
    ${PlanRoleFieldsFragmentDoc}`;

/**
 * __usePlanRoleByPlanIdAndRoleIdQuery__
 *
 * To run a query within a React component, call `usePlanRoleByPlanIdAndRoleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanRoleByPlanIdAndRoleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanRoleByPlanIdAndRoleIdQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function usePlanRoleByPlanIdAndRoleIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PlanRoleByPlanIdAndRoleIdQuery, Types.PlanRoleByPlanIdAndRoleIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanRoleByPlanIdAndRoleIdQuery, Types.PlanRoleByPlanIdAndRoleIdQueryVariables>(PlanRoleByPlanIdAndRoleIdDocument, options);
      }
export function usePlanRoleByPlanIdAndRoleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanRoleByPlanIdAndRoleIdQuery, Types.PlanRoleByPlanIdAndRoleIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanRoleByPlanIdAndRoleIdQuery, Types.PlanRoleByPlanIdAndRoleIdQueryVariables>(PlanRoleByPlanIdAndRoleIdDocument, options);
        }
export type PlanRoleByPlanIdAndRoleIdQueryHookResult = ReturnType<typeof usePlanRoleByPlanIdAndRoleIdQuery>;
export type PlanRoleByPlanIdAndRoleIdLazyQueryHookResult = ReturnType<typeof usePlanRoleByPlanIdAndRoleIdLazyQuery>;
export type PlanRoleByPlanIdAndRoleIdQueryResult = Apollo.QueryResult<Types.PlanRoleByPlanIdAndRoleIdQuery, Types.PlanRoleByPlanIdAndRoleIdQueryVariables>;
export const UpdatePlanRoleDocument = gql`
    mutation UpdatePlanRole($planRoleId: Int!, $changes: plan_role_set_input) {
  UpdatePlanRole: update_plan_role(
    where: {id: {_eq: $planRoleId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdatePlanRoleMutationFn = Apollo.MutationFunction<Types.UpdatePlanRoleMutation, Types.UpdatePlanRoleMutationVariables>;

/**
 * __useUpdatePlanRoleMutation__
 *
 * To run a mutation, you first call `useUpdatePlanRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanRoleMutation, { data, loading, error }] = useUpdatePlanRoleMutation({
 *   variables: {
 *      planRoleId: // value for 'planRoleId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePlanRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlanRoleMutation, Types.UpdatePlanRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePlanRoleMutation, Types.UpdatePlanRoleMutationVariables>(UpdatePlanRoleDocument, options);
      }
export type UpdatePlanRoleMutationHookResult = ReturnType<typeof useUpdatePlanRoleMutation>;
export type UpdatePlanRoleMutationResult = Apollo.MutationResult<Types.UpdatePlanRoleMutation>;
export type UpdatePlanRoleMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePlanRoleMutation, Types.UpdatePlanRoleMutationVariables>;
export const InsertPlanRoleDocument = gql`
    mutation InsertPlanRole($object: [plan_role_insert_input!]!) {
  insert_plan_role(objects: $object) {
    returning {
      planId: plan_id
      roleId: role_id
    }
  }
}
    `;
export type InsertPlanRoleMutationFn = Apollo.MutationFunction<Types.InsertPlanRoleMutation, Types.InsertPlanRoleMutationVariables>;

/**
 * __useInsertPlanRoleMutation__
 *
 * To run a mutation, you first call `useInsertPlanRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPlanRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPlanRoleMutation, { data, loading, error }] = useInsertPlanRoleMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPlanRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPlanRoleMutation, Types.InsertPlanRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPlanRoleMutation, Types.InsertPlanRoleMutationVariables>(InsertPlanRoleDocument, options);
      }
export type InsertPlanRoleMutationHookResult = ReturnType<typeof useInsertPlanRoleMutation>;
export type InsertPlanRoleMutationResult = Apollo.MutationResult<Types.InsertPlanRoleMutation>;
export type InsertPlanRoleMutationOptions = Apollo.BaseMutationOptions<Types.InsertPlanRoleMutation, Types.InsertPlanRoleMutationVariables>;
export const DeletePlanRoleDocument = gql`
    mutation DeletePlanRole($planRoleId: Int!) {
  delete_plan_role(where: {id: {_eq: $planRoleId}}) {
    affected_rows
  }
}
    `;
export type DeletePlanRoleMutationFn = Apollo.MutationFunction<Types.DeletePlanRoleMutation, Types.DeletePlanRoleMutationVariables>;

/**
 * __useDeletePlanRoleMutation__
 *
 * To run a mutation, you first call `useDeletePlanRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanRoleMutation, { data, loading, error }] = useDeletePlanRoleMutation({
 *   variables: {
 *      planRoleId: // value for 'planRoleId'
 *   },
 * });
 */
export function useDeletePlanRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlanRoleMutation, Types.DeletePlanRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlanRoleMutation, Types.DeletePlanRoleMutationVariables>(DeletePlanRoleDocument, options);
      }
export type DeletePlanRoleMutationHookResult = ReturnType<typeof useDeletePlanRoleMutation>;
export type DeletePlanRoleMutationResult = Apollo.MutationResult<Types.DeletePlanRoleMutation>;
export type DeletePlanRoleMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlanRoleMutation, Types.DeletePlanRoleMutationVariables>;
export const AllPlatformsDocument = gql`
    query AllPlatforms($limit: Int, $offset: Int) {
  platforms: platform(limit: $limit, offset: $offset, order_by: {name: asc}) {
    ...PlatformFields
  }
  platform_aggregate(order_by: {name: asc}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${PlatformFieldsFragmentDoc}`;

/**
 * __useAllPlatformsQuery__
 *
 * To run a query within a React component, call `useAllPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlatformsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllPlatformsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllPlatformsQuery, Types.AllPlatformsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPlatformsQuery, Types.AllPlatformsQueryVariables>(AllPlatformsDocument, options);
      }
export function useAllPlatformsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPlatformsQuery, Types.AllPlatformsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPlatformsQuery, Types.AllPlatformsQueryVariables>(AllPlatformsDocument, options);
        }
export type AllPlatformsQueryHookResult = ReturnType<typeof useAllPlatformsQuery>;
export type AllPlatformsLazyQueryHookResult = ReturnType<typeof useAllPlatformsLazyQuery>;
export type AllPlatformsQueryResult = Apollo.QueryResult<Types.AllPlatformsQuery, Types.AllPlatformsQueryVariables>;
export const PlatformByIdDocument = gql`
    query PlatformById($id: Int!) {
  platforms: platform(where: {id: {_eq: $id}}, order_by: {id: desc}) {
    ...PlatformFields
  }
}
    ${PlatformFieldsFragmentDoc}`;

/**
 * __usePlatformByIdQuery__
 *
 * To run a query within a React component, call `usePlatformByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlatformByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PlatformByIdQuery, Types.PlatformByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlatformByIdQuery, Types.PlatformByIdQueryVariables>(PlatformByIdDocument, options);
      }
export function usePlatformByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlatformByIdQuery, Types.PlatformByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlatformByIdQuery, Types.PlatformByIdQueryVariables>(PlatformByIdDocument, options);
        }
export type PlatformByIdQueryHookResult = ReturnType<typeof usePlatformByIdQuery>;
export type PlatformByIdLazyQueryHookResult = ReturnType<typeof usePlatformByIdLazyQuery>;
export type PlatformByIdQueryResult = Apollo.QueryResult<Types.PlatformByIdQuery, Types.PlatformByIdQueryVariables>;
export const UpdatePlatformDocument = gql`
    mutation UpdatePlatform($id: Int!, $changes: platform_set_input) {
  updatePlatform: update_platform(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePlatformMutationFn = Apollo.MutationFunction<Types.UpdatePlatformMutation, Types.UpdatePlatformMutationVariables>;

/**
 * __useUpdatePlatformMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformMutation, { data, loading, error }] = useUpdatePlatformMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePlatformMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlatformMutation, Types.UpdatePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePlatformMutation, Types.UpdatePlatformMutationVariables>(UpdatePlatformDocument, options);
      }
export type UpdatePlatformMutationHookResult = ReturnType<typeof useUpdatePlatformMutation>;
export type UpdatePlatformMutationResult = Apollo.MutationResult<Types.UpdatePlatformMutation>;
export type UpdatePlatformMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePlatformMutation, Types.UpdatePlatformMutationVariables>;
export const InsertPlatformDocument = gql`
    mutation InsertPlatform($platform: platform_insert_input!) {
  insert_platform(objects: [$platform]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPlatformMutationFn = Apollo.MutationFunction<Types.InsertPlatformMutation, Types.InsertPlatformMutationVariables>;

/**
 * __useInsertPlatformMutation__
 *
 * To run a mutation, you first call `useInsertPlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPlatformMutation, { data, loading, error }] = useInsertPlatformMutation({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useInsertPlatformMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPlatformMutation, Types.InsertPlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPlatformMutation, Types.InsertPlatformMutationVariables>(InsertPlatformDocument, options);
      }
export type InsertPlatformMutationHookResult = ReturnType<typeof useInsertPlatformMutation>;
export type InsertPlatformMutationResult = Apollo.MutationResult<Types.InsertPlatformMutation>;
export type InsertPlatformMutationOptions = Apollo.BaseMutationOptions<Types.InsertPlatformMutation, Types.InsertPlatformMutationVariables>;
export const DeletePlatformDocument = gql`
    mutation DeletePlatform($id: Int!) {
  delete_platform(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePlatformMutationFn = Apollo.MutationFunction<Types.DeletePlatformMutation, Types.DeletePlatformMutationVariables>;

/**
 * __useDeletePlatformMutation__
 *
 * To run a mutation, you first call `useDeletePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlatformMutation, { data, loading, error }] = useDeletePlatformMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlatformMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlatformMutation, Types.DeletePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlatformMutation, Types.DeletePlatformMutationVariables>(DeletePlatformDocument, options);
      }
export type DeletePlatformMutationHookResult = ReturnType<typeof useDeletePlatformMutation>;
export type DeletePlatformMutationResult = Apollo.MutationResult<Types.DeletePlatformMutation>;
export type DeletePlatformMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlatformMutation, Types.DeletePlatformMutationVariables>;
export const AllPostTextsDocument = gql`
    query AllPostTexts($belongsToCompanyId: Int!, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  postTexts: post_text(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {id: asc}
  ) {
    ...PostTextsFields
  }
  post_text_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}}
    order_by: {id: asc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${PostTextsFieldsFragmentDoc}`;

/**
 * __useAllPostTextsQuery__
 *
 * To run a query within a React component, call `useAllPostTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPostTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPostTextsQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllPostTextsQuery(baseOptions: Apollo.QueryHookOptions<Types.AllPostTextsQuery, Types.AllPostTextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllPostTextsQuery, Types.AllPostTextsQueryVariables>(AllPostTextsDocument, options);
      }
export function useAllPostTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllPostTextsQuery, Types.AllPostTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllPostTextsQuery, Types.AllPostTextsQueryVariables>(AllPostTextsDocument, options);
        }
export type AllPostTextsQueryHookResult = ReturnType<typeof useAllPostTextsQuery>;
export type AllPostTextsLazyQueryHookResult = ReturnType<typeof useAllPostTextsLazyQuery>;
export type AllPostTextsQueryResult = Apollo.QueryResult<Types.AllPostTextsQuery, Types.AllPostTextsQueryVariables>;
export const GetPostTextDocument = gql`
    query GetPostText($id: Int!) {
  postText: post_text(where: {id: {_eq: $id}}) {
    ...PostTextsFields
  }
}
    ${PostTextsFieldsFragmentDoc}`;

/**
 * __useGetPostTextQuery__
 *
 * To run a query within a React component, call `useGetPostTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostTextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostTextQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPostTextQuery, Types.GetPostTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPostTextQuery, Types.GetPostTextQueryVariables>(GetPostTextDocument, options);
      }
export function useGetPostTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPostTextQuery, Types.GetPostTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPostTextQuery, Types.GetPostTextQueryVariables>(GetPostTextDocument, options);
        }
export type GetPostTextQueryHookResult = ReturnType<typeof useGetPostTextQuery>;
export type GetPostTextLazyQueryHookResult = ReturnType<typeof useGetPostTextLazyQuery>;
export type GetPostTextQueryResult = Apollo.QueryResult<Types.GetPostTextQuery, Types.GetPostTextQueryVariables>;
export const UpdatePostTextDocument = gql`
    mutation UpdatePostText($id: Int!, $changes: post_text_set_input) {
  updatePostText: update_post_text(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdatePostTextMutationFn = Apollo.MutationFunction<Types.UpdatePostTextMutation, Types.UpdatePostTextMutationVariables>;

/**
 * __useUpdatePostTextMutation__
 *
 * To run a mutation, you first call `useUpdatePostTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostTextMutation, { data, loading, error }] = useUpdatePostTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePostTextMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePostTextMutation, Types.UpdatePostTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePostTextMutation, Types.UpdatePostTextMutationVariables>(UpdatePostTextDocument, options);
      }
export type UpdatePostTextMutationHookResult = ReturnType<typeof useUpdatePostTextMutation>;
export type UpdatePostTextMutationResult = Apollo.MutationResult<Types.UpdatePostTextMutation>;
export type UpdatePostTextMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePostTextMutation, Types.UpdatePostTextMutationVariables>;
export const InsertPostTextDocument = gql`
    mutation InsertPostText($object: post_text_insert_input!) {
  insert_post_text(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertPostTextMutationFn = Apollo.MutationFunction<Types.InsertPostTextMutation, Types.InsertPostTextMutationVariables>;

/**
 * __useInsertPostTextMutation__
 *
 * To run a mutation, you first call `useInsertPostTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPostTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPostTextMutation, { data, loading, error }] = useInsertPostTextMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPostTextMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPostTextMutation, Types.InsertPostTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPostTextMutation, Types.InsertPostTextMutationVariables>(InsertPostTextDocument, options);
      }
export type InsertPostTextMutationHookResult = ReturnType<typeof useInsertPostTextMutation>;
export type InsertPostTextMutationResult = Apollo.MutationResult<Types.InsertPostTextMutation>;
export type InsertPostTextMutationOptions = Apollo.BaseMutationOptions<Types.InsertPostTextMutation, Types.InsertPostTextMutationVariables>;
export const DeletePostTextDocument = gql`
    mutation DeletePostText($id: Int!) {
  delete_post_text(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePostTextMutationFn = Apollo.MutationFunction<Types.DeletePostTextMutation, Types.DeletePostTextMutationVariables>;

/**
 * __useDeletePostTextMutation__
 *
 * To run a mutation, you first call `useDeletePostTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostTextMutation, { data, loading, error }] = useDeletePostTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostTextMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePostTextMutation, Types.DeletePostTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePostTextMutation, Types.DeletePostTextMutationVariables>(DeletePostTextDocument, options);
      }
export type DeletePostTextMutationHookResult = ReturnType<typeof useDeletePostTextMutation>;
export type DeletePostTextMutationResult = Apollo.MutationResult<Types.DeletePostTextMutation>;
export type DeletePostTextMutationOptions = Apollo.BaseMutationOptions<Types.DeletePostTextMutation, Types.DeletePostTextMutationVariables>;
export const AllRolesDocument = gql`
    query AllRoles {
  roles: role(order_by: {name: asc}) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useAllRolesQuery__
 *
 * To run a query within a React component, call `useAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllRolesQuery, Types.AllRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllRolesQuery, Types.AllRolesQueryVariables>(AllRolesDocument, options);
      }
export function useAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllRolesQuery, Types.AllRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllRolesQuery, Types.AllRolesQueryVariables>(AllRolesDocument, options);
        }
export type AllRolesQueryHookResult = ReturnType<typeof useAllRolesQuery>;
export type AllRolesLazyQueryHookResult = ReturnType<typeof useAllRolesLazyQuery>;
export type AllRolesQueryResult = Apollo.QueryResult<Types.AllRolesQuery, Types.AllRolesQueryVariables>;
export const AllNonAdminRolesDocument = gql`
    query AllNonAdminRoles {
  roles: role(order_by: {name: asc}, where: {admin_only: {_eq: false}}) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useAllNonAdminRolesQuery__
 *
 * To run a query within a React component, call `useAllNonAdminRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNonAdminRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNonAdminRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNonAdminRolesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllNonAdminRolesQuery, Types.AllNonAdminRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllNonAdminRolesQuery, Types.AllNonAdminRolesQueryVariables>(AllNonAdminRolesDocument, options);
      }
export function useAllNonAdminRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllNonAdminRolesQuery, Types.AllNonAdminRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllNonAdminRolesQuery, Types.AllNonAdminRolesQueryVariables>(AllNonAdminRolesDocument, options);
        }
export type AllNonAdminRolesQueryHookResult = ReturnType<typeof useAllNonAdminRolesQuery>;
export type AllNonAdminRolesLazyQueryHookResult = ReturnType<typeof useAllNonAdminRolesLazyQuery>;
export type AllNonAdminRolesQueryResult = Apollo.QueryResult<Types.AllNonAdminRolesQuery, Types.AllNonAdminRolesQueryVariables>;
export const AllNonAdminRolesByCompanyTypeDocument = gql`
    query AllNonAdminRolesByCompanyType($companyType: String!) {
  roles: role(
    order_by: {name: asc}
    where: {admin_only: {_eq: false}, company_type_roles: {company_type: {shortcode: {_eq: $companyType}}}}
  ) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useAllNonAdminRolesByCompanyTypeQuery__
 *
 * To run a query within a React component, call `useAllNonAdminRolesByCompanyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNonAdminRolesByCompanyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNonAdminRolesByCompanyTypeQuery({
 *   variables: {
 *      companyType: // value for 'companyType'
 *   },
 * });
 */
export function useAllNonAdminRolesByCompanyTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.AllNonAdminRolesByCompanyTypeQuery, Types.AllNonAdminRolesByCompanyTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllNonAdminRolesByCompanyTypeQuery, Types.AllNonAdminRolesByCompanyTypeQueryVariables>(AllNonAdminRolesByCompanyTypeDocument, options);
      }
export function useAllNonAdminRolesByCompanyTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllNonAdminRolesByCompanyTypeQuery, Types.AllNonAdminRolesByCompanyTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllNonAdminRolesByCompanyTypeQuery, Types.AllNonAdminRolesByCompanyTypeQueryVariables>(AllNonAdminRolesByCompanyTypeDocument, options);
        }
export type AllNonAdminRolesByCompanyTypeQueryHookResult = ReturnType<typeof useAllNonAdminRolesByCompanyTypeQuery>;
export type AllNonAdminRolesByCompanyTypeLazyQueryHookResult = ReturnType<typeof useAllNonAdminRolesByCompanyTypeLazyQuery>;
export type AllNonAdminRolesByCompanyTypeQueryResult = Apollo.QueryResult<Types.AllNonAdminRolesByCompanyTypeQuery, Types.AllNonAdminRolesByCompanyTypeQueryVariables>;
export const AllNonAdminRolesbyPlanIdDocument = gql`
    query AllNonAdminRolesbyPlanId($planId: Int!) {
  plan(
    where: {id: {_eq: $planId}, plan_roles: {role: {admin_only: {_eq: false}}}}
  ) {
    id
    planRoles: plan_roles(order_by: {role: {name: asc}}) {
      role {
        ...RoleFields
      }
    }
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useAllNonAdminRolesbyPlanIdQuery__
 *
 * To run a query within a React component, call `useAllNonAdminRolesbyPlanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNonAdminRolesbyPlanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNonAdminRolesbyPlanIdQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useAllNonAdminRolesbyPlanIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllNonAdminRolesbyPlanIdQuery, Types.AllNonAdminRolesbyPlanIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllNonAdminRolesbyPlanIdQuery, Types.AllNonAdminRolesbyPlanIdQueryVariables>(AllNonAdminRolesbyPlanIdDocument, options);
      }
export function useAllNonAdminRolesbyPlanIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllNonAdminRolesbyPlanIdQuery, Types.AllNonAdminRolesbyPlanIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllNonAdminRolesbyPlanIdQuery, Types.AllNonAdminRolesbyPlanIdQueryVariables>(AllNonAdminRolesbyPlanIdDocument, options);
        }
export type AllNonAdminRolesbyPlanIdQueryHookResult = ReturnType<typeof useAllNonAdminRolesbyPlanIdQuery>;
export type AllNonAdminRolesbyPlanIdLazyQueryHookResult = ReturnType<typeof useAllNonAdminRolesbyPlanIdLazyQuery>;
export type AllNonAdminRolesbyPlanIdQueryResult = Apollo.QueryResult<Types.AllNonAdminRolesbyPlanIdQuery, Types.AllNonAdminRolesbyPlanIdQueryVariables>;
export const AllNonAdminRolesbyCompanyIdDocument = gql`
    query AllNonAdminRolesbyCompanyId($companyId: Int!) {
  company(where: {id: {_eq: $companyId}}) {
    plan {
      id
      planRoles: plan_roles(order_by: {role: {name: asc}}) {
        role {
          ...RoleFields
        }
      }
    }
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useAllNonAdminRolesbyCompanyIdQuery__
 *
 * To run a query within a React component, call `useAllNonAdminRolesbyCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNonAdminRolesbyCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNonAdminRolesbyCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllNonAdminRolesbyCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AllNonAdminRolesbyCompanyIdQuery, Types.AllNonAdminRolesbyCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllNonAdminRolesbyCompanyIdQuery, Types.AllNonAdminRolesbyCompanyIdQueryVariables>(AllNonAdminRolesbyCompanyIdDocument, options);
      }
export function useAllNonAdminRolesbyCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllNonAdminRolesbyCompanyIdQuery, Types.AllNonAdminRolesbyCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllNonAdminRolesbyCompanyIdQuery, Types.AllNonAdminRolesbyCompanyIdQueryVariables>(AllNonAdminRolesbyCompanyIdDocument, options);
        }
export type AllNonAdminRolesbyCompanyIdQueryHookResult = ReturnType<typeof useAllNonAdminRolesbyCompanyIdQuery>;
export type AllNonAdminRolesbyCompanyIdLazyQueryHookResult = ReturnType<typeof useAllNonAdminRolesbyCompanyIdLazyQuery>;
export type AllNonAdminRolesbyCompanyIdQueryResult = Apollo.QueryResult<Types.AllNonAdminRolesbyCompanyIdQuery, Types.AllNonAdminRolesbyCompanyIdQueryVariables>;
export const RoleDocument = gql`
    query Role($id: Int!) {
  role: role(where: {id: {_eq: $id}}) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<Types.RoleQuery, Types.RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RoleQuery, Types.RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RoleQuery, Types.RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RoleQuery, Types.RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<Types.RoleQuery, Types.RoleQueryVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: Int!, $changes: role_set_input) {
  UpdateRole: update_role(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<Types.UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>;
export const InsertRoleDocument = gql`
    mutation InsertRole($object: role_insert_input!) {
  insert_role(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertRoleMutationFn = Apollo.MutationFunction<Types.InsertRoleMutation, Types.InsertRoleMutationVariables>;

/**
 * __useInsertRoleMutation__
 *
 * To run a mutation, you first call `useInsertRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRoleMutation, { data, loading, error }] = useInsertRoleMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertRoleMutation, Types.InsertRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertRoleMutation, Types.InsertRoleMutationVariables>(InsertRoleDocument, options);
      }
export type InsertRoleMutationHookResult = ReturnType<typeof useInsertRoleMutation>;
export type InsertRoleMutationResult = Apollo.MutationResult<Types.InsertRoleMutation>;
export type InsertRoleMutationOptions = Apollo.BaseMutationOptions<Types.InsertRoleMutation, Types.InsertRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: Int!) {
  delete_role(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<Types.DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>;
export const SmsBillingUsageByFilterDocument = gql`
    query SmsBillingUsageByFilter($filter: sms_billing_usage_bool_exp, $limit: Int) {
  smsBillingUsages: sms_billing_usage(where: $filter, limit: $limit) {
    ...SmsBillingUsageFields
  }
}
    ${SmsBillingUsageFieldsFragmentDoc}`;

/**
 * __useSmsBillingUsageByFilterQuery__
 *
 * To run a query within a React component, call `useSmsBillingUsageByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmsBillingUsageByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmsBillingUsageByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSmsBillingUsageByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.SmsBillingUsageByFilterQuery, Types.SmsBillingUsageByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmsBillingUsageByFilterQuery, Types.SmsBillingUsageByFilterQueryVariables>(SmsBillingUsageByFilterDocument, options);
      }
export function useSmsBillingUsageByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmsBillingUsageByFilterQuery, Types.SmsBillingUsageByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmsBillingUsageByFilterQuery, Types.SmsBillingUsageByFilterQueryVariables>(SmsBillingUsageByFilterDocument, options);
        }
export type SmsBillingUsageByFilterQueryHookResult = ReturnType<typeof useSmsBillingUsageByFilterQuery>;
export type SmsBillingUsageByFilterLazyQueryHookResult = ReturnType<typeof useSmsBillingUsageByFilterLazyQuery>;
export type SmsBillingUsageByFilterQueryResult = Apollo.QueryResult<Types.SmsBillingUsageByFilterQuery, Types.SmsBillingUsageByFilterQueryVariables>;
export const InsertSmsBillingUsageDocument = gql`
    mutation InsertSmsBillingUsage($object: [sms_billing_usage_insert_input!]!) {
  insertSmsBillingUsage: insert_sms_billing_usage(objects: $object) {
    returning {
      ...SmsBillingUsageFields
    }
  }
}
    ${SmsBillingUsageFieldsFragmentDoc}`;
export type InsertSmsBillingUsageMutationFn = Apollo.MutationFunction<Types.InsertSmsBillingUsageMutation, Types.InsertSmsBillingUsageMutationVariables>;

/**
 * __useInsertSmsBillingUsageMutation__
 *
 * To run a mutation, you first call `useInsertSmsBillingUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsBillingUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsBillingUsageMutation, { data, loading, error }] = useInsertSmsBillingUsageMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSmsBillingUsageMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertSmsBillingUsageMutation, Types.InsertSmsBillingUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertSmsBillingUsageMutation, Types.InsertSmsBillingUsageMutationVariables>(InsertSmsBillingUsageDocument, options);
      }
export type InsertSmsBillingUsageMutationHookResult = ReturnType<typeof useInsertSmsBillingUsageMutation>;
export type InsertSmsBillingUsageMutationResult = Apollo.MutationResult<Types.InsertSmsBillingUsageMutation>;
export type InsertSmsBillingUsageMutationOptions = Apollo.BaseMutationOptions<Types.InsertSmsBillingUsageMutation, Types.InsertSmsBillingUsageMutationVariables>;
export const AllSmsProvidersDocument = gql`
    query AllSmsProviders {
  smsProviders: sms_provider(order_by: {name: asc}) {
    ...SmsProviderFields
  }
}
    ${SmsProviderFieldsFragmentDoc}`;

/**
 * __useAllSmsProvidersQuery__
 *
 * To run a query within a React component, call `useAllSmsProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSmsProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSmsProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSmsProvidersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllSmsProvidersQuery, Types.AllSmsProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllSmsProvidersQuery, Types.AllSmsProvidersQueryVariables>(AllSmsProvidersDocument, options);
      }
export function useAllSmsProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllSmsProvidersQuery, Types.AllSmsProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllSmsProvidersQuery, Types.AllSmsProvidersQueryVariables>(AllSmsProvidersDocument, options);
        }
export type AllSmsProvidersQueryHookResult = ReturnType<typeof useAllSmsProvidersQuery>;
export type AllSmsProvidersLazyQueryHookResult = ReturnType<typeof useAllSmsProvidersLazyQuery>;
export type AllSmsProvidersQueryResult = Apollo.QueryResult<Types.AllSmsProvidersQuery, Types.AllSmsProvidersQueryVariables>;
export const AllSmsProvidersByCompanyQueryDocument = gql`
    query AllSmsProvidersByCompanyQuery($belongsToCompanyId: Int) {
  smsProviders: sms_provider(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}}
  ) {
    ...SmsProviderFields
  }
}
    ${SmsProviderFieldsFragmentDoc}`;

/**
 * __useAllSmsProvidersByCompanyQuery__
 *
 * To run a query within a React component, call `useAllSmsProvidersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSmsProvidersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSmsProvidersByCompanyQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *   },
 * });
 */
export function useAllSmsProvidersByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllSmsProvidersByCompanyQuery, Types.AllSmsProvidersByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllSmsProvidersByCompanyQuery, Types.AllSmsProvidersByCompanyQueryVariables>(AllSmsProvidersByCompanyQueryDocument, options);
      }
export function useAllSmsProvidersByCompanyQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllSmsProvidersByCompanyQuery, Types.AllSmsProvidersByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllSmsProvidersByCompanyQuery, Types.AllSmsProvidersByCompanyQueryVariables>(AllSmsProvidersByCompanyQueryDocument, options);
        }
export type AllSmsProvidersByCompanyQueryHookResult = ReturnType<typeof useAllSmsProvidersByCompanyQuery>;
export type AllSmsProvidersByCompanyQueryLazyQueryHookResult = ReturnType<typeof useAllSmsProvidersByCompanyQueryLazyQuery>;
export type AllSmsProvidersByCompanyQueryQueryResult = Apollo.QueryResult<Types.AllSmsProvidersByCompanyQuery, Types.AllSmsProvidersByCompanyQueryVariables>;
export const SmsProviderDocument = gql`
    query SmsProvider($id: Int!) {
  smsProvider: sms_provider(where: {id: {_eq: $id}}) {
    ...SmsProviderFields
  }
}
    ${SmsProviderFieldsFragmentDoc}`;

/**
 * __useSmsProviderQuery__
 *
 * To run a query within a React component, call `useSmsProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmsProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmsProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSmsProviderQuery(baseOptions: Apollo.QueryHookOptions<Types.SmsProviderQuery, Types.SmsProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmsProviderQuery, Types.SmsProviderQueryVariables>(SmsProviderDocument, options);
      }
export function useSmsProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmsProviderQuery, Types.SmsProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmsProviderQuery, Types.SmsProviderQueryVariables>(SmsProviderDocument, options);
        }
export type SmsProviderQueryHookResult = ReturnType<typeof useSmsProviderQuery>;
export type SmsProviderLazyQueryHookResult = ReturnType<typeof useSmsProviderLazyQuery>;
export type SmsProviderQueryResult = Apollo.QueryResult<Types.SmsProviderQuery, Types.SmsProviderQueryVariables>;
export const SmsProvidersByCompanyAdminDocument = gql`
    query SmsProvidersByCompanyAdmin($companyId: Int) {
  smsProviders: sms_provider(
    order_by: {name: asc}
    where: {_or: [{belongs_to_company_id: {_eq: $companyId}}, {default_provider: {_eq: true}}]}
  ) {
    ...SmsProviderFields
  }
}
    ${SmsProviderFieldsFragmentDoc}`;

/**
 * __useSmsProvidersByCompanyAdminQuery__
 *
 * To run a query within a React component, call `useSmsProvidersByCompanyAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmsProvidersByCompanyAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmsProvidersByCompanyAdminQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSmsProvidersByCompanyAdminQuery(baseOptions?: Apollo.QueryHookOptions<Types.SmsProvidersByCompanyAdminQuery, Types.SmsProvidersByCompanyAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmsProvidersByCompanyAdminQuery, Types.SmsProvidersByCompanyAdminQueryVariables>(SmsProvidersByCompanyAdminDocument, options);
      }
export function useSmsProvidersByCompanyAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmsProvidersByCompanyAdminQuery, Types.SmsProvidersByCompanyAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmsProvidersByCompanyAdminQuery, Types.SmsProvidersByCompanyAdminQueryVariables>(SmsProvidersByCompanyAdminDocument, options);
        }
export type SmsProvidersByCompanyAdminQueryHookResult = ReturnType<typeof useSmsProvidersByCompanyAdminQuery>;
export type SmsProvidersByCompanyAdminLazyQueryHookResult = ReturnType<typeof useSmsProvidersByCompanyAdminLazyQuery>;
export type SmsProvidersByCompanyAdminQueryResult = Apollo.QueryResult<Types.SmsProvidersByCompanyAdminQuery, Types.SmsProvidersByCompanyAdminQueryVariables>;
export const UpdateSmsProviderDocument = gql`
    mutation UpdateSmsProvider($id: Int!, $changes: sms_provider_set_input) {
  UpdateSmsProvider: update_sms_provider(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateSmsProviderMutationFn = Apollo.MutationFunction<Types.UpdateSmsProviderMutation, Types.UpdateSmsProviderMutationVariables>;

/**
 * __useUpdateSmsProviderMutation__
 *
 * To run a mutation, you first call `useUpdateSmsProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsProviderMutation, { data, loading, error }] = useUpdateSmsProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateSmsProviderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSmsProviderMutation, Types.UpdateSmsProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSmsProviderMutation, Types.UpdateSmsProviderMutationVariables>(UpdateSmsProviderDocument, options);
      }
export type UpdateSmsProviderMutationHookResult = ReturnType<typeof useUpdateSmsProviderMutation>;
export type UpdateSmsProviderMutationResult = Apollo.MutationResult<Types.UpdateSmsProviderMutation>;
export type UpdateSmsProviderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSmsProviderMutation, Types.UpdateSmsProviderMutationVariables>;
export const InsertSmsProviderDocument = gql`
    mutation InsertSmsProvider($object: sms_provider_insert_input!) {
  insert_sms_provider(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertSmsProviderMutationFn = Apollo.MutationFunction<Types.InsertSmsProviderMutation, Types.InsertSmsProviderMutationVariables>;

/**
 * __useInsertSmsProviderMutation__
 *
 * To run a mutation, you first call `useInsertSmsProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsProviderMutation, { data, loading, error }] = useInsertSmsProviderMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSmsProviderMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertSmsProviderMutation, Types.InsertSmsProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertSmsProviderMutation, Types.InsertSmsProviderMutationVariables>(InsertSmsProviderDocument, options);
      }
export type InsertSmsProviderMutationHookResult = ReturnType<typeof useInsertSmsProviderMutation>;
export type InsertSmsProviderMutationResult = Apollo.MutationResult<Types.InsertSmsProviderMutation>;
export type InsertSmsProviderMutationOptions = Apollo.BaseMutationOptions<Types.InsertSmsProviderMutation, Types.InsertSmsProviderMutationVariables>;
export const DeleteSmsProviderDocument = gql`
    mutation DeleteSmsProvider($id: Int!) {
  delete_sms_provider(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteSmsProviderMutationFn = Apollo.MutationFunction<Types.DeleteSmsProviderMutation, Types.DeleteSmsProviderMutationVariables>;

/**
 * __useDeleteSmsProviderMutation__
 *
 * To run a mutation, you first call `useDeleteSmsProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmsProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmsProviderMutation, { data, loading, error }] = useDeleteSmsProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmsProviderMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSmsProviderMutation, Types.DeleteSmsProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSmsProviderMutation, Types.DeleteSmsProviderMutationVariables>(DeleteSmsProviderDocument, options);
      }
export type DeleteSmsProviderMutationHookResult = ReturnType<typeof useDeleteSmsProviderMutation>;
export type DeleteSmsProviderMutationResult = Apollo.MutationResult<Types.DeleteSmsProviderMutation>;
export type DeleteSmsProviderMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSmsProviderMutation, Types.DeleteSmsProviderMutationVariables>;
export const BulkInsertTargetSiteCategoryDocument = gql`
    mutation BulkInsertTargetSiteCategory($object: [target_site_category_insert_input!]!) {
  insert_target_site_category(objects: $object) {
    returning {
      categoryId: category_id
      targetSiteId: target_site_id
    }
  }
}
    `;
export type BulkInsertTargetSiteCategoryMutationFn = Apollo.MutationFunction<Types.BulkInsertTargetSiteCategoryMutation, Types.BulkInsertTargetSiteCategoryMutationVariables>;

/**
 * __useBulkInsertTargetSiteCategoryMutation__
 *
 * To run a mutation, you first call `useBulkInsertTargetSiteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkInsertTargetSiteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkInsertTargetSiteCategoryMutation, { data, loading, error }] = useBulkInsertTargetSiteCategoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useBulkInsertTargetSiteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkInsertTargetSiteCategoryMutation, Types.BulkInsertTargetSiteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkInsertTargetSiteCategoryMutation, Types.BulkInsertTargetSiteCategoryMutationVariables>(BulkInsertTargetSiteCategoryDocument, options);
      }
export type BulkInsertTargetSiteCategoryMutationHookResult = ReturnType<typeof useBulkInsertTargetSiteCategoryMutation>;
export type BulkInsertTargetSiteCategoryMutationResult = Apollo.MutationResult<Types.BulkInsertTargetSiteCategoryMutation>;
export type BulkInsertTargetSiteCategoryMutationOptions = Apollo.BaseMutationOptions<Types.BulkInsertTargetSiteCategoryMutation, Types.BulkInsertTargetSiteCategoryMutationVariables>;
export const UpdateTargetSiteCategoryDocument = gql`
    mutation UpdateTargetSiteCategory($targetSiteId: Int!, $changes: target_site_category_set_input) {
  updateTargetSiteCategory: update_target_site_category(
    where: {target_site_id: {_eq: $targetSiteId}}
    _set: $changes
  ) {
    affected_rows
  }
}
    `;
export type UpdateTargetSiteCategoryMutationFn = Apollo.MutationFunction<Types.UpdateTargetSiteCategoryMutation, Types.UpdateTargetSiteCategoryMutationVariables>;

/**
 * __useUpdateTargetSiteCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateTargetSiteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetSiteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetSiteCategoryMutation, { data, loading, error }] = useUpdateTargetSiteCategoryMutation({
 *   variables: {
 *      targetSiteId: // value for 'targetSiteId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateTargetSiteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTargetSiteCategoryMutation, Types.UpdateTargetSiteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTargetSiteCategoryMutation, Types.UpdateTargetSiteCategoryMutationVariables>(UpdateTargetSiteCategoryDocument, options);
      }
export type UpdateTargetSiteCategoryMutationHookResult = ReturnType<typeof useUpdateTargetSiteCategoryMutation>;
export type UpdateTargetSiteCategoryMutationResult = Apollo.MutationResult<Types.UpdateTargetSiteCategoryMutation>;
export type UpdateTargetSiteCategoryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTargetSiteCategoryMutation, Types.UpdateTargetSiteCategoryMutationVariables>;
export const DeleteTargetSiteCategoryDocument = gql`
    mutation DeleteTargetSiteCategory($targetSiteId: Int!, $categoryId: Int) {
  delete_target_site_category(
    where: {target_site_id: {_eq: $targetSiteId}, category_id: {_eq: $categoryId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteTargetSiteCategoryMutationFn = Apollo.MutationFunction<Types.DeleteTargetSiteCategoryMutation, Types.DeleteTargetSiteCategoryMutationVariables>;

/**
 * __useDeleteTargetSiteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteTargetSiteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTargetSiteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTargetSiteCategoryMutation, { data, loading, error }] = useDeleteTargetSiteCategoryMutation({
 *   variables: {
 *      targetSiteId: // value for 'targetSiteId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteTargetSiteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTargetSiteCategoryMutation, Types.DeleteTargetSiteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTargetSiteCategoryMutation, Types.DeleteTargetSiteCategoryMutationVariables>(DeleteTargetSiteCategoryDocument, options);
      }
export type DeleteTargetSiteCategoryMutationHookResult = ReturnType<typeof useDeleteTargetSiteCategoryMutation>;
export type DeleteTargetSiteCategoryMutationResult = Apollo.MutationResult<Types.DeleteTargetSiteCategoryMutation>;
export type DeleteTargetSiteCategoryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTargetSiteCategoryMutation, Types.DeleteTargetSiteCategoryMutationVariables>;
export const GetAllTargetSitesDocument = gql`
    query GetAllTargetSites($belongsToCompanyId: Int, $belongsToChatCompanyId: Int, $belongsToRoleUserId: Int, $belongsToRoleAffiliateId: Int) {
  targetSites: target_site(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_user_id: {_eq: $belongsToRoleUserId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {site_name: asc}
  ) {
    id
    siteName: site_name
    siteType: site_type
    siteDomain: site_domain
    sitePlatform: site_platform
    newPostUrl: new_post_url
    targetSiteCategories: target_site_categories {
      category {
        id
        categoryName: category_name
      }
    }
  }
}
    `;

/**
 * __useGetAllTargetSitesQuery__
 *
 * To run a query within a React component, call `useGetAllTargetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTargetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTargetSitesQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToChatCompanyId: // value for 'belongsToChatCompanyId'
 *      belongsToRoleUserId: // value for 'belongsToRoleUserId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *   },
 * });
 */
export function useGetAllTargetSitesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllTargetSitesQuery, Types.GetAllTargetSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllTargetSitesQuery, Types.GetAllTargetSitesQueryVariables>(GetAllTargetSitesDocument, options);
      }
export function useGetAllTargetSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllTargetSitesQuery, Types.GetAllTargetSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllTargetSitesQuery, Types.GetAllTargetSitesQueryVariables>(GetAllTargetSitesDocument, options);
        }
export type GetAllTargetSitesQueryHookResult = ReturnType<typeof useGetAllTargetSitesQuery>;
export type GetAllTargetSitesLazyQueryHookResult = ReturnType<typeof useGetAllTargetSitesLazyQuery>;
export type GetAllTargetSitesQueryResult = Apollo.QueryResult<Types.GetAllTargetSitesQuery, Types.GetAllTargetSitesQueryVariables>;
export const GetTargetSiteDocument = gql`
    query GetTargetSite($id: Int!) {
  targetSites: target_site(where: {id: {_eq: $id}}) {
    id
    siteName: site_name
    siteType: site_type
    siteDomain: site_domain
    sitePlatform: site_platform
    newPostUrl: new_post_url
    targetSiteCategories: target_site_categories {
      category {
        id
        categoryName: category_name
      }
    }
  }
}
    `;

/**
 * __useGetTargetSiteQuery__
 *
 * To run a query within a React component, call `useGetTargetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTargetSiteQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTargetSiteQuery, Types.GetTargetSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTargetSiteQuery, Types.GetTargetSiteQueryVariables>(GetTargetSiteDocument, options);
      }
export function useGetTargetSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTargetSiteQuery, Types.GetTargetSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTargetSiteQuery, Types.GetTargetSiteQueryVariables>(GetTargetSiteDocument, options);
        }
export type GetTargetSiteQueryHookResult = ReturnType<typeof useGetTargetSiteQuery>;
export type GetTargetSiteLazyQueryHookResult = ReturnType<typeof useGetTargetSiteLazyQuery>;
export type GetTargetSiteQueryResult = Apollo.QueryResult<Types.GetTargetSiteQuery, Types.GetTargetSiteQueryVariables>;
export const UpdateTargetSiteDocument = gql`
    mutation UpdateTargetSite($id: Int!, $changes: target_site_set_input) {
  updateTargetSite: update_target_site(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateTargetSiteMutationFn = Apollo.MutationFunction<Types.UpdateTargetSiteMutation, Types.UpdateTargetSiteMutationVariables>;

/**
 * __useUpdateTargetSiteMutation__
 *
 * To run a mutation, you first call `useUpdateTargetSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetSiteMutation, { data, loading, error }] = useUpdateTargetSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateTargetSiteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTargetSiteMutation, Types.UpdateTargetSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTargetSiteMutation, Types.UpdateTargetSiteMutationVariables>(UpdateTargetSiteDocument, options);
      }
export type UpdateTargetSiteMutationHookResult = ReturnType<typeof useUpdateTargetSiteMutation>;
export type UpdateTargetSiteMutationResult = Apollo.MutationResult<Types.UpdateTargetSiteMutation>;
export type UpdateTargetSiteMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTargetSiteMutation, Types.UpdateTargetSiteMutationVariables>;
export const InsertTargetSiteDocument = gql`
    mutation InsertTargetSite($object: target_site_insert_input!) {
  insert_target_site(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertTargetSiteMutationFn = Apollo.MutationFunction<Types.InsertTargetSiteMutation, Types.InsertTargetSiteMutationVariables>;

/**
 * __useInsertTargetSiteMutation__
 *
 * To run a mutation, you first call `useInsertTargetSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTargetSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTargetSiteMutation, { data, loading, error }] = useInsertTargetSiteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTargetSiteMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTargetSiteMutation, Types.InsertTargetSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTargetSiteMutation, Types.InsertTargetSiteMutationVariables>(InsertTargetSiteDocument, options);
      }
export type InsertTargetSiteMutationHookResult = ReturnType<typeof useInsertTargetSiteMutation>;
export type InsertTargetSiteMutationResult = Apollo.MutationResult<Types.InsertTargetSiteMutation>;
export type InsertTargetSiteMutationOptions = Apollo.BaseMutationOptions<Types.InsertTargetSiteMutation, Types.InsertTargetSiteMutationVariables>;
export const DeleteTargetSiteDocument = gql`
    mutation DeleteTargetSite($id: Int!) {
  delete_target_site(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteTargetSiteMutationFn = Apollo.MutationFunction<Types.DeleteTargetSiteMutation, Types.DeleteTargetSiteMutationVariables>;

/**
 * __useDeleteTargetSiteMutation__
 *
 * To run a mutation, you first call `useDeleteTargetSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTargetSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTargetSiteMutation, { data, loading, error }] = useDeleteTargetSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTargetSiteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTargetSiteMutation, Types.DeleteTargetSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTargetSiteMutation, Types.DeleteTargetSiteMutationVariables>(DeleteTargetSiteDocument, options);
      }
export type DeleteTargetSiteMutationHookResult = ReturnType<typeof useDeleteTargetSiteMutation>;
export type DeleteTargetSiteMutationResult = Apollo.MutationResult<Types.DeleteTargetSiteMutation>;
export type DeleteTargetSiteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTargetSiteMutation, Types.DeleteTargetSiteMutationVariables>;
export const AllTrackingDocument = gql`
    query AllTracking {
  trackings: tracking(order_by: {conversation_id: asc}) {
    id
    conversion_id
    conv_key
    conversion {
      id
      payinAdvertiser: payin_advertiser
      payoutCompany: payout_company
      payoutAffiliate: payout_affiliate
      payoutChatCompany: payout_chat_company
      payoutChatOperator: payout_chat_operator
    }
    clickCount: click_count
    offerUrl: offer_url
    offerLinkSent: offer_link_sent
  }
}
    `;

/**
 * __useAllTrackingQuery__
 *
 * To run a query within a React component, call `useAllTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTrackingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTrackingQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllTrackingQuery, Types.AllTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllTrackingQuery, Types.AllTrackingQueryVariables>(AllTrackingDocument, options);
      }
export function useAllTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllTrackingQuery, Types.AllTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllTrackingQuery, Types.AllTrackingQueryVariables>(AllTrackingDocument, options);
        }
export type AllTrackingQueryHookResult = ReturnType<typeof useAllTrackingQuery>;
export type AllTrackingLazyQueryHookResult = ReturnType<typeof useAllTrackingLazyQuery>;
export type AllTrackingQueryResult = Apollo.QueryResult<Types.AllTrackingQuery, Types.AllTrackingQueryVariables>;
export const TrackingByConvKeyDocument = gql`
    query TrackingByConvKey($convKey: String!) {
  trackings: tracking(where: {conv_key: {_eq: $convKey}}) {
    id
    conversion_id
    conv_key
    conversion {
      id
      payinAdvertiser: payin_advertiser
      payoutCompany: payout_company
      payoutAffiliate: payout_affiliate
      payoutChatCompany: payout_chat_company
      payoutChatOperator: payout_chat_operator
    }
    clickCount: click_count
    offerUrl: offer_url
    offerLinkSent: offer_link_sent
  }
}
    `;

/**
 * __useTrackingByConvKeyQuery__
 *
 * To run a query within a React component, call `useTrackingByConvKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingByConvKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingByConvKeyQuery({
 *   variables: {
 *      convKey: // value for 'convKey'
 *   },
 * });
 */
export function useTrackingByConvKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.TrackingByConvKeyQuery, Types.TrackingByConvKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TrackingByConvKeyQuery, Types.TrackingByConvKeyQueryVariables>(TrackingByConvKeyDocument, options);
      }
export function useTrackingByConvKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrackingByConvKeyQuery, Types.TrackingByConvKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TrackingByConvKeyQuery, Types.TrackingByConvKeyQueryVariables>(TrackingByConvKeyDocument, options);
        }
export type TrackingByConvKeyQueryHookResult = ReturnType<typeof useTrackingByConvKeyQuery>;
export type TrackingByConvKeyLazyQueryHookResult = ReturnType<typeof useTrackingByConvKeyLazyQuery>;
export type TrackingByConvKeyQueryResult = Apollo.QueryResult<Types.TrackingByConvKeyQuery, Types.TrackingByConvKeyQueryVariables>;
export const UsageByCompanyIdDocument = gql`
    query UsageByCompanyId($companyId: Int!, $newerThanDate: timestamptz!) {
  affiliates: company_role_user_aggregate(
    where: {company_id: {_eq: $companyId}, role: {shortcode: {_eq: "affiliate"}}}
  ) {
    aggregate {
      count
    }
  }
  apiConversationMessages: conversation_message_aggregate(
    where: {conversation: {belongs_to_company_id: {_eq: $companyId}, created_at: {_gte: $newerThanDate}, platform_id: {_nin: [7, 8]}}}
  ) {
    aggregate {
      count
    }
  }
  campaigns: campaign_aggregate(where: {belongs_to_company_id: {_eq: $companyId}}) {
    aggregate {
      count
    }
  }
  keywordIntents: intent_aggregate(
    where: {belongs_to_company_id: {_eq: $companyId}}
  ) {
    aggregate {
      count
    }
  }
  personas: persona_aggregate(where: {belongs_to_company_id: {_eq: $companyId}}) {
    aggregate {
      count
    }
  }
  phoneNumbers: phone_number_aggregate(
    where: {belongs_to_company_id: {_eq: $companyId}, is_active: {_eq: true}}
  ) {
    aggregate {
      count
    }
  }
  smsConversationMessages: conversation_message_aggregate(
    where: {conversation: {belongs_to_company_id: {_eq: $companyId}, created_at: {_gte: $newerThanDate}, platform_id: {_eq: 7}}}
  ) {
    aggregate {
      count
    }
  }
  webConversationMessages: conversation_message_aggregate(
    where: {conversation: {belongs_to_company_id: {_eq: $companyId}, created_at: {_gte: $newerThanDate}, platform_id: {_eq: 8}}}
  ) {
    aggregate {
      count
    }
  }
  webChatForms: web_chat_form_aggregate(
    where: {belongs_to_company_id: {_eq: $companyId}}
  ) {
    aggregate {
      count
    }
  }
  webPages: web_page_aggregate(where: {belongs_to_company_id: {_eq: $companyId}}) {
    aggregate {
      count
    }
  }
  users: company_role_user_aggregate(
    where: {company_id: {_eq: $companyId}, role: {shortcode: {_neq: "affiliate"}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsageByCompanyIdQuery__
 *
 * To run a query within a React component, call `useUsageByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      newerThanDate: // value for 'newerThanDate'
 *   },
 * });
 */
export function useUsageByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<Types.UsageByCompanyIdQuery, Types.UsageByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsageByCompanyIdQuery, Types.UsageByCompanyIdQueryVariables>(UsageByCompanyIdDocument, options);
      }
export function useUsageByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsageByCompanyIdQuery, Types.UsageByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsageByCompanyIdQuery, Types.UsageByCompanyIdQueryVariables>(UsageByCompanyIdDocument, options);
        }
export type UsageByCompanyIdQueryHookResult = ReturnType<typeof useUsageByCompanyIdQuery>;
export type UsageByCompanyIdLazyQueryHookResult = ReturnType<typeof useUsageByCompanyIdLazyQuery>;
export type UsageByCompanyIdQueryResult = Apollo.QueryResult<Types.UsageByCompanyIdQuery, Types.UsageByCompanyIdQueryVariables>;
export const AllUsersDocument = gql`
    query AllUsers {
  users: user(order_by: {username: asc}) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllUsersQuery, Types.AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllUsersQuery, Types.AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllUsersQuery, Types.AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllUsersQuery, Types.AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<Types.AllUsersQuery, Types.AllUsersQueryVariables>;
export const GetUserByEmailDocument = gql`
    query GetUserByEmail($email: String!) {
  users: user(where: {email: {_eq: $email}}) {
    id
    first_name
    last_name
    telegram_id
    skype_id
    email
    company_role_users {
      id
      company_id
      role_id
    }
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>;
export const GetUserByEmailForLoginDocument = gql`
    query GetUserByEmailForLogin($email: String!) {
  users: user(where: {email: {_eq: $email}}) {
    id
    email
    password
  }
}
    `;

/**
 * __useGetUserByEmailForLoginQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailForLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailForLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailForLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailForLoginQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserByEmailForLoginQuery, Types.GetUserByEmailForLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserByEmailForLoginQuery, Types.GetUserByEmailForLoginQueryVariables>(GetUserByEmailForLoginDocument, options);
      }
export function useGetUserByEmailForLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByEmailForLoginQuery, Types.GetUserByEmailForLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserByEmailForLoginQuery, Types.GetUserByEmailForLoginQueryVariables>(GetUserByEmailForLoginDocument, options);
        }
export type GetUserByEmailForLoginQueryHookResult = ReturnType<typeof useGetUserByEmailForLoginQuery>;
export type GetUserByEmailForLoginLazyQueryHookResult = ReturnType<typeof useGetUserByEmailForLoginLazyQuery>;
export type GetUserByEmailForLoginQueryResult = Apollo.QueryResult<Types.GetUserByEmailForLoginQuery, Types.GetUserByEmailForLoginQueryVariables>;
export const UserDocument = gql`
    query User($userId: Int!) {
  user: user(limit: 1, where: {id: {_eq: $userId}}) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<Types.UserQuery, Types.UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserQuery, Types.UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<Types.UserQuery, Types.UserQueryVariables>;
export const GetBasicUserByIdDocument = gql`
    query GetBasicUserById($userId: Int!) {
  user: user(limit: 1, where: {id: {_eq: $userId}}) {
    id
  }
}
    `;

/**
 * __useGetBasicUserByIdQuery__
 *
 * To run a query within a React component, call `useGetBasicUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBasicUserByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBasicUserByIdQuery, Types.GetBasicUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBasicUserByIdQuery, Types.GetBasicUserByIdQueryVariables>(GetBasicUserByIdDocument, options);
      }
export function useGetBasicUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBasicUserByIdQuery, Types.GetBasicUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBasicUserByIdQuery, Types.GetBasicUserByIdQueryVariables>(GetBasicUserByIdDocument, options);
        }
export type GetBasicUserByIdQueryHookResult = ReturnType<typeof useGetBasicUserByIdQuery>;
export type GetBasicUserByIdLazyQueryHookResult = ReturnType<typeof useGetBasicUserByIdLazyQuery>;
export type GetBasicUserByIdQueryResult = Apollo.QueryResult<Types.GetBasicUserByIdQuery, Types.GetBasicUserByIdQueryVariables>;
export const UserByRefreshTokenDocument = gql`
    query UserByRefreshToken($refreshToken: String!) {
  user: user(limit: 1, where: {refresh_token: {_eq: $refreshToken}}) {
    id
    token
    refreshToken: refresh_token
  }
}
    `;

/**
 * __useUserByRefreshTokenQuery__
 *
 * To run a query within a React component, call `useUserByRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByRefreshTokenQuery({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useUserByRefreshTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.UserByRefreshTokenQuery, Types.UserByRefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserByRefreshTokenQuery, Types.UserByRefreshTokenQueryVariables>(UserByRefreshTokenDocument, options);
      }
export function useUserByRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserByRefreshTokenQuery, Types.UserByRefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserByRefreshTokenQuery, Types.UserByRefreshTokenQueryVariables>(UserByRefreshTokenDocument, options);
        }
export type UserByRefreshTokenQueryHookResult = ReturnType<typeof useUserByRefreshTokenQuery>;
export type UserByRefreshTokenLazyQueryHookResult = ReturnType<typeof useUserByRefreshTokenLazyQuery>;
export type UserByRefreshTokenQueryResult = Apollo.QueryResult<Types.UserByRefreshTokenQuery, Types.UserByRefreshTokenQueryVariables>;
export const UserTokenByUserIdDocument = gql`
    query UserTokenByUserId($userId: Int!) {
  user: user(limit: 1, where: {id: {_eq: $userId}}) {
    id
    refreshToken: refresh_token
  }
}
    `;

/**
 * __useUserTokenByUserIdQuery__
 *
 * To run a query within a React component, call `useUserTokenByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTokenByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTokenByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTokenByUserIdQuery(baseOptions: Apollo.QueryHookOptions<Types.UserTokenByUserIdQuery, Types.UserTokenByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserTokenByUserIdQuery, Types.UserTokenByUserIdQueryVariables>(UserTokenByUserIdDocument, options);
      }
export function useUserTokenByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserTokenByUserIdQuery, Types.UserTokenByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserTokenByUserIdQuery, Types.UserTokenByUserIdQueryVariables>(UserTokenByUserIdDocument, options);
        }
export type UserTokenByUserIdQueryHookResult = ReturnType<typeof useUserTokenByUserIdQuery>;
export type UserTokenByUserIdLazyQueryHookResult = ReturnType<typeof useUserTokenByUserIdLazyQuery>;
export type UserTokenByUserIdQueryResult = Apollo.QueryResult<Types.UserTokenByUserIdQuery, Types.UserTokenByUserIdQueryVariables>;
export const GetUserByUsernameDocument = gql`
    query GetUserByUsername($username: String!) {
  users: user(limit: 1, where: {username: {_eq: $username}}) {
    username
  }
}
    `;

/**
 * __useGetUserByUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserByUsernameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserByUsernameQuery, Types.GetUserByUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserByUsernameQuery, Types.GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
      }
export function useGetUserByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByUsernameQuery, Types.GetUserByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserByUsernameQuery, Types.GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
        }
export type GetUserByUsernameQueryHookResult = ReturnType<typeof useGetUserByUsernameQuery>;
export type GetUserByUsernameLazyQueryHookResult = ReturnType<typeof useGetUserByUsernameLazyQuery>;
export type GetUserByUsernameQueryResult = Apollo.QueryResult<Types.GetUserByUsernameQuery, Types.GetUserByUsernameQueryVariables>;
export const InsertUserDocument = gql`
    mutation InsertUser($object: user_insert_input!) {
  insert_user(objects: [$object]) {
    returning {
      id
    }
  }
}
    `;
export type InsertUserMutationFn = Apollo.MutationFunction<Types.InsertUserMutation, Types.InsertUserMutationVariables>;

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertUserMutation, Types.InsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertUserMutation, Types.InsertUserMutationVariables>(InsertUserDocument, options);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<Types.InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<Types.InsertUserMutation, Types.InsertUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $changes: user_set_input) {
  UpdateUser: update_user(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;
export const UpdateUserTokenDocument = gql`
    mutation UpdateUserToken($id: Int!, $changes: user_set_input) {
  UpdateUser: update_user(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      email
      firstName: first_name
      lastName: last_name
      inviteAccepted: invite_accepted
      currentCompanyId: current_company_id
      currentCompany: current_company {
        isApproved: is_approved
        isEnabled: is_enabled
      }
      companyRoleUsersAggregate: company_role_users_aggregate(
        where: {company: {is_approved: {_eq: true}, is_enabled: {_eq: true}}, is_approved: {_eq: true}, is_enabled: {_eq: true}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;
export type UpdateUserTokenMutationFn = Apollo.MutationFunction<Types.UpdateUserTokenMutation, Types.UpdateUserTokenMutationVariables>;

/**
 * __useUpdateUserTokenMutation__
 *
 * To run a mutation, you first call `useUpdateUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTokenMutation, { data, loading, error }] = useUpdateUserTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserTokenMutation, Types.UpdateUserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserTokenMutation, Types.UpdateUserTokenMutationVariables>(UpdateUserTokenDocument, options);
      }
export type UpdateUserTokenMutationHookResult = ReturnType<typeof useUpdateUserTokenMutation>;
export type UpdateUserTokenMutationResult = Apollo.MutationResult<Types.UpdateUserTokenMutation>;
export type UpdateUserTokenMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserTokenMutation, Types.UpdateUserTokenMutationVariables>;
export const UpdateUserByTokenDocument = gql`
    mutation UpdateUserByToken($refreshToken: String!, $changes: user_set_input) {
  UpdateUser: update_user(
    where: {refresh_token: {_eq: $refreshToken}}
    _set: $changes
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateUserByTokenMutationFn = Apollo.MutationFunction<Types.UpdateUserByTokenMutation, Types.UpdateUserByTokenMutationVariables>;

/**
 * __useUpdateUserByTokenMutation__
 *
 * To run a mutation, you first call `useUpdateUserByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByTokenMutation, { data, loading, error }] = useUpdateUserByTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserByTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserByTokenMutation, Types.UpdateUserByTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserByTokenMutation, Types.UpdateUserByTokenMutationVariables>(UpdateUserByTokenDocument, options);
      }
export type UpdateUserByTokenMutationHookResult = ReturnType<typeof useUpdateUserByTokenMutation>;
export type UpdateUserByTokenMutationResult = Apollo.MutationResult<Types.UpdateUserByTokenMutation>;
export type UpdateUserByTokenMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserByTokenMutation, Types.UpdateUserByTokenMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Int!) {
  delete_user(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;
export const AllWebChatFormsDocument = gql`
    query AllWebChatForms {
  webChatForms: web_chat_form(order_by: {name: asc}) {
    ...WebChatFormFields
  }
  campaigns: campaign(order_by: {name: asc}) {
    id
    name
    company {
      id
      name
    }
  }
  offers: offer(order_by: {name: asc}) {
    id
    name
    isPrivate: is_private
    company {
      id
      name
    }
  }
  personas: persona(order_by: {first_name: asc}) {
    id
    firstName: first_name
    lastName: last_name
    username
    company {
      id
      name
    }
  }
}
    ${WebChatFormFieldsFragmentDoc}`;

/**
 * __useAllWebChatFormsQuery__
 *
 * To run a query within a React component, call `useAllWebChatFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWebChatFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWebChatFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWebChatFormsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllWebChatFormsQuery, Types.AllWebChatFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllWebChatFormsQuery, Types.AllWebChatFormsQueryVariables>(AllWebChatFormsDocument, options);
      }
export function useAllWebChatFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllWebChatFormsQuery, Types.AllWebChatFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllWebChatFormsQuery, Types.AllWebChatFormsQueryVariables>(AllWebChatFormsDocument, options);
        }
export type AllWebChatFormsQueryHookResult = ReturnType<typeof useAllWebChatFormsQuery>;
export type AllWebChatFormsLazyQueryHookResult = ReturnType<typeof useAllWebChatFormsLazyQuery>;
export type AllWebChatFormsQueryResult = Apollo.QueryResult<Types.AllWebChatFormsQuery, Types.AllWebChatFormsQueryVariables>;
export const AdminWebChatFormsDocument = gql`
    query AdminWebChatForms($limit: Int, $offset: Int) {
  webChatForms: web_chat_form(
    limit: $limit
    offset: $offset
    order_by: {name: asc}
  ) {
    ...WebChatFormFields
  }
  campaigns: campaign(order_by: {name: asc}) {
    id
    name
    company {
      id
      name
    }
  }
  offers: offer(order_by: {name: asc}) {
    id
    name
    isPrivate: is_private
    company {
      id
      name
    }
  }
  personas: persona(order_by: {first_name: asc}) {
    id
    firstName: first_name
    lastName: last_name
    username
    company {
      id
      name
    }
  }
  targetSites: target_site(order_by: {site_name: asc}) {
    id
    siteName: site_name
    company {
      id
      name
    }
  }
  companies: company(order_by: {name: asc}) {
    id
    name
  }
  web_chat_form_aggregate(order_by: {name: asc}) {
    aggregate {
      totalCount: count
    }
  }
}
    ${WebChatFormFieldsFragmentDoc}`;

/**
 * __useAdminWebChatFormsQuery__
 *
 * To run a query within a React component, call `useAdminWebChatFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWebChatFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWebChatFormsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdminWebChatFormsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdminWebChatFormsQuery, Types.AdminWebChatFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWebChatFormsQuery, Types.AdminWebChatFormsQueryVariables>(AdminWebChatFormsDocument, options);
      }
export function useAdminWebChatFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWebChatFormsQuery, Types.AdminWebChatFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWebChatFormsQuery, Types.AdminWebChatFormsQueryVariables>(AdminWebChatFormsDocument, options);
        }
export type AdminWebChatFormsQueryHookResult = ReturnType<typeof useAdminWebChatFormsQuery>;
export type AdminWebChatFormsLazyQueryHookResult = ReturnType<typeof useAdminWebChatFormsLazyQuery>;
export type AdminWebChatFormsQueryResult = Apollo.QueryResult<Types.AdminWebChatFormsQuery, Types.AdminWebChatFormsQueryVariables>;
export const WebChatFormsByFilterDocument = gql`
    query WebChatFormsByFilter($belongsToCompanyId: Int, $belongsToRoleAffiliateId: Int, $limit: Int, $offset: Int) {
  webChatForms: web_chat_form(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}, belongs_to_role_affiliate_id: {_eq: $belongsToRoleAffiliateId}}
    order_by: {name: asc}
  ) {
    ...WebChatFormFields
  }
  campaigns: campaign(order_by: {name: asc}) {
    id
    name
    company {
      id
      name
    }
  }
  offers: offer(order_by: {name: asc}) {
    id
    name
    isPrivate: is_private
    company {
      id
      name
    }
  }
  personas: persona(order_by: {first_name: asc}) {
    id
    firstName: first_name
    lastName: last_name
    username
    company {
      id
      name
    }
  }
  targetSites: target_site(order_by: {site_name: asc}) {
    id
    siteName: site_name
    company {
      id
      name
    }
  }
  companies: company(order_by: {name: asc}) {
    id
    name
  }
  web_chat_form_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}}
    order_by: {name: asc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${WebChatFormFieldsFragmentDoc}`;

/**
 * __useWebChatFormsByFilterQuery__
 *
 * To run a query within a React component, call `useWebChatFormsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebChatFormsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebChatFormsByFilterQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      belongsToRoleAffiliateId: // value for 'belongsToRoleAffiliateId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWebChatFormsByFilterQuery(baseOptions?: Apollo.QueryHookOptions<Types.WebChatFormsByFilterQuery, Types.WebChatFormsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WebChatFormsByFilterQuery, Types.WebChatFormsByFilterQueryVariables>(WebChatFormsByFilterDocument, options);
      }
export function useWebChatFormsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WebChatFormsByFilterQuery, Types.WebChatFormsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WebChatFormsByFilterQuery, Types.WebChatFormsByFilterQueryVariables>(WebChatFormsByFilterDocument, options);
        }
export type WebChatFormsByFilterQueryHookResult = ReturnType<typeof useWebChatFormsByFilterQuery>;
export type WebChatFormsByFilterLazyQueryHookResult = ReturnType<typeof useWebChatFormsByFilterLazyQuery>;
export type WebChatFormsByFilterQueryResult = Apollo.QueryResult<Types.WebChatFormsByFilterQuery, Types.WebChatFormsByFilterQueryVariables>;
export const WebChatFormByKeyDocument = gql`
    query webChatFormByKey($key: uuid!) {
  webChatForms: web_chat_form(limit: 1, where: {web_chat_form_key: {_eq: $key}}) {
    ...WebChatFormFields
  }
}
    ${WebChatFormFieldsFragmentDoc}`;

/**
 * __useWebChatFormByKeyQuery__
 *
 * To run a query within a React component, call `useWebChatFormByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebChatFormByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebChatFormByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useWebChatFormByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.WebChatFormByKeyQuery, Types.WebChatFormByKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WebChatFormByKeyQuery, Types.WebChatFormByKeyQueryVariables>(WebChatFormByKeyDocument, options);
      }
export function useWebChatFormByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WebChatFormByKeyQuery, Types.WebChatFormByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WebChatFormByKeyQuery, Types.WebChatFormByKeyQueryVariables>(WebChatFormByKeyDocument, options);
        }
export type WebChatFormByKeyQueryHookResult = ReturnType<typeof useWebChatFormByKeyQuery>;
export type WebChatFormByKeyLazyQueryHookResult = ReturnType<typeof useWebChatFormByKeyLazyQuery>;
export type WebChatFormByKeyQueryResult = Apollo.QueryResult<Types.WebChatFormByKeyQuery, Types.WebChatFormByKeyQueryVariables>;
export const UpdateWebChatFormDocument = gql`
    mutation UpdateWebChatForm($id: Int!, $changes: web_chat_form_set_input) {
  updateWebChatForm: update_web_chat_form(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateWebChatFormMutationFn = Apollo.MutationFunction<Types.UpdateWebChatFormMutation, Types.UpdateWebChatFormMutationVariables>;

/**
 * __useUpdateWebChatFormMutation__
 *
 * To run a mutation, you first call `useUpdateWebChatFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebChatFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebChatFormMutation, { data, loading, error }] = useUpdateWebChatFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateWebChatFormMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWebChatFormMutation, Types.UpdateWebChatFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWebChatFormMutation, Types.UpdateWebChatFormMutationVariables>(UpdateWebChatFormDocument, options);
      }
export type UpdateWebChatFormMutationHookResult = ReturnType<typeof useUpdateWebChatFormMutation>;
export type UpdateWebChatFormMutationResult = Apollo.MutationResult<Types.UpdateWebChatFormMutation>;
export type UpdateWebChatFormMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWebChatFormMutation, Types.UpdateWebChatFormMutationVariables>;
export const InsertWebChatFormDocument = gql`
    mutation InsertWebChatForm($web_chat_form: web_chat_form_insert_input!) {
  insert_web_chat_form(objects: [$web_chat_form]) {
    returning {
      id
    }
  }
}
    `;
export type InsertWebChatFormMutationFn = Apollo.MutationFunction<Types.InsertWebChatFormMutation, Types.InsertWebChatFormMutationVariables>;

/**
 * __useInsertWebChatFormMutation__
 *
 * To run a mutation, you first call `useInsertWebChatFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWebChatFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWebChatFormMutation, { data, loading, error }] = useInsertWebChatFormMutation({
 *   variables: {
 *      web_chat_form: // value for 'web_chat_form'
 *   },
 * });
 */
export function useInsertWebChatFormMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertWebChatFormMutation, Types.InsertWebChatFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertWebChatFormMutation, Types.InsertWebChatFormMutationVariables>(InsertWebChatFormDocument, options);
      }
export type InsertWebChatFormMutationHookResult = ReturnType<typeof useInsertWebChatFormMutation>;
export type InsertWebChatFormMutationResult = Apollo.MutationResult<Types.InsertWebChatFormMutation>;
export type InsertWebChatFormMutationOptions = Apollo.BaseMutationOptions<Types.InsertWebChatFormMutation, Types.InsertWebChatFormMutationVariables>;
export const DeleteWebChatFormDocument = gql`
    mutation DeleteWebChatForm($id: Int!) {
  delete_web_chat_form(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteWebChatFormMutationFn = Apollo.MutationFunction<Types.DeleteWebChatFormMutation, Types.DeleteWebChatFormMutationVariables>;

/**
 * __useDeleteWebChatFormMutation__
 *
 * To run a mutation, you first call `useDeleteWebChatFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebChatFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebChatFormMutation, { data, loading, error }] = useDeleteWebChatFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebChatFormMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWebChatFormMutation, Types.DeleteWebChatFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWebChatFormMutation, Types.DeleteWebChatFormMutationVariables>(DeleteWebChatFormDocument, options);
      }
export type DeleteWebChatFormMutationHookResult = ReturnType<typeof useDeleteWebChatFormMutation>;
export type DeleteWebChatFormMutationResult = Apollo.MutationResult<Types.DeleteWebChatFormMutation>;
export type DeleteWebChatFormMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWebChatFormMutation, Types.DeleteWebChatFormMutationVariables>;
export const WebChatConversationDocument = gql`
    query webChatConversation($conversationId: Int!) {
  conversation(where: {id: {_eq: $conversationId}}) {
    ...ConversationFields
  }
}
    ${ConversationFieldsFragmentDoc}`;

/**
 * __useWebChatConversationQuery__
 *
 * To run a query within a React component, call `useWebChatConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebChatConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebChatConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useWebChatConversationQuery(baseOptions: Apollo.QueryHookOptions<Types.WebChatConversationQuery, Types.WebChatConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WebChatConversationQuery, Types.WebChatConversationQueryVariables>(WebChatConversationDocument, options);
      }
export function useWebChatConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WebChatConversationQuery, Types.WebChatConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WebChatConversationQuery, Types.WebChatConversationQueryVariables>(WebChatConversationDocument, options);
        }
export type WebChatConversationQueryHookResult = ReturnType<typeof useWebChatConversationQuery>;
export type WebChatConversationLazyQueryHookResult = ReturnType<typeof useWebChatConversationLazyQuery>;
export type WebChatConversationQueryResult = Apollo.QueryResult<Types.WebChatConversationQuery, Types.WebChatConversationQueryVariables>;
export const WebChatConversationSubscriptionDocument = gql`
    subscription webChatConversationSubscription($conversationId: Int) {
  conversation_message(
    limit: 1
    order_by: {created_at: desc}
    where: {conversation_id: {_eq: $conversationId}}
  ) {
    id
    from
    messageText: message_text
    conversationId: conversation_id
    baseMessageId: base_message_id
    rawMessage: raw_message
    replyingTo: replying_to
    createdAt: created_at
    messageType: message_type
    created_at
    deliveryStatus: delivery_status
    errorCode: error_code
    errorMessage: error_message
    medias: conversation_message_media {
      media {
        id
        rank
        url
        isForSms: is_for_sms
        isForChat: is_for_chat
        mediaType: media_type
      }
    }
  }
}
    `;

/**
 * __useWebChatConversationSubscription__
 *
 * To run a query within a React component, call `useWebChatConversationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWebChatConversationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebChatConversationSubscription({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useWebChatConversationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.WebChatConversationSubscription, Types.WebChatConversationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.WebChatConversationSubscription, Types.WebChatConversationSubscriptionVariables>(WebChatConversationSubscriptionDocument, options);
      }
export type WebChatConversationSubscriptionHookResult = ReturnType<typeof useWebChatConversationSubscription>;
export type WebChatConversationSubscriptionSubscriptionResult = Apollo.SubscriptionResult<Types.WebChatConversationSubscription>;
export const AllWebPagesDocument = gql`
    query AllWebPages($belongsToCompanyId: Int!, $limit: Int, $offset: Int) {
  webPages: web_page(
    limit: $limit
    offset: $offset
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}}
    order_by: {id: desc}
  ) {
    ...WebPageFields
  }
  web_page_aggregate(
    where: {belongs_to_company_id: {_eq: $belongsToCompanyId}}
    order_by: {id: desc}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${WebPageFieldsFragmentDoc}`;

/**
 * __useAllWebPagesQuery__
 *
 * To run a query within a React component, call `useAllWebPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWebPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWebPagesQuery({
 *   variables: {
 *      belongsToCompanyId: // value for 'belongsToCompanyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllWebPagesQuery(baseOptions: Apollo.QueryHookOptions<Types.AllWebPagesQuery, Types.AllWebPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllWebPagesQuery, Types.AllWebPagesQueryVariables>(AllWebPagesDocument, options);
      }
export function useAllWebPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllWebPagesQuery, Types.AllWebPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllWebPagesQuery, Types.AllWebPagesQueryVariables>(AllWebPagesDocument, options);
        }
export type AllWebPagesQueryHookResult = ReturnType<typeof useAllWebPagesQuery>;
export type AllWebPagesLazyQueryHookResult = ReturnType<typeof useAllWebPagesLazyQuery>;
export type AllWebPagesQueryResult = Apollo.QueryResult<Types.AllWebPagesQuery, Types.AllWebPagesQueryVariables>;
export const WebPageByIdDocument = gql`
    query WebPageById($id: Int!) {
  webPage: web_page(where: {id: {_eq: $id}}, order_by: {id: desc}) {
    ...WebPageFields
  }
}
    ${WebPageFieldsFragmentDoc}`;

/**
 * __useWebPageByIdQuery__
 *
 * To run a query within a React component, call `useWebPageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebPageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebPageByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWebPageByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.WebPageByIdQuery, Types.WebPageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WebPageByIdQuery, Types.WebPageByIdQueryVariables>(WebPageByIdDocument, options);
      }
export function useWebPageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WebPageByIdQuery, Types.WebPageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WebPageByIdQuery, Types.WebPageByIdQueryVariables>(WebPageByIdDocument, options);
        }
export type WebPageByIdQueryHookResult = ReturnType<typeof useWebPageByIdQuery>;
export type WebPageByIdLazyQueryHookResult = ReturnType<typeof useWebPageByIdLazyQuery>;
export type WebPageByIdQueryResult = Apollo.QueryResult<Types.WebPageByIdQuery, Types.WebPageByIdQueryVariables>;
export const UpdateWebPageDocument = gql`
    mutation UpdateWebPage($id: Int!, $changes: web_page_set_input) {
  updateWebPage: update_web_page(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
  }
}
    `;
export type UpdateWebPageMutationFn = Apollo.MutationFunction<Types.UpdateWebPageMutation, Types.UpdateWebPageMutationVariables>;

/**
 * __useUpdateWebPageMutation__
 *
 * To run a mutation, you first call `useUpdateWebPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebPageMutation, { data, loading, error }] = useUpdateWebPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateWebPageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWebPageMutation, Types.UpdateWebPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWebPageMutation, Types.UpdateWebPageMutationVariables>(UpdateWebPageDocument, options);
      }
export type UpdateWebPageMutationHookResult = ReturnType<typeof useUpdateWebPageMutation>;
export type UpdateWebPageMutationResult = Apollo.MutationResult<Types.UpdateWebPageMutation>;
export type UpdateWebPageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWebPageMutation, Types.UpdateWebPageMutationVariables>;
export const InsertWebPageDocument = gql`
    mutation InsertWebPage($web_page: [web_page_insert_input!]!) {
  insert_web_page(objects: $web_page) {
    returning {
      id
    }
  }
}
    `;
export type InsertWebPageMutationFn = Apollo.MutationFunction<Types.InsertWebPageMutation, Types.InsertWebPageMutationVariables>;

/**
 * __useInsertWebPageMutation__
 *
 * To run a mutation, you first call `useInsertWebPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWebPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWebPageMutation, { data, loading, error }] = useInsertWebPageMutation({
 *   variables: {
 *      web_page: // value for 'web_page'
 *   },
 * });
 */
export function useInsertWebPageMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertWebPageMutation, Types.InsertWebPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertWebPageMutation, Types.InsertWebPageMutationVariables>(InsertWebPageDocument, options);
      }
export type InsertWebPageMutationHookResult = ReturnType<typeof useInsertWebPageMutation>;
export type InsertWebPageMutationResult = Apollo.MutationResult<Types.InsertWebPageMutation>;
export type InsertWebPageMutationOptions = Apollo.BaseMutationOptions<Types.InsertWebPageMutation, Types.InsertWebPageMutationVariables>;
export const DeleteWebPageDocument = gql`
    mutation DeleteWebPage($id: Int!) {
  delete_web_page(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteWebPageMutationFn = Apollo.MutationFunction<Types.DeleteWebPageMutation, Types.DeleteWebPageMutationVariables>;

/**
 * __useDeleteWebPageMutation__
 *
 * To run a mutation, you first call `useDeleteWebPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebPageMutation, { data, loading, error }] = useDeleteWebPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebPageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWebPageMutation, Types.DeleteWebPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWebPageMutation, Types.DeleteWebPageMutationVariables>(DeleteWebPageDocument, options);
      }
export type DeleteWebPageMutationHookResult = ReturnType<typeof useDeleteWebPageMutation>;
export type DeleteWebPageMutationResult = Apollo.MutationResult<Types.DeleteWebPageMutation>;
export type DeleteWebPageMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWebPageMutation, Types.DeleteWebPageMutationVariables>;