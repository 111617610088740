import * as dotenv from 'dotenv'

dotenv.config()

// const AppEnv = process.env.REACT_APP_ENV

// const NodeAppEnv = process.env.NODE_APP_ENV

// const hostname = window.location.hostname

// if (AppEnv === 'development') {
//   dotenv.config({ path: `.env.local` })
// } else {
//   dotenv.config({ path: `.env.${process.env.NODE_ENV}` })
// }

export const getTalkApiUrl = () => {
  return process.env.REACT_APP_HYDRA_TALK_API_URL
}

export const getTalkApiWebSocketUrl = () => {
  return process.env.REACT_APP_HYDRA_TALK_API_URL
  // return process.env.REACT_APP_HYDRA_TALK_WEBSOCKET_URL
}

export const getUserApiUrl = () => {
  return process.env.REACT_APP_HYDRA_USER_API_URL
}

export const getHasuraAdminSecret = () => {
  return process.env.REACT_APP_HASURA_ADMIN_SECRET
}

export const getHasuraApiUrl = () => {
  return process.env.REACT_APP_HYDRA_HASURA_API_URL
}

export const getHasuraApiWebSocketUrl = () => {
  return process.env.REACT_APP_HYDRA_HASURA_WEBSOCKET_URL
}

export const getCloudinarySettings = () => {
  return {
    cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  }
}

export const getStripePublicKey = () => {
  return process.env.REACT_APP_STRIPE_SECRET_KEY
}

export const getMetaBaseSettings = () => {
  return {
    metabase_site_url: process.env.REACT_APP_METABASE_SITE_URL,
    metabase_secret_key: process.env.REACT_APP_METABASE_SECRET_KEY,
  }
}
