import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/user-reducer'
import webChatReducer from './reducers/web-chat-reducer'

const reducers: any = {
  user: userReducer,
  webChat: webChatReducer,
}

export default configureStore({
  reducer: reducers,
})
