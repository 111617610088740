import React, {
  useEffect,
  lazy,
  useState,
  // useCallback,
  Suspense,
} from 'react'
import { Router } from 'react-router-dom'
// import ClearCache from 'react-clear-cache'
import { useClearCache } from 'react-clear-cache'
import { createBrowserHistory } from 'history'
import { useDispatch, useSelector } from 'react-redux'

// UI
import { Alert, Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
// import 'antd/dist/antd.min.css'
import styled from 'styled-components'

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/pro-solid-svg-icons'

// DB
import { withApollo, initApolloClient } from '../apollo'

// Utils
import {
  getFromLocalStorage,
  // setInLocalStorage,
  clearLocalStorage,
} from '../utils/local-storage-crud'
import { getCompanyByDomainQuery } from '../graphql/queries/companies-queries'
import {
  setCurrentCompanyDomain,
  setCurrentCompany,
  setCurrentCompanyFeatures,
  setCurrentUser,
  setCurrentCompanyRoleUser,
  setCurrentUserPermissions,
  getCurrentUser,
} from '../redux/reducers/user-reducer'

import CssReset from '../css/css-reset'

// Utils
import expressApi from '../settings-api'
import { setAccessToken } from '../utils/access-token'
import { useUpdateUserMutation } from '../generated/apollo-types'

const UserAuthenticated = lazy(() => import(/* webpackChunkName: "app" */ '../layout/UserAuthenticatedLayout'))
const UserUnauthenticated = lazy(() => import(/* webpackChunkName: "login" */ '../layout/UserUnauthenticatedLayout'))

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin rev={undefined} />

export const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon])

library.add(...iconList)

const history = createBrowserHistory()

const UpdateVersionContainer = styled.div`
  width: 400px;
  margin: auto;
  height: 100vh;
  padding-top: 10%;
  button {
    margin-top: 20px;
  }
`

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [company, setCompany] = useState(null)
  // const [, updateState] = useState()
  // const forceUpdate = useCallback(() => updateState(null), [])
  const user = getFromLocalStorage('user')
  const isIframe = window.location.search.includes('webChatIframe=true')

  const apollo = initApolloClient()

  const [updateUser] = useUpdateUserMutation()

  const onLogout = () => {
    setIsLoggedIn(false)
    if (currentUser) {
      updateUser({
        variables: {
          id: currentUser?.id,
          changes: {
            is_chatting: false,
          },
        },
      }).catch((error) => {
        console.error('Error logging out', error)
      })
    }
    expressApi.logout()
    setAccessToken('')
    clearLocalStorage()
    localStorage.removeItem('user')
    localStorage.removeItem('company')
    dispatch(setCurrentCompany(null))
    dispatch(setCurrentCompanyFeatures(null))
    dispatch(setCurrentUser(null))
    dispatch(setCurrentCompanyRoleUser(null))
    dispatch(setCurrentUserPermissions([]))
  }

  const onLogin = () => {
    setIsLoggedIn(true)
  }

  // useEffect(() => {
  //   if (company) {
  //     const newC = { ...company }
  //     delete newC['__typename']
  //     // setInLocalStorage('company', newC)
  //     forceUpdate()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [company])

  useEffect(() => {
    if (!user) {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
    }
    if (window.location.href.startsWith('http://') && !window.location.origin.includes('localhost')) {
      window.location.href = window.location.href.replace('http', 'https')
    }
    const domain = window.location.host || ''
    apollo
      .query({
        query: getCompanyByDomainQuery,
        variables: {
          domain: domain,
        },
      })
      .then((resp) => {
        const company = resp && resp.data ? resp.data.company[0] : null
        const defaultCompany = resp && resp.data ? resp.data.defaultCompany[0] : null
        if (company) {
          setCompany(company)
          dispatch(setCurrentCompanyDomain(company))
        } else {
          setCompany(defaultCompany)
          dispatch(setCurrentCompanyDomain(defaultCompany))
        }
      })
      .catch((error) => {
        console.error(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!company) {
    return <div />
  }

  const appContent = (
    <Suspense
      fallback={
        <>
          <Spin indicator={loadingIcon} />
          <h1>Loading...</h1>
        </>
      }
    >
      <Router history={history}>
        {isLoggedIn ? <UserAuthenticated onLogout={onLogout} /> : <UserUnauthenticated onLogin={onLogin} />}
      </Router>
    </Suspense>
  )

  return (
    <div>
      <CssReset />
      <div>
        {!isLatestVersion ? (
          <div>
            {isIframe ? (
              appContent
            ) : (
              <UpdateVersionContainer>
                <Alert
                  message="New version available"
                  description="You are using old version, Please click update version to update your app."
                  type="warning"
                  showIcon
                />
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    emptyCacheStorage()
                  }}
                >
                  Update Version
                </Button>
              </UpdateVersionContainer>
            )}
          </div>
        ) : (
          appContent
        )}
      </div>
    </div>
  )
}

export default withApollo(App)
