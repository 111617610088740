/* eslint-disable */
import axios from 'axios'
import * as AxiosLogger from 'axios-logger'

import { getUserApiUrl, getTalkApiUrl } from './settings'
import { getAccessToken, setAccessToken } from './utils/access-token'

// Functions to make local express api calls
const baseUrl = getUserApiUrl()

if (process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'development' && process.env.REACT_APP_ENV !== 'staging') {
  // Axios logger (logs axios requests & response to browser console only on localhost)
  AxiosLogger.setGlobalConfig({
    dateFormat: 'HH:MM:ss',
    status: false,
    headers: true,
    data: true,
    params: true,
  })
  axios.interceptors.request.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger)
  axios.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger)
}

// Request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken()
    if (accessToken) {
      config.headers['x-auth-token'] = accessToken
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response
  },

  function (error) {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      axios.defaults.withCredentials = true
      return axios.post(`${baseUrl}/hydra/auth/refresh_token`).then((res) => {
        if (res.status === 200) {
          setAccessToken(res.data.accessToken)
          return axios(originalRequest)
        }
      })
    }
    return Promise.reject(error)
  }
)

/* -----------------------------------------------------------------------------
/ User-Api Express API
/ -----------------------------------------------------------------------------*/

export const expressApi = {
  // Hydra Auth
  login: (body) => {
    axios.defaults.withCredentials = true
    return axios.post(`${baseUrl}/hydra/auth/login`, body)
  },
  refreshToken: () => {
    axios.defaults.withCredentials = true
    return axios.post(`${baseUrl}/hydra/auth/refresh-token`)
  },
  logout: () => {
    axios.defaults.withCredentials = true
    return axios.post(`${baseUrl}/hydra/auth/logout`)
  },

  // Cloudinary
  // Media Delete
  cloudinaryMediaDelete: (body) => {
    return axios.post(`${baseUrl}/cloudinary/delete`, body)
  },

  // Company
  approveCompany: (body) => {
    return axios.post(`${baseUrl}/hydra/company/approve`, body)
  },

  // Contact List
  contactListDelete: (body) => {
    return axios.post(`${baseUrl}/hydra/contact-list/delete`, body)
  },

  // Cloudflare Start
  // Confirm a custom hostname
  domainCFConfirm: (body) => {
    return axios.post(`${baseUrl}/cloudflare/hostname/confirmed`, body)
  },

  // Create a custom hostname
  domainCFCreate: (body) => {
    return axios.post(`${baseUrl}/cloudflare/hostname/create`, body)
  },

  // Delete domain
  domainCFDelete: (body) => {
    return axios.post(`${baseUrl}/cloudflare/hostname/delete`, body)
  },

  // Details of a domain
  domainCFDetails: (body) => {
    return axios.post(`${baseUrl}/cloudflare/hostname/details`, body)
  },
  // Cloudflare End

  // Hydra
  // Start Conversation
  hydraStartConversation: (body) => {
    return axios.post(`${baseUrl}/hydra/start-conversation`, body)
  },

  // Stripe
  stripeCustomerCreate: (body) => {
    return axios.post(`${baseUrl}/stripe/customer/create`, body)
  },
  stripeCustomerPortalCreateSession: (body) => {
    return axios.post(`${baseUrl}/stripe/customer-portal/create-session`, body)
  },

  stripeCustomerPortalCreateSubscription: (body) => {
    return axios.post(`${baseUrl}/stripe/customer-portal/create-subscription`, body)
  },

  stripePricesList: () => {
    return axios.get(`${baseUrl}/stripe/prices/list`)
  },
  stripeSubscriptionCreate: (body) => {
    return axios.post(`${baseUrl}/stripe/subscription/create`, body)
  },
  stripeSubscriptionUpdate: (body) => {
    return axios.post(`${baseUrl}/stripe/subscription/update`, body)
  },

  // Twilio
  // Start Conversation
  twilioSubAccountCreate: (body) => {
    return axios.post(`${baseUrl}/twilio/subaccount/create`, body)
  },
  twilioSubAccountDelete: (body) => {
    return axios.post(`${baseUrl}/twilio/subaccount/delete`, body)
  },
  twilioSubAccountSuspend: (body) => {
    return axios.post(`${baseUrl}/twilio/subaccount/suspend`, body)
  },
  twilioSubAccountUnsuspend: (body) => {
    return axios.post(`${baseUrl}/twilio/subaccount/unsuspend`, body)
  },

  // User
  forgotPassword: (body) => {
    return axios.post(`${baseUrl}/hydra/user/forgot-password`, body)
  },
  inviteUser: (body) => {
    return axios.post(`${baseUrl}/hydra/user/invite`, body)
  },
  approveUser: (body) => {
    return axios.post(`${baseUrl}/hydra/user/approve`, body)
  },
}

/* -----------------------------------------------------------------------------
/ Talk-Api Express API
/ -----------------------------------------------------------------------------*/

const chatBotApiUrl = getTalkApiUrl()
export const chatBotApi = {
  // WebChat
  webChatStartConversation: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/webchat/start-conversation`, body)
  },

  // Hydra
  // FIXME(KMK): Most of these below could be moved to Hydra Express backend

  // Persona Account Token Upload
  personaAccountTokenUpload: (body) => {
    return axios.post(`${chatBotApiUrl}/api/persona/account/token-upload`, body)
  },

  // Create Contact (Public flow start)
  publicContactCreate: (body) => {
    return axios.post(`${chatBotApiUrl}/api/contact/create`, body)
  },

  // Create Contact List (Private no flow start)
  publicAddMultipleContactsToList: (body, header) => {
    return axios.post(`${chatBotApiUrl}/private/api/contact-list/add-multiple-contacts`, body, header)
  },

  // Add Contact List to  Campaign Responder (Private starts added contacts list of campaign responder)
  privateAddContactListToCampaignResponder: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/campaign-responder/add-contact-list`, body)
  },

  // Enabled Campaign Responder (Private starts all contacts in lists of campaign responder)
  privateCampaignResponderEnable: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/campaign-responder/enable`, body)
  },

  // Disable Campaign Responder (Private removes all jobs in campaign responder queue)
  privateCampaignResponderDisable: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/campaign-responder/disable`, body)
  },

  // Create single Contact (Private no flow start)
  privateContactCreate: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/contact/create`, body)
  },
  // Create Contact (Private flow start)
  privateContactCreateMultiple: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/contact/create-multiple`, body)
  },
  // Check Domain DNS
  privateCheckDomainDns: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/hydra/check-domain-dns`, body)
  },

  // Send Message
  sendMessage: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/hydra/send-message`, body)
  },

  // Send Campaign to Contacts
  privateSendCampaignToContacts: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/hydra/send-campaign`, body)
  },
  // Create Offer Link
  createOfferLink: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/offer/create-link`, body)
  },

  // Twilio
  // Available Phone Number
  twilioAvailablePhoneNumbers: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/twilio/available-numbers`, body)
  },
  // Create Phone Number
  twilioCreatePhoneNumbers: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/twilio/create-numbers`, body)
  },
  // Delete Phone Number
  twilioDeletePhoneNumber: (body) => {
    return axios.post(`${chatBotApiUrl}/private/api/twilio/delete-number`, body)
  },
}

export default expressApi
