import { createGlobalStyle } from 'styled-components'
import theme from '../theme'

export default createGlobalStyle`
* {
    box-sizing: border-box;
}

html, body, div, ul, li, main, h1, h2, h3, h4,h5, h6 {
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.sourceSansPro};
	font-size: ${theme.fontSizes[2]}
}

h2, h3 {
    font-weight: 500
}

h3 {
	font-size: 22px;
}

a {
	text-decoration: none;
}

input,
label,
select,
button,
textarea
{
	margin:0;
	border:0;
	padding:0;
	display:inline-block;
	vertical-align:middle;
	white-space:normal;
	background:none;
	line-height:1;
	
}

/* Remove the stupid outer glow in Webkit */
input:focus
{
	outline:0;
}

/* Box Sizing Reset
-----------------------------------------------*/




/* Text Inputs
-----------------------------------------------*/

input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week]
{
}

/* Button Controls
-----------------------------------------------*/

input[type=checkbox],
input[type=radio]
{
	width:13px;
	height:13px;
}

/* File Uploads
-----------------------------------------------*/

input[type=file]
{

}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type=search]
{
	-webkit-appearance:textfield;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration
{
	display:none;
}


/* Textarea
-----------------------------------------------*/

textarea 
{
	/* Move the label to the top */
	vertical-align:top;
	
	/* Turn off scroll bars in IE unless needed */
	overflow:auto;
}

/* Selects
-----------------------------------------------*/

select
{

}

select[multiple] 
{
	/* Move the label to the top */
	vertical-align:top;
}
`
